import React from 'react';
import { StyleSheet, View, Text, TextInput} from 'react-native';
import {theme} from '../theme';

type Props = {
    name: string;
    setValue: Function;
    value: string;
};

const ProcesadoListItem = ({ name, setValue, value}:Props) => {   
    return(
        <View style={{flexDirection: 'row', justifyContent: 'center', marginBottom: 20}}>
            <View style={{ flex:1, flexDirection: 'row', alignSelf:'center'}}>
                <Text style={styles.text}>{name+': '}</Text>
                <TextInput
                    value={value}
                    placeholderTextColor={theme.colors.blue} 
                    style={styles.inputText} 
                    onChangeText={(text) => setValue(text)} 
                    maxLength={3}
                />
                <Text style={styles.textGrey}> (sobre 100)</Text>
            </View>
        </View>
    )
};

const styles = StyleSheet.create({
    text: {
        color: theme.colors.text,
        fontSize: 20,
        flex:1,
        fontFamily: theme.fonts.regular.fontFamily
    },
    textGrey: {
        color: theme.colors.grey,
        fontSize: 16,
        fontFamily: theme.fonts.regular.fontFamily,
        width: 200,
        flex:1,
        alignSelf: 'flex-end',
        textAlign: 'right'
    },
    inputText: {
        backgroundColor: 'transparent',
        color: theme.colors.blue,
        fontFamily: theme.fonts.regular.fontFamily,
        fontSize: 20,
        width: 40,
        flex:1
    },
});

export default ProcesadoListItem;

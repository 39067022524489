// !
import sappiScoreInterface from './sappiScoreInterface';

export default class SappiScoreModel {
    props: sappiScoreInterface;

    constructor(productData: sappiScoreInterface) {
        this.props = productData;
    }
    // SAPPI SCORE
    getSappiScore(): any {
        return this.props.sappiScore;
    }
    getPunctuation_process(): number {
        return this.props.sappiScore.process;
    }
    getPunctuation_composition(): number {
        return this.props.sappiScore.composition;
    }
    getyPunctuation_quality(): number {
        return this.props.sappiScore.quality;
    }
    // PROCESS
    getProcesses(): any {
        return this.props.process;
    }
    getProcesses_extrusionado(): any {
        return this.props.process.extrusionado;
    }
    getProcesses_humid(): any {
        return this.props.process.humid;
    }
    getProcesses_natural(): any {
        return this.props.process.natural;
    }
    getProcesses_prensado(): any {
        return this.props.process.prensado;
    }
    getProcesses_cocinado(): any {
        return this.props.process.cocinado;
    }
    getProcesses_deshidratado(): any {
        return this.props.process.deshidratado;
    }
    getProcesses_snack(): any {
        return this.props.process.snack;
    }
    // COMPOSITION
    getComposition_protein(): any {
        return this.props.composition.protein
    }
    getComposition_fat(): any {
        return this.props.composition.fat
    }
    getComposition_fiber(): any {
        return this.props.composition.fiber
    }
    getComposition_carbs(): any {
        return this.props.composition.carbs
    }
    getComposition_ash(): any {
        return this.props.composition.ash
    }
    //QUALITY
    getQuality_ingredients(): any[] {
        return this.props.quality.ingredients;
    }
    getQuality_values(): any {
        return this.props.quality.values;
    }
    getQuality_bonusFirstIngredient(): any {
        return this.props.quality.bonusFirstIngredient;
    }
    toObject(): sappiScoreInterface {
        const obj: sappiScoreInterface = this.props;
        return obj;
    }
}
import React from 'react';
import { useFonts } from 'expo-font';
import {theme} from '../theme';
import { useAppStore } from 'baseapp/context/appStore';

import QuicksandRegular from '../assets/fonts/Quicksand-Regular.ttf';
import QuicksandMedium from '../assets/fonts/Quicksand-Medium.ttf';
import QuicksandLight from '../assets/fonts/Quicksand-Light.ttf';
import QuicksandBold from '../assets/fonts/Quicksand-Bold.ttf';

export default () => {
    const {setFontsLoaded} = useAppStore();
    let [fontsLoaded, error] = useFonts({
        "Quicksand-Regular": QuicksandRegular,
        "Quicksand-Medium": QuicksandMedium,
        "Quicksand-Light": QuicksandLight,
        "Quicksand-Bold": QuicksandBold
    });


    React.useEffect(() => {
        console.log('fonts loaded: ', fontsLoaded);
        setFontsLoaded(fontsLoaded)
    }, [fontsLoaded]);
    return <></>
}
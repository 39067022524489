import React, { memo } from 'react';
import { theme } from '../../theme';
import { IconButton, Checkbox } from "react-native-paper";
import { Text, Platform, View } from "react-native";

const AppIntro7 = (props) => {
    return (
        <View style={{ justifyContent: 'center', flex: 1, backgroundColor: theme.colors.background}}>
        <View style={{ backgroundColor: theme.colors.background, width: '90%', maxWidth: 400, height: 500, alignSelf: 'center', justifyContent: 'center', borderRadius: 10 }}>
            <IconButton style={{ backgroundColor: theme.colors.primary, height: 130, width: 130, alignSelf: 'center', borderRadius: 200, justifyContent: 'center', alignItems: 'center' }}
                icon="shopping"
                size={75}
                color={theme.colors.background}/>
            <Text style={{ fontSize: 25, fontWeight: '700', textAlign: 'center', fontFamily: "Quicksand-Bold", marginTop: 20 }}>¡Sappi te busca el mejor precio!</Text>
            <Text style={{ fontSize: 25, fontWeight: '700', textAlign: 'center', fontFamily: "Quicksand-Light", marginTop: 20 }}>Gracias a nuestros algoritmos de búsqueda en tiempo real de ofertas, podemos ofrecerte la mejor experiencia de compra para la alimentación de tu peludo.</Text>
        </View>
    </View>
    )
}
export default memo(AppIntro7);

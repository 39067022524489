import React from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { Button, Dialog, Portal, Snackbar } from 'react-native-paper';
import { theme } from '../theme';
import SappiScoreModel from '../models/sappiScore/sappiScoreModel';
import { useSappiScoreStore } from '../context/sappiScoreContext';

type Props = {
    visibleUpload: boolean;
    hideUpload: Function;
    currentUser: any;
    sappiScoreChanging: SappiScoreModel;
};

const AdminUploadDialog = ({ visibleUpload, hideUpload, currentUser, sappiScoreChanging }: Props) => {
    const { updateSappiScore } = useSappiScoreStore();
    const [snackMsg, setSnackMsg] = React.useState<string>('');
    const [snackVisible, setSnackVisible] = React.useState<boolean>(false);

    const onUploadData = async () => {
        var error:boolean = false
        if (sappiScoreChanging?.getSappiScore()?.composition + sappiScoreChanging?.getSappiScore()?.process + sappiScoreChanging?.getSappiScore()?.quality != 100) { // Error sappiscore not 100%
            setSnackMsg('Error: Los valores asociados a los porcentajes del SappiScore deben sumar un total de 100%');
            error=true;
        }
        if (sappiScoreChanging?.getProcesses_extrusionado() > 100 || sappiScoreChanging?.getProcesses_humid() > 100
            || sappiScoreChanging?.getProcesses_natural() > 100 || sappiScoreChanging?.getProcesses_prensado() > 100
            || sappiScoreChanging?.getProcesses_cocinado() > 100 || sappiScoreChanging?.getProcesses_deshidratado() > 100
            || sappiScoreChanging?.getProcesses_snack() > 100 || sappiScoreChanging?.getProcesses_extrusionado() < 0 || sappiScoreChanging?.getProcesses_humid() < 0
            || sappiScoreChanging?.getProcesses_natural() < 0 || sappiScoreChanging?.getProcesses_prensado() < 0
            || sappiScoreChanging?.getProcesses_cocinado() < 0 || sappiScoreChanging?.getProcesses_deshidratado() < 0
            || sappiScoreChanging?.getProcesses_snack() < 0) {
            setSnackMsg('Error: Los valores asociados a las categorias de procesados deben tener valores comprendidos entre 0 y 100.');
            error=true;
        } // Error, some or one process hasn't have a good value compressed between 0 and 100

        if ((sappiScoreChanging?.getComposition_protein()?.value + sappiScoreChanging?.getComposition_fat()?.value
            + sappiScoreChanging?.getComposition_fiber()?.value + sappiScoreChanging?.getComposition_carbs()?.value +
            sappiScoreChanging?.getComposition_ash()?.value) != 100) { // Error, the sum of ingredients should be 100
            setSnackMsg('Error: Los valores asociados a los porcentajes de la composición debe sumar un total de 100');
            error=true;
            console.log("Error is true? ", error);
        }

        if ((sappiScoreChanging?.getQuality_values().tresIngredientes[2] + sappiScoreChanging?.getQuality_values().tresIngredientes[1]
            + sappiScoreChanging?.getQuality_values().tresIngredientes[0]) != 100) { // Error, the sum of ingredients should be 100
            setSnackMsg('Error: Los valores asociados a los porcentajes de bonus en alimento con 3 o más ingredientes deben sumar un total de 100%');
            error=true;
        }

        if ((sappiScoreChanging?.getQuality_values().dosIngredientes[1]
            + sappiScoreChanging?.getQuality_values().dosIngredientes[0]) != 100) { // Error, the sum of ingredients should be 100
            setSnackMsg('Error: Los valores asociados a los porcentajes de bonus en alimento con 2 ingredientes deben sumar un total de 100%');
            error=true;
        }
        if (!error) {
            setSnackMsg('Configuración guardada correctamente');
            updateSappiScore(currentUser, sappiScoreChanging);
            error=false;
        }
        setSnackVisible(true);
        hideUpload();
    }

    return (
        <Portal>
            <Dialog onDismiss={() => hideUpload()} visible={visibleUpload} style={{ width: 500, alignSelf: 'center' }}>
                <View style={{ width: '100%', alignSelf: 'center', padding: 40, flex: 5 }}>
                    <View>
                        <Text style={styles.paragraph}>Estás seguro que quieres guardar los cambios realizados?</Text>
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-around', width: '100%' }}>
                        <Button uppercase={false} onPress={() => hideUpload()} labelStyle={{ color: theme.colors.primary, fontFamily: theme.fonts.regular.fontFamily }} style={{ width: '40%' }}>Cancelar</Button>
                        <Button uppercase={false} onPress={() => onUploadData()} labelStyle={{ color: theme.colors.background, fontFamily: theme.fonts.regular.fontFamily }} style={{ backgroundColor: theme.colors.primary, borderRadius: 5, width: '40%' }}
                        >Guardar</Button>
                    </View>
                </View>
            </Dialog>
            <Snackbar
                visible={snackVisible}
                duration={3000}
                style={{ backgroundColor: theme.colors.text, alignSelf:'center', width: '70%'}}
                onDismiss={() => setSnackVisible(false)}
                action={{
                    label: 'Cerrar',
                    onPress: () => setSnackVisible(false),
                }}>
                {snackMsg}
            </Snackbar>
        </Portal>
    )
};

const styles = StyleSheet.create({
    paragraph: {
        color: theme.colors.text,
        fontSize: 18,
        lineHeight: 18,
        fontFamily: theme.fonts.light.fontFamily,
        marginBottom: 20
    },
});

export default AdminUploadDialog;

import Screens from 'internalapp/Screens'
import pluginPages from '../plugins/Pages';

const toScreensObject = (screens:any) => {
    return {
        screens: screens.map((screen:any)=>{
            return {
                ...screen,
                route: screen.route ? screen.route : screen.name
            }
        }),

        getScreen: (screenName:string) => screens.find((screen:any) => screen.name == screenName),
        getFallbackScreen: (authLevel?) => {
            return screens.find((screen:any) => screen.fallback && ((!authLevel && !screen.requireAuthLevel) || (screen.requireAuthLevel && screen.requireAuthLevel.find((lvl) => lvl == authLevel))))
        }
    }
}


export default toScreensObject(pluginPages.concat(Screens));
import { useFocusEffect, useRoute } from '@react-navigation/native';
import React, { memo, useState } from 'react';
import { View, Text, ScrollView, Image, StyleSheet, Platform } from 'react-native';
import { ArticleModel, ArticleCollection } from 'systemDomain';
import { useHeaderStore } from "baseapp/context/headerContext";
import EmptyMessage from '../components/EmptyMessage';
import { Card, ActivityIndicator } from 'react-native-paper';
import { useAppStore } from 'baseapp/context/appStore';
import ArticleApi from '../api/ArticleApi';
import { theme } from '../theme';
import moment from 'moment';
import { useNavigation } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';

const ArticlesTab = (props: any) => {
    const route = useRoute();
    const { setHeader } = useHeaderStore();
    const [articles, setArticles] = useState<ArticleCollection>(new ArticleCollection([]));
    const { session } = useAppStore();
    const currentUser = session.userInfo;
    const navigation = useNavigation();
    const isWeb = Platform.OS == 'web';
    const [loading, setLoading] = useState<boolena>(true);


    const onArticlePress = (article: ArticleModel) => {
        if (isWeb) {
            WebBrowser.openBrowserAsync(article?.get('link'));
        } else {
            navigation.push('article', { id: article?.getId() }); 
        }
    }

    const readArticles = async () => {
        try {
            const data: ArticleCollection = await ArticleApi.all(currentUser);
            // sort articles by ts
            const sortedData = new ArticleCollection(data.items().sort((a: ArticleModel, b: ArticleModel) => b.get('ts') - a.get('ts') ))
            setArticles(data);
        } catch (e) { }
    }

    useFocusEffect(
        React.useCallback(() => {
            setLoading(true);
            if (route.params?.p == 'articles') readArticles();
        }, [route])
    );

    useFocusEffect(
        React.useCallback(() => {
            setHeader("home", 'default');
        }, [route])
    )

    useFocusEffect(
        React.useCallback(() => {
            setLoading(false)
        }, [articles])
    );

    return (
        <View style={{ flex: 1 }}>
            {
                loading?
                <ActivityIndicator size='large' style={{flex:1, alignSelf:'center'}}/>
                :articles != undefined && articles?.items() != [] ?
                    <ScrollView style={{ height: 0 }} contentContainerStyle={{ paddingBottom: 20 }}>
                        {
                            articles?.items().map((article: ArticleModel, index: number) => (
                                <Card elevation={3} style={styles.card} key={index} onPress={() => onArticlePress(article)}>
                                    <View style={{ flex: 1 }}>
                                        <Image source={{ uri: article?.get('image') }} style={{ width: '100%', height: 300, flex: 1, borderTopLeftRadius: 12, borderTopRightRadius: 12 }} />
                                    </View>
                                    <View style={{ flex: 1, flexDirection: 'column', marginRight: 16, padding: 20 }}>
                                        <Text numberOfLines={2} style={styles.title}>{article?.get('title')}</Text>
                                        <Text numberOfLines={2} style={styles.subtitle}>{article?.get('subtitle')}</Text>
                                    </View>
                                </Card>
                            ))
                        }
                    </ScrollView>
                    : <EmptyMessage icon="book-outline" message="Aún no se han añadido artículos"></EmptyMessage>
            }
        </View>
    );
}
export default memo(ArticlesTab);
const styles = StyleSheet.create({
    card: {
        width: '90%',
        alignSelf: 'center',
        justifyContent: 'center',
        maxWidth: 700,
        borderRadius: 12,
        marginVertical: 20,
        borderWidth: 0.1,
        height: 300,
        borderColor: 'rgba(0,0,0,0.1)'
    },
    title: {
        fontWeight: '700',
        fontSize: 24,
        marginBottom: 20,
        color: 'black'
    },
    subtitle: {
        fontWeight: '500',
        fontSize: 16,
        marginBottom: 20,
        color: 'rgba(0,0,0,0.3)'
    },
    mainContainer: {
        flex: 1,
        marginTop: 40,
        backgroundColor: theme.colors.background,
        justifyContent: 'center',
        width: '100%',
        alignSelf: 'center'
    }
});
import React, { memo, useEffect } from "react";
import { StyleSheet, View, Platform } from 'react-native';
import Background from "../Background";
import { Text, FAB } from "react-native-paper";
import { theme } from "../theme";
import AdminOptions from "../components/AdminOptions";
import AdminFormula from "../components/AdminFormula";
import AdminPush from "../components/AdminPush";
import AdminProduct from "../components/AdminProduct";
import AdminArticles from "../components/AdminArticles";
import AdminUsers from "../components/AdminUsers";
import { useProductStore } from "../context/productContext";
import AdminUploadDialog from "../components/AdminUploadDialog";
import { useRoute } from "@react-navigation/native";
import { useHeaderStore } from "baseapp/context/headerContext";
import { useSappiScoreStore } from "../context/sappiScoreContext";
import AdminRestartDialog from "../components/AdminRestartDialog";
import { useAppStore } from 'baseapp/context/appStore';

const AdminScreen = (props) => {
    const { session } = useAppStore();
    const currentUser = session.userInfo;
    const route = useRoute();
    const { setHeader } = useHeaderStore();
    const { setCurrentProduct } = useProductStore()
    const { readSappiScore, sappiScoreChanging } = useSappiScoreStore();

    const [selectedMenu, setSelectedMenu] = React.useState<string>('formula');
    const [selectedFormula, setSelectedFormula] = React.useState<string>('composition');
    const [visibleRestart, setVisibleRestart] = React.useState(false);
    const [visibleUpload, setVisibleUpload] = React.useState(false);

    const showRestart = () => setVisibleRestart(true);
    const hideRestart = () => setVisibleRestart(false);
    const showUpload = () => setVisibleUpload(true);
    const hideUpload = () => setVisibleUpload(false);

    const cleanProduct = (selectedMenu: string) => {
        setCurrentProduct(undefined)
        setSelectedMenu(selectedMenu);
    }
    const mainContent = {
        'formula': () => <AdminFormula selectedFormula={selectedFormula} setSelectedFormula={setSelectedFormula} />,
        'products': () => <AdminProduct />,
        'users': () => <AdminUsers />,
        'articles': () => <AdminArticles />,
        'push': () => <AdminPush />
    }
    const FormulaButtons = () => (
        <>
            <FAB icon={'content-save-move'} style={styles.fab_save} color={'white'} onPress={() => showUpload()} />
            <FAB icon={'restart'} style={styles.fab_restart} color={'white'} onPress={() => showRestart()} />
        </>
    )

    useEffect(() => {
        readSappiScore(currentUser);
        if (currentUser.type != 'admin') props.navigation.navigate('home')
    }, []);
    useEffect(() => {
        setHeader("admin", "noHeader")
    }, [route])

    return (
        <Background>
            {Platform.OS == 'web'
                ?
                <>
                    < View style={{ flexDirection: 'row', flex: 1, width: '100%' }}>
                        {/* OPTIONS */}
                        <AdminOptions selectedMenu={selectedMenu} setSelectedMenu={cleanProduct} titleOptions={'Panel Admin'} />
                        {/* CONTENT */}
                        <View style={{ flex: 4, backgroundColor: theme.colors.background, paddingHorizontal: 10 }}>
                            {mainContent[selectedMenu]()}
                        </View>
                        {/* FAB BUTTONS */}
                        {selectedMenu == 'formula' ? <FormulaButtons /> : null}
                    </View>
                    {/* DIALOGS */}
                    <AdminUploadDialog currentUser={currentUser} sappiScoreChanging={sappiScoreChanging} visibleUpload={visibleUpload} hideUpload={hideUpload} />
                    <AdminRestartDialog currentUser={currentUser} sappiScoreChanging={sappiScoreChanging} visibleUpload={visibleRestart} hideRestart={hideRestart} />
                </>
                : <Text>Para utilizar el modo Administrador debes estar conectado en web</Text>}
        </Background >
    )
}
const styles = StyleSheet.create({
    fab_restart: {
        backgroundColor: 'orange',
        justifyContent: 'center',
        alignItems: 'center',
        width: 55,
        borderRadius: 50,
        bottom: 100,
        left: 20,
        position: 'absolute'
    },
    fab_save: {
        backgroundColor: 'green',
        justifyContent: 'center',
        alignItems: 'center',
        width: 55,
        borderRadius: 50,
        bottom: 20,
        left: 20,
        position: 'absolute'
    }
});
export default memo(AdminScreen);
import React, { useState, useEffect } from 'react';
import { StyleSheet, View, Text, Image, ScrollView } from 'react-native';
import { Button, Card, Portal, Dialog } from 'react-native-paper';
import { theme } from '../theme';
import ArticleApi from "../api/ArticleApi";
import { useAppStore } from 'baseapp/context/appStore';
import { ArticleModel, ArticleCollection } from 'systemDomain';
import moment from 'moment';
import MaterialCommunityIcons from '@expo/vector-icons/build/MaterialCommunityIcons';
import { Clipboard } from 'react-native';
import getEnvVars from '../environments';

type Props = {
    close: Function;
}
const AdminManageArticle = ({ close }: Props) => {

    const [articles, setArticles] = useState(new ArticleCollection([]));
    const { session } = useAppStore();
    const currentUser = session.userInfo;
    const [deleteDialogVisible, setDeleteDialogVisible] = useState<boolean>(false);
    const [selectedArticle, setSelectedArticle] = useState<ArticleModel>();

    const hideDialog = () => {
        setSelectedArticle(undefined);
        setDeleteDialogVisible(false);
    }
    
    const showDialog = () => setDeleteDialogVisible(true);

    const copyToClipboard = (url: string) => {
        Clipboard.setString(url);
    };

    const readArticles = async () => {
        try {
            const data: ArticleCollection = await ArticleApi.all(currentUser);
            const sortedData = new ArticleCollection(data.items().sort((a: ArticleModel, b: ArticleModel) => b.get('ts') - a.get('ts') ))
            setArticles(sortedData);
        } catch (e) { }
    }

    const onDelete = async (id: string) => {
        try {
            await ArticleApi.delete(currentUser, id);
            readArticles().then(() => hideDialog());
        } catch (e) { }
    }

    useEffect(() => {
        readArticles();
    }, [])

    return (
        <View style={styles.mainContainer}>
            <ScrollView style={{ height: 0, marginBottom: 30 }} contentContainerStyle={{ flex: 1, paddingBottom: 20 }}>
                {
                    articles.length() == 0 ?
                        <Text> Aun no se han añadido artículos</Text>
                        :
                        articles.items().map((article: ArticleModel, index) => (
                            <Card elevation={3} style={styles.card} key={index}>
                                <View style={{ flex: 1 }}>
                                    <Image source={{ uri: article?.get('image') }} style={{ width: '100%', height: 300, flex: 1, borderTopLeftRadius: 12, borderTopRightRadius: 12 }} />
                                </View>
                                <View style={{ flex: 1, flexDirection: 'column', marginRight: 16, padding: 20 }}>
                                    <Text numberOfLines={2} style={styles.title}>{article?.get('title')}</Text>
                                    <Text numberOfLines={4} style={styles.subtitle}>{article?.get('subtitle')}</Text>
                                    <Text numberOfLines={1} style={[styles.subtitle, { fontSize: 14 }]}>{moment(article?.get('ts')).format("DD/MM/YYYY")}</Text>
                                    <View style={{ flexDirection: 'row', alignContent: 'center' }}>
                                        <Text numberOfLines={1} style={[styles.subtitle, { marginRight: 8, textAlignVertical: 'center', fontWeight: '700' }]}>Obtener Link </Text>
                                        <MaterialCommunityIcons
                                            name={'content-copy'}
                                            color={'rgba(0,0,0,0.3)'}
                                            size={25}
                                            style={{ justifyContent: 'flex-end' }}
                                            onPress={() => { copyToClipboard(getEnvVars().apiUrl.split('/api')[0] + '/article/' + article?.getId()) }}
                                        />
                                    </View>
                                    <View style={{ flexDirection: 'row', alignContent: 'center' }}>
                                        <MaterialCommunityIcons
                                            name={'delete-outline'}
                                            color={'red'}
                                            size={25}
                                            style={{}}
                                            onPress={async () => { setSelectedArticle(article); showDialog()}}
                                        />
                                    </View>
                                </View>
                            </Card>
                        ))
                }
            </ScrollView >
            <Portal>
                <Dialog onDismiss={hideDialog} style={{ maxWidth: 600, alignSelf: 'center', padding: 30, width: '90%' }} visible={deleteDialogVisible}>
                    <Text style={styles.title}>Seguro que desea eliminar el artículo?</Text>
                    <View style={{ flexDirection: 'row', alignItems: 'center', alignSelf: 'center', alignContent: 'center' }}>
                        <Button mode='contained' onPress={() => {hideDialog()}} labelStyle={{ color: 'rgba(0,0,0,0.6)' }} style={{ backgroundColor: 'white', borderWidth: 0.1, marginRight: 20 }}>Cancelar</Button>
                        <Button mode='contained' onPress={() => onDelete(selectedArticle?.getId())} style={{ backgroundColor: 'red', marginLeft: 20 }}>Eliminar</Button>
                    </View>
                </Dialog>
            </Portal>
        </View>
    )
};

const styles = StyleSheet.create({
    card: {
        width: '100%',
        alignSelf: 'center',
        justifyContent: 'center',
        maxWidth: 700,
        borderRadius: 12,
        marginVertical: 20,
        borderWidth: 0.1,
        borderColor: 'rgba(0,0,0,0.1)'
    },
    title: {
        fontWeight: '700',
        fontSize: 24,
        marginBottom: 20,
        color: 'black'
    },
    subtitle: {
        fontWeight: '500',
        fontSize: 16,
        marginBottom: 20,
        color: 'rgba(0,0,0,0.3)'
    },
    mainContainer: {
        flex: 1,
        marginTop: 40,
        backgroundColor: theme.colors.background,
        justifyContent: 'center',
        width: '100%',
        alignSelf: 'center'
    }
});


export default AdminManageArticle;

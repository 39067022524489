import * as React from 'react';
import { Provider } from 'react-redux';
import configureStore from './store/store';
import FontsLoader from './components/FontsLoader';

const store = configureStore();
const App = (props) => (
    <Provider store={store}>
        <FontsLoader/>{props.children}
    </Provider>);

export default App;
import React from "react";
import { Platform } from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import { useEffect } from "react";
import { ProductModel } from "systemDomain";
import ProductApi from "../api/Product.api";
import ApiCaller from "baseapp/core/ApiCaller";
import ProductForm from "../components/ProductForm";
import ErrorMessage from "../components/ErrorMessage";
import Loading from "../components/Loading";
import { useAppStore } from 'baseapp/context/appStore';

type Props = {
    navigation: any;
    route: any;
};


const EditScreen = ({ navigation, route }: Props) => {
    //this form is intended to create new documents, so it receives default values for the fields
    //but it should also accept ProductModels, so the easiest is to check if it contains 'getId'.
    const { session } = useAppStore();
    const currentUser = session.userInfo;

    const productApi = new ProductApi(new ApiCaller(currentUser.token));
    const id = route.params?.id;
    const [isLoading, setIsLoading] = React.useState(true);
    const [product, setProduct] = React.useState<any>();
    const [isError, setIsError] = React.useState(false);
    const readId = async (id: string) => {
        try {
            const productItem = await productApi.read(id);
            setIsLoading(false);
            const data:any = productItem.toObject();

            setProduct(data);
        } catch (e) {
            setIsLoading(false);
            setIsError(true);
        }
    }

    React.useEffect(() => {
        if (currentUser.token && id) readId(id);
    }, [currentUser]);

    const updateProduct = async (productData: any) => {
        const product = new ProductModel(productData);
        try {
            const result: any = await productApi.update(product.getId(), product);
            navigation.push('item', {id:product.getId()});
        } catch (e) {
            alert("Error actualizando el producto: " + e);
        }

    }

    useEffect(() => {
        (async () => {
            if (Platform.OS !== 'web') {
                const { status } = await ImagePicker.requestCameraRollPermissionsAsync();
                if (status !== 'granted') {
                    alert('Sorry, we need camera roll permissions to make this work!');
                }
            }
        })();
    }, []);

    return (product
        ?<ProductForm caption="Guardar cambios" route={route} defaults={product} onSubmit={(productData:any) => { updateProduct(productData);}} ></ProductForm>
        : isLoading
        ? <Loading text="Cargando producto...."></Loading>
        : isError 
        ? <ErrorMessage submessage="Prueba a buscar cobertura" message="Error intentando leer el producto. Al menos lo hemos intentado."></ErrorMessage>
        : null)
}

export default EditScreen;
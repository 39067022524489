import React, { useState } from "react";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { theme } from '../theme';

export default (props) => {
    const [animals, setAnimals] = useState<string[]>([]);
    const hasAnimal = (animal: string) => animals.indexOf(animal) != -1;
    
    const toggleAnimal = (animal: string) => {
        const index = animals.indexOf(animal);
        const tmpAnimals = [...animals];
        if (index == -1) {
          tmpAnimals.push(animal);
        } else {
          tmpAnimals.splice(index, 1);
        }
        setAnimals(tmpAnimals);
        props.onValueChanged?props.onValueChanged(tmpAnimals):null;
    }

    return(
        <>
            <Text style={{ color: "grey", marginTop: 20, textAlign: "center", fontSize: 16 }}> Animal/es </Text>      
            <View style={{ height: 64, flexDirection: "row", justifyContent: "space-evenly" }}>
                <MaterialCommunityIcons onPress={() => toggleAnimal('dog')} name="dog" color={hasAnimal('dog') ? theme.colors.primary : 'grey'} size={64} />
                <MaterialCommunityIcons onPress={() => toggleAnimal('cat')} name="cat" color={hasAnimal('cat') ? theme.colors.primary : 'grey'} size={64} />
            </View>
        </>)
}
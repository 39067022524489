import React, { memo } from 'react';
import { StyleSheet, View, ScrollView } from 'react-native';
import ProductCard from './ProductCard';
import { ProductCollection } from 'systemDomain';

const ProductList = ({onScroll, products}:{onScroll: Function, products:ProductCollection}) => {
    return (
    <ScrollView style={{ flex: 1, paddingTop:1}} contentContainerStyle={{paddingTop:1}} scrollEventThrottle={1} onScroll={(e) => { onScroll(e.nativeEvent.contentOffset.y) }}>
        <View style={{ marginTop: 0, padding: 5,justifyContent: 'center', flexDirection: 'row', flexWrap: 'wrap', alignContent: 'flex-start' }}>
            {products.productArr.map((product) => {
                return <ProductCard
                    key={product.getId()} product={product}></ProductCard>
            })}
        </View>
    </ScrollView>
    )
};

const styles = StyleSheet.create({

});

export default memo(ProductList);

import CustomLinking from './plugins/Linking';
import * as ExpoLinking from 'expo-linking';
import Screens from './core/Screens';
import Conf from 'internalapp/conf';

const prefix = ExpoLinking.makeUrl('/');
const allPrefixes = [ ... [prefix], ... Conf.pushNotifications.additionalPrefixes];

const defaultLinking = {
    prefixes: allPrefixes,
    config: {
        screens: {
            app: {
                screens: Screens.screens.reduce((screens: any, screen: any) => {
                    screens[screen.name] = screen.route;
                    return screens;
                }, {})
            },
        }
    },
    enabled: true
}

var mergedLinkings = {};
CustomLinking.map((linking:any) => mergedLinkings = {... mergedLinkings, ...linking});

export const Linking = { ...defaultLinking, ...mergedLinkings };
import React, { memo } from 'react';
import { View, Text, TouchableHighlight } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { theme } from '../theme';
import { useAnalyticsStore } from '../context/analytics';
import { useAppStore } from 'baseapp/context/appStore';

type Props = {
    icon: string;
    iconText: string;
    toggleFilter: Function;
    selected: boolean;
    filter: string
};

const FilterIcon = ({ icon, iconText, selected, filter, toggleFilter }: Props) => {
    
    const {session} = useAppStore();
    const { AnalyticsLogEvent } = useAnalyticsStore();

    return (
        <TouchableHighlight underlayColor="transparent" onPress={() => {
            AnalyticsLogEvent("Filter Pressed", {"user":session.userInfo.email?session.userInfo.email:"guest", "filterType": filter});
            toggleFilter(filter)
        }} style={{ alignItems: 'center', justifyContent: 'center' }} >
            <View style={{ marginHorizontal: 15, alignSelf: 'center', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                <View style={{ width: 50, height: 50, backgroundColor: selected ? theme.colors.primary : theme.colors.noSelected, justifyContent: 'center', borderRadius: 50, marginBottom: 5 }}>
                    <MaterialCommunityIcons
                        name={icon}
                        color={theme.colors.background}
                        size={25}
                        style={{ alignSelf: 'center' }}
                    />
                </View>
                <Text style={{ textAlign: 'center', color: selected ? theme.colors.primary : theme.colors.noSelected, fontSize: 14, fontFamily: theme.fonts.thin.fontFamily }}>{iconText}</Text>

            </View>
        </TouchableHighlight>
    )
};

export default memo(FilterIcon);

import React, { memo } from 'react';
import { theme } from '../../theme';
import { IconButton } from "react-native-paper";
import { Text, View } from "react-native";

const AppIntro2 = () => {
    return (
        <View style={{ justifyContent: 'center', flex: 1, backgroundColor: theme.colors.background }}>
            <View style={{ backgroundColor: theme.colors.background, width: '90%', maxWidth: 400, height: 500, alignSelf: 'center', justifyContent: 'center', borderRadius: 10, padding: 15 }}>
                <IconButton style={{ backgroundColor: theme.colors.primary, height: 130, width: 130, alignSelf: 'center', borderRadius: 200, justifyContent: 'center', alignItems: 'center', marginTop: 100 }}
                    icon="barcode-scan"
                    size={75}
                    color={theme.colors.background} />
                <Text style={{ fontSize: 25, fontWeight: '700', textAlign: 'center', fontFamily: "Quicksand-Bold", marginTop: 50 }}>Botón de escanear</Text>
                <Text style={{ fontSize: 25, fontWeight: '700', textAlign: 'center', fontFamily: "Quicksand-Light", marginTop: 10 }}>Con este botón podrás consultar la información de un producto a partir de escanear su código de barras.</Text>
                <Text style={{ fontSize: 25, fontWeight: '700', textAlign: 'center', fontFamily: "Quicksand-Light", marginTop: 10 }}>Si no encuentras un producto con el escáner, prueba en la página Buscar.</Text>
                <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 40 }}>

                </View>
            </View>
        </View>
    )
}
export default memo(AppIntro2);

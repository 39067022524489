import * as React from 'react';
import LoginSocialApi from "../../../api/LoginSocialApi"
import User from '../../../models/user';
import { Alert, Platform, View, Image } from 'react-native';
import Settings from '../../../core/settings';
import { useAppStore } from '../../../context/appStore';
import { useNavigation, CommonActions } from '@react-navigation/native';
import { Button } from 'react-native-paper';
import { theme } from 'internalapp/theme';
import conf from 'internalapp/conf';
import * as Google from 'expo-auth-session/providers/google';
import * as WebBrowser from 'expo-web-browser';

const SocialLogin = () => {
    const apiIds = conf.socialLogin.apiIds;

    const apiIdsNoAndroid = {
        ...apiIds,
        responseType: "id_token"
    }
    WebBrowser.maybeCompleteAuthSession();
    const [authentication, setAuthentication] = React.useState() // response from GoogleSignIn Server
    const [request, response, promptAsync] = Google.useAuthRequest(Platform.OS != "web" ? apiIds : apiIdsNoAndroid); // functions from GoogleSignIn API
    const { setSession } = useAppStore();
    const navigation = useNavigation();

    const onSocialLoginPress = () => {
        promptAsync();
    }


    const sendToken = async (token: string) => {
        let res = await LoginSocialApi.sendToken(token)
        return res
    }

    const getTokenId = (data: any) => (data?.params ? data.params?.id_token : '');

    React.useEffect(() => { // UseEffect Looking up for GoogleSignIn response
        (response?.type === 'success') ? setAuthentication(response) : null;
    }, [response]);

    const resetStackNavigator = (routeName: string) => {
        const resetAction = CommonActions.reset({
            index: 1,
            routes: [{ name: routeName }]
        });
        navigation.dispatch(resetAction);
    }

    const createNewSocialLoginUser = () => {
        try {
            sendToken(getTokenId(authentication)).then((data: any) => {
                let user = new User(data.email, data.token, data.type, data.status);
                setSession(user, 'socialLogin');
                Settings.setCurrentUser(user).then(() => {
                    resetStackNavigator('home');
                });
            })
        } catch (e) {
            (Platform.OS == 'web') ?
                alert("Problema con el login de Google") :
                Alert.alert("", "Problema con el login de Google", [{ text: "OK", onPress: () => console.log("OK Pressed") }], { cancelable: false });
        }
    }
    React.useEffect(() => {
        (authentication) ? createNewSocialLoginUser() : null;
    }, [authentication]);


    return (
            <Button
                style={{ marginTop: 30, backgroundColor: theme.colors.background, flex: 1, borderRadius: 7, borderWidth:2, borderColor: theme.colors.primary, justifyContent: 'center' }}
                disabled={!request}
                mode='contained'
                uppercase={false}
                icon={()=><Image source={{uri:'https://rotulosmatesanz.com/wp-content/uploads/2017/09/2000px-Google_G_Logo.svg_.png'}} style={{width:26, height:26}}/>}
                labelStyle={{
                    top: -2,
                    fontFamily: theme.fonts.medium.fontFamily,
                    fontWeight: 'bold',
                    fontSize: 15,
                    lineHeight: 26,
                    color: theme.colors.primary,
                    flex: 1
                }}
                onPress={() => onSocialLoginPress()}
            >
                Login con Google
            </Button>
        );
}
export default React.memo(SocialLogin);
import React, { memo, useEffect } from "react";
import { View, StyleSheet, Platform, Image } from 'react-native';
import { theme } from "../theme";
import { TextInput, Text, Divider, Snackbar, Button } from "react-native-paper";

import { MaterialCommunityIcons } from '@expo/vector-icons';
import { ScrollView } from "react-native-gesture-handler";
import Background from "../Background";
import { Picker } from "@react-native-picker/picker";
import Additives from "./Additives";
import AdditivesApi from "../api/AdditivesApi";
import ApiCaller from "baseapp/core/ApiCaller";
import BaseButton from 'baseapp/components/Button';
import ProductApi from "../api/Product.api";
import NewProductApi from "../api/ProductApi";
import { useProductStore } from "../context/productContext";
import { useAppStore } from 'baseapp/context/appStore';
import * as ImagePicker from 'expo-image-picker';
import getEnvVars, { getMode } from "../environments";

const ProductFormAdmin = ({ defaults, caption, onSubmit, onClose, wide }: { defaults: any, caption: string, onSubmit: Function, onClose?: Function,wide:boolean }) => {
    const { session } = useAppStore();
    const currentUser = session.userInfo;

    if (defaults && defaults.getId) {
        defaults = defaults.toObject();
    }

    if (!defaults) defaults = {};

    if (defaults.aditives && defaults.aditives.join) {
        defaults.aditives = defaults.aditives.join(',');
    }

    if (defaults && !defaults.analysis_nut) {
        defaults.analysis_nut = {};
    } else if (defaults && defaults.analysis_nut) {
        defaults.analysis_nut.protein = "" + (defaults.analysis_nut.protein != undefined ? defaults.analysis_nut.protein : "");
        defaults.analysis_nut.carbs = "" + (defaults.analysis_nut.carbs != undefined ? defaults.analysis_nut.carbs : "");
        defaults.analysis_nut.fiber = "" + (defaults.analysis_nut.fiber != undefined ? defaults.analysis_nut.fiber : "");
        defaults.analysis_nut.ash = "" + (defaults.analysis_nut.ash != undefined ? defaults.analysis_nut.ash : "");
        defaults.analysis_nut.humidity = "" + (defaults.analysis_nut.humidity != undefined ? defaults.analysis_nut.humidity : "");
        defaults.analysis_nut.fat = "" + (defaults.analysis_nut.fat != undefined ? defaults.analysis_nut.fat : "");
    }

    const [productData, setProductData] = React.useState<any>();
    const [error, setError] = React.useState<any>({});
    const [productAdditives, setProductAdditives] = React.useState<string[]>((defaults.aditives && defaults.aditives.split) ? defaults.aditives.split(',') : []);
    const [allAdditives, setAllAdditives] = React.useState<any>();
    const productApi = new ProductApi(new ApiCaller(currentUser.token));
    const { currentProduct } = useProductStore();
    const [firstIngredient, setFirstIngredient] = React.useState<any>();
    const [secondIngredient, setSecondIngredient] = React.useState<string>();
    const [thirdIngredient, setThirdIngredient] = React.useState<string>();
    const [displayClose, setDisplayClose] = React.useState<boolean>(false);
    const [base64Image, setBase64Image] = React.useState<string>("");
    const [imageId, setImageId] = React.useState<string>("");

    const [visibleSnackbar, setVisibleSnackbar] = React.useState<boolean>(false);

    React.useEffect(() => {
        const defaultFirstIngredient: object = {
            name: '',
            percent: ''
        }
        const defaultSecondIngredient = '';
        const defaultThirdIngredient = '';

        !productData?.bonus ? setFirstIngredient(defaultFirstIngredient) : setFirstIngredient(productData?.bonus);
        !productData?.secondIngredient ? setSecondIngredient(defaultSecondIngredient) : setSecondIngredient(productData?.secondIngredient);
        !productData?.thirdIngredient ? setThirdIngredient(defaultThirdIngredient) : setThirdIngredient(productData?.thirdIngredient);

        setProductAdditives((defaults.aditives && defaults.aditives.split) ? defaults.aditives.split(',') : [])
    }, [productData]);

    React.useEffect(() => {

        const loadAdditives = async () => {
            const additivesApi = new AdditivesApi(new ApiCaller());
            const additives = await additivesApi.getAdditives();
            const formattedAdditives = additives.map((additive: string) => {
                return { id: additive, title: additive }
            });
            setAllAdditives(formattedAdditives);
        }
        loadAdditives();

        if (onClose) {
            setDisplayClose(true);
        }
    }, []);

    const readId = async () => {
        try {
            var productItem = await productApi.read(currentProduct?.getId());
            if (!productItem.toObject().hasOwnProperty("shopLink")) {
                productItem.toObject().shopLink = ['', ''];
            }
            setProductData(productItem.toObject());
            setImageId(productItem.toObject().picture)
        } catch (e) {
            console.error('Error leyendo producto: ', e)
        }
    }

    const pickImage = async () => {
        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsEditing: true,
            quality: 1,
            base64: true
        });
        if (!result.cancelled) {
            var data: any = result;
            if (data.base64) {
                data = 'data:image/jpeg;base64,' + data.base64;
                setBase64Image(data);
            } else {
                data = data.uri;
            }
            // try {
            //     const filename: string = await NewProductApi.uploadImageToServer(currentUser, data);
            //     setImageId(filename)
            //     console.log('filename: ', filename)
            // } catch (e) { console.error('Error uploading image. Error: ', e) }
        }
    };

    const getImageSource = () => {
        if (base64Image != "") {
            console.log(base64Image)
            return ({ uri: base64Image })
        }
        else {
            const data = imageId ? `/public/images/${imageId}` : null
            console.log("Image link: " + data)
            return data;
        }
    }


    useEffect(() => {
        readId();
    }, [currentProduct]);

    useEffect(() => {
        (async () => {
            if (Platform.OS !== 'web') {
                const { status } = await ImagePicker.requestCameraRollPermissionsAsync();
                if (status !== 'granted') {
                    alert('Sorry, we need camera roll permissions to make this work!');
                }
            }
        })();
    }, []);



    const _onAddPressed = async () => {
        // Add first ingredient prop only if it is not empty!
        var tmpProductData = productData;
        if (firstIngredient) {
            var tmpFirstIngredient = firstIngredient;
            //ts-ignore
            if (tmpFirstIngredient['percent'] != '') {
                tmpFirstIngredient['percent'] = parseFloat(tmpFirstIngredient["percent"]);
                tmpProductData['bonus'] = tmpFirstIngredient;
            }
            if (tmpFirstIngredient['percent'] == '' || tmpFirstIngredient['name'] == '') {
                delete tmpProductData.bonus
            }
        }
        if (secondIngredient != '') {
            tmpProductData['secondIngredient'] = secondIngredient;
        }
        else { delete tmpProductData.secondIngredient }
        if (thirdIngredient) {
            tmpProductData['thirdIngredient'] = thirdIngredient
        }
        else { delete tmpProductData.thirdIngredient }

        if (tmpProductData['shopLink'] == []) delete tmpProductData['shopLink']

        var localError: any = {};
        if (!tmpProductData.picture) return alert("Por favor, selecciona una foto para agregar el producto");
        tmpProductData.aditives = productAdditives;
        if (tmpProductData.barcodes) {
            try {
                tmpProductData.barcodes = tmpProductData.barcodes?.split(',')
            } catch (e) { }
        }
        if ( tmpProductData.barcodes && tmpProductData.barcodes.length == 0) delete tmpProductData.barcodes;

        if (!tmpProductData.animal) return alert('Por favor, selecciona el tipo de animal');
        if (!tmpProductData.food_type) return alert('Por favor, selecciona el tipo de comida');
        //validate
        if (!tmpProductData.name) localError.name = true;
        if (!tmpProductData.company) localError.company = true;
        // if (!tmpProductData.ingredients)
        if (Number.isNaN(tmpProductData.analysis_nut.protein)) localError.protein = true;
        if (Number.isNaN(tmpProductData.analysis_nut.fat)) localError.fat = true;
        if (Number.isNaN(tmpProductData.analysis_nut.humidity)) localError.humidity = true;
        if (Number.isNaN(tmpProductData.analysis_nut.ash)) localError.ash = true;
        if (Number.isNaN(tmpProductData.analysis_nut.fiber)) localError.fiber = true;

        const an = tmpProductData.analysis_nut;
        if (!an.carbs || Number.isNaN(an.carbs)) {
            if (!localError.fiber && !localError.ash && !localError.humidity
                && !localError.fat && !localError.protein) {
                tmpProductData.analysis_nut.carbs = parseFloat((100 - an.protein - an.fat - an.humidity - an.ash).toFixed(1));
                tmpProductData.analysis_nut.carbs = tmpProductData.analysis_nut.carbs < 0 ? 0 : tmpProductData.analysis_nut.carbs; // set carbs to 0 if negative
            }
        }
        if (Object.keys(localError).length) {
            setError(localError);
            return;
        }
        tmpProductData.analysis_nut.protein = parseFloat(tmpProductData.analysis_nut.protein);
        tmpProductData.analysis_nut.fat = parseFloat(tmpProductData.analysis_nut.fat);
        tmpProductData.analysis_nut.fiber = parseFloat(tmpProductData.analysis_nut.fiber);
        tmpProductData.analysis_nut.ash = parseFloat(tmpProductData.analysis_nut.ash);
        tmpProductData.analysis_nut.humidity = parseFloat(tmpProductData.analysis_nut.humidity);
        tmpProductData.analysis_nut.carbs = parseFloat(tmpProductData.analysis_nut.carbs ?? null);

        try {
            if (base64Image != "") {
                const filename: string = await NewProductApi.uploadImageToServer(currentUser, base64Image);
                setImageId(filename)
                console.log('filename: ', filename)
                tmpProductData.picture = filename;
            }
            setVisibleSnackbar(true);
            setProductData(tmpProductData);
            onSubmit(tmpProductData);
        } catch (e) { console.error('Error uploading image. Error: ', e) }



    };

    return (
        <Background>
            <View style={{ backgroundColor: theme.colors.background, flex: 1, margin: 20, shadowRadius: 8, shadowOpacity: 0.2, elevation: 5, borderRadius: 10, width: wide?"70%":"auto" }}>
                <ScrollView
                    contentContainerStyle={{ flexDirection: "column", padding: 40, }}
                    style={{ height: 0 }}
                    scrollEventThrottle={1}
                >
                    {displayClose ?
                        <MaterialCommunityIcons
                            name="close"
                            color={theme.colors.text}
                            size={25}
                            style={{ position: "absolute", top: 20, right: 20 }}
                            onPress={() => onClose()}
                        /> : null}
                    <View style={{flexDirection:"row"}}>
                        
                        <View style={{flex:1}}>
                            <TextInput
                                label="Marca"
                                returnKeyType="next"
                                value={productData?.company}
                                onChangeText={(text: string) => setProductData({ ...productData, company: text })}
                                style={styles.entry}
                                error={error['company']}
                            />
                            <TextInput
                                label="Nombre del producto"
                                returnKeyType="next"
                                value={productData?.name}
                                onChangeText={(text: string) => setProductData({ ...productData, name: text })}
                                style={styles.entry}
                                error={error['name']}
                            />
                            <TextInput
                                label="Link de compra 1 (opcional)"
                                returnKeyType="next"
                                value={productData?.shopLink ? productData?.shopLink[0] : ""}
                                onChangeText={(text: string) => {
                                    let newShopLink = productData?.shopLink;
                                    newShopLink[0] = text;
                                    setProductData({ ...productData, shopLink: newShopLink })
                                }}
                                style={styles.entry}
                            />
                            <TextInput
                                label="Link de compra 2 (opcional)"
                                returnKeyType="next"
                                value={productData?.shopLink ? productData?.shopLink[1] : ""}
                                onChangeText={(text: string) => {
                                    let newShopLink = productData?.shopLink;
                                    newShopLink[1] = text;
                                    setProductData({ ...productData, shopLink: newShopLink })
                                }}
                                style={styles.entry}
                            />
                        </View>
                        <View style={{ flex:1, flexDirection: "column", alignItems: "center" }}>
                            <Text style={{ fontSize: 16, lineHeight: 18, marginBottom: 10 }}>Imagen Producto: </Text>
                            <Image
                                style={styles.productImage}
                                source={getImageSource()}
                            />
                            <Button
                                theme={theme}
                                style={{}}
                                labelStyle={{ justifyContent: "center" }}
                                icon="camera"
                                mode="outlined"
                                onPress={() => pickImage()}

                            >Subir Imagen</Button>

                        </View>
                    </View>
                    <View style={{ flexDirection: "row", justifyContent: 'space-between' }}>
                        <Picker
                            selectedValue={productData?.animal}
                            onValueChange={(text: any) => setProductData({ ...productData, animal: text })}
                            style={styles.selectBox}
                            mode="dropdown">
                            <Picker.Item label="Animal" />
                            <Picker.Item label="Gatos" value="cat" />
                            <Picker.Item label="Perros" value="dog" />
                            <Picker.Item label="Perros y Gatos" value="dog cat" />
                        </Picker>
                        <Picker
                            selectedValue={productData?.production_process}
                            onValueChange={(text: any) => setProductData({ ...productData, production_process: text })}
                            style={styles.selectBox}
                            mode="dropdown">
                            <Picker.Item label="Procesado" />
                            <Picker.Item label="Extrusionado" value="extrusionado" />
                            <Picker.Item label="Prensado en frío" value="prensado" />
                            <Picker.Item label="Húmedo Pasteurizado" value="humedo" />
                            <Picker.Item label="Cocinado a bajas temperaturas" value="cocinado" />
                            <Picker.Item label="Natural congelado" value="natural" />
                            <Picker.Item label="Snack" value="snack" />
                            <Picker.Item label="Deshidratado" value="deshidratado" />
                        </Picker>
                        <Picker
                            selectedValue={productData?.food_type}
                            onValueChange={(text: any) => setProductData({ ...productData, food_type: text })}
                            style={styles.selectBox}
                            mode="dropdown">
                            <Picker.Item label="Tipo" />
                            <Picker.Item label="Pienso" value="dry" />
                            <Picker.Item label="Latas o Húmedo" value="humid" />
                            <Picker.Item label="Natural" value="natural" />
                            <Picker.Item label="Premio" value="snack" />
                        </Picker>
                    </View>
                    <View style={{ flexDirection: "row", justifyContent: 'space-between' }}>
                        <TextInput
                            label="Proteína bruta"
                            returnKeyType="next"
                            value={productData?.analysis_nut?.protein}
                            onChangeText={(text: string) => setProductData({ ...productData, analysis_nut: { ...productData?.analysis_nut, protein: text } })}
                            style={styles.entryPicker}
                            error={error['protein']}
                            keyboardType="numeric"
                        />
                        <TextInput
                            label="Grasa"
                            returnKeyType="next"
                            value={productData?.analysis_nut?.fat}
                            onChangeText={(text: string) => setProductData({ ...productData, analysis_nut: { ...productData?.analysis_nut, fat: text } })}
                            style={styles.entryPicker}
                            error={error['fat']}
                            keyboardType="numeric"
                        />
                        <TextInput
                            label="Fibra"
                            returnKeyType="next"
                            value={productData?.analysis_nut?.fiber}
                            onChangeText={(text: string) => setProductData({ ...productData, analysis_nut: { ...productData?.analysis_nut, fiber: text } })}
                            style={styles.entryPicker}
                            error={error['fiber']}
                            keyboardType="numeric"
                        />
                    </View>
                    <View style={{ flexDirection: "row", justifyContent: 'space-between' }}>
                        <TextInput
                            label="Carbohidratos"
                            returnKeyType="next"
                            value={productData?.analysis_nut?.carbs}
                            onChangeText={(text: string) => setProductData({ ...productData, analysis_nut: { ...productData?.analysis_nut, carbs: text } })}
                            style={styles.entryPicker}
                            keyboardType="numeric"
                        />
                        <TextInput
                            label="Ceniza"
                            returnKeyType="next"
                            value={productData?.analysis_nut?.ash}
                            onChangeText={(text: string) => setProductData({ ...productData, analysis_nut: { ...productData?.analysis_nut, ash: text } })}
                            style={styles.entryPicker}
                            error={error['ash']}
                            keyboardType="numeric"
                        />
                        <TextInput
                            label="Humedad"
                            returnKeyType="next"
                            value={productData?.analysis_nut?.humidity}
                            onChangeText={(text: string) => setProductData({ ...productData, analysis_nut: { ...productData?.analysis_nut, humidity: text } })}
                            style={styles.entryPicker}
                            error={error['humidity']}
                            keyboardType="numeric"
                        />
                    </View>
                    <Additives
                        currentAdditives={productAdditives}
                        allAdditives={allAdditives}
                        onAddAdditive={(additive: string) => setProductAdditives(productAdditives.concat(additive))}
                        onDeleteAdditive={(additive: string) => setProductAdditives(productAdditives.filter((adtv) => adtv != additive))}
                    />
                    <TextInput
                        label="Ingredientes"
                        returnKeyType="next"
                        value={productData?.ingredients}
                        onChangeText={(text: string) => setProductData({ ...productData, ingredients: text })}
                        style={styles.entry}
                        error={error['ingredients']}
                    />
                    {/*Bonus primer ingrediente*/}
                    <Divider style={{ marginTop: 30 }} />
                    <View style={{ marginTop: 20, }}>
                        <Text style={{ lineHeight: 20, fontSize: 18 }}>Especifique los ingredientes (opcional):</Text>
                        <View style={{ flexDirection: 'row', marginTop: 20 }}>
                            <View style={{ flexDirection: 'row', borderRadius: 5, alignItems: 'center' }}>
                                <Text style={{ fontSize: 16, lineHeight: 18 }}>Nombre primer ingrediente: </Text>
                                <TextInput
                                    dense={true}
                                    //@ts-ignore
                                    value={firstIngredient?.name}
                                    style={[styles.inputNumber, { width: 150 }]}
                                    //@ts-ignore
                                    onChangeText={(text: string) => setFirstIngredient({ ...firstIngredient, name: text })}
                                />

                            </View>
                        </View>
                        <View style={{ flexDirection: 'row' }}>
                            <View style={{ flexDirection: 'row', borderRadius: 5, alignItems: 'center' }}>
                                <Text style={{ fontSize: 16, lineHeight: 18 }}>Bonus (%): </Text>
                                <TextInput
                                    dense={true}
                                    //@ts-ignore
                                    value={firstIngredient?.percent}
                                    style={[styles.inputNumber, { width: 150 }]}
                                    //@ts-ignore
                                    onChangeText={(text: string) => setFirstIngredient({ ...firstIngredient, percent: text })}
                                />
                            </View>
                        </View>
                        <View style={{ flexDirection: 'row', borderRadius: 5, alignItems: 'center' }}>
                            <Text style={{ fontSize: 16, lineHeight: 18 }}>Nombre segundo ingrediente: </Text>
                            <TextInput
                                dense={true}
                                //@ts-ignore
                                value={secondIngredient}
                                style={[styles.inputNumber, { width: 150 }]}
                                //@ts-ignore
                                onChangeText={(text: string) => setSecondIngredient(text)}
                            />

                        </View>
                        <View style={{ flexDirection: 'row', borderRadius: 5, alignItems: 'center' }}>
                            <Text style={{ fontSize: 16, lineHeight: 18 }}>Nombre tercer ingrediente: </Text>
                            <TextInput
                                dense={true}
                                //@ts-ignore
                                value={thirdIngredient}
                                style={[styles.inputNumber, { width: 150 }]}
                                //@ts-ignore
                                onChangeText={(text: string) => setThirdIngredient(text)}
                            />

                        </View>
                    </View>
                    <Divider style={{ marginTop: 10 }} />
                    <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                        <TextInput
                            label="Código de barras"
                            returnKeyType="next"
                            value={productData?.barcodes}
                            onChangeText={(text: string) => setProductData({ ...productData, barcodes: text })}
                            style={[styles.entry, { flex: 1 }]}
                            error={error['barcodes']}
                        />
                    </View>
                    <View style={{ flexDirection: "row", justifyContent: 'flex-end', width: "70%", alignSelf: "center", marginTop: 40 }}>
                        <BaseButton onPress={_onAddPressed}>
                            {caption}
                        </BaseButton>
                    </View>
                </ScrollView>
                <Snackbar
                    duration={2000}
                    style={{ backgroundColor: theme.colors.text, alignSelf: 'center', width: '70%' }}
                    visible={visibleSnackbar}
                    onDismiss={() => setVisibleSnackbar(false)}
                    action={{
                        label: 'Cerrar',
                        onPress: () => {
                            setVisibleSnackbar(false);
                        },
                    }}>
                    Vuelve a buscar el producto o bien refresca la pagina para visualizar los cambios!
                </Snackbar>
            </View>
        </Background>
    );
}

const styles = StyleSheet.create({
    inputText: {
        fontSize: 16,
        lineHeight: 18,
        color: theme.colors.blue,
        fontFamily: theme.fonts.regular.fontFamily
    },
    inputNumber: {
        backgroundColor: 'white',
        color: theme.colors.primary,
        fontFamily: theme.fonts.regular.fontFamily,
        width: 50,
        borderWidth: 0.5,
        borderColor: 'rgba(0,0,0,0.2)'

    },
    entryPicker: {
        width: "30%",
        color: theme.colors.text,
        backgroundColor: theme.colors.background,
    },
    entry: {
        backgroundColor: theme.colors.background,
    },
    selectBox: {
        width: "30%",
        height: 56,
        padding: 10,
        backgroundColor: theme.colors.background,
        color: theme.colors.primary,
        borderWidth: 0,
    },
    form: {
        flex: 1,
        maxWidth: 420,
        margin: 20,
        flexDirection: "column",
        alignItems: "center"
    },
    row: {
        flexDirection: 'row',
        marginTop: 4,
    },
    label: {
        color: theme.colors.text,
    },
    link: {
        fontWeight: 'bold',
        color: theme.colors.primary,
    },
    productImage: {
        width: "80%",
        aspectRatio: 1,
        resizeMode: "contain",
        marginBottom: 10
    },
});
export default memo(ProductFormAdmin);
import {Appearance} from 'react-native';

const isDarkMode = Appearance.getColorScheme() == 'dark';

export const getWebTheme = (DefaultTheme) => {
    return {
        ...DefaultTheme,
        fonts: {
            ...DefaultTheme.fonts,

        },
        colors: {
            ...DefaultTheme.colors,
            background: isDarkMode ? '#202124' : 'white'
        },
        gradients: {
            ...DefaultTheme.gradients,

        },
        plugins: {
            ...DefaultTheme.plugins,
        }
    };
}


export default {
    help:{
        email: 'ayuda@xxx.com'
    },
    version: "v5.0",
    auth:{
        login: {
            backToHomeEnabled:true
        }
    },
    autoload: {
        profile: {
            enabled: true,
            settings: {
                authRequired: true
            }
        }
    },
    socialLogin: {
        enabled: true,
        apiIds: { // CONFIG: https://docs.google.com/document/d/1gXPRaRWfwMOcGE6a6DEIb4UVk9KjusksRLq1FVhHf5U/edit?usp=sharing
            expoClientId: '79557164000-v52rana5ud042ar527vnnkotrju7o1tt.apps.googleusercontent.com',
            iosClientId: '79557164000-vm7f7sla914asb8hs4fmjlrg7i5tad66.apps.googleusercontent.com',
            androidClientId: '79557164000-f87dkodjsbjjl104d2i6smno60q8ds2e.apps.googleusercontent.com',
            webClientId: '79557164000-e51cp3k7vl9tru5jold6mdk5gaf2ncjr.apps.googleusercontent.com'
        },
        profile:{
            autoCreate: true,
            extendProfileRoute: 'completeprofile'
        }
    },
    mqtt: false,
    pushNotifications: {
        additionalPrefixes: ['https://sappipreprod.protofy.xyz/', 'https://sappi.protofy.xyz/', 'https://buscador.sappi.app/', 'exp://sappipreprod.protofy.xyz:19000', 'exp://backofficesappipreprod.protofy.xyz:19000', 'https://*.protofy.xyz/']
    },
    appName: "protofy-seed", //internal name, used for expo and other settings
    appTitle: "Protofy", //title shown if page do not select any title
    appHeader: true,
    menu: {
        type: "slide", //available: slide, ...
        position: "right", //available: rigth, left,
        content: {
            title: {type: "email"},
            //title: {type: "text", text: "Hello"}
        }
    },
    devUI: false
}
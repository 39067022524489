import React, { memo } from 'react';
import { View } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
type Props = {
    maxRate: number;
    rate: number;
    rateColor: string;
    unrateColor: string;
    size: number;
    setRate?: Function;
}

const Rate = ({maxRate, rate, rateColor, unrateColor, size, setRate }: Props) => {
    var foo = [];
    for (var i = 1; i <= maxRate; i++) {
        foo.push(i);
    }
    return (
    <View style={{flexDirection:'row', marginLeft:-10}}>
    {
        foo.map((item, key)=> {
        return(<MaterialCommunityIcons 
            key={key}
            name={item<= rate? 'star': 'star-outline'}
            color={item<=rate?rateColor:unrateColor}
            size={size}
            style={{marginLeft:key!=0?3:0}}
            onPress={()=>{setRate?setRate(key+1):null}}
        />)
        })
    }
    </View>);
};

export default memo(Rate);

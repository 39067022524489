import React, { memo } from 'react';
import { Text, TouchableHighlight, View } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { ProductModel } from 'systemDomain';
import ProfileApi from '../api/ProfileApi';
import { theme } from '../theme';
import { useAppStore } from 'baseapp/context/appStore';

const FavoriteMenuButton = ({product, navigation}:{product:ProductModel, navigation: any}) => {
    const { profile, setProfile, session } = useAppStore();
    const currentUser = session.userInfo;
    const [isFavorite, setIsFavorite] = React.useState(false);
    
    const addFav = async (e?:any) => {
        //e.stopPropagation();
        if(currentUser.token){
            setIsFavorite(!isFavorite);
            try {
                setProfile(await ProfileApi.toggleFavorite(currentUser, product));
            } catch(e) {
                setIsFavorite(!isFavorite);
            }
        }
        else{
            navigation.navigate('login')
        }
    }

    React.useEffect(() => {
        if(profile?.get('favorites').indexOf(product.getId())==-1){
            setIsFavorite(false);
        } else {
            setIsFavorite(true);
        }

    }, [profile]);
    
    return (
        <TouchableHighlight underlayColor="transparent"  style={{ alignItems: 'center', justifyContent: 'flex-start' }} onPress={(e:any) => {e.preventDefault(); e.stopPropagation();addFav();}}>
            <View style={{ marginRight: 20, marginBottom: 20, justifyContent: 'flex-start', alignItems: 'center', width: '100%', flexDirection: 'row' }}>
            <MaterialCommunityIcons
                name={isFavorite?"heart":"heart-outline"}
                color={isFavorite?"#EC1277":theme.colors.text}
                size={25}
                style={{ alignSelf: 'center', marginRight: 10 }}
                />
                <Text style={{ textAlign: 'center', color: isFavorite?"#EC1277":theme.colors.text, fontSize: 16 , fontFamily: theme.fonts.light.fontFamily }}>{isFavorite?"Eliminar de favoritos":"Añadir a favoritos"}</Text>
            </View>
        </TouchableHighlight>
    )
    };

export default memo(FavoriteMenuButton);

import ApiCaller from '../core/ApiCaller';

const getApiCaller = (): ApiCaller => {
    const apiCaller = new ApiCaller();
    return apiCaller
}

class LoginSocialApi {
    constructor() {}
    static async sendToken(token: any){
        const message = await getApiCaller().call('/v1/loginsocial/token', 'POST', {"token":token});
        return message
    }
    static async testToken(token: any){
        const message = await getApiCaller().call('/v1/loginsocial/test', 'POST', {"token":token});
        return message
    }
}

export default LoginSocialApi;
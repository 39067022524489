import React from 'react';
import { StyleSheet, View, Text, Keyboard } from 'react-native';
import { Button, Dialog, Portal, TextInput } from 'react-native-paper';
import { theme } from '../theme';
import EmailApi from '../api/EmailApi';
import ApiCaller from 'baseapp/core/ApiCaller';
import AddPhotoButton from './AddPhotoButton';
import { useAppStore } from 'baseapp/context/appStore';

type Props = {
    hideDialogReport: Function;
    visibleDialogReport: boolean;
    photoBack: string;
    photoFront: string;
    openCamera: Function;
};

const ReportProblemDialog = ({ hideDialogReport, visibleDialogReport, photoBack, photoFront, openCamera }: Props) => {
    const [reportMsg, setReportMsg] = React.useState<string>("");
    const { session } = useAppStore();
    const currentUser = session.userInfo;

    const sendReport = async () => {
        const emailApi = new EmailApi(new ApiCaller(currentUser.token));
        const images: any[] = [];

        if (photoFront) {
            const tmpFrontImage = {
                type: photoFront.split(':')[1].split(';')[0], name: "front", content: photoFront.split(',')[1]
            }
            images.push(tmpFrontImage);
        }
        if (photoBack) {
            const tmpBackImage = {
                type: photoBack.split(':')[1].split(';')[0], name: "back", content: photoBack.split(',')[1]
            }
            images.push(tmpBackImage);
        }

        const payload = {
            additionalMsg: reportMsg,
            attachments: images,
        }
        emailApi.sendReport(payload)

    }

    const takeFrontImage = async () => {
        Keyboard.dismiss();
        openCamera(false);
    }
    const takeBackImage = async () => {
        openCamera(true);
    }

    return (
        <Portal>
            <Dialog visible={visibleDialogReport} onDismiss={() => hideDialogReport()} style={{ borderRadius: 15, width: '80%', maxWidth: 350, alignSelf: 'center' }}>
                <View style={{ width: '90%', maxWidth: 450, alignSelf: 'center', justifyContent: 'center', paddingVertical: 30 }}>
                    <Text style={{ color: theme.colors.primary, fontSize: 18, fontFamily: theme.fonts.regular.fontFamily, textAlign: 'center', marginTop: 0, marginBottom: 25 }}>Ayúdanos a mejorar!</Text>
                    <View style={{ flexDirection: 'row', alignSelf: 'center', width: '90%', justifyContent: 'space-between', alignItems: 'center', marginBottom: 10 }}>
                        <AddPhotoButton cameraText={'Foto 1'} onPress={takeFrontImage} pictureReady={photoFront != ""} />
                        <AddPhotoButton cameraText={'Foto 2'} onPress={takeBackImage} pictureReady={photoBack != ""} />
                    </View>
                    <TextInput
                        onChangeText={(text: string) => setReportMsg(text)}
                        style={{ paddingHorizontal: 10, fontFamily: theme.fonts.thin.fontFamily, color: theme.colors.text, width: '90%', alignSelf: 'center' }}
                        multiline={true}
                        numberOfLines={3}
                        placeholder={"Dejanos tu mensaje aqui y haremos lo posible para mejorarlo"}
                        placeholderTextColor={theme.colors.grey}
                    />
                    <Button
                        disabled={(!photoBack && !photoFront)}
                        labelStyle={{ fontFamily: theme.fonts.light.fontFamily }}
                        style={{ backgroundColor: (photoBack || photoFront)?theme.colors.primary: theme.colors.shadedBox, width: '45%', alignSelf: 'center', marginTop: 30 }}
                        color={theme.colors.background} uppercase={false}
                        onPress={() => { 
                        sendReport() 
                        hideDialogReport() }}
                    >Enviar</Button>
                </View>
            </Dialog>
        </Portal>
    )
};

const styles = StyleSheet.create({

});

export default ReportProblemDialog;

import React, { memo } from 'react';
import FilterIcon from './FilterIcon';
import { ScrollView } from 'react-native-gesture-handler';
import { Platform, ViewStyle } from 'react-native';

type Props = {
    filters: string[];
    toggleFilter: Function;
    style?: ViewStyle;
}
const FiltersList = ({filters, toggleFilter, style}:Props) => {

    return (
        <ScrollView showsHorizontalScrollIndicator={Platform.OS == 'web' ? true : false} horizontal={true}  style={{ width: '100%', maxWidth:  410, backgroundColor: 'transparent', flexDirection: 'row', marginVertical: 10, alignSelf: 'flex-start', ...style }}>
            <FilterIcon selected={filters.includes('dog')} filter="dog" toggleFilter={toggleFilter} icon={"dog"} iconText={"Perro"} />
            <FilterIcon selected={filters.includes('cat')} filter="cat" toggleFilter={toggleFilter} icon={"cat"} iconText={"Gato"} />
            <FilterIcon selected={filters.includes('humedo')}filter="humedo" toggleFilter={toggleFilter} icon={"water"} iconText={"Húmedo"} />
            <FilterIcon selected={filters.includes('natural')} filter="natural"toggleFilter={toggleFilter} icon={"food-drumstick"} iconText={"Natural"} />
            <FilterIcon selected={filters.includes('pienso')} filter="pienso" toggleFilter={toggleFilter} icon={"grain"} iconText={"Pienso"} />
            <FilterIcon selected={filters.includes('premio')} filter="premio" toggleFilter={toggleFilter} icon={"seal"} iconText={"Premio"} />
        </ScrollView>
    )
};

export default memo(FiltersList);

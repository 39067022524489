import * as React from 'react';
import { View, Image, StyleSheet } from 'react-native';
import { Text } from 'react-native-paper';
import { ProductModel } from 'systemDomain';
import {theme} from '../theme';
import { getBarValueColor, toDry, getPercentNutValue} from "../utils";
import { MaterialCommunityIcons } from '@expo/vector-icons';

type Props = {
  product: ProductModel;
  nutr: string;
  title: string;
  isGood: boolean;
}

const NutBar = ({product, nutr, title, isGood}:Props) => {
  const hum = (product?.getAnalysis_nut() as any)?.humidity;

  const getDryGrams = (nutr:string) => {
    const hum = (product?.getAnalysis_nut() as any)?.humidity;
    if (nutr== 'carbs'){
        return product?.computeCarbs();
    }
    else {
        return toDry((product?.getAnalysis_nut() as any)[nutr], hum).toFixed(1);
    }
  }
  const getBarValue = () => {
    if (nutr== 'carbs'){
      return getPercentNutValue(product?.computeCarbs(), nutr).toString()+'%';
    }
    else {
      return getPercentNutValue(toDry((product?.getAnalysis_nut() as any)[nutr], hum), nutr).toString()+'%';
    }
  }

    return (
    <View style={{flex:1}}>
      <View style={{flexDirection:'column'}}>
        <View style={{flexDirection:'row'}}>
            <View style={{flexDirection:'column', flex:1, alignItems:'flex-start'}}>
                <Text style={styles.labelName}>{title}</Text> 
            </View>
            <View style={{flexDirection:'column', flex:1, alignItems:'flex-end'}}>
                <Text style={styles.labelName}>
                    {getDryGrams(nutr)}<Text style={styles.labelName}> g</Text>
                </Text>
            </View>
        </View>
      </View>
      <View style={{flexDirection:'column', width:'100%', marginBottom:15, marginTop:5}}>
          <View style={{flex:1, flexDirection:'row', marginLeft: getBarValue()}}>
              <MaterialCommunityIcons
                      name='triangle'
                      color={getBarValueColor(nutr, product, hum)}
                      size={15}
                      style={{ transform: [{ rotate: '180deg'}] }}
              />                              
          </View>
          <View style={{flex:1, flexDirection:'column', justifyContent:'center'}}>
              <Image style={{transform: (isGood)?[{ rotate: '0deg'}]:[{ rotate: '180deg'}], height:13, width:'100%', resizeMode:'contain', flex:1}} source={require('../assets/gradient.png')}/>
          </View>
      </View>
    </View>
    );
}
const styles = StyleSheet.create({
  entry: {
      backgroundColor: theme.colors.background
  },
  labelName: {
      fontSize: 18,
      color: theme.colors.text,
      fontWeight: '500',
      lineHeight: 27

  },
  sublabelName: {
      color: 'rgba(159, 159, 159, 1)', 
      fontSize:14, 
      lineHeight:16, 
      fontWeight:'400'
  },
  row: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "flex-end",
      marginBottom: 4
  },
  valueLabel: {
      fontSize: 16,
      fontWeight: "bold",
      color: theme.colors.text
  },
  sectionTitle: {
      fontSize: 15,
      fontWeight: 'bold',
      color: theme.colors.secondary   
  },

  smallCaption: {
      fontSize:12,
      color: "#999"
  },
  sectionTitleSmall: {
      fontSize:10,
      fontWeight: 'bold'
  },
  paragraph: {
      color: theme.colors.colorParagraphItem,
      fontWeight:'500',
      fontSize:14,
      lineHeight:18,     
  }
});

export default NutBar;
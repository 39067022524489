import React, { memo, useRef } from 'react';
import { Image, View } from 'react-native';
import { Appbar, IconButton, Snackbar } from 'react-native-paper';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { theme } from './theme';
import { useSelector } from 'react-redux';
import { useNavigation, useNavigationState } from '@react-navigation/native';
import { Platform } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import getEnvVars from './environments';
import RBSheet from "react-native-raw-bottom-sheet";
import BottomSheetContent from './components/BottomSheetContent';
import BottomProductSheetContent from './components/BottomProductSheetContent';
import ProductMenuDialog from './components/ProductMenuDialog';
import { Clipboard } from 'react-native';
import { useProductStore } from './context/productContext';
import { useHeaderStore } from 'baseapp/context/headerContext';
import { useAppStore } from "baseapp/context/appStore"; 
import { AppState } from './store/store';
import { useAnalyticsStore } from './context/analytics';

const Header = (prop: any) => {

    const {setDialogReport } = useHeaderStore();
    const header = prop.headerStyle;

    const { session} = useAppStore();
    const { AnalyticsLogEvent } = useAnalyticsStore();

    const currentUser = useAppStore(state=>state.session.userInfo);
    const navigation = useNavigation();
    const screenName = useNavigationState((state) => state.routes[state.index].name);
    const navState = useNavigationState((state) => state.routes[state.index]);
    const [headerStyle, setHeaderStyle] = React.useState('default');
    const [enableEdit, setEnableEdit] = React.useState(false);
    const [currentId, setCurrentId] = React.useState();
    const currentTab = useSelector((state: AppState) => state.page.currentTab);
    const refRBSheet = useRef();
    const refRBProductSheet = useRef();
    const [inScan, setInScan] = React.useState(false);
    const handleScan = () => {
        navigation.navigate('scan');
    }
    const { product } = useProductStore();

    const [visibleProductMenu, setVisibleProductMenu] = React.useState<boolean>(false);
    const onDialogProductMenuPress = () => { setVisibleProductMenu(true); }
    const hideProductMenu = () => { setVisibleProductMenu(false) }
    const onReportPressed = () => { setDialogReport(true); }


    const [visibleSnackbar, setVisibleSnackbar] = React.useState<boolean>(false);
    const copyToClipboard = (url: string) => {
        Clipboard.setString(url);
    };
    const onSharePressed = () => {
        AnalyticsLogEvent("Product Shared", { "user": session.userInfo.email ? session.userInfo.email : "guest", "product ID": product?.getId() ? product?.getId() : null });
        copyToClipboard(getEnvVars().apiUrl.split('/api')[0] + '/itemGuest/' + product?.getId());
        setVisibleSnackbar(true);
    }

    const onMenuPress = () => {
        (Platform.OS == 'web') ? ((navigation as any) as DrawerNavigationProp<{}>).openDrawer() : refRBSheet.current?.open()// MENU CONTENT: Web- Menu.tsx  !Web MenuBottomSheet.tsx
    }

    const handleMore = () => {
        if (Platform.OS != 'web' && !inScan) {
            navigation.navigate('scan', { target: 'add' });
        } else {
            navigation.push('add');
        }

    }

    const handleEdit = () => {
        navigation.push('edit', { id: currentId });
    }

    const refreshBar = () => {
        setInScan(false);
        if (screenName == 'home') {
            if (currentTab == 'search') {
                setHeaderStyle('search');
                return;
            }
            setEnableEdit(false);
        } else if (screenName == 'item') {
            //@ts-ignore
            setCurrentId(navState.params?.id);
            setEnableEdit(true);
        } else if (screenName == 'scan') {
            setInScan(true);
        } else {
            setEnableEdit(false);
        }
        setHeaderStyle('default');
    }

    const onProductMenuPress = () => {
        (Platform.OS == 'web') ? onDialogProductMenuPress() : refRBProductSheet.current.open()
    }
    React.useEffect(() => {
        refreshBar();
        // isReportVisible
    }, [currentTab]);

    useFocusEffect(() => {
        refreshBar();
    });

    const paddingBottom = headerStyle == 'search' ? 5 : 0;
    return (
        <>
            <Appbar.Header style={{ backgroundColor: theme.colors.navBar, height: 'auto', paddingBottom: paddingBottom, borderBottomColor: '#e3e3e3', elevation: 0, borderBottomWidth: prop.headerBorder }}>
            { header == "default" && currentUser.type != 'admin' ? 
                    <View style={{flexDirection: 'row', justifyContent: 'space-between', width: '98%', alignSelf: 'center', alignItems: 'center'}}>
                        <View style={{ alignItems: 'center', justifyContent: 'center', marginLeft: 20}}>
                                <Image source={require("./assets/logo.png")}
                                    style={{
                                        width: 30,
                                        height: 30,
                                        resizeMode: 'contain',
                                        alignSelf: 'center',
                                    }}
                                    />
                                </View>
                        <IconButton icon={'menu'} color={theme.colors.primary} onPress={() => onMenuPress()} style={{zIndex:1000}}></IconButton>
                    </View>
                :header == "default" && currentUser.type == 'admin'? 
                <View style={{flexDirection: 'row', justifyContent: 'space-between', width: '98%', alignSelf: 'center', alignItems: 'center'}}>
                        <View style={{ alignItems: 'center', justifyContent: 'center', marginLeft: 20}}>
                                <Image source={require("./assets/logo.png")}
                                    style={{
                                        width: 30,
                                        height: 30,
                                        resizeMode: 'contain',
                                        alignSelf: 'center',
                                    }}
                                    />
                                </View>
                        <View style={{flexDirection: 'row'}}>
                        <IconButton icon={'plus'} color={theme.colors.text} onPress={handleMore} style={{}}></IconButton>
                        <IconButton icon={'menu'} color={theme.colors.primary} onPress={() => onMenuPress()} style={{}}></IconButton>
                        </View>        
                    </View>
                : null}
                { header == "item" ? 
                    <View style={{flexDirection: 'row', justifyContent: 'space-between', width: '100%', alignSelf: 'center', alignItems: 'center'}}>
                        <IconButton icon={'chevron-left'} onPress={() => navigation.navigate('home')} size={30}></IconButton>
                        <IconButton icon={'dots-vertical'} onPress={onProductMenuPress}></IconButton>
                    </View>
                :null}
                { header == "onlyBack" ? 
                    <View style={{flexDirection: 'row', justifyContent: 'space-between', width: '100%', alignSelf: 'center', alignItems: 'flex-start'}}>
                        <IconButton icon={'chevron-left'} onPress={ () => {navigation.goBack();}} size={30}></IconButton>
                    </View>
                :null}
                { header == "noHeader" ?
                    null
                :null}
                {/* {headerStyle == 'default' && currentUser.type == 'admin' ? <Appbar.Action icon="plus" onPress={handleMore} style={{position: 'absolute', right: 50}}/> : null} */}
            </Appbar.Header>
            {
                Platform.OS != 'web' ?
                    <View style={{ flex: 1 }}>
                        <RBSheet
                            ref={refRBSheet}
                            closeOnDragDown={true}
                            closeOnPressMask={true}
                            height={700}
                            animationType={"fade"}
                            openDuration={200}
                            closeDuration={200}
                            customStyles={{
                                wrapper: {
                                    backgroundColor: "rgba(0,0,0,0.3)",
                                },
                                draggableIcon: {
                                    backgroundColor: "#B3B3B3"
                                },
                                container: {
                                    borderTopLeftRadius: 40,
                                    borderTopRightRadius: 40
                                }
                            }}
                        >
                            <BottomSheetContent sheetREF={refRBSheet} />
                            {/* Content */}
                        </RBSheet>
                    </View> : null
            }
            {
                Platform.OS != 'web' ?
                    <View style={{ flex: 1 }}>
                        <RBSheet
                            ref={refRBProductSheet}
                            closeOnDragDown={true}
                            closeOnPressMask={true}
                            height={400}
                            animationType={"fade"}
                            openDuration={200}
                            closeDuration={200}
                            customStyles={{
                                wrapper: {
                                    backgroundColor: "rgba(0,0,0,0.3)",
                                },
                                draggableIcon: {
                                    backgroundColor: "#B3B3B3"
                                },
                                container: {
                                    borderTopLeftRadius: 40,
                                    borderTopRightRadius: 40
                                }
                            }}
                        >
                            <BottomProductSheetContent navigation={navigation} product={product} onSharePressed={onSharePressed} onReportPressed={onReportPressed} sheetREF={refRBProductSheet} />
                            {/* Contwnt */}
                        </RBSheet>
                    </View> : null
            }
            <ProductMenuDialog navigation={navigation} product={product} hideProductMenu={hideProductMenu} visibleProductMenu={visibleProductMenu} onSharePressed={onSharePressed} onReportPressed={onReportPressed} />
            <Snackbar
                visible={visibleSnackbar}
                onDismiss={() => { setVisibleSnackbar(false) }}
                duration={1000}
                style={{ backgroundColor: theme.colors.text, width: '98%', alignSelf: 'center', flexDirection: 'column-reverse', flex: 1 }}
                wrapperStyle={{ position: 'absolute', zIndex: 10000, maxWidth: 600 }}
            >
                Enlace copiado en el portapapeles
            </Snackbar>
        </>
    );
}

export default memo(Header);

import React from 'react';
import { StyleSheet, View, TextInput } from 'react-native';
import { theme } from '../theme';
import { MaterialCommunityIcons } from '@expo/vector-icons';

type Props = {
    additiveName: string;
    onDeleteAdditive: Function;
    // setIngredientsList: Function;
    // ingredientsList: any[];
};

const AdditiveItem = ({ additiveName, onDeleteAdditive }: Props) => {
    // const onErase = () => {
    //     const tmpActualizedIngredientList = ingredientsList.filter((item:any) =>(item.name != name));
    //     setIngredientsList(tmpActualizedIngredientList);
    // }
    return (
        <View style={{ flexDirection: 'row', justifyContent: 'space-between', backgroundColor: '#F5F5F5', height: 40, borderRadius: 5, padding: 10, marginBottom: 10 }}>
            <TextInput value={additiveName} placeholderTextColor={theme.colors.blue} style={styles.inputText} onChangeText={(text) => additiveName = text}></TextInput>
            <View style={{ flexDirection: 'row' }}>
                <MaterialCommunityIcons
                    name="close"
                    size={20}
                    color={theme.colors.grey}
                    onPress={() => onDeleteAdditive(additiveName)}
                />
            </View>
        </View>
    )
};

const styles = StyleSheet.create({
    inputText: {
        backgroundColor: 'transparent',
        color: theme.colors.blue,
        fontFamily: theme.fonts.regular.fontFamily,

    },
    inputNumber: {
        backgroundColor: 'transparent',
        color: theme.colors.primary,
        fontFamily: theme.fonts.regular.fontFamily,
        width: 30,

    }
});

export default AdditiveItem;

import React, { useEffect } from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { TextInput } from 'react-native-paper';
import { useSappiScoreStore } from '../context/sappiScoreContext';
import { theme } from '../theme';
import sappiScoreInterface from '../models/sappiScore/sappiScoreInterface';
import SappiScoreModel from '../models/sappiScore/sappiScoreModel';

const FormulaPercentage = () => {
    const { sappiScore, setSappiScoreChanging, sappiScoreChanging } = useSappiScoreStore();

    const [processValue, setProcessValue] = React.useState<any>(sappiScore?.getPunctuation_process());
    const [compositionValue, setCompositionValue] = React.useState<any>(sappiScore?.getPunctuation_composition());
    const [qualityValue, setQualityValue] = React.useState<any>(sappiScore?.getyPunctuation_quality());

    useEffect(() => {
        setProcessValue(sappiScore?.getPunctuation_process());
        setCompositionValue(sappiScore?.getPunctuation_composition());
        setQualityValue(sappiScore?.getyPunctuation_quality());
    }, [sappiScore]);

    const changeProcessValue = (text: string) => {
        setProcessValue(text);
        //@ts-ignore
        var tmpSappiScoreObj: sappiScoreInterface = sappiScoreChanging?.toObject();
        tmpSappiScoreObj['sappiScore']['process'] = parseInt(text);
        setSappiScoreChanging(new SappiScoreModel(tmpSappiScoreObj));
    }
    const changeCompositionValue = (text: string) => {
        setCompositionValue(text);
        //@ts-ignore
        var tmpSappiScoreObj: sappiScoreInterface = sappiScoreChanging?.toObject();
        tmpSappiScoreObj['sappiScore']['composition'] = parseInt(text);
        setSappiScoreChanging(new SappiScoreModel(tmpSappiScoreObj));
    }
    const changeQualityValue = (text: string) => {
        setQualityValue(text);
        //@ts-ignore
        var tmpSappiScoreObj: sappiScoreInterface = sappiScoreChanging?.toObject();
        tmpSappiScoreObj['sappiScore']['quality'] = parseInt(text);
        setSappiScoreChanging(new SappiScoreModel(tmpSappiScoreObj));
    }
    return (
        <View style={{ flexDirection: 'row', width: '80%', alignSelf: 'center' }}>
            <View style={{ width: processValue + '%' }}>
                <Text style={styles.ingredienteText}>Procesado</Text>
                <View style={{ width: '100%', backgroundColor: theme.colors.green, height: 20 }} />
                <TextInput onChangeText={(text) => changeProcessValue(text)} label={processValue} style={styles.input} underlineColor={theme.colors.background} />
            </View>
            <View style={{ width: compositionValue + '%' }}>
                <Text style={styles.ingredienteText}>Composición</Text>
                <View style={{ width: '100%', backgroundColor: theme.colors.blue, height: 20 }} />
                <TextInput onChangeText={(text) => changeCompositionValue(text)} label={compositionValue} style={styles.input} underlineColor={theme.colors.background} />
            </View>
            <View style={{ width: qualityValue + '%' }}>
                <Text style={styles.ingredienteText}>Calidad</Text>
                <View style={{ width: '100%', backgroundColor: theme.colors.orange, height: 20 }} />
                <TextInput onChangeText={(text) => changeQualityValue(text)} label={qualityValue} style={styles.input} underlineColor={theme.colors.background} />
            </View>
        </View>
    )
};

const styles = StyleSheet.create({
    ingredienteText: {
        color: theme.colors.grey,
        fontSize: 16,
        fontFamily: theme.fonts.light.fontFamily,
        alignSelf: 'center',
        marginBottom: 10,
    },
    input: {
        backgroundColor: theme.colors.background,
        width: 65,
        alignSelf: 'center',
    },
});

export default FormulaPercentage;

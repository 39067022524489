import React, { useEffect } from 'react';
import { StyleSheet, View, Text, TextInput } from 'react-native';
import { useSappiScoreStore } from '../context/sappiScoreContext';
import { theme } from '../theme';
import SappiScoreModel from '../models/sappiScore/sappiScoreModel';

const FormulaQualityBonus = () => {
    const { sappiScore, setSappiScoreChanging, sappiScoreChanging } = useSappiScoreStore();
    //  BONUS
    const [minBonus, setMinBonus] = React.useState<string>('');
    const [maxBonus, setMaxBonus] = React.useState<string>('');
    const [valueBonus, setValueBonus] = React.useState<string>('');

    useEffect(()=>{
        setMinBonus(sappiScore?.getQuality_bonusFirstIngredient().minBonus);
        setMaxBonus(sappiScore?.getQuality_bonusFirstIngredient().maxBonus);
        setValueBonus(sappiScore?.getQuality_bonusFirstIngredient().bonus);
    },[sappiScore]);

    useEffect(() => {
        // @ts-ignore
        var tmpSappiScoreObj: sappiScoreInterface = sappiScoreChanging?.toObject();
        tmpSappiScoreObj['quality']['bonusFirstIngredient']["minBonus"]= parseInt(minBonus);
        tmpSappiScoreObj['quality']['bonusFirstIngredient']["maxBonus"] = parseInt(maxBonus);
        tmpSappiScoreObj['quality']['bonusFirstIngredient']["bonus"] = parseInt(valueBonus);
        setSappiScoreChanging(new SappiScoreModel(tmpSappiScoreObj));
    }, [minBonus, maxBonus, valueBonus]);


    return (
        <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
            <View style={styles.boxInput}>
                <Text style={styles.paragraph}>% Mínimo: </Text>
                <TextInput onChangeText={(text) => setMinBonus(text)} value={minBonus} style={styles.inputText} maxLength={4} />
            </View>
            <View style={styles.boxInput}>
                <Text style={styles.paragraph}>% Máximo: </Text>
                <TextInput onChangeText={(text) => setMaxBonus(text)} value={maxBonus} style={styles.inputText} maxLength={4} />
            </View>
            <View style={styles.boxInput}>
                <Text style={styles.paragraph}>% Valor: </Text>
                <TextInput onChangeText={(text) => setValueBonus(text)} value={valueBonus} style={styles.inputText} maxLength={4} />
            </View>
        </View>
    )
};

const styles = StyleSheet.create({
    boxInput: {
        width: '30%',
        backgroundColor: theme.colors.noSelected,
        height: 50,
        padding: 10,
        justifyContent: 'space-between',
        borderRadius: 10,
        flexDirection: 'row',
        alignItems: 'center'
    },
    paragraph: {
        color: theme.colors.text,
        fontSize: 18,
        lineHeight: 18,
        fontFamily: theme.fonts.light.fontFamily,
    },
    inputText: {
        backgroundColor: 'transparent',
        color: theme.colors.blue,
        fontFamily: theme.fonts.regular.fontFamily,
        fontSize: 18,
        width: 50
    },
});

export default FormulaQualityBonus;

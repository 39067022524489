import React, { useState } from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { Button } from 'react-native-paper';
import { theme } from '../theme';
import AdminApi from '../api/AdminApi';
import getEnvVars from '../environments';
import * as Linking from 'expo-linking';
import EditUsers from './EditUsers';
import { useAppStore } from "baseapp/context/appStore"; 

type Props = {
}

const AdminUsers = ({ }: Props) => {
    const { session } = useAppStore();

    const currentUser = session.userInfo;
    const downloadUsers = () => Linking.openURL(getEnvVars().apiUrl + AdminApi.downloadUsers(currentUser));
    const [submenu, setSubmenu] = useState<string>('default');
    return (
        <View style={{ flex: 4, backgroundColor: theme.colors.background, justifyContent: 'center' }}>
            {
                submenu == 'default' ?
                    <View style={{ shadowRadius: 8, shadowOpacity: 0.2, elevation: 5, backgroundColor: theme.colors.background, width: '50%', padding: 40, borderRadius: 10, alignSelf: 'center' }}>
                        <Text style={styles.textOption}>ADMINISTRAR USUARIOS</Text>
                        <Button style={styles.button} labelStyle={styles.buttonLabel} uppercase={false} onPress={() => setSubmenu('editUsers')}
                        >Editar usuarios</Button>
                        {/* <Button style={styles.buttonDisabled} labelStyle={styles.buttonLabel} uppercase={false}
                        >Estadísticas</Button> */}
                        <Button style={styles.button} labelStyle={styles.buttonLabel} uppercase={false} onPress={() => downloadUsers()}
                        >Descargar usuarios</Button>
                    </View>
                    :
                    submenu == 'editUsers' ?
                        <EditUsers setSubmenu={setSubmenu} />
                        : null
            }
        </View>
    )
};

const styles = StyleSheet.create({
    button: {
        backgroundColor: theme.colors.primary,
        marginBottom: 20,
        width: '90%',
        alignSelf: 'center',
        height: 60,
        borderRadius: 10,
        justifyContent: 'center'
    },
    buttonLabel: {
        color: theme.colors.background,
        fontFamily: theme.fonts.regular.fontFamily,
    },
    buttonDisabled: {
        backgroundColor: theme.colors.grey,
        marginBottom: 20,
        width: '90%',
        alignSelf: 'center',
        height: 60,
        borderRadius: 10,
        justifyContent: 'center'
    },
    textOption: {
        fontSize: 14,
        fontFamily: theme.fonts.regular.fontFamily,
        alignSelf: 'center',
        marginBottom: 20
    },
});

export default AdminUsers;

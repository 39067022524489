import { ProductCollection } from "systemDomain"


//------------  state definition
export interface SearchState {
    results: any[],
    status: string,
}

//------------ actions
export const UPDATE_SEARCH = 'UPDATE_SEARCH';
export const UPDATE_STATUS = 'UPDATE_STATUS';
export const CLEAN_SEARCH = 'CLEAN_SEARCH';

interface UpdateSearchAction {
    type: typeof UPDATE_SEARCH
    payload: SearchState
}

interface CleanSearchAction{
    type: typeof CLEAN_SEARCH
}

interface UpdateStatusAction {
    type: typeof UPDATE_STATUS
    payload: { status: string }
}

export function updateSearch(newSearch: SearchState): SearchActionTypes {
    console.log("RESULTS INSIDE REDUCER", newSearch);
    return {
        type: UPDATE_SEARCH,
        payload: newSearch
    }
}

export function updateStatus(newStatus: string): SearchActionTypes {
    return {
        type: UPDATE_STATUS,
        payload: { status: newStatus }
    }
}

export function cleanSearch(): SearchActionTypes {
    return {
        type: CLEAN_SEARCH,
    }
}



export type SearchActionTypes = UpdateSearchAction | UpdateStatusAction | CleanSearchAction;

//------------ reducer
const initialState: SearchState = {
    results: [],
    status: 'waiting'
}

export function searchReducer(state = initialState, action: SearchActionTypes): SearchState {
    switch (action.type) {        
        case UPDATE_SEARCH: {
            console.log("SearchReducer-actionpayload: ",action.payload)
            console.log("SearchReducer-state: ",state)
            console.log("POLLAS: ",{...state,...action.payload})
            return {
                ...state,
                ...action.payload
            }
        }
        case CLEAN_SEARCH: {
            console.log("CLEAN_SEARCH ",state);
            return {
                ...{results:[],status:"waiting"}
           }
        }
        case UPDATE_STATUS: {
            return {
                ...state,
                ...action.payload
            }
        }
        default:
            return state
    }
}
import React from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { Button, TextInput, List } from 'react-native-paper';
import { theme } from '../theme';
import ApiCaller from 'baseapp/core/ApiCaller';
import { useAppStore } from 'baseapp/context/appStore';

const AdminPush = () => {
    const [pushNotification, setPushNotification] = React.useState<any>({
        title: "",
        description: "",
        link: "",
        targetUsers: 'all'
    });
    const [error, setError] = React.useState<string>('');
    const [target, setTarget] = React.useState<string>('all');
    const [expanded, setExpanded] = React.useState<boolean>(false);

    const { session } = useAppStore();
    const currentUser = session.userInfo;

    const targetTraduction = () => {
        if(target == 'all') return 'Todos'
        if(target == 'cat') return 'Gato'
        if(target == 'dog') return 'Perro'
    }

    const sendBroadcastPush = async () => {
        setError('');
        const apiCaller = new ApiCaller(currentUser?.token);
        let newPushNotification = pushNotification;
        newPushNotification["targetUsers"] = target;

        try {
            await apiCaller.call('/v1/pushnotification/send', 'POST', newPushNotification);
            setTarget('all');
            setPushNotification(
                {
                    title: "",
                    description: "",
                    link: "",
                    targetUsers: 'all'
                }
            );
        } catch (e) {
            setError('Error enviando push notification')
        }
    }
    return (
        <View style={{ flex: 4, backgroundColor: theme.colors.background, justifyContent: 'center' }}>
            <View style={{ shadowRadius: 8, shadowOpacity: 0.2, elevation: 5, backgroundColor: theme.colors.background, width: '50%', padding: 40, borderRadius: 10, alignSelf: 'center' }}>
                <Text style={styles.textOption}>ADMINISTRAR PUSH NOTIFICATIONS</Text>
                <TextInput
                    label="Título"
                    mode='outlined'
                    value={pushNotification?.title ? pushNotification?.title : ""}
                    style={{ marginBottom: 30 }}
                    onChangeText={(text) => {
                        let newPushNotification = {}
                        newPushNotification["title"] = text;
                        setPushNotification({ ...pushNotification, ...newPushNotification })
                    }}
                />
                <TextInput
                    label="Descripción"
                    mode='outlined'
                    value={pushNotification?.description ? pushNotification?.description : ""}
                    style={{ marginBottom: 30 }}
                    onChangeText={(text) => {
                        let newPushNotification = {}
                        newPushNotification["description"] = text;
                        setPushNotification({ ...pushNotification, ...newPushNotification })
                    }}
                />
                <TextInput
                    label="Link (opcional)"
                    mode='outlined'
                    value={pushNotification?.link ? pushNotification?.link : ""}
                    style={{ marginBottom: 30 }}
                    onChangeText={(text) => {
                        let newPushNotification = {}
                        newPushNotification["link"] = text;
                        setPushNotification({ ...pushNotification, ...newPushNotification })
                    }}
                />
                 <List.Accordion
                    title={"Destinatarios:     "+ targetTraduction()}
                    titleStyle={{color:theme.colors.primary}}
                    onPress={()=> setExpanded(!expanded)}
                    expanded={expanded}
                >
                    <List.Item
                        onPress={() => {setTarget('all'); setExpanded(false)}}
                        title="Todos"
                    />
                    <List.Item
                        onPress={() => {setTarget('cat'); setExpanded(false)}}
                        title="Gato"
                    />
                    <List.Item
                        onPress={() => {setTarget('dog'); setExpanded(false)}}
                        title="Perro"
                    />
                </List.Accordion>
                <Button disabled={(!pushNotification?.title || !pushNotification?.description)} onPress={() => sendBroadcastPush()} style={[styles.button, { backgroundColor: (!pushNotification?.title || !pushNotification?.description) ? 'rgba(0,0,0,0.4)' : theme.colors.primary }]} labelStyle={styles.buttonLabel} uppercase={false}
                >Enviar push</Button>
                {error ? <Text style={{ alignSelf: 'center' }}>{error}</Text> : null}
            </View>
        </View>
    )
};

const styles = StyleSheet.create({
    button: {
        backgroundColor: theme.colors.primary,
        marginBottom: 20,
        width: '90%',
        alignSelf: 'center',
        height: 60,
        borderRadius: 10,
        justifyContent: 'center'
    },
    buttonLabel: {
        color: theme.colors.background,
        fontFamily: theme.fonts.regular.fontFamily,
    },
    textOption: {
        fontSize: 14,
        fontFamily: theme.fonts.regular.fontFamily,
        alignSelf: 'center',
        marginBottom: 20
    },
});

export default AdminPush;

import React, { memo } from 'react';
import { Platform, TextInput, View } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
type Props = {
    searchQuery:string;
    onChangeSearch:any;
    onSubmit:any;
    onClose:any;
    placeholder:string
}
const Searchbar = ({searchQuery, placeholder, onChangeSearch, onSubmit, onClose}:Props) => {

    return (
        <View style={{flexDirection:'column', flex:1, width: Platform.OS=='web'?'100%': '80%'}}>  
            <View style={{maxWidth:700, flexDirection:'row', width:'95%', alignSelf:'center', borderRadius: 30, marginBottom:10, marginTop: 15, padding: 9, marginHorizontal: 12, elevation:3,shadowRadius:6, shadowOpacity:0.3, backgroundColor: 'white'}}>
            <MaterialCommunityIcons
                name={'magnify'}
                size={26}
                color="#6F6F6F"
                onPress={onSubmit}
                style={{opacity: 1, textAlign:"center", marginRight:10}}
            />
            <TextInput 
                style={{fontSize: 15, flex:1}}    
                placeholder={placeholder}
                onChangeText={onChangeSearch}
                autoCorrect={false}
                clearButtonMode="always"
                enablesReturnKeyAutomatically={true}
                returnKeyType="search"
                spellCheck={false}
                onSubmitEditing={onSubmit}
                value={searchQuery}
                placeholderTextColor={'#6F6F6F'}
                
            />
            {
            searchQuery!='' && Platform.OS!='ios'?
            <MaterialCommunityIcons
                name={'close-circle-outline'}
                size={26}
                color="#6F6F6F"
                style={{opacity: 1, textAlign:"center", marginLeft:5, alignSelf:'flex-end'}}
                onPress={onClose}
            />:null
            }
            </View>
        </View>
    );
};

export default memo(Searchbar);

import ApiCaller from '../core/ApiCaller';

class AuthApi {
    apiCaller: ApiCaller;

    constructor(apiCaller:ApiCaller) {
        this.apiCaller = apiCaller;
    }

    login(email:string, password:string): Promise<void  | undefined> {
        return this.apiCaller.call('/v1/auth/login', 'POST', {email:email, password:password})
    }

    register(email:string, password:string, name:string): Promise<void  | undefined> {
        return this.apiCaller.call('/v1/auth/register', 'POST', {name:name, email:email, password:password})
    }

    verify(email: string, token: string): Promise<void | undefined> {
        return this.apiCaller.call('/v1/auth/verify?email='+email+'&code='+token, 'GET');
    }

    resetPassword(email: string): Promise<void | undefined> {
        return this.apiCaller.call('/v1/auth/resetPassword/'+email, 'GET');
    }

    changePassword(email: string, password: string, token: string): Promise<void | undefined> {
        return this.apiCaller.call('/v1/auth/resetPassword', 'POST', {email: email, password: password, token: token});
    }
}

export default AuthApi;
import React, { memo, useState } from "react";
import { Navigation } from "baseapp/types";
import { StyleSheet, View } from 'react-native';
import { useAppStore } from "baseapp/context/appStore"
import { ArticleModel } from 'systemDomain';
import ArticleApi from "../api/ArticleApi";
import Frame from '../components/Frame';

type Props = {
    navigation: Navigation;
    setHeaderBorder: Function;
    setHeaderStyle: Function;
    route: any;
};

const ArticleScreen = ({ navigation, setHeaderBorder, setHeaderStyle, route }: Props) => {
    const { session } = useAppStore();
    const currentUser = session.userInfo;
    const [articleUrl, setArticleUrl] = useState<string>('');

    const readArticle = async (id: string) => {
        try {
            const data: ArticleModel = await ArticleApi.read(currentUser, id);
            setArticleUrl(data?.get("link"));
        } catch (e) { }
    }

    React.useEffect(() => {
        if (route?.params.id) {
            readArticle(route?.params.id);
        }
    }, [route])

    return (
        <View style={{ flex: 1, backgroundColor: 'white' }}>
                {articleUrl ?
                    <Frame src={articleUrl} />
                    : null
                }
        </View>
    );
}

const styles = StyleSheet.create({
    title: {
        fontWeight: '700',
        fontSize: 24,
        lineHeight: 26,
        marginBottom: 30,
        color: 'black'
    },
    subtitle: {
        fontWeight: '500',
        fontSize: 18,
        lineHeight: 20,
        marginBottom: 30,
        color: 'rgba(0,0,0,0.7)'
    },
    body: {
        fontWeight: '500',
        fontSize: 16,
        lineHeight: 18,
        marginVertical: 30,
        color: 'rgba(0,0,0,0.3)'
    }
})
export default memo(ArticleScreen);
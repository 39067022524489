import React from 'react';
import { useAppStore } from 'baseapp/context/appStore';
import { useRoute } from '@react-navigation/native';
import { StyleSheet, View, Text, ScrollView } from 'react-native';
import { theme } from '../theme';
import AdminFormulaComposition from './AdminFormulaComposition';
import AdminFormulaProcesado from './AdminFormulaProcesado';
import AdminFormulaQuality from './AdminFormulaQuality';
import AdminSearch from './AdminSearch';
import FormulaOption from './FormulaOption';
import FormulaPercentage from './FormulaPercentage';
import { useProductStore } from '../context/productContext';
import { ProductModel } from 'systemDomain';
import ProductFormAdmin from './ProductFormAdmin';
import ProductApi from "../api/Product.api";
import ApiCaller from "baseapp/core/ApiCaller";

type Props = {
    setSelectedFormula: Function;
    selectedFormula: string;
};


const AdminFormula = ({ setSelectedFormula, selectedFormula }: Props) => {
    const { session } = useAppStore();
    const { currentProduct,setCurrentProduct } = useProductStore();
    
    const route = useRoute();

    const currentUser = session.userInfo;
    const productApi = new ProductApi(new ApiCaller(currentUser.token));

    const updateProduct = async (productData: any) => {
        const product = new ProductModel(productData);
        try {
            const result: any = await productApi.update(product.getId(), product);
        } catch (e) {
            alert("Error actualizando el producto: " + e);
        }
    }

    return (
        <View style={{ flexDirection: 'row', flex: 1, paddingHorizontal: 20 }}>

            
            <View style={{ backgroundColor: theme.colors.background, flex: 4, paddingHorizontal: 20 }}>
            {currentProduct == undefined
            ?
                <>
                <View style={{ padding: 25, alignSelf: 'center', width: '90%' }}>
                    <Text style={styles.textOption}>SAPPI SCORE</Text>
                    <FormulaPercentage />
                </View>
                <View style={{ flex: 1, borderTopLeftRadius: 25, borderTopRightRadius: 25, shadowRadius: 8, shadowOpacity: 0.2, elevation: 5, backgroundColor: theme.colors.background }}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-around', marginTop: 20 }}>
                        <FormulaOption selectedFormula={selectedFormula} setSelectedFormula={setSelectedFormula} optionText={'Procesados'} optionPressed={'procesado'} />
                        <FormulaOption selectedFormula={selectedFormula} setSelectedFormula={setSelectedFormula} optionText={'Composición'} optionPressed={'composition'} />
                        <FormulaOption selectedFormula={selectedFormula} setSelectedFormula={setSelectedFormula} optionText={'Calidad'} optionPressed={'quality'} />
                    </View>
                    <ScrollView contentContainerStyle={{ flex: 1, paddingBottom: 40 }} style={{ height: 0 }}>
                        {selectedFormula == 'procesado'
                            ? <AdminFormulaProcesado />
                            : selectedFormula == 'composition'
                                ? <AdminFormulaComposition />
                                : <AdminFormulaQuality />}
                    </ScrollView>
                </View>
                </>
                :
                <ProductFormAdmin onClose={()=>setCurrentProduct()} caption="Guardar cambios" route={route} defaults={currentProduct} onSubmit={(productData: any) => { updateProduct(productData) }}/>
            }
            </View>
            
            <AdminSearch />
        </View>
    )
};

const styles = StyleSheet.create({
    textOption: {
        fontSize: 14,
        fontFamily: theme.fonts.regular.fontFamily,
        alignSelf: 'flex-start',
        marginBottom: 20
    },
    viewOption: {
        marginVertical: 10
    },
    paragraph: {
        color: theme.colors.colorParagraphItem,
        fontWeight: '500',
        fontSize: 14,
        lineHeight: 18,
    },
});

export default AdminFormula;

import React from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { Button } from 'react-native-paper';
import { theme } from '../theme';
import { ProductModel } from 'systemDomain';

type Props = {
    product: ProductModel;
    onProcesadoPress: Function;
    getProcesRating: Function;
    getProductionProcessColor: Function;
};

const Procesado = ({ product, onProcesadoPress, getProcesRating, getProductionProcessColor }: Props) => {

    return (
        <View style={{ flexDirection: 'row', marginTop: 24, flex: 1 }}>
            <View style={{ flexDirection: 'row', flex: 1 }}>
                <View style={{ flexDirection: 'column' }}>
                    <View style={{ flexDirection: 'column' }}>
                        <Text style={styles.labelName}>Procesado</Text>
                    </View>
                    <View style={{ flexDirection: 'column' }}>
                        <Text style={styles.sublabelName}>{getProcesRating(product.getProduction_process())}</Text>
                    </View>
                </View>
            </View>
            <View style={{ flexDirection: 'row', flex: 1, justifyContent: 'center', alignItems: 'center', paddingLeft: '20%', alignSelf: 'center', alignContent: 'center' }}>
                <Button
                    mode='contained'
                    style={{ flexDirection: 'column', flex: 1, backgroundColor: getProductionProcessColor(product?.getProduction_process()), borderRadius: 20 }}
                    uppercase={false}
                    labelStyle={{ color: 'white', fontSize: 14, fontWeight: '500', lineHeight: 17 }}
                    contentStyle={{ height: 40, flexDirection: 'row', width: '100%', justifyContent: 'center', alignSelf: 'center', alignContent: 'center', alignItems: 'center' }}
                    onPress={() => onProcesadoPress(product.getProduction_process())}
                >
                    {
                        product.getProduction_process() ?
                            product.getProduction_process() == "humedo" ?
                                "pasteurizado" :
                                product.getProduction_process()
                            : "?"
                    }
                </Button>
            </View>
        </View>
    )
};

const styles = StyleSheet.create({
    entry: {
        backgroundColor: theme.colors.background
    },
    labelName: {
        fontSize: 18,
        color: theme.colors.text,
        fontWeight: '500',
        lineHeight: 27

    },
    sublabelName: {
        color: 'rgba(159, 159, 159, 1)',
        fontSize: 14,
        lineHeight: 16,
        fontWeight: '400'
    },
    row: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-end",
        marginBottom: 4
    },
    valueLabel: {
        fontSize: 16,
        fontWeight: "bold",
        color: theme.colors.text
    },
    sectionTitle: {
        fontSize: 15,
        fontWeight: 'bold',
        color: theme.colors.secondary
    },

    smallCaption: {
        fontSize: 12,
        color: "#999"
    },
    sectionTitleSmall: {
        fontSize: 10,
        fontWeight: 'bold'
    },
    paragraph: {
        color: theme.colors.colorParagraphItem,
        fontWeight: '500',
        fontSize: 14,
        lineHeight: 18,
    }
});

export default Procesado;

import React, { memo } from 'react';
import {theme} from './theme';
import { View, ViewStyle } from 'react-native';

type Props = {
  children: React.ReactNode;
  style?: ViewStyle;
};

const Background = ({ children, style }: Props) => {
  return (<View style={{    
    backgroundColor: theme.colors.background,
    flex: 1,
    width: '100%',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    ...style}}>
    {children}
  </View>);
};

export default memo(Background);
import React, { useRef, memo, useState, useEffect } from 'react';
import { Camera } from 'expo-camera';
import { TouchableOpacity, View, Image, StyleSheet, Dimensions, Platform  } from 'react-native';
import { Dialog, FAB, Portal } from 'react-native-paper';
import { theme } from '../theme';

type Props = {
  onSuccess: Function;
  onFail: Function;
  hideCamera: Function;
  visibleCamera: boolean;
};

const TakePhotoComponent = ({onSuccess, onFail, visibleCamera, hideCamera}:Props) => {
  const windowWidth = Dimensions.get('window').width;
  const windowHeight = Dimensions.get('window').height
  const ref = useRef(null)
  const [hasPermission, setHasPermission] = useState(null);
  const [type, setType] = useState(Camera.Constants.Type.back);
  const [myPhoto,setMyPhoto] =useState<string>("");

  useEffect(() => {
    (async () => {
      const { status } = await Camera.requestPermissionsAsync();
      setHasPermission(status === 'granted');
    })();
  }, []);

  if (hasPermission === null) {
    return <View />;
  }
  if (hasPermission === false && Platform.OS != 'web') {
    alert('No tienes acceso a la cámara');
  }
  const _takePhoto = async () => {
    const photo = await ref.current.takePictureAsync({base64:true, quality: 0.2, skipProcessing: true, width: 1024, ratio: '16:9'})
    var data: any = photo;
    if (data.base64) {
        data = 'data:image/jpeg;base64,' + data.base64;
    } else {
        data = data.uri;
    }
    setMyPhoto(data);
  }
  
  const uploadPhoto = async () => {
    onSuccess(myPhoto);
    setMyPhoto("");
  }
  const cancelPhoto = async () => {
    setMyPhoto("");
    onFail()
  }
  return (
    <Portal >
    <Dialog visible={visibleCamera} dismissable={false} onDismiss={() => hideCamera()} style={{flex: 1, height: windowHeight, width: windowWidth, alignSelf: 'center'}}>
    <View style={{ flex: 1 }}>
      { myPhoto == "" ?
      <Camera style={{ flex: 1,  width: 580, alignSelf: 'center' }} type={type} ref={ref}  >
        <View style={{ flex: 1 }}>
          <FAB
            icon="camera-switch"
            color={theme.colors.text}
            style={styles.flipButton}
            onPress={() => {
              setType(
                type === Camera.Constants.Type.back
                ? Camera.Constants.Type.front
                : Camera.Constants.Type.back
                );
              }}>
          </FAB>
          <FAB icon="close" small={true} onPress={() => hideCamera()} style={styles.close} uppercase={false}></FAB>
          <TouchableOpacity
            style={styles.photoButton}
            onPress={_takePhoto}
          >
          </TouchableOpacity>
        </View>
      </Camera> :
      <View style={{flex: 1}}>
        <Image source={{ uri: myPhoto }} style={{flex: 4}} />
        <View style={{flexDirection: 'row', flex: 1, position: 'absolute', bottom: 50, justifyContent: 'space-around', alignSelf: 'center'}}>
          <FAB icon="close-thick" onPress={cancelPhoto} style={styles.cancelButton} uppercase={false}></FAB>
          <FAB icon="check-bold" onPress={uploadPhoto} style={styles.uploadButton} uppercase={false}></FAB>
        </View>
      </View>
      } 
    </View>
    </Dialog>
    </Portal>
  );
}
const styles = StyleSheet.create({
  photoButton: {
    position: 'absolute', 
    alignSelf: 'center', 
    backgroundColor: 'white', 
    borderColor: 'grey',
    bottom: 40, 
    height: 80, 
    width: 80, 
    borderRadius: 80, 
    borderWidth: 5
  },
  flipButton: {
    backgroundColor: 'grey', 
    borderColor: 'white',
    borderWidth: 3,
    position: 'absolute', 
    bottom: 40,
    width: 50, 
    height: 50, 
    alignSelf: 'flex-start', 
    alignItems: 'center',
    justifyContent: 'center',
    left: '20%',
    shadowOpacity: 0,
    elevation:0
  },
  cancelButton: {
    width: '45%',
    alignItems: 'center',
    padding: 0,
    borderRadius: 8,
    backgroundColor: 'white'
  },
  uploadButton: {
    width: '45%',
    alignItems: 'center',
    borderRadius: 8,
    padding: 0,
    backgroundColor: theme.colors.primary
  },
  close: {
    position: 'absolute',
    alignItems: 'center',
    top: 30,
    left: 100,
    backgroundColor: 'transparent',
  }
})
export default memo(TakePhotoComponent);
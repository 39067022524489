import React, { memo } from 'react';
import { StyleSheet } from 'react-native';
import { Button as PaperButton } from 'react-native-paper';
import { theme } from 'internalapp/theme';
import { LinearGradient } from 'expo-linear-gradient';

type ButtonType = {
  labelColor?: string,
  onPress?: Function,
  mode?: string,
  style?: any,
  color?: string | string[],
  children?: any
}
const Button = ({ mode, style, children, ...props }: ButtonType) => (
  <LinearGradient
    // Button Linear Gradient
    colors={theme.gradients.main}
    style={[{flex: 1, borderRadius:8, padding: 2.1}, style]}
    start={[0,0]}
    end ={[1,0]}
    >
    <LinearGradient
      colors={mode === "outlined"?["white", "white"]:["transparent", "transparent"]}
      style={styles.containerStyle}
      start={[0,0]}
      end ={[1,0]}
      >
      <PaperButton
        style={[
          styles.button
        ]}
        labelStyle={[
          styles.text, 
          mode === 'outlined' && { 
            color: props.labelColor?props.labelColor:theme.colors.primary,
          }
        ]}
        onPress={props.onPress}
        uppercase={false}
      >
        {children}
      </PaperButton>
    </LinearGradient>
  </LinearGradient>
);

const styles = StyleSheet.create({
  containerStyle: {
    flex: 1, 
    borderRadius:7
  },
  button: {
    alignSelf: 'center',
    width: "100%",
    position:"relative",
    top:2,
    borderRadius:7,
    borderWidth:0
  },
  text: {
    position: "relative",
    top: -2,
    fontFamily: theme.fonts.medium.fontFamily,
    fontWeight: 'bold',
    fontSize: 15,
    lineHeight: 26,
    color: theme.colors.background,    
    flex: 1
  },
});

export default memo(Button);

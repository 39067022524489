import React from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { Button, Dialog, Portal, Snackbar } from 'react-native-paper';
import { theme } from '../theme';
import SappiScoreModel from '../models/sappiScore/sappiScoreModel';
import { useSappiScoreStore } from '../context/sappiScoreContext';

type Props = {
    visibleUpload: boolean;
    hideRestart: Function;
    currentUser: any;
    sappiScoreChanging: SappiScoreModel;
};

const AdminRestartDialog = ({ visibleUpload, hideRestart, currentUser, sappiScoreChanging }: Props) => {

    const { restoreSappiScore } = useSappiScoreStore();
    const [snackVisible, setSnackVisible] = React.useState<boolean>(false);

    const onRestart = async () => {
        restoreSappiScore(currentUser);
        setSnackVisible(true);
        hideRestart();
    }

    return (
        <Portal>
            <Dialog onDismiss={() => hideRestart()} visible={visibleUpload} style={{ width: 500, alignSelf: 'center' }}>
                <View style={{ width: '100%', alignSelf: 'center', padding: 40, flex: 5 }}>
                    <View>
                        <Text style={styles.paragraph}>Estás seguro que quieres restablecer los valores a por defecto?</Text>
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-around', width: '100%' }}>
                        <Button uppercase={false} onPress={() => hideRestart()} labelStyle={{ color: theme.colors.primary, fontFamily: theme.fonts.regular.fontFamily }} style={{ width: '40%' }}>Cancelar</Button>
                        <Button uppercase={false} onPress={() => onRestart()} labelStyle={{ color: theme.colors.background, fontFamily: theme.fonts.regular.fontFamily }} style={{ backgroundColor: theme.colors.primary, borderRadius: 5, width: '40%' }}
                        >Guardar</Button>
                    </View>
                </View>
            </Dialog>
            <Snackbar
                visible={snackVisible}
                duration={3000}
                style={{ backgroundColor: theme.colors.text, alignSelf: 'center', width: '70%' }}
                onDismiss={() => setSnackVisible(false)}
                action={{
                    label: 'Cerrar',
                    onPress: () => setSnackVisible(false),
                }}>
                Se han reseteado los valores por defecto de la SappiScore.
            </Snackbar>
        </Portal>
    )
};

const styles = StyleSheet.create({
    paragraph: {
        color: theme.colors.text,
        fontSize: 18,
        lineHeight: 18,
        fontFamily: theme.fonts.light.fontFamily,
        marginBottom: 20
    },
});

export default AdminRestartDialog;

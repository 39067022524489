import React from 'react';
import { StyleSheet, View, Text, Image, TextInput } from 'react-native';
import { Divider, Switch } from 'react-native-paper';
import { theme } from '../theme';

type Props = {
    name: string;
    maxValue: string;
    value: string;
    setValue: Function;
    setMaxValue: Function;
    good: boolean;
    setGood: Function;
};
const FormulaCompositionRange = ({ name, maxValue, value, setValue, setMaxValue, setGood, good }: Props) => {

    const onToggleSwitch = () => setGood(!good);
    return (
        <View style={{ flexDirection: 'column', width: '80%', alignSelf: 'center', marginTop: 20 }}>
            <Text style={styles.textUppercase}>{name+" (%)"}</Text>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <View style={styles.boxInput}>
                    <Text style={styles.paragraph}>Valor (%)</Text>
                    <TextInput onChangeText={(text) => setValue(text)} value={value} style={styles.inputText} maxLength={3} />
                </View>
                <View style={styles.boxInput}>
                    <Text style={styles.paragraph}>Máximo (por 100g)</Text>
                    <TextInput onChangeText={(text) => setMaxValue(text)} value={maxValue} style={styles.inputText} maxLength={3} />
                </View>
            </View>
            <View style={{ flex: 1, flexDirection: 'column', justifyContent: 'center', width: '100%', marginTop: 20 }}>
                <Image style={{ transform: (good) ? [{ rotate: '0deg' }] : [{ rotate: '180deg' }], height: 20, width: '100%', resizeMode: 'contain', flex: 1 }} source={require('../assets/gradient.png')} />
                {/* <Image style={{transform: (isGood)?[{ rotate: '0deg'}]:[{ rotate: '180deg'}], height:13, width:'100%', resizeMode:'contain', flex:1}} source={require('../assets/gradient.png')}/> */}
            </View>
            <View style={{ alignSelf: 'center', alignItems: 'center', width: '100%', marginTop: 40, flexDirection: 'row', justifyContent: 'center' }}>
                <Text style={[styles.paragraph, { color: good ? theme.colors.grey : theme.colors.primary }]}>A menos contenido mejor</Text>
                <Switch value={good} onValueChange={onToggleSwitch} color={theme.colors.primary} style={{ marginHorizontal: 20 }} />
                <Text style={[styles.paragraph, { color: good ? theme.colors.primary : theme.colors.grey }]}>A más contenido mejor</Text>
            </View>
            <Divider style={{width: '100%', alignSelf: 'center', marginVertical: 40}}/>
        </View>
    )
};

const styles = StyleSheet.create({
    inputText: {
        backgroundColor: 'transparent',
        color: theme.colors.blue,
        fontFamily: theme.fonts.regular.fontFamily,
        fontSize: 16,
        width: 20
    },
    boxInput: {
        width: 200,
        backgroundColor: theme.colors.noSelected,
        height: 50,
        padding: 10,
        justifyContent: 'space-between',
        borderRadius: 10,
        flexDirection: 'row',
    },
    textUppercase: {
        color: theme.colors.primary,
        fontSize: 14,
        fontFamily: theme.fonts.regular.fontFamily,
        marginBottom: 20,
    },
    paragraph: {
        color: theme.colors.text,
        fontSize: 16,
        lineHeight: 18,
        fontFamily: theme.fonts.light.fontFamily,
        alignSelf: 'center'
    },
});

export default FormulaCompositionRange;

export interface LoginSocialInterface {
    _id?: string,
    name: string,
    email: string,
    userid: string,
}


export default class LoginSocialModel {
    props: LoginSocialInterface;

    constructor(loginSocialData: LoginSocialInterface) {
        this.props = loginSocialData;
    }

    getId(): string | undefined {
        return this.props._id;
    }

    get(key: string): any {
        if(this.props.hasOwnProperty(key)) {
            //@ts-ignore
            return this.props[key];
        }
        throw "Error: LoginSocial doesn't have the property: " + key;
    }

    toObject(): LoginSocialInterface {
        return this.props;
    }

    static keys(): string[] {
        return ["name", "email", "userid", ];
    }
}

export class LoginSocialCollection {
    loginSocialArr: LoginSocialModel[];

    constructor(loginSocialList: LoginSocialModel[]) {
        this.loginSocialArr = loginSocialList;
    }

    findBy(key: string, value: string): LoginSocialModel[] {
        return this.loginSocialArr.filter((loginSocial: LoginSocialModel) => loginSocial.get(key) === value);
    }

    length() {
        return this.loginSocialArr.length;
    }

    items() {
        return this.loginSocialArr;
    }
}


import * as React from 'react';
import { Platform, View, Dimensions, Linking } from 'react-native';
import { Avatar, Title, Text } from 'react-native-paper';
import { ProductModel} from 'systemDomain';
import Settings from 'baseapp/core/settings';
import { theme } from '../theme';
import SappiScore from './SappiScore';
import { getColor } from '../utils';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import DogCatImage from './DogCatImage';

type Props = {
  onGoToBuy:Function;
  product: ProductModel;
}


const ProductContentHeader = ({ product, onGoToBuy }: Props) => {
  product.props['shopLink'] = product.props['shopLink']?((typeof product.props['shopLink'] != "object" )?[product.props['shopLink']]:product.props['shopLink']):null
  const shopLinks = product.props['shopLink'] ? product?.get('shopLink').filter(link => link != "") : undefined;
  const haveShop = (product.props['shopData'] || shopLinks) ? true : false

  const getIcon = (product: ProductModel) => {  
    return product.getAnimal();
    // const icons = { malo: "emoticon-sad", bueno: "emoticon-happy", excelente: "emoticon-excited" };
    // return icons[getSappiRating(product)];
  }
  function capitalizeFirstLetter(stri: string) {
    const lowerCaseStri = stri.toLowerCase();
    const firstCapital = lowerCaseStri[0].toUpperCase();
    return firstCapital+lowerCaseStri.slice(1);
  }
  
  console.log(product.getAnimal())
  return (
    <View style={{ flexDirection: 'row', flex: 1, paddingHorizontal: 25, marginBottom: 20 }}>
      {/* Content */}
      <View style={{ flexDirection: 'column', flex: 5, justifyContent: 'center' }}>
        {/* Product Title */}
        {/* Share */}
        <View style={{ flexDirection: 'column' }}>
          <Title numberOfLines={2} style={{ lineHeight: 26, fontSize: 18, fontFamily: theme.fonts.regular.fontFamily }}>{capitalizeFirstLetter(product.getName())}</Title>
        </View>
        {/* Marca */}
        <View style={{ flexDirection: 'column' }}>
          <Text numberOfLines={1} style={{ marginTop: 0, fontSize: 14, fontFamily: theme.fonts.light.fontFamily, color: theme.colors.companyTextColor }}>{product.getCompany()}</Text>
        </View>
        {/* Punctuation */}
        <View style={{ flexDirection: 'row', marginBottom: 20, marginTop: 15 }}>
          <SappiScore fontSize={25} product={product}></SappiScore>
          <View style={{ flexDirection: 'column', marginLeft: 30 }}>
            {product.getAnimal() == 'dog cat'? 
            <DogCatImage size={35}  product={product}/> :
            <MaterialCommunityIcons
              name={getIcon(product)}
              color={getColor(product)}
              size={40}
              style={{ textAlign: "center" }}
              onPress={() => { Linking.openURL('https://sappi.app/la-formula/') }}
            />
           } 
          </View>
          <View style={{ flexDirection: 'column' }}>
            <MaterialCommunityIcons
              name={'help-circle-outline'}
              color={'rgba(236, 18, 119, 1)'}
              size={15}
              style={{ textAlign: "center" }}
              onPress={() => { Linking.openURL('https://sappi.app/la-formula/') }}
            />
          </View>
        </View>
        {/* Clipboard */}
        { haveShop ?
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <MaterialCommunityIcons
            name={'cart-outline'}
            color={theme.colors.primary}
            size={20}
            onPress={()=>onGoToBuy()}
          />
          <Text style={{ color: theme.colors.primary }} onPress={()=>onGoToBuy()}> Comprar</Text>
        </View> : null}
      </View>
      {/* Image */}
      <View style={{ flexDirection: 'column', flex: Dimensions.get('window').width > 800 ? 2 : 4, alignSelf: 'flex-end', justifyContent: 'center' }}>
        <Avatar.Image style={{ backgroundColor: 'transparent', shadowRadius: 5, shadowOpacity: 0.3, elevation: 5 }} size={Platform.OS === 'web' ? 160 : 180} source={{ uri: Settings.getImagesURL() + product.getPicture() }} />
      </View>
    </View>
  );
};

export default ProductContentHeader;
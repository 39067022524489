import * as React from 'react';
import { Platform, View } from 'react-native';
import { Card, Text} from 'react-native-paper';
import Rate from "./Rate";
import moment from 'moment';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import User from 'baseapp/models/user';
import ProductApi from "../api/Product.api";
import ApiCaller from "baseapp/core/ApiCaller";
import { ProductModel } from "systemDomain";
moment.locale('es')


type Props = {
  comentario: any;
  user:User;
  product: ProductModel;
  setDeleteRefresh:Function;
  setProduct: Function;
  deleteRefresh:boolean
}

const CommentCard = ({product, setProduct, comentario, user, setDeleteRefresh, deleteRefresh}:Props) => {

  const onDeleteComment = async () => {
    const productApi = new ProductApi(new ApiCaller(user.token));
    try{
      await productApi.deleteComment(product.props._id, comentario?.email, comentario?.timestamp);
      try{
        const productItem = await productApi.read(product.props._id);
        //Volevemos a leer el producto para que se actualizen los comentarios
        setProduct(productItem);
      }catch(e){}
    }catch(e){

    }
    setDeleteRefresh(!deleteRefresh);
  }
  return (
  <Card style={{ marginTop:7, marginLeft: (Platform.OS != 'web') ? 7:20, marginRight: 7, elevation: 2, width:300, height: 180, shadowRadius:5, borderColor:'#F0F0F0', borderWidth:0.3}}>
    <Card.Content style={{display:'flex', flex:1}}>
      <View style={{flexDirection:"column", marginTop:2, flexWrap:'wrap',flex:1}}> 
        <View style={{flexDirection:'row', flex:1, alignItems: 'center'}}>
          <Text style={{lineHeight: 16, fontSize: 14, fontWeight:'700', color:'black'}}>{comentario?.title}</Text>
          <Text style={{flex:2, textAlign:'right', lineHeight: 16, fontSize: 12, fontWeight:'400', color:'rgba(0,0,0,0.3)', fontStyle:'normal'}}>{moment(comentario?.timestamp).fromNow()}</Text>
        </View>
        <View style={{flexDirection:'row', alignItems: 'center', marginLeft: -3 }}>
        <Rate maxRate={5} rate={comentario?.rate} rateColor='#F9A400' unrateColor='#C4C4C4' size={20}/>  
        <Text style={{flex:3, lineHeight: 16, fontSize: 12, fontWeight:'100', color:'rgba(0,0,0,0.3)', fontStyle:'normal', textAlign:'right'}}>{comentario?.name.includes('@')?comentario?.name.split('@')[0]:comentario?.name}</Text>
        </View>
        <View style={{flexDirection:'column', flex: 6}}>
          <Text style={{ marginTop:10, lineHeight: 18, fontSize: 14, fontWeight:'100', fontFamily: 'Quicksand-Regular', color:'black', fontStyle:'normal'}}>{comentario?.description}</Text>
        </View> 
      </View>
    </Card.Content>
    {
      user.type=='admin' || user.email == comentario?.email?
      <MaterialCommunityIcons 
        name="close" 
        color={'rgba(0,0,0,0.3)'}
        size={25}
        style={{position:'absolute', top:5, right:5}}
        onPress={ ()=>{ onDeleteComment()}}
      />:null
    }
  </Card>
  );
};

export default CommentCard;
import MaterialCommunityIcons from '@expo/vector-icons/build/MaterialCommunityIcons';
import { useRoute } from '@react-navigation/native';
import React, { useState } from 'react';
import { StyleSheet, View, Text, ScrollView } from 'react-native';
import { Button, Snackbar, Portal, Dialog, List } from 'react-native-paper';
import { ProductModel } from 'systemDomain';
import { theme } from '../theme';
import ProductForm from './ProductForm';
import ProductApi from "../api/Product.api";
import ApiCaller from "baseapp/core/ApiCaller";
import { useAppStore } from 'baseapp/context/appStore';
import AdminProductAditive from './AdminProductAditive';
import AdminApi from '../api/AdminApi';
import * as Linking from 'expo-linking';
import getEnvVars from '../environments';
import EdtiProducts from "./EditProducts";
import { useProductStore } from '../context/productContext';
import ProductFormAdmin from './ProductFormAdmin';

const AdminProduct = () => {
    const route = useRoute();
    const { session } = useAppStore();
    const { setCurrentProduct, currentProduct } = useProductStore()

    const [adminProductsOption, setAdminProductsOption] = useState<string>('unselected');
    const currentUser = session.userInfo;
    const productApi = new ProductApi(new ApiCaller(currentUser.token));
    const [snackbarVisible, setSnackbarVisible] = React.useState<boolean>(false);
    const [productDialogVisible, setProductDialogVisible] = useState<boolean>(false);
    const [expanded, setExpanded] = useState<boolean>(false);
    const [selectedDownload, setSelectedDownload] = useState<string>();

    const snackbarMsg = 'Este aditivo ya existe'
    const filters = { 'pienso': 'dry', 'humedo': 'humid', 'natural': 'natural', 'snack': 'snack', 'cat': 'cat', 'dog': 'dog' }

    const downloadProducts = () => Linking.openURL(getEnvVars().apiUrl + AdminApi.downloadProducts(currentUser))
    const customDownloadProducts = (animal: string, foodtype: string) => Linking.openURL(getEnvVars().apiUrl + AdminApi.customDownloadProducts(currentUser,animal, foodtype))

    const onDownloadPress = () => {
        if (selectedDownload == 'all')
            try {
                downloadProducts();
                return;
            } catch (e) { console.log(e) }
        else {
            try {
                customDownloadProducts(filters[selectedDownload?.split('-')[0]], filters[selectedDownload?.split('-')[1]]);
                return;
            } catch (e) { console.log(e) }
        }
    }
    const generateProduct = async (productData: any) => {
        const product = new ProductModel(productData);
        try {
            const result: any = await productApi.create(product);
            alert('Producto agregado correctamente');
            setAdminProductsOption('unselected')
        } catch (e) {
            alert("Error agregando el producto: " + e);
        }
    }
    const closeAditives = () => {
        setAdminProductsOption('unselected')
    }
    const productAlreadyExist = () => {
        setSnackbarVisible(true)
    }
    const getDownloadText = () => {
        const obj = {
            "cat-pienso": "Gato (Piensos)",
            "cat-humedo": "Gato (Húmedo)",
            "cat-natural": "Gato (Natural)",
            "cat-snack": "Gato (Snack)",
            "dog-pienso": "Perro (Piensos)",
            "dog-humedo": "Perro (Húmedo)",
            "dog-natural": "Perro (Natural)",
            "dog-snack": "Perro (Snack)",
            "all": "Todos"
        }
        return obj[selectedDownload]
    }
    const updateProduct = async (productData: any) => {
        const product = new ProductModel(productData);
        try {
            const result: any = await productApi.update(product.getId(), product);
        } catch (e) {
            alert("Error actualizando el producto: " + e);
        }
    }
    const productsContent = {
        'unselected': () =>
            <View style={{ shadowRadius: 8, shadowOpacity: 0.2, elevation: 5, backgroundColor: theme.colors.background, width: '50%', padding: 40, borderRadius: 10, alignSelf: 'center' }}>
                <Text style={styles.textOption}>ADMINISTRAR PRODUCTOS</Text>
                <Button style={styles.button} labelStyle={styles.buttonLabel} uppercase={false} onPress={() => { setAdminProductsOption('addProduct') }}
                >Añadir producto</Button>
                <Button style={styles.button} labelStyle={styles.buttonLabel} uppercase={false} onPress={() => { setAdminProductsOption('editProducts') }}
                >Gestionar productos</Button>
                <Button style={styles.button} labelStyle={styles.buttonLabel} uppercase={false} onPress={() => { setAdminProductsOption('aditives') }}
                >Gestionar aditivos</Button>
                {/* <Button style={styles.buttonDisabled} labelStyle={styles.buttonLabel} uppercase={false}
                            >Estadísticas</Button> */}
                <Button style={styles.button} onPress={() => setProductDialogVisible(true)} labelStyle={styles.buttonLabel} uppercase={false}
                >Descargar productos</Button>
            </View>,
        'aditives': () => <AdminProductAditive closeAditives={() => closeAditives()} productAlreadyExist={() => productAlreadyExist()} />,
        'addProduct': () => <ProductForm caption="Agregar producto" route={route} defaults={{}} onSubmit={(productData: any) => { generateProduct(productData) }} />,
        'editProducts': () => <EdtiProducts onBack={() => setAdminProductsOption('unselected')} />,
        'currentProduct': () => <ProductFormAdmin wide={true} caption="Guardar cambios" route={route} defaults={currentProduct} onSubmit={(productData: any) => { updateProduct(productData) }} />
    }

    return (
        <View style={{ flexDirection: 'row', flex: 1, width: '100%' }}>
            <View style={{ flex: 4, backgroundColor: theme.colors.background, justifyContent: 'center' }}>
                {/* CONTENT */}
                {productsContent[currentProduct == undefined ? adminProductsOption : 'currentProduct']()}
                {adminProductsOption != 'unselected'
                    ? <MaterialCommunityIcons
                        name={'close'}
                        color={'rgba(0,0,0,0.3)'}
                        size={25}
                        style={{ position: 'absolute', top: 20, left: 20, zIndex: 100 }}
                        onPress={() => { setAdminProductsOption('unselected'), setCurrentProduct(undefined) }}
                    /> : null}
                {/* ALERT SNACKBAR */}
                <Snackbar
                    visible={snackbarVisible}
                    duration={3000}
                    style={{ backgroundColor: theme.colors.text, alignSelf: 'center', width: '70%' }}
                    onDismiss={() => setSnackbarVisible(false)}
                    theme={{ colors: { surface: 'white', accent: theme.colors.primary }, }}
                    action={{
                        label: 'Cerrar',
                        onPress: () => setSnackbarVisible(false),
                    }}>
                    {snackbarMsg}
                </Snackbar>
                {/* DIALOG */}
                <Portal>
                    <Dialog style={{ width: 400, padding: 20, height: 450, alignSelf: "center" }} visible={productDialogVisible} onDismiss={() => { setProductDialogVisible(false); setSelectedDownload(undefined) }}>
                        <View style={{ flex: 1 }}>
                            <ScrollView style={{ height: 0 }}>
                                <List.Accordion
                                    title={selectedDownload == undefined ? "Que desea descargar?" : getDownloadText()}
                                    left={props => <List.Icon {...props} icon="download" />}
                                    expanded={expanded}
                                    onPress={() => setExpanded(!expanded)}>
                                    <List.Item onPress={() => { setExpanded(false); setSelectedDownload('all') }} title="Todos" />
                                    <List.Item onPress={() => { setExpanded(false); setSelectedDownload('cat-pienso') }} title="Gato (Piensos)" />
                                    <List.Item onPress={() => { setExpanded(false); setSelectedDownload('cat-humedo') }} title="Gato (Húmedo)" />
                                    <List.Item onPress={() => { setExpanded(false); setSelectedDownload('cat-natural') }} title="Gato (Natural)" />
                                    <List.Item onPress={() => { setExpanded(false); setSelectedDownload('cat-snack') }} title="Gato (Snack)" />
                                    <List.Item onPress={() => { setExpanded(false); setSelectedDownload('dog-pienso') }} title="Perro (Piensos)" />
                                    <List.Item onPress={() => { setExpanded(false); setSelectedDownload('dog-humedo') }} title="Perro (Húmedo)" />
                                    <List.Item onPress={() => { setExpanded(false); setSelectedDownload('dog-natural') }} title="Perro (Natural)" />
                                    <List.Item onPress={() => { setExpanded(false); setSelectedDownload('dog-snack') }} title="Perro (Snack)" />
                                </List.Accordion>
                            </ScrollView>
                        </View>
                        <Button disabled={!selectedDownload} style={[styles.button, { backgroundColor: selectedDownload ? theme.colors.primary : theme.colors.grey }]} onPress={() => onDownloadPress()} labelStyle={styles.buttonLabel} uppercase={false}
                        >Descargar</Button>
                    </Dialog>
                </Portal>
            </View>
        </View>
    )
};

const styles = StyleSheet.create({
    button: {
        backgroundColor: theme.colors.primary,
        marginBottom: 20,
        width: '90%',
        alignSelf: 'center',
        height: 60,
        borderRadius: 10,
        justifyContent: 'center'
    },
    buttonDisabled: {
        backgroundColor: theme.colors.grey,
        marginBottom: 20,
        width: '90%',
        alignSelf: 'center',
        height: 60,
        borderRadius: 10,
        justifyContent: 'center'
    },
    buttonLabel: {
        color: theme.colors.background,
        fontFamily: theme.fonts.regular.fontFamily,
    },
    textOption: {
        fontSize: 14,
        fontFamily: theme.fonts.regular.fontFamily,
        alignSelf: 'center',
        marginBottom: 20
    },
});

export default AdminProduct;

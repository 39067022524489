import React, { useState } from 'react';
import { StyleSheet, View, Text, Image} from 'react-native';
import { Button, TextInput } from 'react-native-paper';
import { theme } from '../theme';
import * as ImagePicker from 'expo-image-picker';
import ArticleApi from "../api/ArticleApi";
import { useAppStore } from 'baseapp/context/appStore';
import {ArticleModel} from 'systemDomain';

type Props = {
    close: Function;
}
const AdminAddArticle = ({ close }: Props) => {
    const defaultData = {
        title: "",
        subtitle: "",
        image: "",
        link: ""
    }

    const [articleData, setArticleData] = useState(defaultData);
    const { session } = useAppStore();
    const currentUser = session.userInfo;

    const isInValid = () => {
        let error = false;
        if (articleData.title == "" || articleData.subtitle == "" || articleData.image == "" || articleData.link == "" ) {
            error = true
        }
        return error; 
    }

    const pickImage = async () => {
        // No permissions request is necessary for launching the image library
        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.All,
            allowsEditing: true,
            aspect: [4, 3],
            quality: 1,
        });

        if (!result.cancelled) {
            let newArticleData = {};
            newArticleData['image'] = result?.uri;
            setArticleData( {...articleData, ... newArticleData} );
        }
    };
    const onAddArticle = async () => {
        try{
            const data = await ArticleApi.create(currentUser, new ArticleModel(articleData));
            close();
        }catch(e){}
    }
    return (
        <View style={styles.mainContainer}>
            <TextInput
                label="Título"
                mode='outlined'
                value={articleData?.title}
                style={styles.textInput}
                onChangeText={(text) => {
                    let newArticleData = {}
                    newArticleData["title"] = text;
                    setArticleData({ ...articleData, ...newArticleData })
                }}
            />
            <TextInput
                label="Subtitulo"
                mode='outlined'
                multiline = {true}
                value={articleData?.subtitle}
                style={[styles.textInput, { height: 150}]}
                onChangeText={(text) => {
                    let newArticleData = {}
                    newArticleData["subtitle"] = text;
                    setArticleData({ ...articleData, ...newArticleData })
                }}
            />
            <TextInput
                label="Enlace"
                mode='outlined'
                multiline = {true}
                value={articleData?.body}
                style={[styles.textInput]}
                onChangeText={(text) => {
                    let newArticleData = {}
                    newArticleData["link"] = text;
                    setArticleData({ ...articleData, ...newArticleData })
                }}
            />
            <View style={{ marginVertical: 20, alignItems: 'center' }}>
                <Button mode={'contained'} icon= 'camera' style={{  }} onPress={pickImage}>Imágen</Button>
                {articleData['image'] && <Image source={{ uri: articleData['image'] }} style={{ width: 200, height: 200 }} />}
            </View>
            <Button disabled={isInValid()} onPress={() => onAddArticle()} style={[styles.button, { backgroundColor: isInValid() ? 'rgba(0,0,0,0.4)' : theme.colors.primary }]} labelStyle={styles.buttonLabel} uppercase={false}
            >Añadir artícilo</Button>
            {isInValid() ? <Text style={{ alignSelf: 'center', marginBottom: 20 }}>{"Complete toda la información del artículo para poder añadirlo"}</Text> : null}
        </View>
    )
};

const styles = StyleSheet.create({
    textInput: {
        marginBottom: 30,
        width: '100%',
        maxWidth: 500,
        alignSelf:'center'
    },
    mainContainer:{
        flex: 1, 
        marginTop: 40,
        backgroundColor: theme.colors.background, 
        justifyContent: 'center',
        maxWidth: 800,
        width: '90%',
        alignSelf:'center'
    },
    button: {
        backgroundColor: theme.colors.primary,
        marginBottom: 20,
        width: '100%',
        maxWidth: 400,
        alignSelf: 'center',
        height: 60,
        borderRadius: 10,
        justifyContent: 'center'
    },
    buttonLabel: {
        color: theme.colors.background,
        fontFamily: theme.fonts.regular.fontFamily,
    },
    textOption: {
        fontSize: 14,
        fontFamily: theme.fonts.regular.fontFamily,
        alignSelf: 'center',
        marginBottom: 20
    },
});


export default AdminAddArticle;

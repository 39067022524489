import React from 'react';
import { StyleSheet, View, TextInput } from 'react-native';
import { theme } from '../theme';
import { MaterialCommunityIcons } from '@expo/vector-icons';

type Props = {
    name: string;
    value: number;
    setIngredientsList: Function;
    ingredientsList: any[];
};

const IngredientsList = ({ name, value, ingredientsList, setIngredientsList }: Props) => {
    const onErase = () => {
        const tmpActualizedIngredientList = ingredientsList.filter((item:any) =>(item.name != name));
        setIngredientsList(tmpActualizedIngredientList);
    }
    return (
        <View style={{ flexDirection: 'row', justifyContent: 'space-between', backgroundColor: '#F5F5F5', height: 40, borderRadius: 5, padding: 10, marginBottom: 10 }}>
            <TextInput value={name} placeholderTextColor={theme.colors.blue} style={styles.inputText} onChangeText={(text) => name = text}></TextInput>
            <View style={{ flexDirection: 'row' }}>
                <TextInput value={value} placeholderTextColor={theme.colors.blue} style={styles.inputNumber} onChangeText={(text) => value = text} maxLength={3}></TextInput>
                <MaterialCommunityIcons
                    name="close"
                    size={20}
                    color={theme.colors.grey}
                    onPress={() => onErase()}
                />
            </View>
        </View>
    )
};

const styles = StyleSheet.create({
    inputText: {
        backgroundColor: 'transparent',
        color: theme.colors.blue,
        fontFamily: theme.fonts.regular.fontFamily,

    },
    inputNumber: {
        backgroundColor: 'transparent',
        color: theme.colors.primary,
        fontFamily: theme.fonts.regular.fontFamily,
        width: 30,

    }
});

export default IngredientsList;

import React, { memo, useState } from 'react';
import { View, StyleSheet, Keyboard} from 'react-native';
import Background from '../Background';
import Logo from '../components/Logo';
import Button from 'baseapp/components/Button';
import { TextInput } from 'react-native-paper';
import { theme } from '../theme';
import { useEffect } from 'react';
import ApiCaller from 'baseapp/core/ApiCaller';
import { CommonActions } from '@react-navigation/native'; 

type Props = {
    navigation: any;
};
const ChangePasswordScreen = ({ navigation }: Props) => {
    const [password, setPassword] = useState("");
    const [rePassword, setRePassword] = useState("");
    const [token, setToken] = useState<string>();

    const resetStackNavigator = (routeName:string) => {
        const resetAction = CommonActions.reset({
            index: 1,
            routes: [{ name: routeName}]
        });
        navigation.dispatch(resetAction);
    }

    useEffect (() =>{
        let initialUrl = window.location.href;
        if(initialUrl.includes('?token=')){
            const splittedUrl = initialUrl.split('?token=');
            let tokenTmp = splittedUrl[1];
            setToken(tokenTmp);
        }
    },[])
    
    const _onChangePasswordPressed = async () => {
        if (password != rePassword) {
            console.log(password, rePassword);
            alert("Las contraseñas no coinciden");
            return;
        }
        const apiCaller = new ApiCaller(token);
        try{
            const res = await apiCaller.call('/v1/changePassword/', 'POST', {password});
            console.log("Response", res)
            if(res['modifiedCount'] == 1){
                alert('Contraseña cambiada correctamente');
                resetStackNavigator('login');
                setPassword('');
                setRePassword('')
            }
            else if(res.name == 'TokenExpiredError'){
                alert('El token ha expirado! Los tokens expiran en 15 minutos. Inténtalo de nuevo!')
                setPassword('');
                setRePassword('')
            }
            
        }catch(e){
            alert("Se ha producido un error");
        }
    }
        return (
        <Background style={{alignItems:"center"}}>
        <View style={{ maxWidth: 600, width: '100%', flex: 1, flexDirection: "column", justifyContent: 'center', backgroundColor: theme.colors.background, padding: 15 }}>
            <View style={{ flex:3, flexDirection: "row", justifyContent: 'center' }}>
                <Logo></Logo>
            </View>
            <View style={{flex: 7, flexDirection:'column'}}>
                <TextInput
                label="Contraseña"
                returnKeyType="done"
                onChangeText={(text: string) => setPassword(text)}
                secureTextEntry
                value={password}
                onSubmitEditing={Keyboard.dismiss}
                style={styles.entry}
                />
                <TextInput
                label="Repetir contraseña"
                returnKeyType="done"
                onChangeText={(text: string) => setRePassword(text)}
                secureTextEntry
                value={rePassword}
                onSubmitEditing={Keyboard.dismiss}
                style={styles.entry}
                />
                <View style={{ flexDirection: "row", marginTop:40, justifyContent: 'center', width:"70%", alignSelf:"center" }}>
                <Button onPress={_onChangePasswordPressed}>
                    Change Password
                </Button>
                </View>
            </View>
        </View>
        </Background>
        );
};


const styles = StyleSheet.create({
    entry: {
        backgroundColor: theme.colors.background,
        marginBottom:30

    },
    label: {
        color: theme.colors.text,
    },
    link: {
        fontWeight: 'bold',
        color: theme.colors.primary,
    },
});
export default memo(ChangePasswordScreen);
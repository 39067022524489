export interface PushNotificationInterface {
    _id?: string,
    token: string,
}


export default class PushNotificationModel {
    props: PushNotificationInterface;

    constructor(pushNotificationData: PushNotificationInterface) {
        this.props = pushNotificationData;
    }

    getId(): string | undefined {
        return this.props._id;
    }

    get(key: string): any {
        if(this.props.hasOwnProperty(key)) {
            //@ts-ignore
            return this.props[key];
        }
        throw "Error: PushNotification doesn't have the property: " + key;
    }

    toObject(): PushNotificationInterface {
        return this.props;
    }

    static keys(): string[] {
        return ["token", ];
    }
}

export class PushNotificationCollection {
    pushNotificationArr: PushNotificationModel[];

    constructor(pushNotificationList: PushNotificationModel[]) {
        this.pushNotificationArr = pushNotificationList;
    }

    findBy(key: string, value: string): PushNotificationModel[] {
        return this.pushNotificationArr.filter((pushNotification: PushNotificationModel) => pushNotification.get(key) === value);
    }

    length() {
        return this.pushNotificationArr.length;
    }

    items() {
        return this.pushNotificationArr;
    }
}


import React, { memo } from 'react';
import { StyleSheet, View, Text} from 'react-native';
import { ActivityIndicator } from 'react-native-paper';

const Loading = ({text}:{text:string}) => (
    <View style={{marginTop: "20%",flex:1, flexDirection:"column", alignContent:"center"}}>
        <Text style={{fontSize: 20, margin: 20, fontWeight: "bold", color: '#aaa', textAlign:"center"}}>{text}</Text>
        <ActivityIndicator size="large" animating={true} color="#ccc"></ActivityIndicator>
    </View>
);

const styles = StyleSheet.create({

});

export default memo(Loading);

import React, { memo } from "react";
import { View, StyleSheet } from "react-native";
import * as Linking from 'expo-linking';
import { Caption, Paragraph, Text, Divider } from 'react-native-paper';
import { theme } from "../theme";
import MenuButtons from "./MenuButtons";
import { useNavigation } from "@react-navigation/native";
import { useAppStore } from 'baseapp/context/appStore';
import { useAnalyticsStore } from "../context/analytics";
import conf from "../conf";

type Props = {
    sheetREF: any;
}
const BottomSheetContent = ({ sheetREF }: Props) => {
    const { profile, session, logout } = useAppStore();
    const { AnalyticsLogEvent } = useAnalyticsStore();
    const currentUser = session.userInfo;
    const navigation = useNavigation();
    return (
        <View style={{ flex: 1, padding: 40 }}>
            {
                session.loggedIn ?
                    <View style={styles.userInfoSection}>
                        <Text style={styles.title}>{currentUser.email}</Text>
                        <View style={styles.section}>
                            <Paragraph style={[styles.paragraph, styles.caption]}>
                                {profile?.get('history').length}
                            </Paragraph>
                            <Caption style={styles.caption} onPress={() => { navigation.navigate('home', { p: 'history' }); sheetREF.current.close() }}>Vistos</Caption>
                        </View>
                        <View style={styles.section}>
                            <Paragraph style={[styles.paragraph, styles.caption]}>
                                {profile?.get('favorites').length}
                            </Paragraph>
                            <Caption style={styles.caption} onPress={() => { navigation.navigate('home', { p: 'favorites' }); sheetREF.current.close() }}>Favoritos</Caption>
                        </View>
                    </View>
                    :
                    <View style={styles.userInfoSection}>
                        <Text style={styles.title}>Invitado</Text>
                    </View>
            }
            <Divider style={{ marginBottom: 20, marginTop: 10 }} />
            <View style={{ alignItems: 'flex-start' }}>
                <MenuButtons icon={'web'} iconText={'Web'} onPress={() => { Linking.openURL('https://sappi.app') }} />
                <MenuButtons icon={'instagram'} iconText={'Instagram'} onPress={() => { Linking.openURL('https://www.instagram.com/sappiapp/') }} />
                <MenuButtons icon={'email-outline'} iconText={'Mail'} onPress={() => { Linking.openURL('https://sappi.app/contacto/') }} />
                <MenuButtons icon={'whatsapp'} iconText={'Whatsapp'} onPress={() => { Linking.openURL('https://api.whatsapp.com/send/?phone=34687162880&text=Hola%2C%0D%0AMi+nombre+es%3A%0D%0AOs+escribo+porque+quiero+saber%3A&app_absent=0') }} />
                <MenuButtons icon={'facebook'} iconText={'Facebook'} onPress={() => { Linking.openURL('https://www.facebook.com/sappiapp/') }} />
                <MenuButtons icon={'book-outline'} iconText={'News Feed'} onPress={() => {navigation.navigate('home', { p: 'articles' }); sheetREF.current.close()}} />
            </View>
            <Divider style={{ marginBottom: 20 }} />
            <View style={{ flex: 2 }}>
                <Text
                    onPress={() => {
                        AnalyticsLogEvent("Become a Sappier", { "user": session.userInfo.email ? session.userInfo.email : "guest" });
                        Linking.openURL('https://sappi.app/become-a-sappier/')
                    }}
                    style={{ fontWeight: '400', fontSize: 16, lineHeight: 27, color: 'rgba(236, 18, 119, 1)', marginBottom: 20, fontFamily: theme.fonts.light.fontFamily }}>
                    ¿Quieres ser sappier?
                </Text>
                <Text onPress={() => {
                    AnalyticsLogEvent("Sappi School", { "user": session.userInfo.email ? session.userInfo.email : "guest" });
                    Linking.openURL('https://sappi.app/formacion-alimentacion-animal/')
                }} style={{ fontWeight: '100', fontSize: 16, lineHeight: 27, marginBottom: 20, fontFamily: theme.fonts.light.fontFamily }}>Sappi School</Text>
            </View>
            <View style={{ flexDirection: 'row', alignItems: 'center', alignContent: 'center', flex: 1, justifyContent: 'space-between' }}>
                {/* LOGOUT BUTTON */}
                {
                    session.loggedIn ?
                        <Text style={{ fontSize: 16, fontFamily: theme.fonts.light.fontFamily, lineHeight: 24, textAlignVertical: 'top' }} onPress={async () => { logout(); }}>Cerrar sesión</Text>
                        :<Text style={{ fontSize: 16, fontFamily: theme.fonts.light.fontFamily, lineHeight: 24, textAlignVertical: 'top' }} onPress={async () => { navigation.navigate('login') }}>Iniciar sesión</Text>
                }

                <Text style={{ fontSize: 16, fontFamily: theme.fonts.light.fontFamily, lineHeight: 24, textAlignVertical: 'top', opacity: 0.2 }}>{conf.version}</Text>
            </View>
        </View>
    );
}
const styles = StyleSheet.create({
    userInfoSection: {
    },
    title: {
        fontFamily: theme.fonts.regular.fontFamily,
        fontSize: 20,
        lineHeight: 28,
        marginBottom: 20
    },
    caption: {
        fontSize: 14,
        lineHeight: 14,
        padding: 4,
    },
    section: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 12
    },
    paragraph: {
        fontWeight: 'bold',
        marginRight: 3,
    },
});
export default memo(BottomSheetContent);
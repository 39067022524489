import create from "zustand";
import produce from "immer";

type RegisterStoreData = {
    fields: any,
    setField: Function
}

export const useRegisterStore = create<RegisterStoreData>((set) => ({
    fields: {},
    setField: (key, payload) => set(produce((draft:RegisterStoreData) => {
        draft.fields[key]  = payload;
    }))
}));
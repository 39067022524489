import DemoScreen from './pages/Demo';
import { HomeScreen, ItemScreen, LoadingScreen, ScanScreen, ForgotPasswordScreen, ChangePasswordScreen, TestScreen, AdminScreen, TutorialScreen, ItemGuestScreen, AddScreen, DeleteAccountInformationScreen} from './pages';
import EditScreen from './pages/Edit';
import ArticleScreen from './pages/ArticleScreen';

//to pass parameters to pages:
//route: {path: "view/:id", parse: {id: String}}
//and in page, get param with:
//const id = route.params?.id;

export default [
    {
        component: HomeScreen,
        name: "home",
        fallback: true,
        header: 'default'
    },
    {
        component: AdminScreen,
        name: "admin",
        requireAuthLevel: ['user','admin'],
        header: "noHeader"
    },
    {
        component: TutorialScreen,
        name: "tutorial",
        header: "onlyBack"
    },
    {
        component: TestScreen,
        name: "test",
        header: "onlyBack"
    },
    {
        component: ItemScreen,
        name: "itembarcode",
        requireAuthLevel: ['user', 'admin'],
        route: {path: "item/bc/:barcode", parse: {barcode: String}},
        header: "default"
    },
    {
        component: ArticleScreen,
        name: "article",
        route: {path: "article/:id", parse: {id: String}},
        header: "onlyBack"
    },
    {
        component: ScanScreen,
        name: "scan",
        requireAuthLevel: ['user', 'admin'],
        header: "onlyBack"
    },
    {
        component: AddScreen,
        name: "add",
        requireAuthLevel: ['user', 'admin'],
        header: "onlyBack"
    },
    {
        component: EditScreen,
        name: "edit",
        requireAuthLevel: ['user', 'admin'],
        route: {path: "edit/:id", parse: {id: String}},
        header: "onlyBack"
    },
    {
        component: ForgotPasswordScreen,
        name: "forgotpassword",
        header: "onlyBack"
    },
    {
        component: ChangePasswordScreen,
        name: "changepassword",
        header: "default"
    },
    {
        component: ItemScreen,
        name: "item",
        requireAuthLevel: ['user', 'admin'],
        route: {path: "item/:id", parse: {id: String}},
        header: "default"
    },
    {
        component: ItemGuestScreen,
        name: "itemGuest",
        route: {path: "itemGuest/:id", parse: {id: String}},
        header: "default"
    },
    {
        component: DeleteAccountInformationScreen,
        name: "deleteAccountInformation",
        header: "default"
    }
];


import { ProductModel } from "systemDomain";
import { useSappiScoreStore } from "../context/sappiScoreContext";

export const emailValidator = (email: string) => {
  const re = /\S+@\S+\.\S+/;

  if (!email || email.length <= 0) return 'Email cannot be empty.';
  if (!re.test(email)) return 'Ooops! We need a valid email address.';

  return '';
};

export const passwordValidator = (password: string) => {
  if (!password || password.length <= 0) return 'Password cannot be empty.';

  return '';
};

export const nameValidator = (name: string) => {
  if (!name || name.length <= 0) return 'Name cannot be empty.';

  return '';
};

//TODO: move to domain object
export const getSappiRating = (product: ProductModel) => {
  if (product.getSappiScore() >= 6) {
    return "excelente";
  } else if (product.getSappiScore() < 4) {
    return "malo";
  } else {
    return "bueno";
  }
}
// Calidad segun procesado
export const getProcesRating = (process: string) =>{

  if(process === 'extrusionado'){
    return 'malo';
  }
  else if (process === 'prensado en frío' || process === 'humedo'){
    return 'bueno';

  }
  else if (process === 'cocinado' || process === 'congelado' || process == 'snack' || process == 'deshidratado'){
    return 'excelente';
  }
  else{
    return '';
  }
}

const colors:any = {"unknown": "#222","malo": 'red', "bueno": '#f8961e', "excelente": '#2b9348'};

export const getColor = (product: ProductModel) => {

  const rating = getSappiRating(product);
  return colors[rating];
}

export const getNutValueColor = (value:number, type:string) => {
  const rating = getNutValueRating(value, type);
  return colors[rating];
}

export const getNutValueRating = (value:number, type:string):string => {
  if(value == undefined) {
    return 'unknown';
  }
  switch(type) {
    case 'protein':
      return value < 18.2 ? 'malo' : value <= 33.8? 'bueno': 'excelente';
    case 'fat':
      return value < 9.8 ? 'excelente' : value <= 18.2? 'bueno': 'malo';
    case 'carbs':
      return value < 20 ? 'excelente' : value <= 30? 'bueno': 'malo';
    case 'fiber':
      return value < 2.43 ? 'malo' : value <= 4.055? 'bueno': 'excelente';
    case 'ash':
      return value < 6.8 ? 'excelente' : value <= 10.2? 'bueno': 'malo';
  }
  return 'unknown';
}

export const getPercentNutValue = (value:number, type:string):number => {
  var percent = 0;
  switch(type) {
    case 'protein':
      percent = value*66.6/34;
      break;
    case 'fat':
      percent = value*66.6/18.2;
      break;
    case 'carbs':
      percent = value*66.6/30;
      break;
    case 'fiber':
      percent = value*66.6/4.055;
      break;
    case 'ash':
      percent = value*66.6/10.2;
      break;
  }
  return Math.min(percent, 96);
}

export const  getProductionProcessColor = (productionProcess: string) => {
  if (!productionProcess) {
    return "#222";
  }
  switch (productionProcess) {
    case "extrusionado":
      return "red";
    case "humedo":
      return "#f8961e";
    case "prensado":
      return "#f8961e";
    case "natural":
      return "#2b9348";
    case "cocinado":
      return "#2b9348";
    case "deshidratado":
      return "#2b9348";
    case "snack":
      return "#2b9348";
  }
  return "#222";
}

export const getBarValueColor = (nutr:string, product:ProductModel, hum:any) => { // get color of percentage bar
  if (nutr== 'carbs'){
    return getNutValueColor(product.computeCarbs(), nutr);
  }
  else{
    return getNutValueColor(toDry((product?.getAnalysis_nut() as any)[nutr], hum), nutr);
  }
}

export const toDry = (value:number, humidity:number):number => {
  return value*100/(100-humidity);
}

export const maxProductVisitedAsGuest = 3;
import ApiCaller from 'baseapp/core/ApiCaller';
import { ProfileModel, ProfileCollection, ProductModel } from 'systemDomain';
import User from 'baseapp/models/user';

const getApiCaller = (currentUser?: User): ApiCaller => {
    const apiCaller = new ApiCaller(currentUser?.token);
    return apiCaller
}

class ProfileApi {
    constructor() {}

    static async all(currentUser: User): Promise<ProfileCollection> {
        const data = await getApiCaller(currentUser).call('/v1/profiles', 'GET');
        return new ProfileCollection(data.map((item:any) => {
            return new ProfileModel(item);
        }));
    }

    static async read(currentUser: User, id: string): Promise<ProfileModel> {
        const data = await getApiCaller(currentUser).call('/v1/profiles/'+id, 'GET');
        return new ProfileModel(data);
    }

    static async create(currentUser: User, profile: ProfileModel): Promise<ProfileModel>{
        const data = await getApiCaller(currentUser).call('/v1/profiles', 'POST', profile?.toObject());
        return new ProfileModel(data);
    }

    static async update(currentUser: User, profile: ProfileModel): Promise<ProfileModel>{
        const data = await getApiCaller(currentUser).call('/v1/profiles/'+profile?.getId(), 'POST', profile?.toObject());
        return new ProfileModel(data);
    }

    static async delete(currentUser: User, id: string): Promise<void>{
        return await getApiCaller(currentUser).call('/v1/profiles/'+id+'/delete', 'GET');
    }

    static async toggleFavorite(currentUser: User, product: ProductModel) : Promise<ProfileModel> {
        const data = await getApiCaller(currentUser).call('/v1/profile/toggle/favorite?id='+product?.getId(), 'GET');
        return new ProfileModel(data);
    }
}

export default ProfileApi;
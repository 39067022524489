import * as React from 'react';
import { Platform } from 'react-native';
import { StyleSheet } from 'react-native';
import { FAB } from 'react-native-paper';
import { withTheme } from 'react-native-paper';
import { theme } from '../theme';
import Settings from 'baseapp/core/settings';
import { useAppStore } from 'baseapp/context/appStore';
import { useAnalyticsStore } from '../context/analytics';

const ScanButton = (props:any) => {
  const {session} = useAppStore();
  const { AnalyticsLogEvent } = useAnalyticsStore();
  const onScanButtonPress = () => {
    if(session.loggedIn){
      AnalyticsLogEvent("Scan Button", {"user":session.userInfo.email?session.userInfo.email:"guest"})
      props.navigation.navigate('scan')
    }else{ // not logged in
      props.navigation.navigate('login')
    }
    
  }
    if(Platform.OS == 'web' && Settings.getMode()!="dev") {
      return null;
    }
    
    return (<FAB
        style={styles.fab}
        icon="barcode-scan"
        onPress={() => {onScanButtonPress()}}
      />)
};

const styles = StyleSheet.create({
  fab: {
    backgroundColor: theme.colors.primary,
    position: 'absolute',
    margin: 16,
    right: 15,
    bottom: 90,
    zIndex:10000,
  },
})

export default withTheme(ScanButton);
import React, { memo, useState } from 'react';
import { View, Text, Alert, StyleSheet, ScrollView, Platform } from 'react-native';
import Background from 'internalapp/Background';
import Logo from '../../../components/Logo';
import { TextInput, Button } from 'react-native-paper';
import { Navigation } from '../../../types';
import { theme } from 'internalapp/theme';
import ApiCaller from '../../../core/ApiCaller';

type Props = {
  navigation: Navigation;
};

const ForgotPasswordScreen = ({ navigation }: Props) => {

  const [email, setEmail] = useState("");
  const _onRecoverMailPressed  = async () => {
    
    const apiCaller = new ApiCaller();
    try{
      const res = await apiCaller.call('/v1/forgotPassword', 'POST', {email});
      //alert(res)
      (Platform.OS == 'web') ?
      alert("Hemos enviado un mail a "+ email):
      Alert.alert("","Hemos enviado un mail a "+ email)
    }catch(e){
      (Platform.OS == 'web') ?
      alert("Hemos enviado un mail a "+ email):
      Alert.alert("","Hemos enviado un mail a "+ email)
    }
  };
  const _onBackPressed = () => {
    navigation.navigate('login')
  }

  return (
    <Background style={{ alignItems: "center" }}>
      <View style={{ maxWidth: 600, width: '100%', flex: 12, flexDirection: "column", justifyContent: 'center', backgroundColor: theme.colors.background, padding: 15, marginTop: 60 }}>
        <ScrollView>
          <View style={{ flexDirection: "row", justifyContent: 'center' }}>
            <Logo></Logo>
          </View>
          <TextInput
            label="Email"
            returnKeyType="next"
            onChangeText={(text: string) => setEmail(text)}
            autoCapitalize="none"
            autoCompleteType="email"
            textContentType="emailAddress"
            keyboardType="email-address"
            style={styles.entry}
          />
          <Button labelStyle={[{ color: theme.colors.background }, styles.labelButton]} style={[{ backgroundColor: theme.colors.primary }, styles.buttonContained]} uppercase={false} onPress={_onRecoverMailPressed}>
            Enviar al mail
          </Button>
          <View style={{ flexDirection: "row", justifyContent: 'center', width: "70%", alignSelf: "center", marginTop: 190 }}>
            <Text style={{ color: theme.colors.primary, fontFamily: theme.fonts.medium.fontFamily, justifyContent: 'center', alignSelf: 'center', alignItems: 'center', flexDirection: 'row' }}>
              Volver a la página de inicio
            </Text>
          </View>
          <Button labelStyle={[{ color: theme.colors.primary }, styles.labelButton]} style={styles.buttonOutlined} uppercase={false} onPress={_onBackPressed}>
            Atrás
          </Button>
        </ScrollView>
      </View>
    </Background>
  );
};

const styles = StyleSheet.create({
  entry: {
    backgroundColor: theme.colors.background,

  },
  label: {
    color: theme.colors.text,
  },
  link: {
    fontWeight: 'bold',
    color: theme.colors.primary,
  },
  buttonContained: {
    shadowRadius: 15,
    shadowColor: 'rgba(0, 0, 0, 0.2)',
    shadowOpacity: 0.2,
    elevation: 2,
    width: '60%',
    marginTop: 80,
    alignSelf: 'center',
    borderRadius: 8
  },
  labelButton: {
    fontFamily: theme.fonts.medium.fontFamily
  },
  buttonOutlined: {
    width: '60%',
    marginTop: 20,
    alignSelf: 'center',
    borderRadius: 8,
    borderWidth: 2,
    borderColor: theme.colors.primary,
    backgroundColor: theme.colors.background,
  }
});
export default memo(ForgotPasswordScreen);
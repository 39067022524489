import loadBase64PolyFill from './src/lib/base64';
import * as React from 'react';
import { Provider as PaperProvider } from 'react-native-paper';
import { theme } from 'internalapp/theme';
import App from './src/App';
import MiniApp from 'internalapp/App';

loadBase64PolyFill();

function Main() {
  return (
    <PaperProvider theme={theme}>
      <MiniApp>
        <App></App>
      </MiniApp>
    </PaperProvider>
  );
}

export default Main;
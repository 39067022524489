import React from 'react';
import { StyleSheet, View} from 'react-native';
import { Button, Card, Dialog, Portal, Searchbar, Title } from 'react-native-paper';
import { Chip } from 'react-native-paper';
import { FlatList, ScrollView } from 'react-native-gesture-handler';

type Props = {
    allAdditives: string[];
    currentAdditives: string[];
    onAddAdditive: Function;
    onDeleteAdditive: Function;
  };

const Additives = ({currentAdditives, onAddAdditive, onDeleteAdditive, allAdditives}:Props) => {
    const [foundAdditives, setFoundAdditives] = React.useState<string[]>(allAdditives);
    const [visible, setVisible] = React.useState(false);
    const [search, setSearch] = React.useState<string>("");

    const showModal = () => setVisible(true);
    const hideModal = () => setVisible(false);

    React.useEffect(() => {
        setFoundAdditives(allAdditives);
    }, [allAdditives])

    const renderItem = (obj:any) => (
        <Card onPress={()=>{
            onAddAdditive(obj.item.title);
            setSearch("");
            hideModal();
            }}>
        <Card.Content>
          <Title>{obj.item.title}</Title>
        </Card.Content>
      </Card>
      );

    return(
        <View style={{flex:1,marginTop:25,alignContent:"center"}}>
                <Portal>
                    <Dialog contentContainerStyle={{alignContent: "center"}} style={{minWidth: 350, maxWidth:350, alignSelf:"center"}} visible={visible} onDismiss={hideModal}>
                        <View style={{height:500}}>
                            <Searchbar
                            placeholder="Search"
                            onChangeText={(text:string) => {
                                setSearch(text);
                                setFoundAdditives(allAdditives.filter((additive) => additive.title.toLowerCase().indexOf(text.toLowerCase()) != -1))
                            }}

                            value={search}
                            />
                            <ScrollView>
                                <View onStartShouldSetResponder={(): boolean => true}>
                                    <FlatList
                                    data={foundAdditives}
                                    renderItem={renderItem}
                                    keyExtractor={item => item.id}
                                    />
                                </View>
                            </ScrollView>
                        </View>
                    </Dialog>
                </Portal>
            <View style={{flex:1, flexDirection:"row", flexWrap: 'wrap'}}>
                {
                    currentAdditives.map((additive, index)=> {
                        return(
                            <Chip key={index} style={{marginLeft:8, marginTop:10}} onClose={() => onDeleteAdditive(additive)}>{additive}</Chip>
                    )
                    })
                }
            </View>
            <Button icon="plus-circle" compact={true} mode="outlined" style={{ marginTop:20, marginBottom:10, width:"100%"}} onPress={showModal}>
                aditivo
            </Button>

        </View>
    )
};

const styles = StyleSheet.create({

});

export default Additives;

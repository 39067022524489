export interface ArticleInterface {
    _id?: string,
    name: string,
}


export default class ArticleModel {
    props: ArticleInterface;

    constructor(articleData: ArticleInterface) {
        this.props = articleData;
    }

    getId(): string | undefined {
        return this.props._id;
    }

    get(key: string): any {
        if(this.props.hasOwnProperty(key)) {
            //@ts-ignore
            return this.props[key];
        }
        throw "Error: Article doesn't have the property: " + key;
    }

    toObject(): ArticleInterface {
        return this.props;
    }

    static keys(): string[] {
        return ["name", ];
    }
}

export class ArticleCollection {
    articleArr: ArticleModel[];

    constructor(articleList: ArticleModel[]) {
        this.articleArr = articleList;
    }

    findBy(key: string, value: string): ArticleModel[] {
        return this.articleArr.filter((article: ArticleModel) => article.get(key) === value);
    }

    length() {
        return this.articleArr.length;
    }

    items() {
        return this.articleArr;
    }
}


import { DefaultTheme } from 'react-native-paper';
import React from "react";
import AppMode from 'baseapp/core/AppMode';
import {getWebTheme} from './theme.web';

var appTheme = {
  ...DefaultTheme,
  fonts: {
    regular: {fontFamily:"Montserrat-Regular"},
    medium: {fontFamily:"Montserrat-Medium"},
    light: {fontFamily:"Montserrat-Light"},
    bold: {fontFamily:"Montserrat-Bold"}
  },
  colors: {
    ...DefaultTheme.colors,
    background: 'white',
    primary: '#F7B500',
    secondary: '#C9C8C7',
    shadedBox: '#efefef',
    navBar: 'white',
    colorTitleSection: '#555',
    colorParagraphItem: '#777',
    companyTextColor: '#76848b',
    text: '#141c27'
  },
  gradients: {
    main: ['black', 'black']
  },
  plugins: {
    auth: {
      showRegisterBanner: true,
      textRegisterBanner: "¿Aún no estás registrado?",
      textLoginButton: "Entrar",
      textRegisterButton: "Registrar",
      textForgotPasswordLink: "¿Has olvidado tu contraseña?",
      registerButtonLabelColor: "#F7B500",
      buttonContainerStyle:{ width:"100%"},

      textCreateAccountButton: "Registrar",
      registerUISlot: <></>,
      termsAndConditionsURL: "https://protofy.xyz"
    }
  }
};

if(AppMode == 'next') {
  appTheme = getWebTheme(appTheme);  
}

export const theme = appTheme;
import React from "react";
import { View } from "react-native";
import { WebView } from 'react-native-webview';
import { ActivityIndicator } from 'react-native-paper';

type Props = {
    src: string;
}
const Frame = ({ src }: Props) => {
    const url = src;
    const [loading, setLoading] = React.useState(true)

    return (
        <View style={{ flex: 1 }}>
            {url ?
                <>
                    <WebView
                        style={{ flex: 1 }}
                        source={{ uri: url }}
                        onLoad={() => setLoading(false)}
                        startInLoadingState={true}
                    />
                    {loading && <ActivityIndicator
                        size="large"
                        style={{flex:10, alignSelf:'center'}}
                        hidesWhenStopped={true}
                    />
                    }
                </>
                : <ActivityIndicator />}
        </View>
    )
}

export default Frame;
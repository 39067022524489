import {decode, encode} from 'base-64';
// fix for react native lack of support for btoa and atob: 
// https://stackoverflow.com/questions/42829838/react-native-atob-btoa-not-working-without-remote-js-debugging
if (!global.btoa) {
    global.btoa = encode;
}

if (!global.atob) {
    global.atob = decode;
}

export default function loadBase64PolyFill() {
    //this is here in case we need to do something extra, and to avoid the compiler from removing the include 
    return false;
}
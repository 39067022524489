import React, { memo, useEffect, useRef } from "react";
import { useNavigation } from '@react-navigation/native';
import { theme } from 'internalapp/theme';
import { useAppStore } from '../../../context/appStore';
import { Button } from 'react-native-paper';
import Background from "internalapp/Background";
import { View, ScrollView, Text, Keyboard, StyleSheet } from 'react-native';
import { useRegisterStore } from "../stores/RegisterStore";
import { TextInput } from 'react-native-paper';

const CompleteProfileScreen = () => {
    const { session, setSession, profile } = useAppStore();
    const currentUser = session.userInfo;
    const navigation = useNavigation();
    const pluginTheme = theme.plugins.auth;
    const { setField } = useRegisterStore();
    const scrollRef = useRef();
    const [ name, setName ] = React.useState<string>('');

    const createProfile = async () => {
        setField('name', name);
        (currentUser) ? setSession(currentUser, 'socialLogin') : null;
    }

    useEffect(() => {
        if (profile?.ready) navigation.navigate('home')
    }, [profile]);

    return (
        <Background style={{ alignItems: "center" }}>
            <View style={{ maxWidth: 600, width: '100%', flex: 1, flexDirection: "column", justifyContent: 'center', backgroundColor: theme.colors.background, padding: 15 }}>
                <ScrollView style={{ paddingBottom: 10 }} showsVerticalScrollIndicator={false}>
                    <Text style={{ color: 'black', fontWeight: '700', textAlign: 'center', fontSize: 23, marginBottom: 30 }}>Complete su perfil</Text>
                    <Text style={{ color: theme.colors.companyTextColor, textAlign:'center', fontWeight: '400', marginBottom: 20 }}>Rellene los siguientes campos para completar su registro.</Text>
                    <TextInput
                        label="Nombre y apellidos"
                        returnKeyType="next"
                        value={name}
                        onChangeText={(text: string) => setName(text)}
                        onSubmitEditing={Keyboard.dismiss}
                        style={styles.entry}
                        onFocus={() => { scrollRef.current?.scrollTo({ x: 0, y: 0, animated: true }) }}
                    />
                    {theme.plugins.auth.registerUISlot}
                </ScrollView>
            </View>
            <Button uppercase={false} style={{ marginVertical: 30, width: '100%', maxWidth: 200 }} onPress={() => createProfile()} mode='contained'>{pluginTheme.textCreateAccountButton}</Button>
        </Background>
    );
}

const styles = StyleSheet.create({
    entry: {
        backgroundColor: theme.colors.background,

    }
});
export default memo(CompleteProfileScreen);
import create from "zustand";
import produce from "immer";
import { ProductModel } from "systemDomain";

type ProductStoreData = {
    product: ProductModel | null,
    currentProduct: ProductModel | undefined,
    setProductContext: Function,
    setCurrentProduct: Function
}

export const useProductStore = create<ProductStoreData>((set) => ({
    product: null,
    currentProduct: undefined,
    setProductContext: (payload: ProductModel) => set(produce((draft:ProductStoreData) => {
        draft.product = payload;
    })),
    setCurrentProduct: (payload: ProductModel) => set(produce((draft:ProductStoreData) => {
        draft.currentProduct = payload;
    }))
}));
import { useFocusEffect, useRoute } from '@react-navigation/native';
import React, { memo } from 'react';
import { View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { useSelector } from 'react-redux';
import { ProductModel, ProductCollection } from 'systemDomain';
import { useHeaderStore } from 'baseapp/context/headerContext';
import EmptyMessage from '../components/EmptyMessage';
import Loading from '../components/Loading';
import ProductList from '../components/ProductList';
import { useAppStore } from 'baseapp/context/appStore';

const HistoryTab = (props:any) => {
    const route = useRoute();
    const {setHeader} = useHeaderStore();
    const { profile } = useAppStore();

    var historyObjs = [];    
    try {
        //@ts-ignore
        historyObjs = profile.props.historyProducts?.filter((product) => product.msg?false:true);
    } catch (e) {
        //console.error(e);
        historyObjs = [];
    }
    if(!historyObjs) {
        historyObjs = [];
    }

    var products:ProductCollection = new ProductCollection(historyObjs.map((historyItem:any)=>new ProductModel(historyItem)));

    useFocusEffect(
        React.useCallback(() => {
            setHeader("home",'default');
        }, [route])
    )
    
    return (<ScrollView style={{flex: 1}} scrollEventThrottle={1} onScroll={(e) => {props.onScroll(e.nativeEvent.contentOffset.y)}}>
        {profile?<View style={{marginTop: 10, justifyContent: 'space-evenly' ,flexDirection: 'row', flexWrap: 'wrap', alignContent: 'flex-start'}}>
            {
                //@ts-ignore
                profile.ready && products.productArr.length == 0
                ? <EmptyMessage icon="history" message="Tu historial está vacío. Escanea códigos de barras de cómida para animales para ver su puntuación."></EmptyMessage>
                : !profile.ready
                ? <Loading text="Cargando tu historial..."></Loading>
                : profile.ready 
                ? <ProductList onScroll={props.onScroll} products={products}></ProductList>
                : null
            }
        </View>:null}
    </ScrollView>)
}

export default memo(HistoryTab);
import React, { useEffect } from 'react';
import { StyleSheet, View, Text, TouchableWithoutFeedback } from 'react-native';
import { Divider, TextInput } from 'react-native-paper';
import { useSappiScoreStore } from '../context/sappiScoreContext';
import { theme } from '../theme';
import SappiScoreModel from '../models/sappiScore/sappiScoreModel';

const FormulaQualityPercentage = () => {
    const { sappiScore, sappiScoreChanging, setSappiScoreChanging } = useSappiScoreStore();

    //  BAR 3 INGREDIENTS
    const [threeIngredients_ingredient1, setThreeIngredients_ingredient1] = React.useState<string>('');
    const [threeIngredients_ingredient2, setThreeIngredients_ingredient2] = React.useState<string>('');
    const [threeIngredients_ingredient3, setThreeIngredients_ingredient3] = React.useState<string>('');

    //  BAR 2 INGREDIENTS
    const [twoIngredients_ingredient1, setTwoIngredients_ingredient1] = React.useState<string>('');
    const [twoIngredients_ingredient2, setTwoIngredients_ingredient2] = React.useState<string>('');


    useEffect(() => {
        setThreeIngredients_ingredient1(sappiScore?.getQuality_values().tresIngredientes[0]);
        setThreeIngredients_ingredient2(sappiScore?.getQuality_values().tresIngredientes[1]);
        setThreeIngredients_ingredient3(sappiScore?.getQuality_values().tresIngredientes[2]);
        setTwoIngredients_ingredient1(sappiScore?.getQuality_values().dosIngredientes[0]);
        setTwoIngredients_ingredient2(sappiScore?.getQuality_values().dosIngredientes[1]);
    }, [sappiScore]);
    
    // 3 INGREDIENTS
    useEffect(() => {
        // @ts-ignore
        var tmpSappiScoreObj: sappiScoreInterface = sappiScoreChanging?.toObject();
        tmpSappiScoreObj['quality']['values']['tresIngredientes'][0] = parseInt(threeIngredients_ingredient1);
        tmpSappiScoreObj['quality']['values']['tresIngredientes'][1] = parseInt(threeIngredients_ingredient2);
        tmpSappiScoreObj['quality']['values']['tresIngredientes'][2] = parseInt(threeIngredients_ingredient3);
        setSappiScoreChanging(new SappiScoreModel(tmpSappiScoreObj));
    }, [threeIngredients_ingredient1, threeIngredients_ingredient2, threeIngredients_ingredient3]);
    // 2 INGREDIENTS
    useEffect(() => {
        // @ts-ignore
        var tmpSappiScoreObj: sappiScoreInterface = sappiScoreChanging?.toObject();
        tmpSappiScoreObj['quality']['values']['dosIngredientes'][0] = parseInt(twoIngredients_ingredient1);
        tmpSappiScoreObj['quality']['values']['dosIngredientes'][1] = parseInt(twoIngredients_ingredient2);
        setSappiScoreChanging(new SappiScoreModel(tmpSappiScoreObj));
    }, [twoIngredients_ingredient1, twoIngredients_ingredient2]);

    return (
        <View>
            <View style={{ flexDirection: 'row', width: '100%' }}>
                {/* 3 INGREDIENTS BAR */}
                <View style={{ width: threeIngredients_ingredient1 + '%' }}>
                    <Text style={styles.ingredienteText}>Ingrediente 1</Text>
                    <View style={{ width: '100%', backgroundColor: theme.colors.green, height: 20 }} />
                    {/* <Text style={styles.textOption}>{value1}</Text> */}
                    <TextInput onChangeText={(text) => setThreeIngredients_ingredient1(text)} label={threeIngredients_ingredient1} style={styles.input} underlineColor={theme.colors.background} />
                </View>
                <View style={{ width: threeIngredients_ingredient2 + '%' }}>
                    <Text style={styles.ingredienteText}>Ingrediente 2</Text>
                    <View style={{ width: '100%', backgroundColor: theme.colors.blue, height: 20 }} />
                    <TextInput onChangeText={(text) => setThreeIngredients_ingredient2(text)} label={threeIngredients_ingredient2} style={styles.input} underlineColor={theme.colors.background} />
                </View>
                <View style={{ width: threeIngredients_ingredient3 + '%' }}>
                    <Text style={styles.ingredienteText}>Ingrediente 3</Text>
                    <View style={{ width: '100%', backgroundColor: theme.colors.orange, height: 20 }} />
                    <TextInput onChangeText={(text) => setThreeIngredients_ingredient3(text)} label={threeIngredients_ingredient3} style={styles.input} underlineColor={theme.colors.background} />
                </View>
            </View>
            <Divider style={{ marginBottom: 40, marginTop: 20 }} />
            <View style={{ flexDirection: 'row', width: '100%' }}>
                {/* 2 INGREDIENTS BAR */}
                <View style={{ width: twoIngredients_ingredient1 + '%' }}>
                    <Text style={styles.ingredienteText}>Ingrediente 1</Text>
                    <View style={{ width: '100%', backgroundColor: theme.colors.green, height: 20 }} />
                    <TextInput onChangeText={(text) => setTwoIngredients_ingredient1(text)} label={twoIngredients_ingredient1} style={styles.input} underlineColor={theme.colors.background} />
                </View>
                <View style={{ width: twoIngredients_ingredient2 + '%' }}>
                    <Text style={styles.ingredienteText}>Ingrediente 2</Text>
                    <View style={{ width: '100%', backgroundColor: theme.colors.blue, height: 20 }} />
                    <TextInput onChangeText={(text) => setTwoIngredients_ingredient2(text)} label={twoIngredients_ingredient2} style={styles.input} underlineColor={theme.colors.background} />
                </View>
            </View>
        </View>
    )
};

const styles = StyleSheet.create({
    ingredienteText: {
        color: theme.colors.grey,
        fontSize: 16,
        fontFamily: theme.fonts.light.fontFamily,
        alignSelf: 'center',
        marginBottom: 10,
    },
    textOption: {
        fontSize: 14,
        fontFamily: theme.fonts.regular.fontFamily,
        alignSelf: 'center'
    },
    input: {
        backgroundColor: theme.colors.background,
        width: 65,
        alignSelf: 'center',
    },
    paragraph: {
        color: theme.colors.colorParagraphItem,
        fontWeight: '500',
        fontSize: 18,
        lineHeight: 18,
    },
});

export default FormulaQualityPercentage;

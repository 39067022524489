import React, { memo, useEffect } from 'react';
import { View, Platform, Text, Image } from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import ProductApi from '../api/ProductApi';
import { useAppStore } from "baseapp/context/appStore";


const TestScreen = () => {
  const session = useAppStore(state => state.session);
  const currentUser = session.userInfo;

  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      quality: 1,
      base64: true
    });
    if (!result.cancelled) {
      var data: any = result;
      if (data.base64) {
        data = 'data:image/jpeg;base64,' + data.base64;
      } else {
        data = data.uri;
      }
      try {
        const filename: string = await ProductApi.uploadImageToServer(currentUser, data);
        console.log('filename: ', filename)
      } catch (e) { console.error('Error uploading image. Error: ', e) }
    }
  };


  useEffect(() => {
    (async () => {
      if (Platform.OS !== 'web') {
        const { status } = await ImagePicker.requestCameraRollPermissionsAsync();
        if (status !== 'granted') {
          alert('Sorry, we need camera roll permissions to make this work!');
        }
      }
    })();
  }, []);

  return (
    <View style={{ flex: 1 }}>
      <Text onPress={() => pickImage()}>select image</Text>
      <Image
        style={{ width: 100, height: 100, flex:1, resizeMode:'contain' }}
        source={{ uri: "https://sappipreprod.protofy.xyz/public/images/4715684c410649f18885ffa74232cf63.png" }}
      />
    </View>
  );
}
export default (TestScreen);
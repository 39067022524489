import create from "zustand";
import produce from "immer";
import Conf from 'internalapp/conf';

type headerStoreData = {
    headers: any,
    dialogReport: Boolean,
    setDialogReport: Function,
    setHeader: Function,
    getHeader: Function,
    setHeaders: Function
}

const getHeaders = (screens) => {
    let headers = {};
    screens.forEach((screen:any) => {
        headers[screen.name] = screen.header?screen.header:"default"
    })
    return headers;
}


export const useHeaderStore = create<headerStoreData>((set, get) => ({
    headers: {},
    dialogReport: false,

    setHeaders: (screens:any) => set(produce((draft:headerStoreData) => {
        draft.headers = getHeaders(screens);
    })),

    setHeader: (screenName: string, header: string) => set(produce((draft:headerStoreData) => {
        draft.headers[screenName] = header;
    })),

    setDialogReport: (payload: Boolean) => set(produce((draft:headerStoreData) => {
        draft.dialogReport = payload;
    })),

    getHeader: (screenName: string) => {
        return Conf.appHeader?get().headers[screenName]:"noHeader";
    },

}));
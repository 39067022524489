import ProductApiBase from './Product.api.b';
import ApiCaller from 'baseapp/core/ApiCaller';
import { ProductCollection, ProductModel } from 'systemDomain';

export default class ProductApi extends ProductApiBase {
  constructor(apiCaller: ApiCaller) {
      super(apiCaller);
  }

  async search(s: string): Promise<ProductCollection> {
    var data = await this.apiCaller.call('/v1/product?s='+s, 'GET');
    return new ProductCollection(data.map((item:any) => {
        return new ProductModel(item);
    }));
  }
  
  async suggested(id: string): Promise<ProductCollection> {
    var data = await this.apiCaller.call('/v1/product?suggested='+id, 'GET');
    return new ProductCollection(data.map((item:any) => {
        return new ProductModel(item);
    }));
  }

  async readBarcode(barcode: string): Promise<ProductModel> {
    const data = await this.apiCaller.call('/v1/product?s='+barcode, 'GET');
    if(data.message || (data.length < 0 || !data[0].name)) {
      throw "Not found";
    }
    return new ProductModel(data[0]);
  }
  async createComment( productId: string, comment: object): Promise<any> {
    const data = await this.apiCaller.call('/v1/comment/'+productId, 'POST', comment);
    return data;
  }
  async deleteComment( productId: string, email:string, timestamp:number): Promise<any> {
    const data = await this.apiCaller.call('/v1/comment/'+productId+'/'+email+'/'+timestamp+'/delete', 'GET');
    return data;
  }
  async search_V2(queryParams:string, itemsToSkip:number): Promise<ProductCollection> {
    var data = await this.apiCaller.call('/v1/search/'+itemsToSkip+'?'+queryParams, 'GET');
    return new ProductCollection(data.map((item:any) => {
        return new ProductModel(item);
    }));
  }

}
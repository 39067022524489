import React, { useState } from 'react';
import { StyleSheet, View, Text, ScrollView, Platform } from 'react-native';
import { Button, Dialog, Portal, Searchbar } from 'react-native-paper';
import { theme } from '../theme';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import IngredientsList from './IngredientsList';
import IngredientValueDialog from './IngredientValueDialog';


type Props = {
    visibleIngredients: boolean;
    hideIngredients: Function;
    ingredientsList: any[];
    setIngredientsList: Function;
};

const IngredientsDialog = ({ visibleIngredients, hideIngredients, ingredientsList, setIngredientsList }: Props) => {

    const [searchQuery, setSearchQuery] = useState('');
    const [focused, setFocused] = useState<boolean>(false);
    const [editDialog, seteditDialog] = useState(false);

    const onChangeSearch = (query: string) => {
        setSearchQuery(query);
    };

    const onSearchErase = () => {
        setSearchQuery('');
    }

    const showIngredientValue = () => {
        seteditDialog(true);
    };

    const hideIngredientValue = () => seteditDialog(false);
    return (
        <View>
            <Portal >
                <Dialog onDismiss={() => hideIngredients()} visible={visibleIngredients} style={{ width: 500, height: '80%', alignSelf: 'center' }}>
                    <View style={{ width: '100%', alignSelf: 'center', padding: 40, flex: 5 }}>
                        <View style={{ width: '100%', height: 50, flexDirection: 'row', alignSelf: 'center', justifyContent: 'center', marginBottom: 40 }}>
                            <Searchbar
                                placeholder="Buscar ingrediente"
                                onChangeText={(text) => onChangeSearch(text)}
                                value={searchQuery}
                                inputStyle={{ fontSize: 12 }}
                                style={[styles.searchbar, { shadowOffset: { width: 0, height: 0 }, shadowRadius: 5, elevation: Platform.OS == 'android' ? 10 : 4, shadowOpacity: 0.2 }]}
                                onFocus={() => setFocused(true)}
                                onBlur={() => setFocused(false)}
                                clearIcon={() =>
                                    <MaterialCommunityIcons
                                        name="close"
                                        size={30}
                                        color={theme.colors.blue}
                                        onPress={() => onSearchErase()}
                                    />
                                }
                            />
                            {searchQuery != '' ?
                                <Button
                                    disabled={ingredientsList.find((index: any) => index.name == searchQuery) ? true : false}
                                    uppercase={false}
                                    style={[styles.addButton, { backgroundColor: ingredientsList.find((index: any) => index.name == searchQuery) ? 'rgba(0,0,0,0.3)' : theme.colors.blue }]}
                                    labelStyle={styles.addLabel}
                                    onPress={() => showIngredientValue()}
                                >
                                    Añadir
                                </Button>
                                : null
                            }
                        </View>
                        <Text style={styles.title}>INGREDIENTES</Text>
                        <View style={{ flex: 10 }}>
                            {
                                ingredientsList == undefined || ingredientsList.length == 0 ?
                                    <Text>Aún no se han añadido ingredientes</Text>
                                    :
                                    <ScrollView>
                                        {
                                            ingredientsList != undefined ?
                                                ingredientsList.map((ingredient: any, index:number) => (<IngredientsList key={index} ingredientsList={ingredientsList} setIngredientsList={setIngredientsList} name={ingredient.name} value={ingredient.value} />))
                                                : null
                                        }
                                    </ScrollView>
                            }
                        </View>
                        <View style={{ width: '100%', flex: 1, flexDirection: 'row', alignItems: 'flex-end' }}>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-around', width: '100%' }}>
                                <Button uppercase={false} onPress={() => hideIngredients()} labelStyle={{ color: theme.colors.grey }} style={{ width: '40%' }}>Cancelar</Button>
                                <Button uppercase={false} onPress={() => hideIngredients()} labelStyle={{ color: theme.colors.background }} style={{ backgroundColor: theme.colors.primary, borderRadius: 5, width: '40%' }}
                                >Actualizar</Button>
                            </View>
                        </View>
                    </View>
                </Dialog>
            </Portal>
            <IngredientValueDialog searchQuery={searchQuery} ingredientsList={ingredientsList} setIngredientsList={setIngredientsList} visibleIngredientValue={editDialog} hideIngredientValue={hideIngredientValue} />
        </View>
    )
};

const styles = StyleSheet.create({
    searchbar: {
        flex: 1,
        borderRadius: 50,
        height: 50,
        flexDirection: 'row',
        backgroundColor: 'white'
    },
    addButton: {
        marginLeft: 5,
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'flex-start',
        // backgroundColor: theme.colors.blue,
        borderRadius: 50,
        height: 48,
        paddingHorizontal: 20
    },
    addLabel: {
        color: theme.colors.background,

    },
    title: {
        fontSize: 14,
        color: theme.colors.text,
        fontFamily: theme.fonts.regular.fontFamily,
        marginBottom: 10
    }
});

export default IngredientsDialog;

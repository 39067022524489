import ApiCaller from 'baseapp/core/ApiCaller';
import SappiScoreModel from '../models/sappiScore/sappiScoreModel';
import { ProfileModel, ProfileCollection } from 'systemDomain';

const getApiCaller = (currentUser: any) => { return new ApiCaller(currentUser.token) };


class AdminApi {
    constructor() { }
    async getSappiScoreConfig(currentUser: any): Promise<SappiScoreModel> {
        const response = await getApiCaller(currentUser).call('/v1/admin/edit', 'GET');
        return new SappiScoreModel(response);
    }
    async postSappiScoreConfig(currentUser: any, sappiScore: SappiScoreModel): Promise<SappiScoreModel> {
        const response = await getApiCaller(currentUser).call('/v1/admin/edit', 'POST', sappiScore.toObject());
        return new SappiScoreModel(response);
    }
    async restoreSappiScoreConfig(currentUser: any): Promise<SappiScoreModel> {
        const response = await getApiCaller(currentUser).call('/v1/admin/restart/default', 'GET');
        return new SappiScoreModel(response);
    }
    static downloadProducts(currentUser): string {
        return ('/v1/admin/download/products?token='+currentUser.token)
    }
    static downloadUsers(currentUser): string {
        return ('/v1/admin/download/profile?token='+currentUser.token)
    }
    static customDownloadProducts(currentUser,animal: string, foodtype: string): string {
        return ('/v1/admin/download/products/' + animal + '/' + foodtype+'?token='+currentUser.token)
    }
    static async users(currentUser: any, itemsToSkip, searchQuery): Promise<ProfileCollection> {
        const response = await getApiCaller(currentUser).call('/v1/profile/'+itemsToSkip+'/all?s='+searchQuery, 'GET');
        return new ProfileCollection( response.map(item => new ProfileModel(item)))
    }
    static async deleteUser(currentUser: any, email: string): Promise<ProfileCollection> {
        await getApiCaller(currentUser).call('/v1/profile/'+email+'/delete', 'GET');
    }
    static async products(currentUser: any, itemsToSkip, queryParams): Promise<ProfileCollection> {
        const response = await getApiCaller(currentUser).call('/v1/product/'+itemsToSkip+'/all?'+queryParams, 'GET');
        return new ProfileCollection( response.map(item => new ProfileModel(item)))
    }
    static async deleteProduct(currentUser: any, email: string): Promise<ProfileCollection> {
        await getApiCaller(currentUser).call('/v1/product/'+email+'/delete', 'GET');
    }
}

export default AdminApi
import React from 'react';
import { StyleSheet, View, Text, TouchableWithoutFeedback} from 'react-native';
import {theme} from '../theme';

type Props = {
    setSelectedMenu: Function;
    selectedMenu: string;
    optionText: string;
    optionPressed: string;
};

const TextOption = ({ setSelectedMenu, selectedMenu, optionText, optionPressed}:Props) => {

    const onPressMenu = (option:string) => { 
        setSelectedMenu(option);
    };
    return(
        <View>
            <TouchableWithoutFeedback style={{ flexDirection: 'row' }} onPress={() => onPressMenu(optionPressed)}>
                <View style={styles.viewOption}>
                    <Text style={[ styles.textOption ,{ color: selectedMenu == optionPressed ? theme.colors.primary : 'rgba(191, 191, 191, 1)' }]}>{optionText}</Text>
                </View>
            </TouchableWithoutFeedback>
        </View>
    )
};

const styles = StyleSheet.create({
    title: {
        color: theme.colors.text,
        fontSize: 20,
        fontFamily: theme.fonts.regular.fontFamily,
    },
    textOption: {
        fontSize: 16,
        fontFamily: theme.fonts.regular.fontFamily
    },
    viewOption: {
        marginVertical: 10
    },
    paragraph: {
        color: theme.colors.colorParagraphItem,
        fontWeight:'500',
        fontSize:14,
        lineHeight:18,     
    },
});

export default TextOption;

import React, { useEffect } from 'react';
import { View } from 'react-native';
import { useSappiScoreStore } from '../context/sappiScoreContext';
import { theme } from '../theme';
import SappiScoreModel from '../models/sappiScore/sappiScoreModel';
import FormulaCompositionRange from './FormulaCompositionRange';
import sappiScoreInterface from '../models/sappiScore/sappiScoreInterface';

const AdminFormulaComposition = () => {
    const { sappiScore, sappiScoreChanging, setSappiScoreChanging } = useSappiScoreStore();

    const [protein, setProtein] = React.useState<string>('');
    const [maxProtein, setMaxProtein] = React.useState<string>('');
    const [proteinMoreIsGood, setProteinMoreIsGood] = React.useState<boolean>(false);
    const [fat, setFat] = React.useState<string>('');
    const [maxFat, setMaxFat] = React.useState<string>('');
    const [fatMoreIsGood, setFatMoreIsGood] = React.useState<boolean>(false);
    const [fiber, setFiber] = React.useState<string>('');
    const [maxFiber, setMaxFiber] = React.useState<string>('');
    const [fiberMoreIsGood, setFiberMoreIsGood] = React.useState<boolean>(false);
    const [carbs, setCarbs] = React.useState<string>('');
    const [maxCarbs, setMaxCarbs] = React.useState<string>('');
    const [carbsMoreIsGood, setCarbsMoreIsGood] = React.useState<boolean>(false);
    const [ash, setAsh] = React.useState<string>('');
    const [maxAsh, setMaxAsh] = React.useState<string>('');
    const [ashMoreIsGood, setAshMoreIsGood] = React.useState<boolean>(false);

    useEffect(() => {
        // protein
        setProtein(sappiScore?.getComposition_protein().value.toString());
        setMaxProtein(sappiScore?.getComposition_protein().maxValue.toString());
        setProteinMoreIsGood(sappiScore?.getComposition_protein().moreIsGood);
        // fat
        setFat(sappiScore?.getComposition_fat().value.toString());
        setMaxFat(sappiScore?.getComposition_fat().maxValue.toString());
        setFatMoreIsGood(sappiScore?.getComposition_fat().moreIsGood);
        // fiber
        setFiber(sappiScore?.getComposition_fiber().value.toString());
        setMaxFiber(sappiScore?.getComposition_fiber().maxValue.toString());
        setFiberMoreIsGood(sappiScore?.getComposition_fiber().moreIsGood);
        // carbs
        setCarbs(sappiScore?.getComposition_carbs().value.toString());
        setMaxCarbs(sappiScore?.getComposition_carbs().maxValue.toString());
        setCarbsMoreIsGood(sappiScore?.getComposition_carbs().moreIsGood);
        // ash
        setAsh(sappiScore?.getComposition_ash().value.toString());
        setMaxAsh(sappiScore?.getComposition_ash().maxValue.toString());
        setAshMoreIsGood(sappiScore?.getComposition_ash().moreIsGood);
    }, [sappiScore]);

    useEffect(() => {
        // @ts-ignore
        var tmpSappiScoreObj: sappiScoreInterface = sappiScoreChanging?.toObject();
        if (tmpSappiScoreObj != undefined) {
            tmpSappiScoreObj['composition']['protein']['value'] = parseInt(protein);
            tmpSappiScoreObj['composition']['protein']['maxValue'] = parseInt(maxProtein);
            tmpSappiScoreObj['composition']['protein']['moreIsGood'] = proteinMoreIsGood;
            setSappiScoreChanging(new SappiScoreModel(tmpSappiScoreObj));
        }
    }, [protein, maxProtein, proteinMoreIsGood]);

    useEffect(() => {
        // @ts-ignore
        var tmpSappiScoreObj: sappiScoreInterface = sappiScoreChanging?.toObject();
        if (tmpSappiScoreObj != undefined) {
            tmpSappiScoreObj['composition']['fat']['value'] = parseInt(fat);
            tmpSappiScoreObj['composition']['fat']['maxValue'] = parseInt(maxFat);
            tmpSappiScoreObj['composition']['fat']['moreIsGood'] = fatMoreIsGood;
            setSappiScoreChanging(new SappiScoreModel(tmpSappiScoreObj));
        }
    }, [fat, maxFat, fatMoreIsGood]);

    useEffect(() => {
        // @ts-ignore
        var tmpSappiScoreObj: sappiScoreInterface = sappiScoreChanging?.toObject();
        if (tmpSappiScoreObj != undefined) {
            tmpSappiScoreObj['composition']['fiber']['value'] = parseInt(fiber);
            tmpSappiScoreObj['composition']['fiber']['maxValue'] = parseInt(maxFiber);
            tmpSappiScoreObj['composition']['fiber']['moreIsGood'] = fiberMoreIsGood;
            setSappiScoreChanging(new SappiScoreModel(tmpSappiScoreObj));
        }
    }, [fiber, maxFiber, fiberMoreIsGood]);

    useEffect(() => {
        // @ts-ignore
        var tmpSappiScoreObj: sappiScoreInterface = sappiScoreChanging?.toObject();
        if (tmpSappiScoreObj != undefined) {
            tmpSappiScoreObj['composition']['carbs']['value'] = parseInt(carbs);
            tmpSappiScoreObj['composition']['carbs']['maxValue'] = parseInt(maxCarbs);
            tmpSappiScoreObj['composition']['carbs']['moreIsGood'] = carbsMoreIsGood;
            setSappiScoreChanging(new SappiScoreModel(tmpSappiScoreObj));
        }
    }, [carbs, maxCarbs, carbsMoreIsGood]);

    useEffect(() => {
        // @ts-ignore
        var tmpSappiScoreObj: sappiScoreInterface = sappiScoreChanging?.toObject();
        if (tmpSappiScoreObj != undefined) {
            tmpSappiScoreObj['composition']['ash']['value'] = parseInt(ash);
            tmpSappiScoreObj['composition']['ash']['maxValue'] = parseInt(maxAsh);
            tmpSappiScoreObj['composition']['ash']['moreIsGood'] = ashMoreIsGood;
            setSappiScoreChanging(new SappiScoreModel(tmpSappiScoreObj));
        }
    }, [ash, maxAsh, ashMoreIsGood]);

    return (
        <View style={{ flex: 1, backgroundColor: theme.colors.background, width: '100%' }}>
            <FormulaCompositionRange name={'PROTEINA'} value={protein} maxValue={maxProtein} setMaxValue={setMaxProtein} setValue={setProtein} setGood={setProteinMoreIsGood} good={proteinMoreIsGood} />
            <FormulaCompositionRange name={'GRASA'} value={fat} maxValue={maxFat} setMaxValue={setMaxFat} setValue={setFat} setGood={setFatMoreIsGood} good={fatMoreIsGood} />
            <FormulaCompositionRange name={'FIBRA'} value={fiber} maxValue={maxFiber} setMaxValue={setMaxFiber} setValue={setFiber} setGood={setFiberMoreIsGood} good={fiberMoreIsGood} />
            <FormulaCompositionRange name={'CARBOHIDRATOS'} value={carbs} maxValue={maxCarbs} setMaxValue={setMaxCarbs} setValue={setCarbs} setGood={setCarbsMoreIsGood} good={carbsMoreIsGood} />
            <FormulaCompositionRange name={'CENIZA'} value={ash} maxValue={maxAsh} setMaxValue={setMaxAsh} setValue={setAsh} setGood={setAshMoreIsGood} good={ashMoreIsGood} />
        </View>
    )
};

export default AdminFormulaComposition;

import create from "zustand";
// Import Firebase
import * as Analytics from 'expo-firebase-analytics';

type AnalyticsStoreData = {
    AnalyticsLogEvent: Function
}

export const useAnalyticsStore = create<AnalyticsStoreData>(() => ({
    AnalyticsLogEvent: async (key:string, value:any) => await Analytics.logEvent(key, value)
}));
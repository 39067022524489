import React, { memo } from 'react';
import { TextStyle } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { ProductModel } from 'systemDomain';
import ProfileApi from '../api/ProfileApi';
import { useNavigation } from '@react-navigation/native';
import { useAppStore } from 'baseapp/context/appStore';

const Favorite = ({product, style, size}:{product:ProductModel, style?:TextStyle, size:number}) => {
    const { session, profile, setProfile } = useAppStore();
    const currentUser = session.userInfo;
    const [isFavorite, setIsFavorite] = React.useState(false);
    const navigation = useNavigation();
    
    const addFav = async (e?:any) => {
        //e.stopPropagation();
        if(currentUser.token){
            setIsFavorite(!isFavorite);
            try {
                setProfile(await ProfileApi.toggleFavorite(currentUser, product));
            } catch(e) {
                setIsFavorite(!isFavorite);
            }
        }
        else{
            navigation.navigate('login')
        }
    }

    React.useEffect(() => {
        if(profile?.get('favorites').indexOf(product.getId())==-1){
            setIsFavorite(false);
        } else {
            setIsFavorite(true);
        }

    }, [profile]);
    
    return (<MaterialCommunityIcons
        name={session.loggedIn?(isFavorite?"heart":"heart-outline"):"heart-outline"}
        color={session.loggedIn?(isFavorite?"#EC1277":"#aaa"):"#aaa"}
        size={size}
        style={style?{...style, textAlign:"center"}:{textAlign:"center"}}
        onPress={(e:any) => {
            e.preventDefault();
            e.stopPropagation();
            addFav();
        }}
    />);
};

export default memo(Favorite);
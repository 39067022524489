import React, { memo } from 'react';
import { StyleSheet, View, TouchableHighlight } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Text } from 'react-native-paper';
import { theme } from '../theme';


type Props = {
    icon: string;
    iconText: string;
    onPress: Function;
};

const MenuButtons = ({ icon, iconText, onPress }: Props) => {


    return (
        <TouchableHighlight underlayColor="transparent"  style={{ alignItems: 'center', justifyContent: 'flex-start' }} onPress={onPress}>
            <View style={{ marginRight: 20, marginBottom: 20, justifyContent: 'flex-start', alignItems: 'center', width: '100%', flexDirection: 'row' }}>
                    <MaterialCommunityIcons
                        name={icon}
                        color={theme.colors.text}
                        size={25}
                        style={{ alignSelf: 'center', marginRight: 10 }}
                    />
                <Text style={{ textAlign: 'center', color: theme.colors.text, fontSize: 16 , fontFamily: theme.fonts.light.fontFamily }}>{iconText}</Text>
            </View>
        </TouchableHighlight>
    )
};

const styles = StyleSheet.create({

});

export default memo(MenuButtons);

import ApiCaller from 'baseapp/core/ApiCaller';


export default class EmailApi {
    apiCaller: ApiCaller;

    constructor(apiCaller: ApiCaller) {
        this.apiCaller = apiCaller;
    }

    async sendNewProduct(payload: any) {
        
        await this.apiCaller.call('/v1/newProductEmail', 'POST', payload);
        
    }

    async sendReport(payload: any) {
        
        await this.apiCaller.call('/v1/reportEmail', 'POST', payload);
        
    }
}
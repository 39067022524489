import { LoginScreen, RegisterScreen} from './pages';
import CompleteProfileScreen from './pages/CompleteProfileScreen';
import conf from 'internalapp/conf';

export default [
    {
        component: RegisterScreen,
        name: "register",
        header: "none",
    }, 
    {
        component: LoginScreen,
        name: "login",
        header: "none",
        requireAuthLevel: ['guest'],
        fallback: true
    },
    {
        component: CompleteProfileScreen,
        name: conf.socialLogin.profile.extendProfileRoute,
        header: "default",
        requireAuthLevel: ['admin', 'user']
    }
]
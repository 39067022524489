import * as React from 'react';
import { Linking, View } from 'react-native';
import { Card, Text } from 'react-native-paper';
import { theme } from '../theme';
import Rate from './Rate';

type Props = {
  productShop: any;
}
const ShopProductCard = ({ productShop }: Props) => {

  const cardClicked = () => {
    Linking.openURL(productShop?.shopUrl);
  }

  const capitalizeFirstLetter = (stri: string) => {
    const lowerCaseStri = stri.toLowerCase();
    const firstCapital = lowerCaseStri[0].toUpperCase();
    return firstCapital + lowerCaseStri.slice(1);
  }

  return (
    <Card onPress={cardClicked} style={{marginBottom: 10, marginHorizontal: 12, width:'100%', elevation: 2, maxWidth:180, shadowRadius:5, borderColor:'#F0F0F0', borderWidth:0.2}}>
      <Card.Cover style={{ resizeMode: 'contain', height: 150 }} source={{ uri: productShop?.shopImage }} />
      <View style={{ padding: 10 }}>
        <Text numberOfLines={1} style={{ marginTop: 16, color: theme.colors.blue, lineHeight: 18, fontSize: 14, fontWeight: '500' }}>{capitalizeFirstLetter(productShop?.name)}</Text>
        <Text numberOfLines={1} style={{ marginBottom: 10, marginTop: 8, color: 'black', fontSize: 14, fontWeight: '700'}}>{`${productShop?.price??"--"} €`}</Text>
        <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
          <Rate maxRate={5} rate={productShop?.rate == null ? 0: productShop?.rate } rateColor='#F9A400' unrateColor='#C4C4C4' size={20} />
          <Text style={{ color: theme.colors.noSelected, marginLeft: 8 }}>{productShop["reviews"] === null ? '(0)':"(" + productShop?.reviews + ")"}</Text>
        </View>
        <Text numberOfLines={1} style={{ marginTop: 16, color: theme.colors.blue, fontSize: 14, fontWeight: '300', fontFamily: theme.fonts.medium.fontFamily}}>{'De ' + productShop?.sheller}</Text>
      </View>
    </Card>
  );
};

export default ShopProductCard;
import React, { memo } from 'react';
import { Image, StyleSheet } from 'react-native';
import { ProductModel } from 'systemDomain';
import { getSappiRating } from '../utils';

type Props = {
    product: ProductModel; //  malo: red , bueno: yellow, excelente: green
    size: number;
};
const DogCatImage = ({ product, size }: Props) => (

    getSappiRating(product) == "malo" ?
        <Image source={require('../assets/DogCatColors/DogCatRed.png')} style={[styles.image, {width: size, height: size}]} /> : (
            getSappiRating(product) == "bueno" ?
                <Image source={require('../assets/DogCatColors/DogCatYellow.png')} style={[styles.image, {width: size, height: size}]} /> : (
                    getSappiRating(product) == "excelente" ?
                    <Image source={require('../assets/DogCatColors/DogCatGreen.png')} style={[styles.image, {width: size, height: size}]} />:
                    <Image source={require('../assets/DogCatColors/DogCatUnknow.png')} style={[styles.image, {width: size, height: size}]} />
                    )
        )
);

const styles = StyleSheet.create({
    image: {
        marginRight: 5,
        resizeMode: 'contain',
        alignSelf: 'center',
    },
});

export default memo(DogCatImage);
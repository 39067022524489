import Constants from "expo-constants";

const isWeb = () => {
  try{
    const web = window.location.origin
    return 'web';
  }catch(e){
    return 'native'
  }
}
const localhost = isWeb()=='web' ? window.location.origin : 'https://buscador.sappi.app';
const imageUrl = 'https://buscador.sappi.app';

const ENV = {
 dev: {
   apiUrl: localhost+'/api',
   imagesUrl: imageUrl+'/public/images/'
 },
 staging: {
   apiUrl: localhost+'/api',
   imagesUrl: imageUrl+'/public/images/'
 },
 prod: {
  apiUrl: localhost+'/api',
  imagesUrl: imageUrl+'/public/images/'
 }
};

interface EnvOptions {
  apiUrl: string,
  imagesUrl: string
} 

const getEnvVars = (env = Constants.manifest.releaseChannel) : EnvOptions => {
 // What is __DEV__ ?
 // This variable is set to true when react-native is running in Dev mode.
 // __DEV__ is true when run locally, but false when published.
 if (__DEV__) {
   return ENV.dev;
 } else if (env === 'staging') {
   return ENV.staging;
 } else if (env === 'prod') {
   return ENV.prod;
 }
 return ENV.dev;
};

export const getMode = () => __DEV__?"dev":"prod";

export default getEnvVars;

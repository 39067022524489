import React, { memo } from 'react';
import { theme } from '../../theme';
import { IconButton } from "react-native-paper";
import { Text, View } from "react-native";

const AppIntro3 = () => {
    const [checked, setChecked] = React.useState(false);
    
    return (
        <View style={{ justifyContent: 'center', flex: 1, backgroundColor: theme.colors.background}}>
            <View style={{ backgroundColor: theme.colors.background, width: '90%', maxWidth: 400, height: 500, alignSelf: 'center', padding: 20 }}>
                <IconButton style={{ backgroundColor: theme.colors.background, height: 160, width: 160, alignSelf: 'center', borderRadius: 200, justifyContent: 'center', alignItems: 'center' }}
                    icon="magnify"
                    size={130}
                    color={theme.colors.primary}
                >
                </IconButton>
                <Text style={{ fontSize: 25, fontWeight: '700', textAlign: 'center', fontFamily: "Quicksand-Bold", marginTop: 30 }}>Buscar</Text>
                <Text style={{ fontSize: 25, fontWeight: '700', textAlign: 'center', fontFamily: "Quicksand-Light", marginTop: 10 }}>Con el buscador podrás encontrar cualquier producto de nuestra base de datos a partir de su nombre, marca, descripción o tipo de animal.</Text>
                
            </View>
        </View>
    )
}
export default memo(AppIntro3);

import React, { memo } from "react";
import { Platform } from 'react-native';
import Background from "../Background";
import BottomControl from "../components/BottomControl";
import ScanButton from "../components/ScanButton";
import { useDispatch } from "react-redux";
import { updateCurrentTab } from "../store/pageStore";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useFocusEffect } from '@react-navigation/native';
// import * as Analytics from 'expo-firebase-analytics';
import Storage from 'baseapp/core/storage';
import { useEffect } from "react";
import { useHeaderStore } from "baseapp/context/headerContext";
import { useAppStore } from 'baseapp/context/appStore';
import { useAnalyticsStore } from "../context/analytics";

type Props = {
  setHeaderBorder: Function;
};

const HomeScreen = ({ setHeaderBorder }: Props) => {
  const route = useRoute();
  const navigation = useNavigation();
  const { setHeader } = useHeaderStore();
  const dispatch = useDispatch();
  const { session } = useAppStore();
  const { AnalyticsLogEvent } = useAnalyticsStore();

  useEffect(() => {
    if (route.name == 'home') {
      setHeader("home", "default")
    }
  }, [route])

  useEffect(() => {
    AnalyticsLogEvent("Visited Home", { "user": session.userInfo.email ? session.userInfo.email : "guest" });
  }, []);

  const onScroll = (pos: number) => {
    setHeaderBorder(Math.min(1, ((1 / 25) * pos)));
  }
  const changeScene = (key: string, pos: number) => {
    onScroll(pos);
  }

  const readTutorialInStorage = async () => {
    var tutorialStoraged = await Storage.read('tutorialDone');
    if (!tutorialStoraged) {
      navigation.navigate('tutorial');
    }
  }

  useEffect(() => {
    readTutorialInStorage();
  }, []);

  useFocusEffect(() => { // Redirects user to login
    if( (route.params?.p == 'history' || route.params?.p == 'favorites') && !session.loggedIn){
      navigation.navigate('login');
    }
  });

  useEffect(() => {
    navigation.navigate('home', {p:"articles"})
  },[])

  return (
    <Background>
      <ScanButton navigation={navigation}></ScanButton>
      <BottomControl subPage={route.params?.p} onRenderScene={changeScene} onScroll={onScroll}></BottomControl>
    </Background>
  );
}

export default memo(HomeScreen);
import { searchReducer } from './searchStore';
import { combineReducers, createStore } from 'redux'
import { pageReducer } from './pageStore';

const appReducer = combineReducers({
  search: searchReducer,
  page: pageReducer
});

export type AppState = ReturnType<typeof appReducer>

export default function configureStore() {
    const store = createStore(appReducer);
    return store;
}
  
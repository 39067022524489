import React, { memo, useState } from 'react';
import { View, Text,Alert,StyleSheet, Keyboard, Platform, ScrollView } from 'react-native';
import Background from '../Background';
import Logo from '../components/Logo';
import Button from 'baseapp/components/Button';
import { TextInput } from 'react-native-paper';
import { theme } from '../theme';
import ApiCaller from 'baseapp/core/ApiCaller';

type Props = {
  navigation: any;
};

const ForgotPasswordScreen = ({ navigation }: Props) => {

  const [email, setEmail] = useState("");

  const _onRecoverMailPressed  = async () => {
    
    const apiCaller = new ApiCaller();
    try{
      await apiCaller.call('/v1/forgotPassword', 'POST', {email});
      //alert(res)
      (Platform.OS == 'web') ?
      alert("Hemos enviado un mail a "+ email):
      Alert.alert("","Hemos enviado un mail a "+ email)
    }catch(e){
      (Platform.OS == 'web') ?
      alert("Introduce una dirección de correo electrónica válida"):
      Alert.alert("","Introduce una dirección de correo electrónica válida")
    }
  };
  const _onBackPressed = () => { 
    navigation.navigate('login')
  }
  

return (
      <Background style={{alignItems:"center"}}>
      <View style={{ maxWidth: 600, flex:1, width: '100%', flexDirection: "column", justifyContent: 'center', backgroundColor: theme.colors.background, padding: 15, marginTop:60  }}>
        <ScrollView>
        <View style={{ flex:3, flexDirection: "row", justifyContent: 'center' }}>
          <Logo></Logo>
        </View>
        <View style={{flex: 7, flexDirection:'column'}}>
          <TextInput
            label="Email"
            returnKeyType="next"
            onChangeText={(text: string) => setEmail(text)}
            autoCapitalize="none"
            autoCompleteType="email"
            textContentType="emailAddress"
            keyboardType="email-address"
            style={styles.entry}
            value={email}
            onSubmitEditing={()=> Keyboard.dismiss}
          />
          <View style={{ flexDirection: "row", marginBottom:100, justifyContent: 'center', width:"70%", alignSelf:"center"}}>
            <Button labelStyle={{ color: theme.colors.background, fontFamily: theme.fonts.regular.fontFamily, textTransform: 'none' }} style={styles.buttonContained}  onPress={_onRecoverMailPressed}>
              Enviar al mail
            </Button>
          </View>
          <View style={{ flexDirection: "row", justifyContent: 'center', width: "70%", marginBottom:10, alignSelf: "center"}}>
            <Text style={{ color: theme.colors.primary, fontFamily: theme.fonts.regular.fontFamily, justifyContent: 'center', alignSelf: 'center', alignItems: 'center', flexDirection: 'row' }}>
              Volver a la página de inicio
            </Text>
          </View>
          <View style={{ flexDirection: "row", justifyContent: 'center', width: "70%", alignSelf: "center" }}>
            <Button mode='outlined' labelStyle={{ color: theme.colors.primary, fontFamily: theme.fonts.regular.fontFamily, textTransform: 'none' }} style={styles.buttonOutlined} onPress={_onBackPressed}>
              Atrás
            </Button>
          </View>
        </View>
        </ScrollView>
      </View>
    </Background>
    );
};


const styles = StyleSheet.create({
  entry: {
    backgroundColor: theme.colors.background,
    marginBottom:120,

  },
  label: {
    color: theme.colors.text,
  },
  link: {
    fontWeight: 'bold',
    color: theme.colors.primary,
  },
  buttonContained: {
    backgroundColor: theme.colors.primary,
    shadowRadius:15,  
    shadowColor: 'rgba(0, 0, 0, 0.2)', 
    shadowOpacity: 0.2, 
    elevation:5

  },
  buttonOutlined: {
    borderWidth: 2,
    borderColor: theme.colors.primary,  
    borderRadius: 8 
  }
});
export default memo(ForgotPasswordScreen);
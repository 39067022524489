import React, { memo } from 'react';
import { View, Text } from 'react-native';
import { Button, Dialog, Portal, TextInput, IconButton } from 'react-native-paper';
import { theme } from '../theme';
import AddPhotoButton from './AddPhotoButton';
import EmailApi from '../api/EmailApi';
import ApiCaller from 'baseapp/core/ApiCaller';
import { useAppStore } from 'baseapp/context/appStore';
import { useNavigation } from '@react-navigation/native';
import { useAnalyticsStore } from '../context/analytics';
type Props = {
    hideDialogScan: Function;
    visibleScan: boolean;
    barCode: string,
    photoBack: string;
    photoFront: string;
    openCamera: Function;
};

const NotFoundScan = ({ hideDialogScan, visibleScan, barCode, photoBack, photoFront, openCamera }: Props) => {

    const [page, setPage] = React.useState<number>(0);
    const title = ["¡Ups! Parece que no está", "¡Muchas gracias!"];
    const subtitle = ["Ayúdanos a mejorar Sappi añadiendo dos imágenes del producto.", "Lo añadiremos lo antes posible. Puedes probar a encontrar uno similar usando el buscador."];
    const { session } = useAppStore();
    const currentUser = session.userInfo;
    const [additionalMsg, setAdditionalMsg] = React.useState<string>("");
    const navigation = useNavigation()

    const { AnalyticsLogEvent } = useAnalyticsStore();

    React.useEffect(() => {
        AnalyticsLogEvent("Not found scanning", { "user": session.userInfo.email ? session.userInfo.email : "guest", "barCode":barCode?barCode:"No barCode" });
    }, []);

    const sendEmail = async () => {
        const emailApi = new EmailApi(new ApiCaller(currentUser.token));
        const images: any[] = [];
        // hideDialogScan();

        if (photoFront) {
            const tmpFrontImage = {
                type: photoFront.split(':')[1].split(';')[0], name: "front", content: photoFront.split(',')[1]
            }
            images.push(tmpFrontImage);
        }
        if (photoBack) {
            const tmpBackImage = {
                type: photoBack.split(':')[1].split(';')[0], name: "back", content: photoBack.split(',')[1]
            }
            images.push(tmpBackImage);
        }

        const payload = {
            additionalMsg: additionalMsg,
            attachments: images,
            barcode: barCode
        }
        try{
            await emailApi.sendNewProduct(payload);
            navigation.navigate('home', { p: 'search' });
        }catch(e){}
    }

    const cancelDialog = () => {
        setPage(0);
        openCamera(false);
        hideDialogScan();
    }

    const takeFrontImage = async () => {
        openCamera(false);
    }
    const takeBackImage = async () => {
        openCamera(true);
    }

    return (
        <Portal>
            <Dialog visible={visibleScan} onDismiss={() => hideDialogScan()} style={{ borderRadius: 15, width: '90%', maxWidth: 400, alignSelf: 'center' }}>
                {
                    page == 0 ?
                        <View style={{ width: '90%', maxWidth: 450, alignSelf: 'center', justifyContent: 'center' }}>
                            <Text style={{ color: theme.colors.primary, fontSize: 18, fontFamily: theme.fonts.regular.fontFamily, textAlign: 'center', marginTop: 35, marginBottom: 5 }}>{title[page]}</Text>
                            <Text style={{ color: theme.colors.text, fontSize: 14, fontFamily: theme.fonts.light.fontFamily, textAlign: 'center', marginBottom: 25 }}>{subtitle[page]}</Text>
                            <View style={{ flexDirection: 'row', alignSelf: 'center', width: '90%', justifyContent: 'space-between', alignItems: 'center', marginBottom: 10 }}>
                                <AddPhotoButton cameraText={'Portada'} onPress={takeFrontImage} pictureReady={photoFront != ""} />
                                <AddPhotoButton cameraText={'Composición'} onPress={takeBackImage} pictureReady={photoBack != ""} />
                            </View>
                            <View style={{ backgroundColor: theme.colors.shadedBox, alignSelf: 'center', borderRadius: 8, justifyContent: 'center', width: '90%', height: 40 }}>
                                <Text style={{ color: theme.colors.text, fontFamily: theme.fonts.light.fontFamily, marginLeft: 15 }}>{barCode}</Text>
                            </View>
                            <View style={{ flexDirection: 'row', width: '90%', justifyContent: 'space-between', alignSelf: 'center', marginTop: 25, marginBottom: 35 }}>
                                <Button labelStyle={{ fontFamily: theme.fonts.light.fontFamily }} style={{ backgroundColor: theme.colors.background, width: '45%' }} color={theme.colors.primary} uppercase={false} onPress={() => cancelDialog()}>Cancelar</Button>
                                <Button disabled={(!photoFront && !photoFront)} labelStyle={{ fontFamily: theme.fonts.light.fontFamily }} style={{ backgroundColor: (photoFront || photoFront)?theme.colors.primary:theme.colors.shadedBox, width: '45%', borderRadius: 10 }} color={theme.colors.background} uppercase={false} onPress={() => { setPage(1) }}>Siguiente</Button>
                            </View>
                        </View>

                        : (page == 1 ?
                            <View style={{ width: '90%', maxWidth: 450, alignSelf: 'center', justifyContent: 'center' }}>
                                <IconButton icon="help-circle-outline" color={theme.colors.grey} style={{ alignSelf: 'flex-end', position: 'absolute', right: -15, top: 0 }} onPress={() => { setPage(2) }} />
                                <Text style={{ color: theme.colors.primary, fontSize: 18, fontFamily: theme.fonts.regular.fontFamily, textAlign: 'center', marginTop: 35, marginBottom: 5 }}>{title[page]}</Text>
                                <Text style={{ color: theme.colors.text, fontSize: 14, fontFamily: theme.fonts.light.fontFamily, textAlign: 'center', marginBottom: 25 }}>{subtitle[page]}</Text>
                                <TextInput
                                    onChangeText={(text: string) => setAdditionalMsg(text)}
                                    style={{ paddingHorizontal: 10, fontFamily: theme.fonts.thin.fontFamily, color: theme.colors.text, width: '90%', alignSelf: 'center' }}
                                    multiline={true}
                                    numberOfLines={3}
                                    placeholder={"Si lo deseas puedes añadir más información aquí..."}
                                    placeholderTextColor={theme.colors.grey}
                                />
                                <View style={{ flexDirection: 'row', width: '90%', justifyContent: 'center', alignSelf: 'center', marginTop: 25, marginBottom: 35 }}>
                                    <Button labelStyle={{ fontFamily: theme.fonts.light.fontFamily }} style={{ backgroundColor: theme.colors.primary, width: '45%', alignItems: 'center', borderRadius: 10 }} color={theme.colors.background} uppercase={false} onPress={() => {
                                        hideDialogScan();
                                        AnalyticsLogEvent("Adding product not found", { "user": session.userInfo.email ? session.userInfo.email : "guest", "barCode":barCode?barCode:"No barCode" });
                                        sendEmail()
                                    }}>Enviar!</Button>
                                </View>
                            </View>
                            :
                            <View style={{ width: '90%', maxWidth: 450, alignSelf: 'center', justifyContent: 'center', paddingVertical: 30 }}>
                                <IconButton icon="close" color={theme.colors.grey} style={{ alignSelf: 'flex-end', position: 'absolute', left: -15, top: 0 }} onPress={() => { setPage(1) }} />
                                <Text style={{ color: theme.colors.text, fontSize: 14, fontFamily: theme.fonts.light.fontFamily, textAlign: 'center', marginBottom: 25, marginTop: 20, width: '80%', alignSelf: 'center' }}
                                >Los códigos EAN (códigos de barras) varia de un producto a otro, incluso aunque solo cambie el peso, ingrediente aditivo... {"\n"} {"\n"} Por este motivo al buscarlo por la marca o el nombre ser que encuentres uno muy parecido, pero de distinto código.</Text>
                            </View>
                        )
                }

            </Dialog>
        </Portal>
    )
};

export default memo(NotFoundScan);
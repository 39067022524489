import React from "react";
import { Platform } from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import { useEffect } from "react";
import { ProductModel } from "systemDomain";
import ProductApi from "../api/Product.api";
import ApiCaller from "baseapp/core/ApiCaller";
import ProductForm from "../components/ProductForm";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useHeaderStore } from "baseapp/context/headerContext";
import { useAppStore } from 'baseapp/context/appStore';

const AddScreen = () => {
    const { session } = useAppStore();
    const currentUser = session.userInfo;
    const navigation = useNavigation();
    const route = useRoute();
    const {setHeader} = useHeaderStore();
    useEffect(() => {
            setHeader("add","onlyBack")
    }, [route])
    //this form is intended to create new documents, so it receives default values for the fields
    //but it should also accept ProductModels, so the easiest is to check if it contains 'getId'.
    const productApi = new ProductApi(new ApiCaller(currentUser.token));


    const generateProduct = async (productData: any) => {
        const product = new ProductModel(productData);
        try {
            const result: any = await productApi.create(product);
            navigation.navigate('item', { id: result.getId() });
        } catch (e) {
            alert("Error agregando el producto: " + e);
        }

    }

    useEffect(() => {
        (async () => {
            if (Platform.OS !== 'web') {
                const { status } = await ImagePicker.requestCameraRollPermissionsAsync();
                if (status !== 'granted') {
                    alert('Sorry, we need camera roll permissions to make this work!');
                }
            }
        })();
    }, []);

    return <ProductForm caption="Agregar producto" route={route} defaults={{}} onSubmit={(productData:any) => {
        generateProduct(productData);
    }} ></ProductForm>
}

export default AddScreen;

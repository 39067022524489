import ApiCaller from '../core/ApiCaller';

const getApiCaller = (currentUser: any) => {
    return new ApiCaller(currentUser.token);
}

class PushNotificationApi {
    constructor() {}
    static async sendToken(currentUser:any, token: any): Promise<any>{
        console.log('HELLO FROM CURRENT USER', currentUser)
        console.log('HELLOOOOO FROM API: ', token)
        return await getApiCaller(currentUser).call('/v1/pushnotification/', 'POST', {token: token});
    }
}

export default PushNotificationApi;
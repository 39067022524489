import ApiCaller from 'baseapp/core/ApiCaller';

class AdditivesApi {
    apiCaller: ApiCaller;

    constructor(apiCaller:ApiCaller) {
        this.apiCaller = apiCaller;
    }
    async getAdditives() : Promise<string[]> {
        const response = await this.apiCaller.call('/v1/additives', 'GET')
        return response.additives;
    }
    async updateAdditives(additives:string[]) : Promise<string[]> {
        const response = await this.apiCaller.call('/v1/additives', 'POST', additives)
        return response.additives;
    }
}

export default AdditivesApi;
import { useFocusEffect, useRoute } from '@react-navigation/native';
import React, { memo } from 'react';
import { View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { ProductModel, ProductCollection } from 'systemDomain';
import { useHeaderStore } from "baseapp/context/headerContext";
import EmptyMessage from '../components/EmptyMessage';
import Loading from '../components/Loading';
import ProductList from '../components/ProductList';
import { useAppStore } from 'baseapp/context/appStore';

const FavoritesTab = (props:any) => {
    const { profile } = useAppStore();
    const route = useRoute();
    const {setHeader} = useHeaderStore();

    var favoriteObjs = [];    
    try {
        //@ts-ignore
        favoriteObjs = profile.props.favoriteProducts?.filter((favorite) => favorite.msg?false:true);
    } catch (e) {
        //console.error(e);
        favoriteObjs = [];
    }
    if(!favoriteObjs) {
        favoriteObjs = [];
    }

    var favorites:ProductCollection = new ProductCollection(favoriteObjs.map((favorite:any)=>new ProductModel(favorite)));
    useFocusEffect(
        React.useCallback(() => {
            setHeader("home",'default');
        }, [route])
    )

    return (<ScrollView style={{flex: 1}} scrollEventThrottle={1} onScroll={(e) => {props.onScroll(e.nativeEvent.contentOffset.y)}}>
        {profile?<View style={{marginTop: 10, justifyContent: 'space-evenly' ,flexDirection: 'row', flexWrap: 'wrap', alignContent: 'flex-start'}}>
            {
                //@ts-ignore
                profile.ready && favorites.productArr.length == 0
                ? <EmptyMessage icon="heart-outline" message="Tu lista de favoritos está vacía. Busca y agrega productos a favoritos para encontrarlos rapidamente"></EmptyMessage>
                : !profile.ready
                ? <Loading text="Cargando la lista de favoritos..."></Loading>
                : profile.ready 
                ? <ProductList onScroll={props.onScroll} products={favorites}></ProductList>
                : null
            }
        </View>:null}
    </ScrollView>)
}

export default memo(FavoritesTab);
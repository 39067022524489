export interface ProductInterface {
    _id?: string,
    name: string,
    company: string,
    production_process: string,
    analysis_nut_raw: string,
    analysis_nut: object[],
    aditives_raw: string,
    aditives: string[],
    extras_raw: string,
    extras: string[],
    ingredients: string,
    picture: string,
    barcodes: string[],
    animal: string,
    food_type: string,
    source: string,
    source_details: string,
    bonus: object[],
    comments: object[],
    shopData?: object[]
}


export default class ProductModel {
    props: ProductInterface;

    constructor(productData: ProductInterface) {
        this.props = productData;
    }

    getId(): string | undefined {
        return this.props._id;
    }

    get(key: string): any {
        if(this.props.hasOwnProperty(key)) {
            //@ts-ignore
            return this.props[key];
        }
        throw "Error: Product doesn't have the property: " + key;
    }

    getName(): string {
        return this.props.name;
    }

    getCompany(): string {
        return this.props.company;
    }

    getProduction_process(): string|undefined {
        return this.props.production_process;
    }

    getAnalysis_nut_raw(): string|undefined {
        return this.props.analysis_nut_raw;
    }

    getAnalysis_nut(): object|undefined {
        return this.props.analysis_nut;
    }

    getAditives_raw(): string|undefined {
        return this.props.aditives_raw;
    }

    getAditives(): string[]|undefined {
        return this.props.aditives;
    }

    getExtras_raw(): string|undefined {
        return this.props.extras_raw;
    }

    getExtras(): string[]|undefined {
        return this.props.extras;
    }

    getIngredients(): string|undefined {
        return this.props.ingredients;
    }

    getPicture(): string|undefined {
        return this.props.picture;
    }

    getBarcodes(): string[] {
        return this.props.barcodes;
    }

    getAnimal(): string {
        return this.props.animal;
    }

    getFood_type(): string|undefined {
        return this.props.food_type;
    }

    getSource(): string|undefined {
        return this.props.source;
    }

    getSource_details(): string|undefined {
        return this.props.source_details;
    }

    getShopData(): object[]|undefined {
        return this.props?.shopData;
    }

    getSappiScore() {
        //@ts-ignore
        return parseFloat(this.props.sappiScore);
    }  
    
    computeCarbs = () => {
        const n:any = this.getAnalysis_nut();
        if(n && n.carbs) {
            if (n.carbs < 0) {
                n.carbs = 0;
            }
            return n.carbs;
        }
        if(n.protein && n.ash && n.fat && n.humidity) {
            var total = 100 - n.protein - n.ash - n.fat - n.humidity;
            if (total < 0) {
                total = 0;
            }
            return Math.round(total * 10) / 10;
            }
        return '?'  
    }

    toObject() {
        //@ts-ignore
        delete this.props.sappiScore;
        //@ts-ignore
        delete this.props.score;
        //@ts-ignore
        delete this.props.ingredients_list;
        //@ts-ignore
        delete this.props.iScore1;
        //@ts-ignore
        delete this.props.iScore2;
        //@ts-ignore
        delete this.props.iScore3;
        //@ts-ignore
        delete this.props.ingredientsScore;
        //@ts-ignore
        delete this.props.analysis_nut?.dryProtein;
        //@ts-ignore
        delete this.props.analysis_nut?.dryFat;
        //@ts-ignore
        delete this.props.analysis_nut?.dryCarbs;
        //@ts-ignore
        delete this.props.analysis_nut?.dryFiber;
        //@ts-ignore
        delete this.props.analysis_nut?.dryAsh;

        return this.props;
    }

    static keys(): string[] {
        return ["name", "company", "production_process", "analysis_nut_raw", "analysis_nut", "aditives_raw", "aditives", "extras_raw", "extras", "ingredients", "picture", "barcodes", "animal", "food_type", "source", "source_details", "bonus", "comments", "shopData" ];
    }
}

export class ProductCollection {
    productArr: ProductModel[];

    constructor(productList: ProductModel[]) {
        this.productArr = productList;
    }

    findBy(key: string, value: string): ProductModel[] {
        return this.productArr.filter((product: ProductModel) => product.get(key) === value);
    }

    length() {
        return this.productArr.length;
    }

    items() {
        return this.productArr;
    }
}


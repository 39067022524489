import React, { useEffect } from 'react'
import { View, Text, FlatList, SafeAreaView, StyleSheet, ScrollView } from 'react-native'
import { Searchbar, Divider, ActivityIndicator, Portal, Dialog, Button, Paragraph } from 'react-native-paper'
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useAppStore } from "baseapp/context/appStore";
import { ProfileModel, ProfileCollection } from 'systemDomain';
import { theme } from '../theme';
import AdminApi from '../api/AdminApi';

type Props = {
    setSubmenu: Function;
}
const EditUsers = ({ setSubmenu }: Props) => {
    const session = useAppStore(state => state.session);
    const currentUser = session.userInfo;
    const [searchQuery, setSearchQuery] = React.useState('');
    const [users, setUsers] = React.useState<ProfileCollection>(new ProfileCollection([]));
    const [loading, setLoading] = React.useState<boolean>(true);
    const [dialogVisible, setDialogVisible] = React.useState<boolean>(false);
    const [selectedUser, setSelectedUser] = React.useState<ProfileModel>();


    const showDialog = () => setDialogVisible(true);
    const hideDialog = () => {
        setDialogVisible(false);
        setSelectedUser(undefined);
    }

    const searchUsers = async () => {
        setLoading(true)
        const data = await AdminApi.users(currentUser, 0, searchQuery);
        setLoading(false);
        setUsers(data);
    }

    const onLoadMore = async () => {
        const data = await AdminApi.users(currentUser, users?.length(), searchQuery);
        setUsers(new ProfileCollection(users.items().concat(data.items())));
    }

    const onDelete = async () => {
        await AdminApi.deleteUser(currentUser, selectedUser.get('email'));
        const newUsers: ProfileModel[] = users?.items().filter((user: ProfileModel) => user?.get('email') != selectedUser?.get('email'));
        setUsers(new ProfileCollection(newUsers));
        hideDialog();
    }

    const renderItem = ({ item }: ProfileModel) => {
        return (
            <View style={{ width: '100%', marginVertical: 8 }}>
                <View style={{ flexDirection: 'row', flex: 1, width: '100%' }} >
                    <Text style={styles.field}>{item.get('email')}</Text>
                    <Text style={styles.field}>{item.get('name')}</Text>
                    <Text style={styles.field}>{item.get('animals')}</Text>
                    <MaterialCommunityIcons
                        onPress={() => {
                            setSelectedUser(item)
                            showDialog();
                        }}
                        style={{ position: 'absolute', right: 50, bottom: 0, zIndex: 100 }}
                        name="delete"
                        color={'red'}
                        size={24} />
                </View>
                <Divider style={{ width: '90%', alignSelf: 'center', marginTop: 16 }} />
            </View>
        );
    }

    useEffect(() => {
        searchUsers()
    }, [searchQuery]);

    return (
        <View style={{ flex: 1 }}>
            <ScrollView contentContainerStyle={{ flex: 1 }} style={{ height: 0 }}>
                <View style={{ backgroundColor: 'white', flex: 1, paddingHorizontal: '5vw', paddingTop: '5vh' }}>
                    <Text style={{ color: 'black', fontSize: 26, fontFamily: theme.fonts.regular.fontFamily }}>Usuarios</Text>
                    <View style={{ flexDirection: 'column', width: '100%', flex: 1, marginTop: '4vh', borderTopRightRadius: 30, borderTopLeftRadius: 30, shadowRadius: 10, shadowColor: 'rgba(0,0,0,0.3)' }}>
                        <View style={{ width: '40%', marginTop: 30, marginLeft: 30 }}>
                            <Searchbar
                                inputStyle={{ fontSize: 16, fontFamily: theme.fonts.medium.fontFamily, color: 'grey' }}
                                placeholder={'Filtrar por email'}
                                onChangeText={(text) => setSearchQuery(text)}
                                value={searchQuery}
                                style={styles.searchbar}
                            />
                        </View>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%', paddingTop: '4vh', paddingBottom: '2vh' }}>
                            <Text style={styles.text}>Email</Text>
                            <Text style={styles.text}>Nombre</Text>
                            <Text style={styles.text}>Animales</Text>
                        </View>
                        {
                            loading ?
                                <ActivityIndicator style={{ flex: 1 }} color={theme.colors.primary} /> :
                                !users || users.length() == 0 ?
                                    <Text style={[styles.field, { marginTop: '20vh' }]}>No se han encontrado usuarios</Text>
                                    : <SafeAreaView style={{ flex: 1 }}>
                                        <FlatList
                                            data={users.items()}
                                            renderItem={renderItem}
                                            keyExtractor={(item) => item.props._id}
                                            contentContainerStyle={{ flex: 1 }}
                                            numColumns={1}
                                            onEndReachedThreshold={4}
                                            horizontal={false}
                                            style={{ width: '100%' }}
                                            ListFooterComponent={() => <Text onPress={onLoadMore} style={{ color: theme.colors.primary, fontFamily: theme.fonts.regular.bold, textAlign: 'center', alignSelf: 'center', fontSize: 16, marginTop: 16, marginBottom: 30 }}>Cargar más +</Text>}
                                        />
                                    </SafeAreaView>
                        }
                    </View>
                    <MaterialCommunityIcons onPress={() => setSubmenu('default')} style={{ position: 'absolute', top: 20, left: 20, zIndex: 100 }} name="chevron-left" color={'black'} size={42} />
                </View>
                <Portal>
                    <Dialog visible={dialogVisible} onDismiss={hideDialog} style={{ width: 600, borderRadius: 30, alignSelf: 'center' }}>
                        <Dialog.Title>{selectedUser ? "Desea eliminar el usuario " + selectedUser.get('email') + "?" : ""}</Dialog.Title>
                        <Dialog.Content>
                            <Paragraph>Esta acción eliminará el usuario y los datos asociados a éste</Paragraph>
                        </Dialog.Content>
                        <Dialog.Actions>
                            <Button onPress={hideDialog}>Cancelar</Button>
                            <Button onPress={onDelete}>Eliminar</Button>
                        </Dialog.Actions>
                    </Dialog>
                </Portal>
                </ScrollView>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 5,
        backgroundColor: 'white',
        paddingHorizontal: '5vw',
        shadowRadius: 12,
        shadowOpacity: 0.1,
        elevation: 5,
        paddingTop: '3vH',
        alignItems: 'center'
    },
    text: {
        fontSize: 16,
        textAlign: 'center',
        fontFamily: theme.fonts.regular.fontFamily,
        flex: 1
    },
    field: {
        fontFamily: theme.fonts.medium.fontFamily,
        flex: 1,
        textAlign: 'center'
    },
    searchbar: {
        width: '100%',
        alignSelf: 'center',
        borderRadius: 20,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.23,
        shadowRadius: 3,
        elevation: 4,
    },
})

export default EditUsers;
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { useSappiScoreStore } from '../context/sappiScoreContext';
import ProcesadoListItem from './ProcesadoListItem';
import sappiScoreInterface from '../models/sappiScore/sappiScoreInterface';
import SappiScoreModel from '../models/sappiScore/sappiScoreModel';

const AdminFormulaProcesado = () => {
    const { sappiScore, sappiScoreChanging, setSappiScoreChanging } = useSappiScoreStore();

    const [extrusionadoValue, setExtrusionadoValue] = useState<string>('');
    const [humidValue, setHumidValue] = useState<string>('');
    const [naturalValue, setNaturalValue] = useState<string>('');
    const [prensadoValue, setPrensadoValue] = useState<string>('');
    const [cocinadoValue, setCocinadoValue] = useState<string>('');
    const [deshidratadoValue, setDeshidratadoValue] = useState<string>('');
    const [snackValue, setSnackValue] = useState<string>('');

    useEffect(() => {
        setExtrusionadoValue(sappiScore?.getProcesses_extrusionado().toString());
        setHumidValue(sappiScore?.getProcesses_humid().toString());
        setNaturalValue(sappiScore?.getProcesses_natural().toString());
        setPrensadoValue(sappiScore?.getProcesses_prensado().toString()); // prensado tes un congelado
        setCocinadoValue(sappiScore?.getProcesses_cocinado().toString());
        setDeshidratadoValue(sappiScore?.getProcesses_deshidratado().toString());
        setSnackValue(sappiScore?.getProcesses_snack().toString());
    }, [sappiScore]);

    useEffect(() => {
        // @ts-ignore
        var tmpSappiScoreObj: sappiScoreInterface = sappiScoreChanging?.toObject();
        tmpSappiScoreObj['process']['extrusionado'] = parseInt(extrusionadoValue);
        tmpSappiScoreObj['process']['prensado'] = parseInt(prensadoValue);
        tmpSappiScoreObj['process']['humid'] = parseInt(humidValue);
        tmpSappiScoreObj['process']['cocinado'] = parseInt(cocinadoValue);
        tmpSappiScoreObj['process']['natural'] = parseInt(naturalValue);
        tmpSappiScoreObj['process']['deshidratado'] = parseInt(deshidratadoValue);
        tmpSappiScoreObj['process']['snack'] = parseInt(snackValue);
        setSappiScoreChanging(new SappiScoreModel(tmpSappiScoreObj));
    }, [extrusionadoValue, humidValue, naturalValue, prensadoValue, cocinadoValue, deshidratadoValue, snackValue]);


    return (
        <View style={{ marginTop: 40, flexDirection: 'column', width: '80%', justifyContent: 'space-between', alignSelf: 'center' }}>
            <ProcesadoListItem name={'Extrusionado'} setValue={setExtrusionadoValue} value={extrusionadoValue} />
            <ProcesadoListItem name={'Prensado en frío'} setValue={setPrensadoValue} value={prensadoValue} />
            <ProcesadoListItem name={'Humedo'} setValue={setHumidValue} value={humidValue} />
            <ProcesadoListItem name={'Cocinado'} setValue={setCocinadoValue} value={cocinadoValue} />
            <ProcesadoListItem name={'Natural'} setValue={setNaturalValue} value={naturalValue} />
            <ProcesadoListItem name={'Deshidratado'} setValue={setDeshidratadoValue} value={deshidratadoValue} />
            <ProcesadoListItem name={'Snack'} setValue={setSnackValue} value={snackValue} />
        </View>
    )
};

export default AdminFormulaProcesado;

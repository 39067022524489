import React, { useContext } from 'react';
import Settings from '../core/settings';
import { useAppStore } from '../context/appStore';
import { useRegisterStore } from '../plugins/auth/stores/RegisterStore';
import {ProfileModel} from '../internalapp/system';
import { useNavigation } from '@react-navigation/native';
import conf from '../internalapp/conf';

const ProfileLoader: React.FC = () => {
    const {session, profile} = useAppStore();
    const {setProfile} = useAppStore();
    const {fields} = useRegisterStore();
    const navigation = useNavigation();

    React.useEffect(() => {
        const readProfile = async () => {
            try {
                const profile = await ProfileApi.read(session.userInfo, "me");
                setProfile(profile);
            } catch(e) {
                try{
                    const error = JSON.parse(e.message);
                    if(error?.statusCode == 404){
                        console.log('No se ha encontrado el perfil asociado a la cuenta: ', session?.userInfo.email);
                        console.log('Compruebe que esta activo el profile en el socialLogin de frontend')
                        if(conf.socialLogin.profile.autoCreate){
                            const completeProfileRoute = conf.socialLogin.profile.extendProfileRoute;
                            navigation.navigate(completeProfileRoute);
                        }
                    }
                    else{
                        alert('Error cargando tu perfil. Comprueba tu conexi�n a internet y vuelve a intentarlo. Si el problema persiste escribe a: '+Settings.getHelpEmail())
                    }
                    console.log('Error', JSON.parse(e.message).statusCode);
                }catch(er){}
                return;
            }
        }
        console.log('Profile: ', profile, 'ready: ', session.ready, 'token: ', session.userInfo.token);
        if(session.ready && session.userInfo.token) {
            var ProfileApi;

            try {
                ProfileApi = require("../internalapp/api/ProfileApi").default;
            } catch (e) {
                console.error("Profile activated in fontend but not in backend");
                return;
            }

            if(session.from == 'register' || ( session.from == 'socialLogin' && conf.socialLogin.profile.autoCreate )) {
                const createUser = async () => {
                    //create profile
                    try {
                        await ProfileApi.create(session.userInfo, new ProfileModel({ email: session.userInfo.email, name: session.userInfo.name, ...fields }));
                    } catch (err: any) {
                        return;
                    }
                }
                createUser().then(() => readProfile());
            } else {
                readProfile();
            }
        }

    }, [session]);

    return (     
        null
    );
};

export default ProfileLoader;
import React, { memo } from "react";
import { View } from 'react-native';
import Background from "../Background";
import { Text, Snackbar } from "react-native-paper";
import { ProductModel, ProductCollection } from "systemDomain";
import ProductApi from "../api/Product.api";
import ApiCaller from "baseapp/core/ApiCaller";
import Loading from "../components/Loading";
import ErrorMessage from "../components/ErrorMessage";
import { theme } from "../theme";
import { useNavigation } from "@react-navigation/native";
import * as Linking from 'expo-linking';
// import * as ExpoClipboard from 'expo-clipboard';
//import Clipboard from 'expo-clipboard';
import getEnvVars from '../environments';
import { Clipboard } from 'react-native';
import ProductContentHeader from '../components/ProductContentHeader';
import ProductContent from '../components/ProductContent';
import { useAppStore } from 'baseapp/context/appStore';
import { useAnalyticsStore } from '../context/analytics';
import { useFocusEffect } from "@react-navigation/native";
import { useHeaderStore } from "baseapp/context/headerContext";

type Props = {
    setHeaderBorder: Function;
    setHeaderStyle: Function;
    route: any;
};

const ItemGuestScreen =  ({setHeaderBorder, setHeaderStyle, route }: Props) => {
  
    setHeaderBorder(0);
    const { setHeader } = useHeaderStore();

    const id = route.params?.id;
    const barcode = route.params?.barcode;
    const [product, setProduct] = React.useState<ProductModel>();
    const [isError, setIsError] = React.useState(false);
    const { session } = useAppStore();
    const currentUser = session.userInfo;
    const [isLoading, setLoading] = React.useState(true);
    const [onShopSectionPress, setOnShopSectionPress] = React.useState<boolean>(false);
    const productApi = new ProductApi(new ApiCaller(currentUser.token));
    const navigation = useNavigation();
    const [suggested, setSuggested] = React.useState<any>(new ProductCollection([]));
    const [visibleSnackbar, setVisibleSnackbar] = React.useState<boolean>(false);
    const deleteRefresh = false;
    const { AnalyticsLogEvent } = useAnalyticsStore();
    
    const copyToClipboard = (url:string) => {
        Clipboard.setString(url);
    };

    const onSharePressed = () => {
        copyToClipboard(getEnvVars().apiUrl.split('/api')[0]+'/itemGuest/'+product?.getId());
        setVisibleSnackbar(true);
    }

    var productItem:ProductModel;

    const readId = async (id: string, skipSet: boolean) => {
        try {
            productItem = await productApi.readGuest(id);
            if(!skipSet) {
                setLoading(false);
                setProduct(productItem);
            }
        } catch (e) {
            if(!skipSet) {
                setLoading(false);
                setIsError(true);
            }
        }
    }
    React.useEffect(() => {
        if (product) {
            AnalyticsLogEvent("product",
                {
                    "_id": product?.getId(),
                    "name":product?.getName(),
                    "company":product?.getCompany(),
                    "production_process":product?.getProduction_process(),
                    "animal": product?.getAnimal(),
                    "barcodes": product?.getBarcodes(),
                    "sappiScore": product?.getSappiScore()
                }   
            );
        }
    }, [product]);
    const readBarcode = async (barcode: string) => {
        try {
            const productItem = await productApi.readBarcode(barcode);
            readId(productItem.getId(), true);
            setLoading(false);
            setIsError(false);
            setProduct(productItem);
        } catch (e) {
            setLoading(false);
            setIsError(true);
        }
    }
    const reFresh = async () => {
        try {
            const items = await productApi.suggested(id);
            setSuggested(items);
        } catch (e) {

        }

        if (barcode) readBarcode(barcode);
        else if (id) readId(id, false);
    }
    const openMail = () => {
        Linking.openURL('mailto:producto@sappi.app?subject=Producto%20con%20codigo%20'+barcode);
        
    }
    const onGoToBuy = () => {
        setOnShopSectionPress(true)
    }

    const onResetGoToBuy = () => setOnShopSectionPress(false);

    React.useEffect(() => {
        return navigation.addListener('focus', () => {
            reFresh();
        });
    }, []);

    React.useEffect(() => {
        reFresh();
    }, [currentUser, id]);

    React.useEffect(() => {
        readId(id, false);
    },[deleteRefresh])
    useFocusEffect(
        React.useCallback(() => {
            setHeader('itemGuest', 'item');
        }
            , [route])
    )


    return (
        <Background>
        <Snackbar
            visible={visibleSnackbar}
            onDismiss={()=>{setVisibleSnackbar(false)}}
            duration={1000}
            style={{backgroundColor:'#494A4C', width:'100%', alignSelf:'center'}}
            wrapperStyle={{position:'absolute', bottom:0, zIndex:1, maxWidth:600}}
        >
            Enlace copiado en el portapapeles
        </Snackbar>
        {(product && !isError)
            ?
            <View  style={{ flex:1, paddingTop: 30, maxWidth: 600, width: '100%', flexDirection:'column', backgroundColor: theme.colors.background}}>
                {/* Content */}
                <View style={{ flex: 2}}>
                    <ProductContentHeader product={product} onGoToBuy={onGoToBuy}/>
                </View>
                {/* View rounded */}
                <View style={{flex: 6, marginTop:10}}>
                    <ProductContent suggested={suggested} product={product} setProduct={setProduct} id={id} onResetGoToBuy={onResetGoToBuy} onGoToBuyPressed={onShopSectionPress}/>
                </View>
            </View> 
            : null
        }
        {isLoading ? <Loading text="Cargando producto..."></Loading> : null}
        {isError ? <ErrorMessage submessage={"Código: " + (id ? id : barcode)} message={
            <View style={{flexDirection:"column", alignItems:"center", maxWidth:400, marginHorizontal:15}}>
                <Text style={{textAlign:"center", color:"#aaa"}}>¡Ups! Parece que aún no tenemos este producto registrado, aunque puedes probar a utilizar también el buscador sin el código de barras, puede que lo encuentres...</Text>
                <Text style={{textAlign:"center", marginTop:20, fontSize:14, color:"#aaa", flexWrap: 'wrap'}}>Si quieres que lo añadamos envíanos un email a <Text onPress={openMail} style={{color:theme.colors.primary}}>producto@sappi.app</Text>
                                {'\n'}¡Muchas gracias por ayudarnos a que cada vez más gente pueda estar informada!</Text>
            </View>
        }></ErrorMessage> : null}
    </Background>
    )
}

export default memo(ItemGuestScreen);
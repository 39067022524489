import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { Linking, View } from 'react-native';
import {  Card, Text } from 'react-native-paper';
import { ProductModel } from 'systemDomain';
import Settings from 'baseapp/core/settings';
import {theme} from '../theme';
import SappiScore from './SappiScore';
import { getColor } from '../utils';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import DogCatImage from './DogCatImage';
import { useProductStore } from '../context/productContext';


const ProductCard = ({product}:{product: ProductModel}) => {
  const { setCurrentProduct } = useProductStore()
  const getIcon = (product: ProductModel) => {  
    return product.getAnimal();
  }
  
  const cardClicked = () => {
    setCurrentProduct(product)
  }

  function capitalizeFirstLetter(stri: string) {
    const lowerCaseStri = stri.toLowerCase();
    const firstCapital = lowerCaseStri[0].toUpperCase();
    return firstCapital+lowerCaseStri.slice(1);
  }

  return (
  <Card onPress={cardClicked} style={{marginBottom: 10, marginLeft: 5, marginRight: 5, width:'100%', elevation: 2, maxWidth:180, shadowRadius:5, borderColor:'#F0F0F0', borderWidth:0.2}}>
    <Card.Content style={{display:'flex', flex:1}}>
      <View style={{flexDirection:"column", flex:1}}>
        <Text numberOfLines={2} style={{marginTop: 5, lineHeight: 18, fontSize: 14, fontWeight:'500', fontStyle:'normal'}}>{capitalizeFirstLetter(product.getName())}</Text>
        <Text numberOfLines={1} style={{marginBottom: 10, color:theme.colors.companyTextColor, fontSize: 14, fontWeight:'300', fontFamily: theme.fonts.light.fontFamily, flex:1}}>{product.getCompany()}</Text>
      </View>
    </Card.Content>
    <Card.Cover source={{uri: Settings.getImagesURL()+product.getPicture() }} />
    <Card.Content>
      <View style={{flexDirection:'column'}}>
        <View style={{flex:1, justifyContent:'space-between',marginTop: 10, flexDirection:"row"}}>
          <View style={{flex:1,flexDirection:'row', alignItems:'center', alignSelf:'center'}}>
          {product.getAnimal() == 'dog cat'? 
            <DogCatImage size={18} product={product}/> :
            <MaterialCommunityIcons
              name={getIcon(product)}
              color={getColor(product)}
              size={20}
              style={{ textAlign: "center" , marginRight: 5}}
              onPress={() => { Linking.openURL('https://sappi.app/la-formula/') }}
            />
           } 
            {/* <Badge size={14} style={{backgroundColor: getColor(product), marginRight:10, alignSelf:'center'}}/>   */}
            <SappiScore product={product}></SappiScore>
          </View>
          {/* <Favorite size={25} product={product}></Favorite> */}
        </View>
      </View>
    </Card.Content>
  </Card>
  );
};

export default ProductCard;
import React from 'react';
import { StyleSheet, View, Text, ScrollView } from 'react-native';
import { theme } from '../theme';
import FormulaQualityBonus from './FormulaQualityBonus';
import FormulaQualityIngredients from './FormulaQualityIngredients';
import FormulaQualityPercentage from './FormulaQualityPercentage';

const AdminFormulaQuality = () => {

    return (
        <View style={{ flex: 1, backgroundColor: theme.colors.background, paddingHorizontal: 40 }}>
            <ScrollView style={{ flex: 1, paddingTop: 20  }}>
                <Text style={styles.textUppercase}>INGREDIENTES</Text>
                <FormulaQualityIngredients />
                <Text style={styles.textUppercase}>VALOR INGREDIENTES (3 máx.)</Text>
                <FormulaQualityPercentage />
                <Text style={styles.textUppercase}>BONUS (1r Ingrediente)</Text>
                <FormulaQualityBonus />
            </ScrollView>
        </View>
    )
};

const styles = StyleSheet.create({
    textUppercase: {
        color: theme.colors.primary,
        fontSize: 14,
        fontFamily: theme.fonts.regular.fontFamily,
        marginBottom: 40,
    },
    textOption: {
        fontSize: 20,
        fontFamily: theme.fonts.regular.fontFamily,
        alignSelf: 'flex-start',
        marginBottom: 20
    },
    viewOption: {
        marginVertical: 10
    },
    paragraph: {
        color: theme.colors.colorParagraphItem,
        fontWeight: '500',
        fontSize: 14,
        lineHeight: 18,
    },
});

export default AdminFormulaQuality;

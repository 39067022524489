import * as React from 'react';
import { BottomNavigation, DefaultTheme } from 'react-native-paper';
import { StyleSheet } from 'react-native';
import HistoryTab from '../tabs/HistoryTab';
import FavoritesTab from '../tabs/FavoritesTab';
import SearchTab from '../tabs/SearchTab';
import { useNavigation } from '@react-navigation/native';
import ArticlesTab from '../tabs/ArticlesTab';

const BottomControl = (props: any) => {
  const [routes] = React.useState([
    { key: 'articles', title: 'Artículos', icon: 'book-outline' },
    { key: 'history', title: 'Historial', icon: 'history' },
    { key: 'search', title: 'Buscar', icon: 'magnify' },
    { key: 'favorites', title: 'Favoritos', icon: 'heart-outline' },
  ]);

  const indexes: any = {  articles: 0, history: 1, search: 2, favorites: 3 };
  const index = indexes[props.subPage ?? 'search'];
  const navigation = useNavigation();

  const theme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      background: 'white',
      primary: 'white',
    },
  };

  const [scrollPosition, setScrollPosition] = React.useState<any>({});

  const onViewScroll = (key: string, pos: number) => {
    scrollPosition[key] = pos;
    setScrollPosition(scrollPosition);
    props.onScroll(pos);
  }

  const renderScene = (scene: any) => {
    switch (scene.route.key) {
      case 'history':
        return <HistoryTab onScroll={(pos: number) => { onViewScroll(scene.route.key, pos) }}></HistoryTab>;
      case 'search':
        return <SearchTab onScroll={(pos: number) => { onViewScroll(scene.route.key, pos) }}></SearchTab>;
      case 'favorites':
        return <FavoritesTab onScroll={(pos: number) => { onViewScroll(scene.route.key, pos) }}></FavoritesTab>;
      case 'articles':
        return <ArticlesTab onScroll={(pos: number) => { onViewScroll(scene.route.key, pos) }}></ArticlesTab>;
    }
  }

  return (
    <BottomNavigation
      onTabPress={(e) => { props.onRenderScene(e.route.key, scrollPosition[e.route.key] ?? 0) }}
      style={styles.bottomControl}
      navigationState={{ index, routes }}
      onIndexChange={(index) => {
        navigation.navigate('home', { p: routes[index].key });
      }}
      renderScene={renderScene}
      barStyle={styles.barStyle}
      labeled={false}
      theme={theme}
    >
    </BottomNavigation>
  );
};

const styles = StyleSheet.create({
  bottomControl: {
    width: '100%'
  },

  barStyle: {
    borderTopWidth: 1,
    borderTopColor: '#e3e3e3'
  }
});

export default BottomControl;
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { AppState } from '../store/store';
import { Dialog, Divider, Portal } from 'react-native-paper';
import MenuButtons from './MenuButtons';
import FavoriteMenuButton from './FavoriteMenuButton';
import { ProductModel } from 'systemDomain';
import { useSelector } from 'react-redux';
import { useNavigationState } from '@react-navigation/native';
import { useAppStore } from 'baseapp/context/appStore';


type Props = {
    sheetREF: any;
    hideProductMenu: Function;
    visibleProductMenu: boolean;
    product: ProductModel;
    navigation: any;
    onSharePressed: Function;
    onReportPressed: Function;
  };

const ProductMenuDialog = ({hideProductMenu, visibleProductMenu, product, navigation, onSharePressed, onReportPressed, sheetREF}:Props) => { 
    const { session } = useAppStore();
    const currentUser = session.userInfo;
    const navState = useNavigationState((state) => state.routes[state.index]);


    const _onSharePressed = () => { 
        onSharePressed()
        hideProductMenu()
    }
    
    const _onReportPressed = () => { 
        onReportPressed()
        hideProductMenu()
    }
    const handleEdit = () => {
        // setCurrentId(navState.params?.id)
        navigation.push('edit', { id: navState.params?.id });
        hideProductMenu()
    }
    const handleMore = () => {
        navigation.push('add');
        hideProductMenu()
    }
    
    return(
        <Portal>
            <Dialog visible={visibleProductMenu} onDismiss={() => hideProductMenu()} style={{ borderRadius: 15, width: '90%', maxWidth: 400, alignSelf: 'center' }}>
            <View style={[styles.buttonsView, {marginTop: 40}]}>
                <FavoriteMenuButton product={product} navigation={navigation}></FavoriteMenuButton>
            </View>
            <Divider style={styles.divider} />
            <View style={styles.buttonsView}>
                <MenuButtons icon={'message-alert-outline'}  iconText={'Reportar un problema'} onPress={_onReportPressed} />
            </View>
            <Divider style={styles.divider} />
            <View style={[styles.buttonsView, {marginBottom: 20}]}>
                <MenuButtons icon={'export-variant'}  iconText={'Compartir producto'} onPress={_onSharePressed} />
            </View>
            {currentUser.type == 'admin' ?
            <View style={{marginTop: -20, marginBottom: 20}}>
            <Divider style={styles.divider} />
            <View style={styles.buttonsView}>
            <MenuButtons icon={'file-document-edit'}  iconText={'Editar producto'} onPress={handleEdit} />
            </View>
            <Divider style={styles.divider} />
            <View style={styles.buttonsView}>
            <MenuButtons icon={'plus'}  iconText={'Añadir nuevo producto'} onPress={handleMore} />
            </View>
            </View>
            :null}
            </Dialog>
        </Portal>
    )
};

const styles = StyleSheet.create({
    buttonsView: {
        alignItems: 'flex-start', 
        alignSelf: 'center'
    },
    divider: {
        marginBottom: 20,
        alignSelf: 'center',
        width: '80%'
    },
});

export default ProductMenuDialog;

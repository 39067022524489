import React, { memo, useRef, useState } from 'react';
import { StyleSheet, View, Alert, ScrollView, Platform, Keyboard, Linking } from 'react-native';
import Background from 'internalapp/Background';
import Logo from '../../../components/Logo';
import { TextInput, Text, Checkbox } from 'react-native-paper';
import { theme } from 'internalapp/theme';
import { emailValidator, nameValidator } from '../../../core/utils';
import AuthApi from '../../../api/AuthApi';
import ApiCaller from '../../../core/ApiCaller';
import User from '../../../models/user';
import Settings from '../../../core/settings';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { CommonActions } from '@react-navigation/native';
import { useAppStore } from '../../../context/appStore';
import Button from '../../../components/Button';
import { useRegisterStore } from '../stores/RegisterStore';

const RegisterScreen = ({ navigation }) => {
  const pluginTheme = theme.plugins.auth;
  const { setField } = useRegisterStore();

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [checked, setChecked] = React.useState(false);

  const { session, setSession } = useAppStore();
  const scrollRef = useRef();

  React.useEffect(() => {
    try {
      ((navigation as any) as DrawerNavigationProp<{}>)?.closeDrawer();
    } catch (e) { }

  }, []);

  const resetStackNavigator = (routeName: string) => {
    const resetAction = CommonActions.reset({
      index: 1,
      routes: [{ name: routeName }]
    });
    navigation.dispatch(resetAction);
  }

  React.useEffect(() => {
    if (session.loggedIn) {
      resetStackNavigator('home');
    }
  }, [session]);

  const _onBackPressed = () => {
    navigation.navigate('login')
  }

  const _onRegisterPressed = () => {
    const emailError = emailValidator(email);
    const nameError = nameValidator(name);
    if (password != rePassword) {
      (Platform.OS == 'web') ?
        alert("Las contraseñas no coinciden") :
        Alert.alert("", "Las contraseñas no coinciden")
      return;
    }
    if (emailError) {
      (Platform.OS == 'web') ?
        alert("La dirección de correo no parece válida, por favor introduce tu correo electrónico") :
        Alert.alert("", "La dirección de correo no parece válida, por favor introduce tu correo electrónico");
      return;
    }

    if (nameError) {
      (Platform.OS == 'web') ?
        alert("El campo nombre no puede estar vacío") :
        Alert.alert("", "El campo nombre no puede estar vacío");
      return;
    }
    if (!checked) {
      (Platform.OS == 'web') ?
        alert('Debes aceptar los términos y condiciones') :
        Alert.alert("", 'Debes aceptar los términos y condiciones')
    }
    else {
      setField('name', name);
      //do register
      var authApi = new AuthApi(new ApiCaller());

      authApi.register(email, password, name).then((data: any) => {
        let user = new User(data.email, data.token, data.type, data.status);
        Settings.setCurrentUser(user).then(async () => {
          setSession(user, 'register');
        });
      }).catch(err => {
        (Platform.OS == 'web') ?
          alert("Este email ya está registrado. Prueba con otro.") :
          Alert.alert("", 'Este email ya está registrado. Prueba con otro. \n\n Si este mail es tuyo pulsa "Entrar" para volver a la pantalla de inicio',
            [
              { text: "Entrar", onPress: () => navigation.navigate("login") },
              { text: "Entendido", onPress: () => console.log("OK Pressed") },
            ],
            { cancelable: false })

      });
    }
  };

  return (
    <Background style={{ alignItems: "center" }}>
      {!pluginTheme.customRegister ?
        <>
          <View style={{ maxWidth: 600, width: '100%', flex: 12, flexDirection: "column", justifyContent: 'center', backgroundColor: theme.colors.background, padding: 15 }}>
            <ScrollView ref={scrollRef} style={{ paddingBottom: 10 }} showsVerticalScrollIndicator={false}>
              <View style={{ flexDirection: "row", justifyContent: 'center' }}>
                <Logo></Logo>
              </View>
              <TextInput
                label="Email"
                returnKeyType="next"
                onChangeText={(text: string) => setEmail(text)}
                autoCapitalize="none"
                autoCompleteType="email"
                textContentType="emailAddress"
                keyboardType="email-address"
                onSubmitEditing={Keyboard.dismiss}
                style={styles.entry}
                onFocus={() => { scrollRef.current?.scrollTo({ x: 0, y: 0, animated: true }) }}
              />
              <TextInput
                label="Nombre y apellidos"
                returnKeyType="next"
                value={name}
                onChangeText={(text: string) => setName(text)}
                onSubmitEditing={Keyboard.dismiss}
                style={styles.entry}
                onFocus={() => { scrollRef.current?.scrollTo({ x: 0, y: 0, animated: true }) }}
              />
              {pluginTheme.registerUISlot}
              <TextInput
                label="Contraseña"
                returnKeyType="done"
                onChangeText={(text: string) => setPassword(text)}
                secureTextEntry={!showPassword}
                onSubmitEditing={Keyboard.dismiss}
                style={styles.entry}
                onFocus={() => { scrollRef.current?.scrollTo({ x: 0, y: 200, animated: true }) }}
                right={<TextInput.Icon name={showPassword ? "eye-off" : "eye"} color={theme.colors.primary} onPress={() => setShowPassword(!showPassword)} />}
              />
              <TextInput
                label="Repetir contraseña"
                returnKeyType="done"
                onChangeText={(text: string) => setRePassword(text)}
                secureTextEntry={!showPassword}
                onSubmitEditing={_onRegisterPressed}
                style={styles.entry}
                onFocus={() => { scrollRef.current?.scrollTo({ x: 0, y: 200, animated: true }) }}
              />
              <View style={{ flexDirection: "row", alignItems: 'center', width: '70%', marginVertical: 20 }}>
                <View style={{ borderColor: Platform.OS == 'ios' ? '#BFBFBF' : null, borderWidth: Platform.OS == 'ios' ? 1 : null, borderRadius: 10 }}>
                  <Checkbox color={theme.colors.primary} status={checked ? 'checked' : 'unchecked'} onPress={() => { setChecked(!checked) }} />
                </View>
                <Text> Aceptar <Text onPress={() => Linking.openURL(pluginTheme.termsAndConditionsURL)} style={{ color: theme.colors.primary, textDecorationLine: 'underline', }}>términos y condiciones</Text></Text>
              </View>
              <View style={{ alignItems: "center" }}>
                <View style={theme.plugins.auth.buttonContainerStyle}>
                  <Button style={[{ marginTop: 30 }]} onPress={_onRegisterPressed}>
                    {pluginTheme.textCreateAccountButton}
                  </Button>
                  <View style={{ flexDirection: "row", justifyContent: 'center', width: "70%", alignSelf: "center", marginTop: 60 }}>
                    <Text style={{ color: theme.colors.primary, fontFamily: theme.fonts.medium.fontFamily, justifyContent: 'center', alignSelf: 'center', alignItems: 'center', flexDirection: 'row' }}>
                      Volver a la página de inicio
                    </Text>
                  </View>
                  <Button mode="outlined" labelColor={theme.plugins.auth.registerButtonLabelColor} style={{ marginTop: 30 }} onPress={_onBackPressed}>
                    Atrás
                  </Button>
                </View>
              </View>
              <View style={{ height: 300 }}></View>
            </ScrollView>
          </View>
        </>
        : pluginTheme.customRegister}
    </Background>
  );
};

const styles = StyleSheet.create({
  entry: {
    backgroundColor: theme.colors.background,

  },
  label: {
    color: theme.colors.text,
  },
  link: {
    fontWeight: 'bold',
    color: theme.colors.primary,
  },
  buttonContained: {
    shadowRadius: 15,
    shadowColor: 'rgba(0, 0, 0, 0.2)',
    shadowOpacity: 0.2,
    elevation: 2,
    width: '60%',
    marginTop: 10,
    alignSelf: 'center',
    borderRadius: 8
  },
  labelButton: {
    fontFamily: theme.fonts.medium.fontFamily
  },
  buttonOutlined: {
    width: '60%',
    marginTop: 20,
    alignSelf: 'center',
    borderRadius: 8,
    borderWidth: 2,
    borderColor: theme.colors.primary,
    backgroundColor: theme.colors.background,
  }
});

export default memo(RegisterScreen);
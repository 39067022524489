import React, { memo, useState } from 'react';
import { StyleSheet, Text, View, Alert, Platform, ScrollView, Keyboard } from 'react-native';
import Background from 'internalapp/Background';
import Logo from '../../../components/Logo';
import Button from '../../../components/Button';
import { TextInput } from 'react-native-paper';
import { theme } from 'internalapp/theme';
import AuthApi from '../../../api/AuthApi';
import ApiCaller from '../../../core/ApiCaller';
import User from '../../../models/user';
import Settings from '../../../core/settings';
import { Link } from '@react-navigation/native';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { CommonActions } from '@react-navigation/native';
import { useAppStore } from '../../../context/appStore';
import conf from 'internalapp/conf';
import SocialLoginLoader from '../../../components/SocialLoginLoader';
import { MaterialCommunityIcons } from '@expo/vector-icons';


const LoginScreen = ({ navigation }) => {
  const pluginTheme = theme.plugins.auth;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { setSession } = useAppStore();
  const [showPassword, setShowPassword] = useState(false);

  React.useEffect(() => {
    try { ((navigation as any) as DrawerNavigationProp<{}>)?.closeDrawer(); } catch (e) { }
  }, []);

  const resetStackNavigator = (routeName: string) => {
    const resetAction = CommonActions.reset({
      index: 1,
      routes: [{ name: routeName }]
    });
    navigation.dispatch(resetAction);
  }

  const _onRegisterPressed = () => {
    navigation.navigate('register')
  }

  const _onLoginPressed = () => {
    //do login
    var authApi = new AuthApi(new ApiCaller());
    authApi.login(email, password).then((data: any) => {
      let user = new User(data.email, data.token, data.type, data.status);
      setSession(user);
      Settings.setCurrentUser(user).then(() => {
        resetStackNavigator('home');
      });
    }).catch(err => {
      console.log(err);
      (Platform.OS == 'web') ?
        alert("La dirección de correo y la contraseña no coinciden") :
        Alert.alert("", "La dirección de correo y la contraseña no coinciden",
          [
            { text: "OK", onPress: () => console.log("OK Pressed") }
          ],
          { cancelable: false }
        );
    });
  };

  return (
    <Background style={{ alignItems: "center" }}>
      {
        conf.auth.login.backToHomeEnabled ?
          <MaterialCommunityIcons name="close" color='black' size={30} style={{ width:'100%', positionAbsolute: 'absolute', marginTop:10, marginLeft:20 }} onPress={() => navigation.navigate('home')} />
          : null
      }
      {!pluginTheme.customLogin ?
        <>
          <View style={{ maxWidth: 600, width: '100%', flex: 12, flexDirection: "column", justifyContent: 'center', backgroundColor: theme.colors.background, padding: 15 }}>
            <ScrollView >
              <View style={{ flexDirection: "row", justifyContent: 'center', marginBottom: 20 }}>
                <Logo></Logo>
              </View>
              <TextInput
                label="Email"
                returnKeyType="next"
                onChangeText={(text: string) => setEmail(text)}
                autoCapitalize="none"
                autoCompleteType="email"
                textContentType="emailAddress"
                keyboardType="email-address"
                style={styles.entry}
                onSubmitEditing={() => Keyboard.dismiss}
              />
              <TextInput
                label="Contraseña"
                returnKeyType="next"
                onChangeText={(text: string) => setPassword(text)}
                secureTextEntry={!showPassword}
                style={styles.entry}
                onSubmitEditing={_onLoginPressed}
                right={<TextInput.Icon name={showPassword ? "eye-off" : "eye"} color={theme.colors.primary} onPress={() => setShowPassword(!showPassword)} />}
              />
              <Text style={{ marginTop: 10, textAlign: 'right', fontFamily: theme.fonts.regular.fontFamily, fontSize: 16, lineHeight: 20, color: theme.colors.primary }}>
                <Link to='/forgotpassword'>
                  {pluginTheme.textForgotPasswordLink}
                </Link>
              </Text>
              <View style={{ alignItems: "center", marginTop: 30 }}>
                <View style={theme.plugins.auth.buttonContainerStyle}>
                  <Button style={[{ marginTop: 30 }]} onPress={_onLoginPressed}>
                    {pluginTheme.textLoginButton}
                  </Button>
                  {
                    conf.socialLogin.enabled && Platform.OS != 'ios' ?
                      <SocialLoginLoader />
                      : null
                  }
                  {!conf.auth?.disableRegister ?
                    <>
                      {pluginTheme.showRegisterBanner ?
                        <View style={{ flexDirection: "row", justifyContent: 'center', width: "70%", alignSelf: "center", marginTop: 80 }}>
                          <Text style={{ color: theme.colors.primary, fontFamily: theme.fonts.medium.fontFamily, justifyContent: 'center', alignSelf: 'center', alignItems: 'center', flexDirection: 'row' }}>
                            {pluginTheme.textRegisterBanner}
                          </Text>
                        </View>
                        : null}
                      <Button mode="outlined" labelColor={theme.plugins.auth.registerButtonLabelColor} style={{ marginTop: 30 }} onPress={_onRegisterPressed}>
                        {pluginTheme.textRegisterButton}
                      </Button>
                    </>
                    : null}
                </View>

              </View>

            </ScrollView>
          </View>
        </>
        : pluginTheme.customLogin}
    </Background>
  );
};


const styles = StyleSheet.create({
  entry: {
    backgroundColor: theme.colors.background,

  },
  label: {
    color: theme.colors.text,
  },
  link: {
    fontWeight: 'bold',
    color: theme.colors.primary,
  },
  buttonContained: {
    shadowRadius: 15,
    shadowColor: 'rgba(0, 0, 0, 0.2)',
    shadowOpacity: 0.2,
    elevation: 2,
    width: '60%',
    marginTop: 80,
    alignSelf: 'center',
    borderRadius: 8
  },
  labelButton: {
    fontFamily: theme.fonts.medium.fontFamily
  }
});

export default memo(LoginScreen);
import React, { memo } from 'react';
import Header from 'internalapp/Header';

const AppHeader = (props:any) => {
    return(
        <Header 
            title={props.title}
            headerBorder={props.headerBorder}
            headerStyle={props.headerStyle}>
        </Header>
    );
}

export default memo(AppHeader);
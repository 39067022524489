import React, { memo } from 'react';
import { StyleSheet, View, Text} from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';

const EmptyMessage = ({message, icon}:{message:string, icon:string}) => (
    <View style={{flex:1, marginTop:'10%', flexDirection:"column", alignContent:"center"}}>
        <Text style={{fontSize: 20, margin: 20, color: '#ccc', textAlign:"center"}}>{message}</Text>
        <MaterialCommunityIcons
            name={icon}
            size={210}
            color="black"
            style={{opacity: 0.03, textAlign:"center"}}
        />
    </View>
);

const styles = StyleSheet.create({

});

export default memo(EmptyMessage);

import React, { useEffect, useState } from 'react';
import { StyleSheet, View, ScrollView } from 'react-native';
import { Chip, FAB } from 'react-native-paper';
import { useSappiScoreStore } from '../context/sappiScoreContext';
import { theme } from '../theme';
import sappiScoreInterface from '../models/sappiScore/sappiScoreInterface';
import SappiScoreModel from '../models/sappiScore/sappiScoreModel';
import IngredientsDialog from './IngredientsDialog';

const FormulaQualityIngredients = () => {
    const { sappiScore, setSappiScoreChanging, sappiScoreChanging } = useSappiScoreStore();
    
    const [visibleIngredientsDialog, setVisibleIngredientsDialog] = useState(false);
    const [ingredientsList, setIngredientsList] = useState([]);

    const showIngredients = () => setVisibleIngredientsDialog(true);
    const hideIngredients = () => setVisibleIngredientsDialog(false);
    
    useEffect(() => { 
        setIngredientsList(sappiScore?.getQuality_ingredients());
    }, [sappiScore]);

    useEffect(() => {
        // @ts-ignore
        var tmpSappiScoreObj: sappiScoreInterface | undefined = sappiScoreChanging?.toObject();
        if (tmpSappiScoreObj != undefined) {
            tmpSappiScoreObj['quality']['ingredients'] = ingredientsList;
            setSappiScoreChanging(new SappiScoreModel(tmpSappiScoreObj));
        }
    }, [ingredientsList]);

    return (
        <View style={{ flex: 1 }}>
            <View style={{ flex: 1 }}>
                <ScrollView style={{}} contentContainerStyle={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                    {
                        ingredientsList != undefined ?
                            ingredientsList.map((ingredient: any, index:number) => (
                                <Chip key={index} style={{ marginRight: 10, marginBottom: 20 }}>{ingredient.name + " ( " + ingredient.value + " )"}</Chip>
                            )) : null
                    }
                </ScrollView>
            </View>
            <FAB icon={'plus'} label={'Ingredientes'} uppercase={false} color={theme.colors.blue} style={styles.fab} onPress={showIngredients} />
            <IngredientsDialog ingredientsList={ingredientsList} setIngredientsList={setIngredientsList} visibleIngredients={visibleIngredientsDialog} hideIngredients={hideIngredients} />
        </View>
    )
};

const styles = StyleSheet.create({
    fab: {
        width: 200,
        backgroundColor: theme.colors.background,
        borderColor: theme.colors.blue,
        shadowOpacity: 0,
        borderWidth: 2,
        alignSelf: 'center',
        marginBottom: 60,
        fontWeight: '500',
    }
});

export default FormulaQualityIngredients;

import React, { memo, useEffect } from "react";
import { Text } from "react-native-paper";
import { BarCodeScanner } from 'expo-barcode-scanner';
import { View, StyleSheet } from 'react-native';
import { useNavigation, useRoute } from "@react-navigation/native";
import { Platform } from 'react-native';
import { useHeaderStore } from "baseapp/context/headerContext";
import { Camera } from 'expo-camera';
import { Dimensions } from "react-native";

type Props = {
  setHeaderBorder: Function;
};

const ScanScreen = ({ setHeaderBorder }: Props) => {
  const navigation = useNavigation();
  const route = useRoute();
  const { setHeader } = useHeaderStore();
  const [hasPermission, setHasPermission] = React.useState(null);
  const [scanned, setScanned] = React.useState(null);
  const dimensions = React.useRef(Dimensions.get("window"));
  const screenWidth = dimensions.current.width;
  const height = Math.round((screenWidth * 16) / 9);
  
  if (Platform.OS != 'web') {
    useEffect(() => {
      (async () => {
        try{
          const { status } = await Camera.requestCameraPermissionsAsync();
          setHasPermission(status === 'granted');
        }catch(e){}
      })();
    }, []);

    if (hasPermission === undefined) {
      return <Text>Abriendo el lector de código de barras....</Text>;
    }
    if (hasPermission === false) {
      return <Text>Error: no se puede acceder a la camara</Text>;
    }
  }


  const handleBarCodeScanned = ({ type, data }: any) => {
    setScanned(true);
    if(data) navigation.push('item', { barcode: data });
  };

  useEffect(() => {
    setHeader("scan", "onlyBack");
    setHeaderBorder(0);
    setScanned(false);
  }, [route]);


  return (
    <View
      style={{
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'flex-end',
        backgroundColor: 'white'
      }}>

      {
        <Camera
          type={Camera.Constants.Type.back}
          ratio='16:9'
          onBarCodeScanned={!scanned?handleBarCodeScanned:null}
          barCodeScannerSettings={{
            barCodeTypes: [
              BarCodeScanner.Constants.BarCodeType.codabar, 
              BarCodeScanner.Constants.BarCodeType.code39, 
              BarCodeScanner.Constants.BarCodeType.code93, 
              BarCodeScanner.Constants.BarCodeType.code128,
              BarCodeScanner.Constants.BarCodeType.code39mod43,
              BarCodeScanner.Constants.BarCodeType.ean13, 
              BarCodeScanner.Constants.BarCodeType.ean8, 
              BarCodeScanner.Constants.BarCodeType.interleaved2of5, 
              BarCodeScanner.Constants.BarCodeType.itf14, 
              BarCodeScanner.Constants.BarCodeType.rssexpanded, 
              BarCodeScanner.Constants.BarCodeType.upc_a, 
              BarCodeScanner.Constants.BarCodeType.upc_e, 
              BarCodeScanner.Constants.BarCodeType.upc_ean 
            ],
          }}
          style={{
            marginTop: dimensions.current.height-height,
            height: '100%',
            width:'100%', 
            alignSelf:'center'
          }}
        />
      }

    </View>
  );
}

export default memo(ScanScreen);
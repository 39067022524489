//!
import ApiCaller from 'baseapp/core/ApiCaller';
import { ProductModel, ProductCollection } from 'systemDomain';

export default class ProductApiBase {
    apiCaller: ApiCaller;

    constructor(apiCaller: ApiCaller) {
        this.apiCaller = apiCaller;
    }

    async all(): Promise<ProductCollection> {
        const data = await this.apiCaller.call('/v1/product', 'GET');
        return new ProductCollection(data.map((item:any) => {
            return new ProductModel(item);
        }));
    }

    async read(id: string): Promise<ProductModel> {
        const data = await this.apiCaller.call('/v1/product/'+id, 'GET');
        return new ProductModel(data);
    }

    async readGuest(id: string): Promise<ProductModel> {
        const data = await this.apiCaller.call('/v1/productGuest/'+id, 'GET');
        return new ProductModel(data);
    }

    async create(product: ProductModel): Promise<ProductModel>{
        const data = await this.apiCaller.call('/v1/product', 'POST', product.toObject());
        return new ProductModel(data);
    }

    async update(id: string, product: ProductModel): Promise<ProductModel>{
        const data = this.apiCaller.call('/v1/product/'+id, 'POST', product.toObject());
        return data;
    }

    async delete(id: string): Promise<void>{
        return this.apiCaller.call('/v1/product/'+id+'/delete', 'POST', {});
    }

    async readComments(id:string): Promise<void>{
        return this.apiCaller.call('/v1/comment/'+id, 'GET');
    }
}
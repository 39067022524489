import React, { memo } from "react";
import Background from "../Background";
import { View, StyleSheet, Text } from 'react-native';
import { responsiveHeight } from "react-native-responsive-dimensions";
import { theme } from "../theme";

const DeleteAccountInformationScreen = () => {

  return (
    <Background style={{ height: '100%', justifyContent: 'center' }}>
      <Text style={styles.title}>Eliminar Perfil y Datos en Sappi App</Text>
      <Text style={styles.text}>Si deseas eliminar tu cuenta y todos los datos asociados en Sappi App, sigue los pasos detallados a continuación.</Text>
      <Text style={styles.title}>Pasos para borrar tu perfil</Text>
      <Text style={styles.text}>1. Inicia sesión en tu cuenta de Sappi App.</Text>
      <Text style={styles.text}>2. Ve al menú y selecciona 'Borrar Cuenta'.</Text>
      <Text style={styles.text}>3. Confirma que desear borrar el perfil</Text>
      <Text style={styles.title}>Datos que se Eliminarán</Text>
      <Text style={styles.text}>Información personal (nombre, correo electrónico)</Text>
      <Text style={styles.text}>Preferencias y historial de productos</Text>
      <Text style={styles.title}>Datos que se Eliminarán</Text>
      <Text style={styles.text}>Datos agregados y anónimos para análisis estadísticos</Text>
      <Text style={styles.text}>Información requerida por la ley para ser retenida por un período específico</Text>
    </Background>
  );
}

export default memo(DeleteAccountInformationScreen);

const styles = StyleSheet.create({
  text: {
    alignSelf: 'center',
    fontFamily: theme.fonts.medium.fontFamily,
    fontSize: responsiveHeight(2),
    marginBottom: responsiveHeight(3),
  },
  title: {
    alignSelf: 'center',
    fontFamily: theme.fonts.regular.fontFamily,
    fontSize: responsiveHeight(3),
    marginVertical: responsiveHeight(3),
  }
});


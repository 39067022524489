import React, { memo } from 'react';
import { theme } from '../../theme';
import { IconButton } from "react-native-paper";
import { Text, View } from "react-native";

const AppIntro1 = () => {
    const [checked, setChecked] = React.useState(false);

    return (
        <View style={{ justifyContent: 'center', flex: 1, backgroundColor: theme.colors.background}}>
            <View style={{ backgroundColor: theme.colors.background, width: '90%', maxWidth: 400, height: 500, alignSelf: 'center', borderRadius: 10, padding: 20 }}>
                <View style={{ flexDirection: 'row', alignContent: 'center', justifyContent: 'center' , marginTop: 30}}>
                    <IconButton style={{ backgroundColor: theme.colors.background, alignSelf: 'center', borderRadius: 200, justifyContent: 'center', alignItems: 'center' }}
                        icon="circle"
                        size={50}
                        color={'green'}
                    >
                    </IconButton>
                    <Text style={{ fontSize: 35, alignSelf: 'center', fontFamily: "Quicksand-Bold", color: 'grey' }}> <Text style={{ color: 'black' }}>94</Text>/100</Text>
                </View>
                <Text style={{ fontSize: 25, fontWeight: '700', textAlign: 'center', fontFamily: "Quicksand-Bold", marginTop: 60 }}>Puntuación Sappi</Text>
                <Text style={{ fontSize: 25, fontWeight: '700', textAlign: 'center', fontFamily: "Quicksand-Light", marginTop: 10 }}>Esta valoración muestra la calidad del producto, siendo 0 (poca) y 100 (muy buena).</Text>
                <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 50 }}>
                    
                </View>
            </View>
        </View>
    )
}
export default memo(AppIntro1);

import React, { memo, useState } from 'react';
import { View, Text,Alert,StyleSheet} from 'react-native';
import Background from 'internalapp/Background';
import Logo from '../../../components/Logo';
import Button from '../../../components/Button';

import { TextInput } from 'react-native-paper';
import { Navigation } from '../../../types';
import { theme } from 'internalapp/theme';
import { emailValidator, passwordValidator } from '../../../core/utils';

type Props = {
  navigation: Navigation;
};
const ChangePasswordScreen = ({ navigation }: Props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const _onChangePasswordPressed = () => {

    if (password != rePassword) {
      console.log(password, rePassword);
      alert("Las contraseñas no coinciden");
      return;
    }
    alert("Password changed");

  }
    return (
      <Background style={{alignItems:"center"}}>
      <View style={{ maxWidth: 600, width: '100%', flex: 1, flexDirection: "column", justifyContent: 'center', backgroundColor: theme.colors.background, padding: 15 }}>
        <View style={{ flexDirection: "row", justifyContent: 'center' }}>
          <Logo></Logo>
        </View>

        <TextInput
          label="Contraseña"
          returnKeyType="done"
          onChangeText={(text: string) => setPassword(text)}
          secureTextEntry
          onSubmitEditing={_onChangePasswordPressed}
          style={styles.entry}
        />

        <TextInput
          label="Repetir contraseña"
          returnKeyType="done"
          onChangeText={(text: string) => setRePassword(text)}
          secureTextEntry
          onSubmitEditing={_onChangePasswordPressed}
          style={styles.entry}
        />
        <View style={{ flexDirection: "row", justifyContent: 'center', width:"70%", alignSelf:"center" }}>
          <Button onPress={_onChangePasswordPressed}>
            Change Password
          </Button>
        </View>
      </View>
    </Background>
    );
};


const styles = StyleSheet.create({
  entry: {
    backgroundColor: theme.colors.background,

  },
  label: {
    color: theme.colors.text,
  },
  link: {
    fontWeight: 'bold',
    color: theme.colors.primary,
  },
});
export default memo(ChangePasswordScreen);
import ApiCaller from 'baseapp/core/ApiCaller';
import { ArticleModel, ArticleCollection } from 'systemDomain';
import User from 'baseapp/models/user';

const getApiCaller = (currentUser: User): ApiCaller => {
    const apiCaller = new ApiCaller(currentUser.token);
    return apiCaller
}

class ArticleApi {
    constructor() {}

    static async all(currentUser: User): Promise<ArticleCollection> {
        const data = await getApiCaller(currentUser).call('/v1/articles', 'GET');
        return new ArticleCollection(data.map((item:any) => {
            return new ArticleModel(item);
        }));
    }

    static async read(currentUser: User, id: string): Promise<ArticleModel> {
        const data = await getApiCaller(currentUser).call('/v1/articles/'+id, 'GET');
        return new ArticleModel(data);
    }

    static async create(currentUser: User, article: ArticleModel): Promise<ArticleModel>{
        const data = await getApiCaller(currentUser).call('/v1/articles', 'POST', article.toObject());
        return new ArticleModel(data);
    }

    static async update(currentUser: User, article: ArticleModel): Promise<ArticleModel>{
        const data = await getApiCaller(currentUser).call('/v1/articles/'+article.getId(), 'POST', article.toObject());
        return new ArticleModel(data);
    }

    static async delete(currentUser: User, id: string): Promise<void>{
        return await getApiCaller(currentUser).call('/v1/articles/'+id+'/delete', 'GET');
    }
}

export default ArticleApi;
import React, { useEffect } from 'react'
import { View, Text, FlatList, SafeAreaView, StyleSheet, ScrollView, TouchableHighlight } from 'react-native'
import { Searchbar, Divider, ActivityIndicator, Portal, Dialog, Button, Paragraph } from 'react-native-paper'
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useAppStore } from "baseapp/context/appStore";
import { ProductModel, ProductCollection } from 'systemDomain';
import { theme } from '../theme';
import AdminApi from '../api/AdminApi';
import { useProductStore } from '../context/productContext';
import FiltersList from './FiltersList';

const EditProducts = () => {
    const session = useAppStore(state => state.session);
    const currentUser = session.userInfo;
    const { setCurrentProduct } = useProductStore()

    const [searchQuery, setSearchQuery] = React.useState('');
    const [products, setProducts] = React.useState<ProductCollection>(new ProductCollection([]));
    const [loading, setLoading] = React.useState<boolean>(true);
    const [dialogVisible, setDialogVisible] = React.useState<boolean>(false);
    const [selectedProduct, setSelectedProduct] = React.useState<ProductModel>();
    const [filters, setFilters] = React.useState<string[]>([]);

    const showDialog = () => setDialogVisible(true);
    const hideDialog = () => {
        setDialogVisible(false);
        setSelectedProduct(undefined);
    }
    const buildSearchQueryParams = (inputFilters: string[], inputSearchQuery: string) => {
        var queryParams: string = "";
        // build button filters
        inputFilters.map((filter: string) => {
            var queryParam: string;
            queryParam = filter + '=true&';
            queryParams = queryParams + queryParam;
        });
        // Add text search param
        const textSearch: string = 's=' + inputSearchQuery;
        return queryParams + textSearch;
    }
    const searchProducts = async () => {
        const queryParams: string = buildSearchQueryParams(filters, searchQuery);
        setLoading(true)
        const data = await AdminApi.products(currentUser, 0, queryParams);
        setLoading(false);
        setProducts(data);
    }
    const toggleFilter = (filter: string) => {
        setProducts(new ProductCollection([]));
        const tmpFilters = filters.includes(filter) ? filters.filter(index => index != filter) : filters.concat(filter);
        setFilters(tmpFilters);
    }
    const onLoadMore = async () => {
        const queryParams: string = buildSearchQueryParams(filters, searchQuery);
        const data = await AdminApi.products(currentUser, products?.length(), queryParams);
        setProducts(new ProductCollection(products.items().concat(data.items())));
    }
    const cardClicked = (product) => {
        setCurrentProduct(product)
    }
    const onDelete = async () => {
        await AdminApi.deleteProduct(currentUser, selectedProduct.getId());
        const newUsers: ProductModel[] = products?.items().filter((product: ProductModel) => product?.getId() != selectedProduct?.getId());
        setProducts(new ProductCollection(newUsers));
        hideDialog();
    }

    const renderItem = ({ item }: ProductModel) => {
        return (
            <TouchableHighlight onPress={() => cardClicked(item)} underlayColor='transparent' style={{ width: '100%', marginVertical: 8 }}>
                <>
                    <View style={{ flexDirection: 'row', flex: 1, width: '100%' }} >
                        {/* <Text style={[styles.field, {width: '30%'}]}>{item.getId()}</Text> */}
                        <Text numberOfLines={1} style={[styles.field, { flex: 4 }]}>{item.get('name')}</Text>
                        <Text style={styles.field}>{item.get('animal')}</Text>
                        <Text style={styles.field}>{item.get('company')}</Text>
                        <Text style={styles.field}>{item.get('sappiScore')}</Text>
                        <MaterialCommunityIcons
                            onPress={() => {
                                setSelectedProduct(item)
                                showDialog();
                            }}
                            style={{ textAlign: 'center', flex: 1 }}
                            name="delete"
                            color={'red'}
                            size={24} />
                    </View>
                    <Divider style={{ width: '95%', marginTop: 16 }} />
                </>
            </TouchableHighlight>
        );
    }

    useEffect(() => {
        searchProducts()
    }, [searchQuery, filters]);

    return (
        <View style={{ flex: 1 }}>
            <ScrollView contentContainerStyle={{ flex: 1 }} style={{ height: 0 }}>
                <View style={{ backgroundColor: 'white', flex: 1, paddingHorizontal: '5vw', paddingTop: '5vh' }}>
                    {/* <Text style={{ color: 'black', fontSize: 26, fontFamily: theme.fonts.regular.fontFamily }}>Productos</Text> */}
                    <View style={{ flexDirection: 'column', width: '100%', flex: 1, marginTop: '4vh', borderTopRightRadius: 30, borderTopLeftRadius: 30, shadowRadius: 10, shadowColor: 'rgba(0,0,0,0.3)', paddingHorizontal: 20 }}>
                        <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start', marginTop: 30, alignContent: 'center', padding: 20 }}>
                            <Searchbar
                                inputStyle={{ fontSize: 16, fontFamily: theme.fonts.medium.fontFamily, color: 'grey' }}
                                placeholder={'Filtrar por nombre o marca'}
                                onChangeText={(text) => setSearchQuery(text)}
                                value={searchQuery}
                                style={styles.searchbar}
                            />
                            <View style={{ justifyContent: 'center', flexDirection: 'column', flexWrap: 'wrap', alignContent: 'center', backgroundColor: theme.colors.background, alignItems: 'flex-end', marginBottom: 3 }}>
                                <FiltersList toggleFilter={toggleFilter} filters={filters} style={{ maxWidth: 500 }} />
                            </View>
                        </View>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%', paddingTop: '4vh', paddingBottom: '2vh' }}>
                            {/* <Text style={[styles.text, {width: '30%'}]}>_id</Text> */}
                            <Text style={[styles.text, { flex: 4 }]}>Nombre</Text>
                            <Text style={styles.text}>Animal</Text>
                            <Text style={styles.text}>Marca</Text>
                            <Text style={styles.text}>SappiScore</Text>
                            <Text style={styles.text}></Text>
                        </View>
                        {
                            loading ?
                                <ActivityIndicator style={{ flex: 1 }} color={theme.colors.primary} /> :
                                !products || products.length() == 0 ?
                                    <Text style={[styles.field, { marginTop: '20vh' }]}>No se han encontrado productos</Text>
                                    : <SafeAreaView style={{ flex: 1 }}>
                                        <FlatList
                                            data={products.items()}
                                            renderItem={renderItem}
                                            keyExtractor={(item) => item.props._id}
                                            contentContainerStyle={{ flex: 1 }}
                                            numColumns={1}
                                            onEndReachedThreshold={4}
                                            horizontal={false}
                                            style={{ width: '100%' }}
                                            ListFooterComponent={() => <Text onPress={onLoadMore} style={{ color: theme.colors.primary, fontFamily: theme.fonts.regular.bold, textAlign: 'center', alignSelf: 'center', fontSize: 16, marginTop: 16, marginBottom: 30 }}>Cargar más +</Text>}
                                        />
                                    </SafeAreaView>
                        }
                    </View>
                </View>
                <Portal>
                    <Dialog visible={dialogVisible} onDismiss={hideDialog} style={{ width: 600, borderRadius: 30, alignSelf: 'center' }}>
                        <Dialog.Title>{selectedProduct ? "Desea eliminar el producto " + selectedProduct.get('name') + "?" : ""}</Dialog.Title>
                        <Dialog.Content>
                            <Paragraph>Esta acción eliminará el producto</Paragraph>
                        </Dialog.Content>
                        <Dialog.Actions>
                            <Button onPress={hideDialog}>Cancelar</Button>
                            <Button onPress={onDelete}>Eliminar</Button>
                        </Dialog.Actions>
                    </Dialog>
                </Portal>
            </ScrollView>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 5,
        backgroundColor: 'white',
        paddingHorizontal: '5vw',
        shadowRadius: 12,
        shadowOpacity: 0.1,
        elevation: 5,
        paddingTop: '3vH',
        alignItems: 'center'
    },
    text: {
        fontSize: 16,
        textAlign: 'center',
        alignSelf: 'center',
        fontFamily: theme.fonts.regular.fontFamily,
        flex: 1,
        maxWidth: '40%',
    },
    field: {
        fontFamily: theme.fonts.medium.fontFamily,
        textAlign: 'center',
        alignSelf: 'center',
        flex: 1,
        maxWidth: '40%',
        paddingHorizontal: 5
    },
    searchbar: {
        // flex: 1,
        borderRadius: 20,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.23,
        shadowRadius: 3,
        elevation: 4,
        height: 60,
        width: 400,
        marginRight: 20
        // margin: 20
    },
})

export default EditProducts;
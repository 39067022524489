import React from 'react';
import Settings from '../core/settings';
import { useAppStore } from '../context/appStore';

const SessionLoader: React.FC = () => {
    const {setSession} = useAppStore();

    React.useEffect(() => {
		Settings.getCurrentUser().then((userInfo) => {
			setSession(userInfo);
		});
    }, []);

    return (     
        null
    );
};

export default SessionLoader;
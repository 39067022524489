import React from 'react';
import { View, Text } from 'react-native';
import { Button, Dialog, Portal } from 'react-native-paper';
import { theme } from '../theme';

type Props = {
    hideDialogSugestion: Function;
    visibleDialogSugestion: boolean;
  };

const NoBrandsPayDialog = ({hideDialogSugestion, visibleDialogSugestion}:Props) => {

    return(
        <Portal>
            <Dialog visible={visibleDialogSugestion} onDismiss={() => hideDialogSugestion()} style={{ borderRadius: 15, width: '80%', maxWidth: 350, alignSelf: 'center' }}>
                <View style={{ width: '90%', maxWidth: 450, alignSelf: 'center', justifyContent: 'center', paddingVertical: 30 }}>
                    <Text style={{ color: theme.colors.text, fontSize: 14, fontFamily: theme.fonts.medium.fontFamily, textAlign: 'center', marginBottom: 25, width: '80%', alignSelf: 'center' }}
                    >Como parte de nuestro compromiso de objetividad, tenemos como política no recibir financiación de marcas de productos de alimentación. Sappi App es un emprendimiento que se ha financiado con fondos propios de sus socios fundadores.</Text>
                    <Button 
                    labelStyle={{ fontFamily: theme.fonts.light.fontFamily }} 
                    style={{ backgroundColor: theme.colors.primary, width: '45%', alignSelf: 'center' }} 
                    color={theme.colors.background} uppercase={false} 
                    onPress={() => { hideDialogSugestion() }}
                    >Entendido</Button>
                </View>
            </Dialog>
        </Portal>
    )
};

export default NoBrandsPayDialog;

import React, { memo } from 'react';
import { TouchableHighlight, View, Text } from 'react-native';
import { theme } from '../theme';
import { MaterialCommunityIcons } from '@expo/vector-icons';


type Props = {
    cameraText: string;
    onPress: Function;
    pictureReady: boolean;
};

const AddPhotoButton = ({ cameraText, onPress, pictureReady }: Props) => {

    return(
    <TouchableHighlight onPress={() =>onPress()} style={{width: '48%'}} underlayColor={'transparent'}>
        <View style={{ backgroundColor: theme.colors.shadedBox, borderRadius: 8, justifyContent: 'center', height: 125, width: '100%' }}>
            <MaterialCommunityIcons
                name={'camera-outline'}
                color={ !pictureReady?theme.colors.grey:"green"}
                size={25}
                style={{ alignSelf: 'center' }}
            />
            <Text style={{ textAlign: 'center', fontFamily: theme.fonts.light.fontFamily, color: !pictureReady?theme.colors.grey:"green" }}>{cameraText}</Text>
        </View>
    </TouchableHighlight>
 
 )
};

export default memo(AddPhotoButton);

import React, { useState } from 'react';
import { FAB } from 'react-native-paper';
import { Platform, View, SafeAreaView, FlatList, Dimensions } from 'react-native';
import EmptyMessage from '../components/EmptyMessage';
import ErrorMessage from '../components/ErrorMessage';
import Loading from '../components/Loading';
import { theme } from '../theme';
import ProductApi from '../api/Product.api';
import ApiCaller from 'baseapp/core/ApiCaller';
import Searchbar from '../components/Searchbar';
import { ScrollView } from 'react-native-gesture-handler';
import { useEffect } from 'react';
import FiltersList from '../components/FiltersList';
import { ProductCollection } from 'systemDomain';
import { useAppStore } from 'baseapp/context/appStore';
import ProductCardAdmin from './ProductCardAdmin';

const AdminSearch = () => {
    const { session } = useAppStore();
    const currentUser = session.userInfo;
    const productApi = new ProductApi(new ApiCaller(currentUser.token));
    const [searchQuery, setSearchQuery] = React.useState('');
    const onChangeSearch = (query: string) => setSearchQuery(query);
    const [selectedId, setSelectedId] = useState(null);
    const [numColumns, setNumColumns] = useState(2);
    const [searchResults, setSearchResults] = useState<any[]>([]);
    const [status, setSearchStatus] = useState<string>('waiting');
    // Toggle filters buttons
    const [filters, setFilters] = useState<string[]>([]);


    const toggleFilter = (filter: string) => {
        setSearchResults([]);
        const tmpFilters = filters.includes(filter) ? filters.filter(index => index != filter) : filters.concat(filter);
        setFilters(tmpFilters);
        console.log('filters', tmpFilters);
        console.log('searchQuery', searchQuery);
        if (tmpFilters.length == 0 && searchQuery.length == 0) {
            setSearchStatus('waiting')
        }
        if (tmpFilters.length == 0 && searchQuery.length != 0) {
            setSearchStatus('searching');
            searchProducts(tmpFilters, [], searchQuery);
        }
        if (tmpFilters.length != 0) {
            setSearchStatus('searching');
            searchProducts(tmpFilters, [], searchQuery);
        }
    }

    const buildSearchQueryParams = (inputFilters: string[], inputSearchQuery: string) => {
        var queryParams: string = "";
        // build button filters
        inputFilters.map((filter: string) => {
            var queryParam: string;
            queryParam = filter + '=true&';
            queryParams = queryParams + queryParam;
        });
        // Add text search param
        const textSearch: string = 's=' + inputSearchQuery;
        return queryParams + textSearch;
    }
    const searchProducts = async (inputFilters: string[], searchResultsInput: any[], inputSearchQuery: string) => {
        const queryParams: string = buildSearchQueryParams(inputFilters, inputSearchQuery);
        const itemsToSkip: number = searchResultsInput.length;
        console.log("Items to skip: ", itemsToSkip)
        //const itemsToSkip:number = searchResults.length; 
        var result: ProductCollection = await productApi.search_V2(queryParams, itemsToSkip);
        const resultAny: any[] = result.productArr;
        if (!searchResultsInput.includes(resultAny)) {
            const finalResults: any[] = resultAny.concat(searchResultsInput);
            setSearchResults(finalResults);
            setSearchStatus('done');
        }
        if (!resultAny || !resultAny.length || resultAny.length == 0) { // set that there is any article found
            setSearchStatus('empty');
        }
    }

    const onClose = async () => {
        //first, inject a searching status
        setSearchQuery('');
        if (filters.length == 0) {
            setSearchStatus('waiting');
        }
        else {
            setSearchStatus('searching')
            searchProducts(filters, [], '');
        }
        //search in the api
    }

    const onLoadMore = async () => {
        console.log("LOAD MORE");
        if ((searchResults.length) % 30 == 0) {
            searchProducts(filters, searchResults, searchQuery);
        }
    }

    const getNumColumns = () => {
        if (Dimensions.get('window').width > 700) {
            return 4;
        }
        else if (Dimensions.get('window').width > 550) {
            return 3
        } else {
            return 2
        }
    }

    useEffect(() => {
        const numColumnsTmp = getNumColumns();
        setNumColumns(numColumnsTmp);
    }, []);

    const onSubmit = async () => {
        setSearchResults([]);
        if (searchQuery != '') {
            setSearchStatus('searching');
            searchProducts(filters, [], searchQuery);
        }
    }

    const renderItem = ({ item }: any) => {
        return (
            <ProductCardAdmin product={item} />
        );
    }

    const footerComponent = () => {

        if (Platform.OS == 'web') {
            return (
                <FAB
                    style={{ backgroundColor: theme.colors.background, alignSelf: 'center', justifyContent: 'center' }}
                    small
                    uppercase={false}
                    icon="plus"
                    onPress={onLoadMore}
                />
            );
        }
        return null;
    }

    return (
        <View style={{ flex: 2, backgroundColor: theme.colors.background, padding: 25 }}>
            <View style={{ width: '85%', maxWidth: 400, backgroundColor: 'transparent', flexDirection: 'row', alignSelf: 'center' }}>
                <Searchbar searchQuery={searchQuery} placeholder={"Buscar"} onChangeSearch={onChangeSearch} onSubmit={onSubmit} onClose={onClose} />
            </View>
            <View style={{ flex: 1 }} >
                <View style={{ justifyContent: 'center', flexDirection: 'column', flexWrap: 'wrap', alignContent: 'center' }}>
                    <View style={{ flexDirection: 'row', backgroundColor: theme.colors.background }}>
                        <View style={{ alignItems: 'flex-end', marginBottom: 3 }}>
                            <FiltersList toggleFilter={toggleFilter} filters={filters} />
                        </View>
                    </View>
                </View>
                <ScrollView contentContainerStyle={{ flex: 1, paddingBottom: 40 }} style={{ height: 0 }} showsVerticalScrollIndicator={false}>
                    <View style={{ justifyContent: 'center', flexDirection: 'row', flexWrap: 'wrap', alignContent: 'center' }}>
                        {
                            status == 'waiting'
                                ? <EmptyMessage icon="magnify" message="Prueba a buscar marcas de alimento para animales, tipos de alimentos o preparaciones"></EmptyMessage>
                                : status == 'searching'
                                    ? <Loading text="Buscando en sappi..."></Loading>
                                    : status == 'empty'
                                        ? <ErrorMessage
                                            message="¡Ups! No se encontraron productos con estos filtros"
                                        >
                                        </ErrorMessage>
                                        : status == 'done'
                                            ?
                                            <SafeAreaView style={{ flex: 1, alignItems: 'center', alignSelf: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                                <FlatList
                                                    data={searchResults.sort((a: any, b: any) => { return b.props.sappiScore - a.props.sappiScore; })}
                                                    renderItem={renderItem}
                                                    keyExtractor={(item) => item.props._id}
                                                    extraData={selectedId}
                                                    contentContainerStyle={{ justifyContent: 'center', alignContent: 'center', alignSelf: Platform.OS == 'web' ? 'center' : undefined, marginTop: 20, paddingBottom: Platform.OS == 'ios' ? 70 : 10 }}
                                                    columnWrapperStyle={{ flex: 1, alignSelf: Platform.OS != 'web' ? 'center' : undefined,flexWrap: 'wrap',alignItems:"center" }}
                                                    numColumns={2}
                                                    onEndReached={() => { Platform.OS == 'web' ? null : onLoadMore() }}
                                                    onEndReachedThreshold={4}
                                                    horizontal={false}
                                                    style={{ width: '100%' }}
                                                    ListFooterComponent={() => footerComponent()}
                                                />
                                            </SafeAreaView>
                                            : null
                        }

                    </View>
                </ScrollView>
            </View>
        </View>
    )
};


export default AdminSearch;

import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import AppHeader from './AppHeader';
import Conf from 'internalapp/conf';
import Screens from '../core/Screens';
import { useAppStore } from '../context/appStore';
import { useHeaderStore } from '../context/headerContext';

const StackNavigator = () => {
    const {session} = useAppStore();
    const [headerBorder, setHeaderBorder] = React.useState(0);
    const [headerStyle, setHeaderStyle] = React.useState('default');
    const Stack = createStackNavigator();     
    const { getHeader } = useHeaderStore();  

    const getInitialRoute = (ready: boolean, loggedin: boolean) => {
        if (!ready) {
            return null;
        }

        var initialRoute = Screens.getFallbackScreen(session.userInfo.type);
        if(!initialRoute) {
            initialRoute = Screens.getFallbackScreen();
        }

        var route = initialRoute ? initialRoute.name : null;
        return route;
    }

    const getScreenDefinitions = (screens:any) => {
        var computedScreens:any = [];
        screens.forEach((screen:any) => {
            computedScreens = computedScreens.concat(
                <Stack.Screen key={screen.name} name={screen.name}>
                    {(props) => {
                        const CurrentScreen = screen.component;
                        return <CurrentScreen {...props} setHeaderStyle={setHeaderStyle} setHeaderBorder={setHeaderBorder} />
                    }}
                </Stack.Screen>
            )
        })
        return computedScreens;
    }
    const isLoggedIn = () => {
        return !session.ready || (session.ready && session.loggedIn)
            ? true
            : false;
    }

    const getMenuBar = (currentScreenName, previous, navigation, options) => {
        const pageTitle =  Conf.appTitle;
        const title = options.title !== undefined ? options.title : pageTitle;
        const currentScreen = Screens.getScreen(currentScreenName);
        if(!currentScreen || (!isLoggedIn() && !currentScreen.header)) {
            return null; //no header if screen is unknown or user hasn't logged in and page don't force a header
        }
   
        return (
            <AppHeader headerStyle={getHeader(currentScreenName)} headerBorder={headerBorder} previous={previous} navigation={navigation} title={title}></AppHeader>
        );
    }

    var stackScreens = Screens.screens.filter((screen) => !screen.requireAuthLevel);
    stackScreens = stackScreens.concat(Screens.screens.filter((screen) => screen.requireAuthLevel && screen.requireAuthLevel.find((lvl)=>lvl==session.userInfo.type)))
    
    //put fallback screens first, to prevent incorrect behavior when initialRoute fails for unknown reasons
    //this fixes a bug where 'about' screen was returned instead to 'login' when closing session.
    stackScreens = stackScreens.sort((screen) => screen.fallback?-1:1);

    return (
            <Stack.Navigator
                initialRouteName={getInitialRoute(session.ready, session.loggedIn)}
                options={{headerMode: Conf.appHeader?"screen":"none"}}
                screenOptions={{
                    header: ({ route, previous, options, navigation }) => {
                        //const { options } = route.descriptor;
                    
                        const currentScreen = route.name;
                        return getMenuBar(currentScreen, previous, navigation, options);
                    },
                }}
            >
            { getScreenDefinitions(stackScreens )}
        </Stack.Navigator>
    );
};

export default React.memo(StackNavigator);
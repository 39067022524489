import create from "zustand";
import produce from "immer";
import SappiScoreModel from "../models/sappiScore/sappiScoreModel";
import AdminApi from "../api/AdminApi";

type SappiScoreStoreData = {
    sappiScore: SappiScoreModel | null,
    sappiScoreChanging: SappiScoreModel | null,
    setSappiScoreChanging: Function,
    setSappiScore: Function,
    readSappiScore: Function,
    updateSappiScore: Function,
    restoreSappiScore: Function
}

export const useSappiScoreStore = create<SappiScoreStoreData>((set) => ({
    sappiScore: null,
    sappiScoreChanging: null,
    setSappiScoreChanging: (payload: SappiScoreModel) => set(produce((draft: SappiScoreStoreData) => {
        draft.sappiScoreChanging = payload;
    })),
    setSappiScore: (payload: SappiScoreModel) => set(produce((draft: SappiScoreStoreData) => {
        draft.sappiScore = payload;
    })),
    readSappiScore: async (currentUser: any) => {
        const adminApi = new AdminApi();
        try {
            const res = await adminApi.getSappiScoreConfig(currentUser);
            set(produce((draft: SappiScoreStoreData) => {
                draft.sappiScore = res;
                draft.sappiScoreChanging = res;
            }))
        } catch (e) {
            console.error("Error: Can't read sappiScore. ", e);
        }
    },
    updateSappiScore: async (currentUser: any, sappiScore: any) => {
        const adminApi = new AdminApi();
        try {
            const res = await adminApi.postSappiScoreConfig(currentUser, sappiScore);
            set(produce((draft: SappiScoreStoreData) => {
                draft.sappiScore = res;
            }))
        } catch (e) {
            console.error("Error: Can't update sappiScore. ", e);
        }
    },
    restoreSappiScore: async (currentUser: any) => {
        const adminApi = new AdminApi();
        try {
            const res = await adminApi.restoreSappiScoreConfig(currentUser);
            set(produce((draft: SappiScoreStoreData) => {
                draft.sappiScore = res;
            }))
        } catch (e) {
            console.error("Error: Can't restore sappiScore. ", e);
        }
    }
}));
import React from "react";
import {useRegisterStore} from 'baseapp/plugins/auth/stores/RegisterStore';

export default () => {
    const {setField} = useRegisterStore();
    React.useEffect(() => {
        setField('favorites', []);
        setField('history', []);
    }, []);
    
    return <></>
}
import React, { memo } from 'react';
import { useAppStore } from '../context/appStore';
import Settings from '../core/settings';
import mqtt from '@taoqf/react-native-mqtt';

const MqttConector = () => {
	const [client, setClient] = React.useState<any>();
    const {session, messageReceived, busChannels} = useAppStore();

    React.useEffect(() => {
        const client = mqtt.connect(Settings.getMqttURL());
		client.on('connect', function () {
			client.subscribe('public', function (err) {
				console.log(err);
			});
		});
		client.on('message', function (topic, message) {
			// message is Buffer
			//console.log(topic, message.toString())
			messageReceived({topic: topic, ts: Date.now(), message: JSON.parse(message.toString())});
		});
		setClient(client);
		return () => {
			client.end();
		}
	}, []);

	React.useEffect(() => {
		busChannels.forEach((channel) => {
			console.log('Subscribing to: ', channel);
			client.subscribe(channel, function (err) {console.log(err)});
		})
	}, [busChannels])

    React.useEffect(() => {
		if(!client || !session || !session.loggedIn) return;
        client.on('connect', function () {
			client.subscribe('users/'+session.userInfo.email, function (err) {});
		});
    }, [session, client]);

    return null;
};



export default memo(MqttConector);
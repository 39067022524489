import React, { memo } from 'react';
import { theme } from '../../theme';
import { IconButton, Checkbox } from "react-native-paper";
import { Text, Platform, View } from "react-native";

const AppIntro6 = (props) => {
    const [checked, setChecked] = React.useState(false);
    const onCheckButton = () => {
        setChecked(!checked);
        props.onCheck(checked);
    }
    return (
        <View style={{ justifyContent: 'center', flex: 1, backgroundColor: theme.colors.background }}>
            <View style={{ backgroundColor: theme.colors.background, width: '90%', maxWidth: 400, height: 500, alignSelf: 'center', borderRadius: 10, padding: 20 }}>
                <Text style={{ fontSize: 25, fontWeight: '700', textAlign: 'center', fontFamily: "Quicksand-Bold", marginTop: 30 }}>Valoración</Text>
                <View style={{ flexDirection: 'row', alignContent: 'center', justifyContent: 'center', marginVertical: 10 }}>
                    <IconButton style={{ backgroundColor: theme.colors.background, alignSelf: 'center', borderRadius: 200, justifyContent: 'center', alignItems: 'center' }}
                        icon="star"
                        size={30}
                        color={theme.colors.rateColor}
                    >
                    </IconButton>
                    <IconButton style={{ backgroundColor: theme.colors.background, alignSelf: 'center', borderRadius: 200, justifyContent: 'center', alignItems: 'center' }}
                        icon="star"
                        size={30}
                        color={theme.colors.rateColor}
                    >
                    </IconButton>
                    <IconButton style={{ backgroundColor: theme.colors.background, alignSelf: 'center', borderRadius: 200, justifyContent: 'center', alignItems: 'center' }}
                        icon="star"
                        size={30}
                        color={theme.colors.rateColor}
                    >
                    </IconButton>
                    <IconButton style={{ backgroundColor: theme.colors.background, alignSelf: 'center', borderRadius: 200, justifyContent: 'center', alignItems: 'center' }}
                        icon="star"
                        size={30}
                        color={theme.colors.rateColor}
                    >
                    </IconButton>
                    <IconButton style={{ backgroundColor: theme.colors.background, alignSelf: 'center', borderRadius: 200, justifyContent: 'center', alignItems: 'center' }}
                        icon="star"
                        size={30}
                        color={theme.colors.rateColor}
                    >
                    </IconButton>
                </View>
                <Text style={{ fontSize: 25, fontWeight: '700', textAlign: 'center', fontFamily: "Quicksand-Light", marginTop: 0 }}>Tu opinión cuenta. Estamos encantados de que nos dejes un comentario para seguir mejorando tu experiencia</Text>
                <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 100 }}>
                    <View style={{ borderColor: Platform.OS == 'ios' ? '#BFBFBF' : null, borderWidth: Platform.OS == 'ios' ? 1 : null, borderRadius: 10, marginRight: 5 }}>
                        <Checkbox color={theme.colors.primary} status={checked ? 'checked' : 'unchecked'} onPress={() => { onCheckButton() }} />
                    </View>
                    <Text style={{ alignSelf: 'center', fontFamily: "Quicksand-Bold", fontWeight: '700', fontSize: 16 }}>No volver a mostrar</Text>
                </View>
            </View>
        </View>
    )
}
export default memo(AppIntro6);

import React, { memo, useContext } from 'react';
import { Linking, StyleSheet, View } from 'react-native';
import { Title, Drawer, Divider, Text, Paragraph, Caption } from 'react-native-paper';
import { DrawerItem } from '@react-navigation/drawer';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useAppStore } from 'baseapp/context/appStore';
import { theme } from './theme';
import { useNavigation } from '@react-navigation/native';
import { useAnalyticsStore } from './context/analytics';
import conf from './conf';

type Props = {
    dispatch: Function;
};

const Menu = () => {
    const navigation = useNavigation();
    const { profile, session, logout } = useAppStore();
    const { AnalyticsLogEvent } = useAnalyticsStore();
    return (
        <View style={styles.drawerContent}>
            <View style={styles.userInfoSection}>
                {session.loggedIn ?
                    <View style={styles.userInfoSection}>
                        <Title style={styles.title}>{session.userInfo.email}</Title>
                    </View>
                    : 
                    <View style={styles.userInfoSection}>
                        <Title style={styles.title}>Invitado</Title>
                    </View>}
                {session.loggedIn ?
                    <View style={styles.row}>
                        <View style={styles.section}>
                            <Paragraph style={[styles.paragraph, styles.caption]}>
                                {profile?.get('history').length}
                            </Paragraph>
                            <Caption style={styles.caption} onPress={() => navigation.navigate('home', { p: 'history' })}> Vistos</Caption>
                        </View>
                        <View style={styles.section}>
                            <Paragraph style={[styles.paragraph, styles.caption]}>
                                {profile?.get('favorites').length}
                            </Paragraph>
                            <Caption style={styles.caption} onPress={() => navigation.navigate('home', { p: 'favorites' })}> Favoritos</Caption>
                        </View>
                    </View>
                    : null
                }
            </View>
            <Divider style={{ width: '85%', alignSelf: 'center', marginTop: 30, marginBottom: 10 }} />
            <DrawerItem
                icon={(data: any) => (
                    <MaterialCommunityIcons name="web" color={data.color} size={data.size} />
                )}
                label="Web"
                onPress={() => { Linking.openURL('https://sappi.app') }}
            />
            <DrawerItem
                icon={(data: any) => (
                    <MaterialCommunityIcons name="instagram" color={data.color} size={data.size} />
                )}
                label="Instagram"
                onPress={() => { Linking.openURL('https://www.instagram.com/sappiapp/') }}
            />
            <DrawerItem
                icon={(data: any) => (
                    <MaterialCommunityIcons name="email-outline" color={data.color} size={data.size} />
                )}
                label="Email"
                onPress={() => { Linking.openURL('https://sappi.app/contacto/') }}
            />
            <DrawerItem
                icon={(data: any) => (
                    <MaterialCommunityIcons name="whatsapp" color={data.color} size={data.size} />
                )}
                label="Whatsapp"
                onPress={() => { Linking.openURL('https://api.whatsapp.com/send/?phone=34687162880&text=Hola%2C%0D%0AMi+nombre+es%3A%0D%0AOs+escribo+porque+quiero+saber%3A&app_absent=0') }}
            />
            <DrawerItem
                icon={(data: any) => (
                    <MaterialCommunityIcons name="facebook" color={data.color} size={data.size} />
                )}
                label="Facebook"
                onPress={() => { Linking.openURL('https://www.facebook.com/sappiapp/') }}
            />
            <DrawerItem
                icon={(data: any) => (
                    <MaterialCommunityIcons name="book-outline" color={data.color} size={data.size} />
                )}
                label="News Feed"
                onPress={() => navigation.navigate('home', { p: 'articles' })}
            />
            <Divider style={{ width: '85%', alignSelf: 'center', marginVertical: 10 }} />
            <DrawerItem
                label="¿Quieres ser sappier?"
                labelStyle={{ color: theme.colors.primary }}
                onPress={() => {
                    AnalyticsLogEvent("Become a Sappier", { "user": session.userInfo.email ? session.userInfo.email : "guest" });
                    Linking.openURL('https://sappi.app/become-a-sappier/')
                }}
            />
            <DrawerItem
                label="Sappi School"
                onPress={() => {
                    AnalyticsLogEvent("Sappi School", { "user": session.userInfo.email ? session.userInfo.email : "guest" });
                    Linking.openURL('https://sappi.app/formacion-alimentacion-animal/')
                }}
            />
            <Divider style={{ width: '85%', alignSelf: 'center', marginVertical: 10 }} />

            {
                session.loggedIn ?
                    <DrawerItem
                        label="Cerrar sesión"
                        labelStyle={{ color: theme.colors.text, fontWeight: '500', marginTop: 50 }}
                        onPress={async () => {
                            logout();
                        }}
                    />
                    :
                    <DrawerItem
                        label="Iniciar sesión"
                        labelStyle={{ color: theme.colors.text, fontWeight: '500', marginTop: 50 }}
                        onPress={() => {
                            navigation.navigate('login');
                        }}
                    />
            }
            <Drawer.Section style={{ flexDirection: 'column', flex: 1, justifyContent: 'flex-end', alignSelf: 'flex-end' }}>
                <Text style={[styles.paragraph, { paddingRight: 20, paddingBottom: 10, opacity: 0.3 }]}>{conf.version}</Text>
            </Drawer.Section>
        </View>
    );
};

const styles = StyleSheet.create({
    drawerContent: {
        flex: 1,
    },
    userInfoSection: {
        paddingLeft: 20,
    },
    title: {
        marginTop: 20,
        fontWeight: 'bold',
    },
    drawerSection: {
        marginTop: 15,
    },
    caption: {
        fontSize: 14,
        lineHeight: 14,
    },
    row: {
        marginTop: 20,
        flexDirection: 'row',
        alignItems: 'center',
    },
    section: {
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 15,
    },
    paragraph: {
        color: theme.colors.colorParagraphItem,
        fontWeight: '500',
        fontSize: 14,
        lineHeight: 18,
    },
    preference: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingVertical: 12,
        paddingHorizontal: 16,
    }
});

export default memo(Menu);
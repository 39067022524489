import React, { memo } from "react";
import { View } from "react-native";
import { ProductModel } from 'systemDomain';
import { Divider } from 'react-native-paper';
import MenuButtons from "./MenuButtons";
import FavoriteMenuButton from "./FavoriteMenuButton";

type Props ={
    sheetREF: any;
    product: ProductModel;
    navigation: any;
    onSharePressed: Function;
    onReportPressed: Function;

}

const BottomProductSheetContent = ({onReportPressed, product, navigation, onSharePressed, sheetREF}:Props) => {
    const _onSharePressed = () => { 
        onSharePressed()
        sheetREF.current.close()
    }
    
    const _onReportPressed = () => { 
        onReportPressed()
        sheetREF.current.close()
    }

    return (
        <View style={{ flex: 1, padding: 40 }}>
            <View style={{alignItems: 'flex-start'}}>
                <FavoriteMenuButton navigation={navigation} product={product} ></FavoriteMenuButton>
            </View>
            <Divider style={{ marginBottom: 20 }} />
            <View style={{alignItems: 'flex-start'}}>
                <MenuButtons icon={'message-alert-outline'}  iconText={'Reportar un problema'}  onPress={_onReportPressed} />
            </View>
            <Divider style={{ marginBottom: 20 }} />
            <View style={{alignItems: 'flex-start'}}>
                <MenuButtons icon={'export-variant'}  iconText={'Compartir producto'} onPress={_onSharePressed}/>
            </View>
        </View>
    );
}

export default memo(BottomProductSheetContent);
import React from 'react';
import { StyleSheet, View, Text} from 'react-native';
import {theme} from '../theme';
import TextOption from './TextOption';

type Props = {
    titleOptions: string;
    setSelectedMenu: Function;
    selectedMenu: string;
};

const AdminOptions = ({titleOptions, setSelectedMenu, selectedMenu}:Props) => {

    return(
        <View style={{flex: 0.5, backgroundColor:theme.colors.background, padding: 25}}>
                <Text style={styles.title}>{titleOptions}</Text>
                <TextOption selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu} optionText={'Productos'} optionPressed={'products'}/>
                <TextOption selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu} optionText={'Usuarios'} optionPressed={'users'}/>
                <TextOption selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu} optionText={'Fórmula'} optionPressed={'formula'}/>
                <TextOption selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu} optionText={'Push Notifications'} optionPressed={'push'}/>
                <TextOption selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu} optionText={'Artículos'} optionPressed={'articles'}/>
        </View>
    )
};

const styles = StyleSheet.create({
    title: {
        color: theme.colors.text,
        fontSize: 20,
        fontFamily: theme.fonts.regular.fontFamily,
    },
    textOption: {
        fontSize: 14,
        fontFamily: theme.fonts.regular.fontFamily
    },
    viewOption: {
        marginVertical: 10
    },
    paragraph: {
        color: theme.colors.colorParagraphItem,
        fontWeight:'500',
        fontSize:14,
        lineHeight:18,     
    },
});

export default AdminOptions;

//------------  state definition
export interface PageState {
    currentTab: string
}

//------------ actions
export const UPDATE_CURRENT_TAB = 'UPDATE_CURRENT_TAB'

interface UpdateCurrentTabAction {
  type: typeof UPDATE_CURRENT_TAB
  payload: string
}

export function updateCurrentTab(newTab: string): PageActionTypes {
    return {
      type: UPDATE_CURRENT_TAB,
      payload: newTab
    }
}

export type PageActionTypes = UpdateCurrentTabAction

//------------ reducer
const initialState: PageState = {
    currentTab: 'history'
}

export function pageReducer( state = initialState, action: PageActionTypes): PageState {
  switch (action.type) {
    case UPDATE_CURRENT_TAB: {
      return {
        ...state,
        currentTab: action.payload
      }
    }
    default:
      return state
  }
}
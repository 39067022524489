import React, { memo } from "react";
import { View } from 'react-native';
import Background from "../Background";
import { Snackbar } from "react-native-paper";
import { ProductModel, ProductCollection } from "systemDomain";
import ProductApi from "../api/Product.api";
import ApiCaller from "baseapp/core/ApiCaller";
import Loading from "../components/Loading";
import ErrorMessage from "../components/ErrorMessage";
import { theme } from "../theme";
import { useFocusEffect, useNavigation, useRoute } from "@react-navigation/native";
import { Clipboard } from 'react-native';
import ProductContentHeader from '../components/ProductContentHeader';
import ProductContent from '../components/ProductContent';
import NotFoundScan from "../components/NotFoundScan";
import { useProductStore } from '../context/productContext';
import { useHeaderStore } from "baseapp/context/headerContext";
import ReportProblemDialog from "../components/ReportProblemDialog";
import TakePhotoComponent from "../components/TakePhotoComponent";
import { useAppStore } from 'baseapp/context/appStore';
import ProfileApi from '../api/ProfileApi';
import { useAnalyticsStore } from '../context/analytics';
import DialogBuyOptions from "../components/DialogBuyOptions";

const ItemScreen = () => {
    const navigation = useNavigation();
    const route = useRoute();
    const { setHeader, setDialogReport, dialogReport } = useHeaderStore();

    const id = route.params?.id;
    const barcode = route.params?.barcode;
    const [product, setProduct] = React.useState<ProductModel>();
    const [isError, setIsError] = React.useState(false);
    const [onShopSectionPress, setOnShopSectionPress] = React.useState<boolean>(false);
    const { session, setProfile } = useAppStore();
    const currentUser = session.userInfo;
    const [isLoading, setLoading] = React.useState(true);
    const productApi = new ProductApi(new ApiCaller(currentUser.token));
    const [suggested, setSuggested] = React.useState<any>(new ProductCollection([]));
    const [visibleSnackbar, setVisibleSnackbar] = React.useState<boolean>(false);
    const { setProductContext } = useProductStore();
    const [firstFocus, setFirstFocus] = React.useState(true);
    const [visibleScan, setVisibleScan] = React.useState(false);
    const hideDialogScan = () => { setVisibleScan(false); navigation.navigate('home') };
    const hideDialogReport = () => { setDialogReport(false); setPhotoBack(""); setPhotoFront("") }

    const [visibleCamera, setVisibleCamera] = React.useState(false);
    const hideDialogCamera = () => { setVisibleCamera(false) }
    const [photoBack, setPhotoBack] = React.useState("");
    const [photoFront, setPhotoFront] = React.useState("");
    const [isBack, setIsBack] = React.useState(true);
    var productItem: ProductModel;

    const readId = async (id: string, skipSet: boolean) => {
        try {
            productItem = await productApi.read(id);
            if (!skipSet) {
                setLoading(false);
                setProduct(productItem);
                setProductContext(productItem);
            }
        } catch (e) {
            if (!skipSet) {
                setLoading(false);
                setIsError(true);
                setVisibleScan(true);
            }
        }

        try {
            //add to local profile through redux...
            //@ts-ignore
            setProfile(await ProfileApi.read(session.userInfo, "me"));
        } catch (e) {
            console.log(e)
        }
    }

    const readBarcode = async (barcode: string) => {
        try {
            const productItem = await productApi.readBarcode(barcode);
            console.log('Product Item is: ', productItem);
            readId(productItem.getId(), true);
            setLoading(false);
            setIsError(false);
            setProduct(productItem);
        } catch (e) {
            setLoading(false);
            setIsError(true);
            setVisibleScan(true);
        }
    }
    const { AnalyticsLogEvent } = useAnalyticsStore();

    const reFresh = async () => {
        console.log("DIALOG - REPORT: ", dialogReport);
        if (!currentUser.token) return;

        try {
            const items = await productApi.suggested(id);
            setSuggested(items);
        } catch (e) {

        }

        if (barcode) readBarcode(barcode);
        else if (id) readId(id, false);

    }

    const onGoToBuy = () => {
        setOnShopSectionPress(true)
    }

    const onResetGoToBuy = () => setOnShopSectionPress(false);
    
    React.useEffect(() => {
        if (currentUser.token == undefined) {
            navigation.navigate('login');
        }
        //@ts-ignore
        return navigation.addListener('focus', () => {
            if (firstFocus) {
                setFirstFocus(false);
            } else {
                reFresh();
            }
        });

    }, []);

    React.useEffect(() => {
        reFresh();
    }, [currentUser, id]);

    useFocusEffect(
        React.useCallback(() => {
            setHeader('item', 'item');
        }, [route])
    )

    React.useEffect(() => {
        if (product) {
            AnalyticsLogEvent("product",
                {
                    "_id": product?.getId(),
                    "name": product?.getName(),
                    "company": product?.getCompany(),
                    "production_process": product?.getProduction_process(),
                    "animal": product?.getAnimal(),
                    "barcodes": product?.getBarcodes(),
                    "sappiScore": product?.getSappiScore()
                }
            );
        }
    }, [product]);

    return (
        <Background>
            <ReportProblemDialog hideDialogReport={hideDialogReport} visibleDialogReport={dialogReport} photoFront={photoFront} photoBack={photoBack} openCamera={(isBack: boolean) => { setVisibleCamera(true); setIsBack(isBack); }}></ReportProblemDialog>
            
            <Snackbar
                visible={visibleSnackbar}
                onDismiss={() => { setVisibleSnackbar(false) }}
                duration={1000}
                style={{ backgroundColor: '#494A4C', width: '100%', alignSelf: 'center' }}
                wrapperStyle={{ position: 'absolute', bottom: 0, zIndex: 1, maxWidth: 600 }}
            >
                Enlace copiado en el portapapeles
            </Snackbar>
            {isLoading ? <Loading text="Cargando producto..."></Loading> :
                isError ? <ErrorMessage submessage={"Código: " + (id ? id : barcode)} message={
                    <View style={{ flexDirection: "column", alignItems: "center", maxWidth: 400, marginHorizontal: 15 }}>
                        <NotFoundScan barCode={barcode} visibleScan={visibleScan} hideDialogScan={hideDialogScan} photoFront={photoFront} photoBack={photoBack} openCamera={(isBack: boolean) => { setVisibleCamera(true); setIsBack(isBack); }}></NotFoundScan>
                        <TakePhotoComponent onFail={() => { console.log('ONFAIL'); setVisibleCamera(false) }} onSuccess={(photo: string) => { console.log('SUCCESS'); setVisibleCamera(false); setVisibleScan(true); if (isBack) { setPhotoBack(photo) } else { setPhotoFront(photo) } }} hideCamera={() => hideDialogCamera()} visibleCamera={visibleCamera} />
                    </View>

                } /> : (product)
                    ?
                    <View style={{ flex: 1, paddingTop: 30, maxWidth: 600, width: '100%', flexDirection: 'column', backgroundColor: theme.colors.background }}>
                        {/* Content */}
                        <View style={{ flex: 2 }}>
                            <ProductContentHeader product={product} onGoToBuy={onGoToBuy} />
                        </View>
                        {/* View rounded */}
                        <View style={{ flex: 6, marginTop: 10 }}>
                            <ProductContent suggested={suggested} product={product} onResetGoToBuy={onResetGoToBuy} setProduct={setProduct} id={id} onGoToBuyPressed={onShopSectionPress} />
                        </View>
                        {
                            visibleCamera ?
                                <TakePhotoComponent onFail={() => { setVisibleCamera(false) }} onSuccess={(photo: string) => { setVisibleCamera(false); setDialogReport(true); if (isBack) { setPhotoBack(photo) } else { setPhotoFront(photo) } }} hideCamera={() => hideDialogCamera()} visibleCamera={visibleCamera} />
                                : null
                        }
                    </View>
                    : null
            }
        </Background>
    )
}

export default memo(ItemScreen);
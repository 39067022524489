import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { Platform, View, TouchableWithoutFeedback, StyleSheet, ScrollView, Linking } from 'react-native';
import { Portal, Dialog, Text, Chip, Divider } from 'react-native-paper';
import { ProductModel, ProductCollection } from 'systemDomain';
import { theme } from '../theme';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import NutBar from '../components/NutBar';
import Procesado from '../components/Procesado';
import MateriaPrima from '../components/MateriaPrima';
import { getColor, getSappiRating, getProcesRating, getProductionProcessColor } from "../utils";
import ProductCard from '../components/ProductCard';
import CommentCard from '../components/CommentCard';
import CommentForm from '../components/CommentForm';
import CommentWebForm from '../components/CommentWebForm';
import NoBrandsPayDialog from './NoBrandsPayDialog';
import { useAppStore } from 'baseapp/context/appStore';
import ShopProductCard from './ShopProductCard'
import { Card } from 'react-native-paper';
import Settings from 'baseapp/core/settings';

type Props = {
  product: ProductModel;
  setProduct: Function;
  id: any;
  suggested: ProductCollection;
  onGoToBuyPressed: boolean;
  onResetGoToBuy: Function;
}

const textsProcesados = {
  "extrusionado": "La extrusión es el proceso mediante el cual los alimentos son cocinados de una forma rápida, continua y homogénea con el objetivo de obtener un producto alimentario con diferentes tipos de formas, texturas y, en algunos casos, mejorando su digestibilidad. Pasan por un preacondicionador de vapor que hidrata la masa y simultáneamente se calienta a 120ºC. Una vez secadas, las croquetas son rociadas con grasas saborizantes, vitaminas y minerales que se han ido perdiendo durante la extrusión y que tienen que ser reemplazados con sustancias químicas.",
  "natural": "El alimento natural no pasa por ningun tratamiento adicional, aveces es congelado para mantener sus propiedades.",
  "cocinado": "El alimento cocinado pasa por un procesado que aplica un tratamiento térmico a bajas temperaturas que destruye microorganismos que puedan encontrarse en la carne cruda y conserva la concentración de nutrientes y vitaminas, la palatabilidad y digestibilidad del alimento.\nLa carne cruda sin procesar mantiene intactas sus enzimas digestivas, contiene vitaminas y antioxidantes y conserva su alto valor biológico y la biodisponibilidad del alimento. Muchas veces se enumera el riesgo bacteriológico que puede comportar, pero por eso se somete a la congelación, eliminando así el máximo de bacterias posibles.",
  "undefined": "Sappi aún no dispone suficiente información para classificar éste producto.",
  "prensado": "Este pienso se hace a bajas temperaturas, lo cual permite que se conserven mejor los nutrientes de los alimentos y también su sabor. Como preserva las cualidades de los alimentos en gran manera, no es necesario añadir vitaminas u otros nutrientes de manera artificial.\nLos aditivos que suelen utilizar son naturales y de más fácil asimilación.",
  "humedo": "Las materias primas pasan a una mezcladora que tiene la labor de cocinar y combinar los distintos ingredientes. Según los materiales se van mezclando, la temperatura de cocción sube hasta los 89º para que los ingredientes almidonados se vayan gelificando y se desnaturalicen las proteínas. Aquellos alimentos que no llevan almidones no llegan a ser cocinados a temperaturas tan altas. Una vez las latas están selladas, el último paso es su esterilización, proceso que se lleva cabo a 121º durante 3 minutos y que sirve para evitar cualquier crecimiento bacteriano.",
  "snack": "Sometidos a distintos tipos de procesado; extrusionado, deshidratado... También se le deberá dar la máxima importancia a la calidad de la materia prima.",
  "deshidratado": "Se elimina totalmente el agua de los alimentos para evitar su contaminación bacteriana y también conserva mejor la mayoría de los nutrientes."
};

const ProductContent = ({ product, setProduct, id, suggested, onGoToBuyPressed, onResetGoToBuy }: Props) => {
  const { session } = useAppStore();
  const currentUser = session.userInfo;
  const scrollRef = React.useRef();
  const navigation = useNavigation();
  const [selectedMenu, setSelectedMenu] = React.useState<string>('infSappi');
  const [procesadoVisible, setProcesadoVisible] = React.useState<boolean>(false);
  const [procesadoText, setProcesadoText] = React.useState<string>();
  const [materiaPrimaVisible, setMateriaPrimaVisible] = React.useState<boolean>(false);
  const [visible, setVisible] = React.useState<boolean>(false);
  const [description, setDescription] = React.useState<string>('');
  const [title, setTitle] = React.useState<string>('');
  const [rate, setRate] = React.useState<number>(0);
  const [deleteRefresh, setDeleteRefresh] = React.useState<boolean>(false)
  const [visibleDialogSugestion, setVisibleDialogSugestion] = React.useState<boolean>(false);
  const [dataSourceCord, setDataSourceCord] = React.useState<number>(0);
  product.props['shopLink'] = product.props['shopLink']?((typeof product.props['shopLink'] != "object" )?[product.props['shopLink']]:product.props['shopLink']):null
  const shopLinks = product.props['shopLink'] ? product?.get('shopLink').filter(link => link != "") : undefined;
  const haveShop = (product.props['shopData'] || shopLinks) ? true : false
  console.log("havShop", haveShop)


  const onDialogSugestionPress = () => { setVisibleDialogSugestion(true); }
  const hideDialogSugestion = () => { setVisibleDialogSugestion(false) }

  const onPressMenu = (option: string) => {
    scrollRef.current?.scrollTo({
      y: 0,
      animated: true,
    });
    setSelectedMenu(option);
  };

  const onShopSectionPress = () => {
    scrollRef.current?.scrollTo({
      y: dataSourceCord + 450,
      animated: true,
    });
    onResetGoToBuy()
  }

  const showDialog = () => {
    setVisible(true);
  }

  const hideDialog = () => {
    setVisible(false);
    setProcesadoVisible(false);
    setMateriaPrimaVisible(false);

  }

  const onMateriaPrimaPress = (rate: any) => {
    setMateriaPrimaVisible(true)
    showDialog();
  }

  const onProcesadoPress = (rate: string) => {
    setProcesadoText(textsProcesados[rate]);
    setProcesadoVisible(true)
    showDialog();
  }

  function capitalizeFirstLetter(stri: string) {
    const lowerCaseStri = stri.toLowerCase();
    const firstCapital = lowerCaseStri[0].toUpperCase();
    return firstCapital + lowerCaseStri.slice(1);
  }


  React.useEffect(() => {
    if (onGoToBuyPressed) onShopSectionPress()
  }, [onGoToBuyPressed])

  return (
    <View style={{ flex: 1 }}>
      <View style={{ flex: 1, borderTopLeftRadius: 25, borderTopRightRadius: 25, shadowRadius: 8, shadowOpacity: 0.2, flexDirection: 'column', elevation: 5, backgroundColor: theme.colors.background }}>
        {/* Menu */}
        <View style={{ flex: 1, alignContent: 'center', justifyContent: 'space-between', paddingTop: 20, flexDirection: 'column' }}>
          <View style={{ width: '90%', alignSelf: 'center', justifyContent: 'space-between', flexDirection: 'row' }}>
            <TouchableWithoutFeedback style={{ flexDirection: 'row' }} onPress={() => onPressMenu('infSappi')}>
              <View style={{ borderBottomColor: 'rgba(236, 18, 119, 1)', borderBottomWidth: selectedMenu == 'infSappi' ? 3 : 0 }}>
                <Text style={{ color: selectedMenu == 'infSappi' ? 'black' : 'rgba(191, 191, 191, 1)' }}>Inf. Sappi</Text>
              </View>
            </TouchableWithoutFeedback>
            <TouchableWithoutFeedback style={{ flexDirection: 'row' }} onPress={() => onPressMenu('infNutricional')}>
              <View style={{ borderBottomColor: 'rgba(236, 18, 119, 1)', borderBottomWidth: selectedMenu == 'infNutricional' ? 3 : 0 }}>
                <Text style={{ color: selectedMenu == 'infNutricional' ? 'black' : 'rgba(191, 191, 191, 1)' }}>Inf. Nutricional</Text>
              </View>
            </TouchableWithoutFeedback>
            <TouchableWithoutFeedback style={{ flexDirection: 'row' }} onPress={() => onPressMenu('Aditivos')}>
              <View style={{ borderBottomColor: 'rgba(236, 18, 119, 1)', borderBottomWidth: selectedMenu == 'Aditivos' ? 3 : 0 }}>
                <Text style={{ color: selectedMenu == 'Aditivos' ? 'black' : 'rgba(191, 191, 191, 1)' }}>Aditivos</Text>
              </View>
            </TouchableWithoutFeedback>
          </View>
        </View>
        {/*Scrollable content*/}
        <View style={{ flex: 12, flexDirection: 'column' }}>
          <View style={{ flex: 1, width: '90%', alignSelf: 'center' }}>
            <ScrollView ref={scrollRef} style={{ height: 0 }} contentContainerStyle={{ flexDirection: "column" }} scrollEventThrottle={1} showsVerticalScrollIndicator={false}>
              <View style={{ flex: 3 }}>
                {/*INFO SAPPI*/}
                {
                  selectedMenu == 'infSappi' &&
                  // Main view
                  <View style={{ flexDirection: 'column' }}>
                    <View style={{ flexDirection: 'column', flex: 1, marginBottom: 10 }}>
                      <Text style={[{ textAlign: 'right' }, styles.sublabelName]}> valor medio por 100 g</Text>
                    </View>
                    {/* Nutr. name */}
                    <View style={{ flexDirection: 'column' }}>
                      <NutBar product={product} nutr='protein' title='Proteínas' isGood={true} />
                      <NutBar product={product} nutr='carbs' title='Carbohidratos' isGood={false} />
                      <NutBar product={product} nutr='fat' title='Grasas' isGood={false} />
                      <NutBar product={product} nutr='ash' title='Ceniza' isGood={false} />
                    </View>
                    <View>
                      {/* Procesado */}
                      <Procesado product={product} onProcesadoPress={onProcesadoPress} getProcesRating={getProcesRating} getProductionProcessColor={getProductionProcessColor} />
                      {/* Calidad Materia prima */}
                      <MateriaPrima product={product} getColor={getColor} onMateriaPrimaPress={onMateriaPrimaPress} getSappiRating={getSappiRating} />
                    </View>
                  </View>
                }
                {
                  selectedMenu == 'infNutricional' &&
                  <View style={{ flexDirection: 'column', flex: 1 }}>
                    <View style={{ flexDirection: 'column', flex: 1 }}>
                      <Text style={[styles.paragraph, { fontWeight: '700', color: 'black' }]}>Composición: <Text style={styles.paragraph}>{product.getIngredients()}</Text></Text>
                    </View>
                  </View>
                }
                {
                  selectedMenu == 'Aditivos' &&
                  <View style={{ flexDirection: 'column', flex: 1 }}>
                    <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                      {
                        product.getAditives().length == 0 ?
                          <Text style={[styles.paragraph, { fontWeight: '700', color: 'black' }]}>Aditivos: <Text style={styles.paragraph}>Este producto no tiene aditivos</Text></Text>
                          :
                          product.getAditives()?.map((additive, index: number) => {
                            return <Chip key={index} key={additive} style={{ marginBottom: 5, marginRight: 5, backgroundColor: 'rgba(245, 245, 245, 1)' }}>{additive}</Chip>
                          })
                      }
                    </View>
                  </View>
                }
              </View>
              <View>
                <Divider style={styles.divider} />
                {/* SHOPPING */}
                {haveShop ?
                  <>
                    <View
                      onLayout={event => {
                        const layout = event.nativeEvent.layout;
                        setDataSourceCord(layout.y);
                      }}
                      style={{ flexDirection: 'column', flex: 2, marginBottom: 5 }}>
                      <Text style={{ fontWeight: '400', fontSize: 18, lineHeight: 21 }}>Encuentra ahora el mejor precio</Text>
                      <Text style={{ marginTop: 13, marginBottom: 5, fontSize: 14, fontFamily: theme.fonts.light.fontFamily, color: theme.colors.companyTextColor }}>El algoritmo de Sappi encuentra en tiempo real los mejores precios de producto</Text>
                    </View>
                    {
                      <ScrollView style={{ flexDirection: 'row', width: '100%', marginTop: 10, alignSelf: 'center', flexWrap: 'nowrap' }} scrollEventThrottle={1} showsHorizontalScrollIndicator={true} horizontal={true}>
                        <View style={{ flex: 1 }}>
                          <View style={{ paddingVertical: 10, paddingLeft: 5, marginHorizontal: 2, justifyContent: (suggested.productArr.length > 1 && Platform.OS != 'web') ? 'space-evenly' : 'flex-start', flexDirection: 'row', alignContent: 'flex-start' }}>
                            {
                              (product?.getShopData() && product?.getShopData().length > 0) ?
                                product?.getShopData().map((productShop: any, index: number) => (
                                  <ShopProductCard
                                    key={index} productShop={productShop} />
                                ))
                                : null
                            }
                            {
                              shopLinks ? shopLinks.map((link: string, index: number) => (
                                <Card key={index} onPress={() => Linking.openURL(link)} style={{ marginBottom: 10, marginHorizontal: 12, width: '100%', elevation: 2, maxWidth: 180, shadowRadius: 5, height: 310 }}>
                                  <Card.Cover style={{ resizeMode: 'contain', height: 150 }} source={{ uri: Settings.getImagesURL() + product.get('picture') }} />
                                  <Card.Content style={{flex:1}}>
                                    <View style={{ flexDirection: "column", flex: 1, marginTop:8}}>
                                      <Text numberOfLines={2} style={{ marginTop: 5, lineHeight: 18, fontSize: 14, fontWeight: '500', fontStyle: 'normal' }}>{capitalizeFirstLetter(product.getName())}</Text>
                                      <Text numberOfLines={1} style={{ marginBottom: 10, color: theme.colors.companyTextColor, fontSize: 14, fontWeight: '300', fontFamily: theme.fonts.light.fontFamily, flex: 1 }}>{product.getCompany()}</Text>
                                    </View>
                                    <View style={{}}>
                                      <Text numberOfLines={1} style={{ marginTop: 16, color: theme.colors.blue, fontSize: 14, fontWeight: '300', fontFamily: theme.fonts.medium.fontFamily }}>{link}</Text>
                                    </View>
                                  </Card.Content>

                                </Card>
                              ))
                                : null
                            }
                          </View>
                        </View>
                      </ScrollView>
                    }
                    <Divider style={styles.divider} />
                  </> : null}
                {/* Comentarios */}
                <View style={{ marginVertical: 15 }}>
                  <View style={{ flexDirection: 'column', marginBottom: 10, flex: 1 }}>
                    <Text style={{ fontWeight: '400', fontSize: 18, lineHeight: 21 }}>Comentarios</Text>
                  </View>
                  {
                    ((product?.props.comments != undefined) && (product?.props.comments != []) && product?.props.comments.length != 0) ?
                      <ScrollView style={{ flexDirection: 'row', width: '100%', alignSelf: 'center', flexWrap: 'nowrap' }} scrollEventThrottle={1} showsHorizontalScrollIndicator={true} horizontal={true}>
                        <View style={{ flex: 1, marginBottom: 5 }}>
                          <View style={{ paddingVertical: 7, justifyContent: (Platform.OS != 'web') ? 'space-evenly' : 'flex-start', flexDirection: 'row', alignContent: 'flex-start' }}>
                            {product?.props.comments?.sort((x, y) => { return y.timestamp - x.timestamp }).map((comment: object, index: number) => {
                              return <CommentCard key={index} setProduct={setProduct} deleteRefresh={deleteRefresh} setDeleteRefresh={setDeleteRefresh} comentario={comment} user={currentUser} product={product}></CommentCard>
                            })}
                          </View>
                        </View>
                      </ScrollView>
                      : <Text style={{ marginBottom: 30, marginTop: 20, color: theme.colors.companyTextColor }}>Aun no se han añadido comentarios</Text>
                  }
                </View>
                {
                  Platform.OS != 'web' ?
                    <CommentForm
                      setProduct={setProduct}
                      id={id} title={title}
                      setTitle={setTitle}
                      description={description}
                      setDescription={setDescription}
                      rate={rate}
                      setRate={setRate}
                      navigation={navigation}
                    /> :
                    <CommentWebForm
                      setProduct={setProduct}
                      id={id} title={title}
                      setTitle={setTitle}
                      description={description}
                      setDescription={setDescription}
                      rate={rate}
                      setRate={setRate}
                      navigation={navigation}
                    />
                }
                <Divider style={{ marginBottom: 25, marginTop: -8, backgroundColor: theme.colors.grey, opacity: 0.3 }} />
                {/* Sugeridos */}
                <View style={{ flexDirection: 'column', flex: 1, marginBottom: 5 }}>
                  <Text style={{ fontWeight: '400', fontSize: 18, lineHeight: 21 }}>Alternativas que le pueden gustar</Text>
                </View>
                <ScrollView style={{ flexDirection: 'row', width: '100%', marginTop: 10, alignSelf: 'center', flexWrap: 'nowrap' }} scrollEventThrottle={1} showsHorizontalScrollIndicator={true} horizontal={true}>
                  <View style={{ flex: 1 }}>
                    <View style={{ paddingVertical: 10, paddingLeft: 5, marginHorizontal: 2, justifyContent: (suggested.productArr.length > 1 && Platform.OS != 'web') ? 'space-evenly' : 'flex-start', flexDirection: 'row', alignContent: 'flex-start' }}>
                      {suggested.productArr.map((product: any, index: number) => {
                        return <ProductCard
                          style={{ marginHorizontal: 12 }}
                          key={product.getId()} product={product}></ProductCard>
                      })}
                    </View>
                  </View>
                </ScrollView>
                {/* <Text style={{ fontFamily: theme.fonts.light.fontFamily, color: theme.colors.grey, fontSize: 11, marginTop: 10, marginLeft: 5, paddingBottom: 50 }}>Las opciones que aparecen en esta selección son aleatorias. Sappi no recibe ningún tipo de financiación por parte de marcas de alimentación. */}
                <Text style={{ fontFamily: theme.fonts.light.fontFamily, color: theme.colors.grey, fontSize: 11, marginTop: 10, marginLeft: 5, paddingBottom: 50 }}>Sappi no recibe ningún tipo de financiación por parte de marcas de alimentación.
                  <Text style={{ color: theme.colors.primary }} onPress={onDialogSugestionPress}> Más info.</Text> </Text>
              </View>
            </ScrollView>
          </View>
        </View >
      </View >
      <NoBrandsPayDialog hideDialogSugestion={hideDialogSugestion} visibleDialogSugestion={visibleDialogSugestion} />
      <Portal>
        <Dialog style={{ paddingHorizontal: 30, paddingTop: 30, borderRadius: 40, maxWidth: 600, alignSelf: 'center' }} visible={visible} onDismiss={hideDialog}>
          {/* Procesado */}
          {procesadoVisible && <View><Text style={[styles.labelName, { marginBottom: 20, alignSelf: 'center' }]}>{product.getProduction_process() ? product.getProduction_process() == "prensado" ? "prensado en frío" : product.getProduction_process() : 'procesado'}</Text><Text style={styles.paragraph}>{procesadoText}</Text></View>}
          {/* Calidad materia prima */}
          {
            materiaPrimaVisible &&
            <View><Text style={[styles.labelName, { marginBottom: 30, alignSelf: 'center' }]}>Calidad de la materia prima</Text><Text style={styles.paragraph}>{"Cuanto más fresco y de mayor calidad sea un alimento, mayor biodisponibilidad de todos sus nutrientes, y en consecuencia menos necesidad de añadirlos de manera artificial. Así que también analizamos los aditivos que se incorporan a la fórmula, para acabar de tener una puntuación adaptada a la realidad global del alimento.\n\nSegún el Reglamento R 767/2009 y sus artículos 17.1 (e) y 16.1 (a), las materias primas deben ser nombradas en orden decreciente dentro de la composición total del alimento en cuestión. Así que las primeras posiciones dentro de la lista de ingredientes de un pienso u otros tipos de alimentación, serán las que se encuentran presentes en mayor porcentaje."}</Text></View>
          }
          <MaterialCommunityIcons
            name='help-circle'
            color='rgba(236, 18, 119, 1)'
            size={25}
            style={{ marginVertical: 20, flexDirection: 'row', justifyContent: 'flex-end', alignSelf: 'flex-end' }}
            onPress={() => { Linking.openURL('https://sappi.app/la-formula/') }}
          />
        </Dialog>
      </Portal>

    </View >
  );
};
const styles = StyleSheet.create({
  entry: {
    backgroundColor: theme.colors.background
  },
  labelName: {
    fontSize: 18,
    color: theme.colors.text,
    fontWeight: '500',
    lineHeight: 27

  },
  sublabelName: {
    color: 'rgba(159, 159, 159, 1)',
    fontSize: 14,
    lineHeight: 16,
    fontWeight: '400',
    fontFamily: theme.fonts.regular.fontFamily,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    marginBottom: 4
  },
  valueLabel: {
    fontSize: 16,
    fontWeight: "bold",
    color: theme.colors.text
  },
  sectionTitle: {
    fontSize: 15,
    fontWeight: 'bold',
    color: theme.colors.secondary
  },

  smallCaption: {
    fontSize: 12,
    color: "#999"
  },
  sectionTitleSmall: {
    fontSize: 10,
    fontWeight: 'bold'
  },
  paragraph: {
    color: theme.colors.colorParagraphItem,
    fontWeight: '500',
    fontSize: 14,
    lineHeight: 18,
  },
  divider: {
    marginVertical: 25,
    backgroundColor: theme.colors.grey,
    opacity: 0.3,
  }
});

export default ProductContent;
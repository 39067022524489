import React, { memo } from 'react';
import { Image, StyleSheet } from 'react-native';

const Logo = () => (
  <Image source={require('../assets/logos/logo-negro-color.png')} style={styles.image} />
);

const styles = StyleSheet.create({
  image: {
    width: 114,
    height: 126,
    marginBottom: 60,
    marginTop:10
  },
});

export default memo(Logo);

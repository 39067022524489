import React, { useState } from 'react';
import { StyleSheet, View, Text, } from 'react-native';
import { Button, TextInput } from 'react-native-paper';
import { theme } from '../theme';
import ApiCaller from 'baseapp/core/ApiCaller';
import { useAppStore } from 'baseapp/context/appStore';
import MaterialCommunityIcons from '@expo/vector-icons/build/MaterialCommunityIcons';
import AdminAddArticle from './AdminAddArticle';
import AdminManageArticle from './AdminManageArticle';

const AdminArticles = () => {
    const { session } = useAppStore();
    const currentUser = session.userInfo;

    const [adminProductsOption, setAdminProductsOption] = useState<string>('unselected');
    const backToMenu = () => {
        setAdminProductsOption('unselected')
    }


    return (
        <View style={{ flex: 4, backgroundColor: theme.colors.background, justifyContent: 'center' }}>
            {
                adminProductsOption == 'unselected' ?
                    <View style={{ shadowRadius: 8, shadowOpacity: 0.2, elevation: 5, backgroundColor: theme.colors.background, width: '50%', padding: 40, borderRadius: 10, alignSelf: 'center' }}>
                        <Text style={styles.textOption}>ADMINISTRAR ARTÍCULOS</Text>
                        <Button style={styles.button} labelStyle={styles.buttonLabel} uppercase={false} onPress={() => { setAdminProductsOption('addArticle') }}
                        >Añadir artículo</Button>
                        <Button style={styles.button} labelStyle={styles.buttonLabel} uppercase={false} onPress={() => { setAdminProductsOption('manageArticles') }}
                        >Gestionar artículos</Button>
                    </View>
                    : null
            }
            {
                adminProductsOption == 'addArticle' ?
                    <View style={{ flex: 1 }}>
                        <MaterialCommunityIcons
                            name={'close'}
                            color={'rgba(0,0,0,0.3)'}
                            size={25}
                            style={{ position: 'absolute', top: 20, left: 20, zIndex: 100 }}
                            onPress={() => { setAdminProductsOption('unselected') }}
                        />
                        <AdminAddArticle close={backToMenu} />
                    </View>
                    : null
            }
            {
                adminProductsOption == 'manageArticles' ?
                    <View style={{ flex: 1 }}>
                        <MaterialCommunityIcons
                            name={'close'}
                            color={'rgba(0,0,0,0.3)'}
                            size={25}
                            style={{ position: 'absolute', top: 20, left: 20, zIndex: 100 }}
                            onPress={() => { setAdminProductsOption('unselected') }}
                        />
                        <AdminManageArticle close={backToMenu} />
                    </View>
                    : null
            }
        </View>
    )
};

const styles = StyleSheet.create({
    button: {
        backgroundColor: theme.colors.primary,
        marginBottom: 20,
        width: '90%',
        alignSelf: 'center',
        height: 60,
        borderRadius: 10,
        justifyContent: 'center'
    },
    buttonLabel: {
        color: theme.colors.background,
        fontFamily: theme.fonts.regular.fontFamily,
    },
    textOption: {
        fontSize: 14,
        fontFamily: theme.fonts.regular.fontFamily,
        alignSelf: 'center',
        marginBottom: 20
    },
});

export default AdminArticles;

import React, { memo, useEffect } from "react";
import { View } from "react-native";
import { useNavigation, useRoute } from '@react-navigation/native';
import { theme } from '../theme';
import AppIntro5 from "../components/AppIntros/AppIntro5";
import AppIntroSlider from "react-native-app-intro-slider";
import AppIntro1 from "../components/AppIntros/AppIntro1";
import AppIntro2 from "../components/AppIntros/AppIntro2";
import AppIntro3 from "../components/AppIntros/AppIntro3";
import AppIntro4 from "../components/AppIntros/AppIntro4";
import AppIntro6 from "../components/AppIntros/AppIntro6";
import AppIntro7 from "../components/AppIntros/AppIntro7";
import Storage from 'baseapp/core/storage';
import { useHeaderStore } from "baseapp/context/headerContext";
import { MaterialCommunityIcons } from '@expo/vector-icons';

const TutorialScreen = () => {
    const navigation = useNavigation();
    const route = useRoute();
    const { setHeader } = useHeaderStore();

    useEffect(() => {
        setHeader("tutorial", "noHeader")
    }, [route])

    const data = [
        { title: 'Title 1' },
        { title: 'Title 2' },
        { title: 'Title 3' },
        { title: 'Title 4' },
        { title: 'Title 5' },
        { title: 'Title 6' },
        { title: 'Title 7' }
    ];

    const endTutorial = async (val: boolean) => {
        if (!val) {
            await Storage.write('tutorialDone', true);
            navigation.navigate('home');
        }
    };

    const keyExtractor = (item: any) => item.title;
    const renderItem = ({ item }: any) => {
        if (item.title == 'Title 1') { return <AppIntro1 />; }
        if (item.title == 'Title 2') { return <AppIntro7 />; }
        if (item.title == 'Title 3') { return <AppIntro2 />; }
        if (item.title == 'Title 4') { return <AppIntro3 />; }
        if (item.title == 'Title 5') { return <AppIntro4 />; }
        if (item.title == 'Title 6') { return <AppIntro5 />; }
        if (item.title == 'Title 7') { return <AppIntro6 onCheck={(value: boolean) => { endTutorial(value) }} />; }
    }
    return (
        <View style={{ flex: 1, backgroundColor:'white'}}>
            <MaterialCommunityIcons name="close" color={'rgba(0,0,0,0.8)'} style={{marginLeft:'5%', marginTop:'5%'}} size={25} onPress={()=>endTutorial(false)}/>
            <AppIntroSlider
                showNextButton={false}
                showDoneButton={false}
                dotStyle={{ backgroundColor: theme.colors.companyTextColor }}
                activeDotStyle={{ backgroundColor: theme.colors.primary }}
                keyExtractor={keyExtractor}
                renderItem={renderItem}
                data={data}
            />

        </View>
    )
}

export default memo(TutorialScreen);
import React, { memo } from 'react';
import { View } from 'react-native';
import { ProductModel } from 'systemDomain';
import { Text } from 'react-native-paper';

const SappiScore = ({ product, fontSize }: { product: ProductModel, fontSize?: number }) => {
    return (<View>

        <View style={{flex: 1, flexDirection:"row", alignItems:"center" }}>
            {
                product.getSappiScore()
                    ?<Text style={{  fontSize: 14, fontWeight: "bold" }}>{product.getSappiScore()*10}</Text>
                    :<Text style={{  fontSize: 14, fontWeight: "bold" }}>?</Text>
            }
            <Text style={{ fontSize: 12, color:'grey', fontWeight: "bold" }}>/100</Text>
        </View>
    </View>);
};

export default memo(SappiScore);

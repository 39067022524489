import * as React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { DrawerContent } from './components/drawerContent';
import StackNavigator from './components/stackNavigator';
import { Platform, View, Text } from 'react-native'
import ProfileLoader from './components/ProfileLoader';
import Conf from 'internalapp/conf';
import Screens from './core/Screens';
import { useAppStore } from './context/appStore';
import { useHeaderStore } from './context/headerContext';
import MqttConnector from './components/MqttConnector';
import SessionLoader from './components/SessionLoader';
import { Linking } from './Linking';
import Loaders from './plugins/Loaders';
const Drawer = createDrawerNavigator();

const noGlow = `
textarea, select, input {
	-webkit-appearance: none;
	outline: none!important;
}
textarea:focus, select:focus, input:focus, button:focus {
	-webkit-appearance: none;
	outline: none!important;
}

html, body {
	overflow-x: hidden;
}
`

export const injectWebCss = () => {

	// Only on web
	if (Platform.OS != 'web') return

	// Inject style
	const style = document.createElement('style')
	style.textContent = noGlow;
	return document.head.append(style)
}

// 👉 And this in the App.js file
injectWebCss()

const linking = Linking;

export default function App() {
	const { session, fontsLoaded } = useAppStore();
	const { setHeaders } = useHeaderStore();

	React.useEffect(() => {
		setHeaders(Screens.screens);
	}, []);

	if (!fontsLoaded || !session.ready) {
		return <View><SessionLoader /></View>;
	} else {
		return (
			<View style={{ flex: 1 }}>
				{Loaders}
				{Conf.mqtt ? <MqttConnector /> : null}
				<NavigationContainer linking={linking}>
					{Conf.autoload.profile && Conf.autoload.profile.enabled ? <ProfileLoader></ProfileLoader> : false}
					<Drawer.Navigator screenOptions={{ headerShown: false, gestureEnabled: Platform.OS == 'web' ? true : false, overlayColor: "0", drawerType: "slide", swipeEnabled: false, drawerPosition: Conf.menu.position as any, }}
						drawerContent={(props) => session.ready ? <DrawerContent {...props} /> : null}>
						<Drawer.Screen name="app" component={StackNavigator} />
					</Drawer.Navigator>
				</NavigationContainer>
			</View>
		);
	}
}

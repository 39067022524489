import React, { useEffect, useState } from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { Button, Dialog, Portal } from 'react-native-paper';
import { theme } from '../theme';
import {TextInput } from 'react-native-paper';

type Props = {
    visibleIngredientValue: boolean;
    hideIngredientValue: Function;
    searchQuery: string;
    ingredientsList: any[];
    setIngredientsList: Function;
};

const IngredientValueDialog = ({ visibleIngredientValue, hideIngredientValue, setIngredientsList, ingredientsList, searchQuery }: Props) => {
    const [ingredient, setIngredient] = useState<any>();
    const [text, setText ] = useState<string>(ingredient?.value.toString());

    const changeValue = (value:string)=>{
        var tmpIngredient = ingredient;
        tmpIngredient.value = parseFloat(value);
        setIngredient(tmpIngredient);
        setText(text);
    }

    const onCancel = ()=> {
        hideIngredientValue();
    }

    const onAdd = () => {
        var tmpIngredientsList = ingredientsList;
        tmpIngredientsList.push(ingredient);
        setIngredientsList(tmpIngredientsList);
        hideIngredientValue();
    }

    useEffect(() => {
        const ingredientToAdd = { name: searchQuery, value: 0 }
        setIngredient(ingredientToAdd);
    }, [searchQuery])

    return (
        <Portal >
            <Dialog onDismiss={() => hideIngredientValue()} visible={visibleIngredientValue} style={{ padding: 40, backgroundColor: 'white', alignSelf: 'center', shadowOpacity: 0, width: 450, height: 200 }}>
                <View style={{ width: '100%', alignSelf: 'center', flex: 1 }}>
                    <View style={{ flexDirection: 'column', justifyContent: 'space-between', marginBottom:20, borderRadius: 5 }}>
                        <Text style={{fontSize: 16, lineHeight: 18}}>Nombre del ingrediente: <Text style={styles.inputText}>{ingredient?.name}</Text></Text>
                    </View>
                    <View style={{ flexDirection: 'row', borderRadius: 5, alignItems:'center' }}>
                        <Text style={{fontSize: 16, lineHeight: 18}}>Valor: </Text>
                        <View>
                        <TextInput 
                            dense = {true}
                            value={text} 
                            placeholderTextColor={theme.colors.blue} 
                            style={[styles.inputNumber]} 
                            onChangeText={(text) => {changeValue(text)}} 
                        />
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-around', width: '100%', marginVertical: 20 }}>
                        <Button uppercase={false} onPress={() => onCancel()} mode='outlined' labelStyle={{ }} style={{ width: '40%' }}>Cancelar</Button>
                        <Button uppercase={false} onPress={() => onAdd()} labelStyle={{ color: theme.colors.background }} style={{ backgroundColor: theme.colors.primary, borderRadius: 5, width: '40%' }}
                        >Añadir</Button>
                    </View>
                </View>
            </Dialog>
        </Portal>
    )
};

export default IngredientValueDialog;

const styles = StyleSheet.create({
    inputText: {
        fontSize: 16,
        lineHeight: 18,
        color: theme.colors.blue,
        fontFamily: theme.fonts.regular.fontFamily
    },
    inputNumber: {
        backgroundColor: 'white',
        color: theme.colors.primary,
        fontFamily: theme.fonts.regular.fontFamily,
        width: 50,
        borderWidth: 0.5,
        borderColor: 'rgba(0,0,0,0.2)'

    }
});
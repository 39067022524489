import React, { useRef, memo } from "react";
import { View, StyleSheet } from "react-native";
import RBSheet from "react-native-raw-bottom-sheet";
import { MaterialCommunityIcons } from '@expo/vector-icons';
import Rate from '../components/Rate';
import { Title, Button, Text, TextInput } from 'react-native-paper';
import {theme} from '../theme';
import ProductApi from "../api/Product.api";
import ApiCaller from "baseapp/core/ApiCaller";
import { useAppStore } from 'baseapp/context/appStore';
import { useAnalyticsStore } from "../context/analytics";

type Props = {
    title: string;
    setTitle: Function;
    description: string;
    setDescription: Function;
    rate: number;
    setRate: Function;
    id: string;
    setProduct: Function;
    navigation: any
}
const CommentForm = ({ id, title, setTitle, description,setDescription, rate, setRate, setProduct, navigation} : Props) => {
    const { AnalyticsLogEvent } = useAnalyticsStore();
    const { profile, session } = useAppStore();
    const currentUser = session.userInfo;
    const productApi = new ProductApi(new ApiCaller(currentUser.token));
    const refRBSheet = useRef();

    const newCommentPress = ()=>{
        console.log('Current token', currentUser.token)
        if(currentUser.token){
            setTitle('');
            setDescription('');
            setRate(0);
            refRBSheet.current.open();
        }else{
            navigation.navigate('login');
        }
    }

    const onCommentPress = async () => {
        if(title == '' || description == ''){
            refRBSheet.current.close();
            return
        }

        let comment = {
            "rate": rate,
            "title":title,
            "description":description,
            "name":profile?.get('name')
        }
        
        // make post call, sending comment as a payload
        try {
            comment = await productApi.createComment(id,comment);
            try {
                AnalyticsLogEvent("Added Comment", {"user":session.userInfo.email?session.userInfo.email:"guest"})
                const productItem = await productApi.read(id);
                //Volevemos a leer el producto para que se actualizen los comentarios
                setProduct(productItem);
            }catch(e){
                console.log("Error while getting comments")
            }
            refRBSheet.current.close();
        }
        catch(e){
            console.log("Couldn't add the comment in the product with id: ", id)
        }
    }
    return (
    <View
    style={{
        flex: 1,
        paddingBottom:40
    }}
    >
    <View style={{flexDirection:'row', alignItems:'center'}}>
        <MaterialCommunityIcons 
            name={'square-edit-outline'}
            color={'#EC1277'}
            size={20}
            style={{marginRight:10}}
            onPress={()=>{newCommentPress()}}
        />
        <Text onPress={()=>{newCommentPress()}} style={{ color: '#EC1277' }}>Escribir un comentario</Text>
    </View>
    <RBSheet
        ref={refRBSheet}
        closeOnDragDown={true}
        closeOnPressMask={true}
        height={600}
        animationType={"slide"}
        openDuration={500}
        closeDuration={500}
        customStyles={{
            wrapper: {
                backgroundColor: "rgba(0,0,0,0.3)",
            },
            draggableIcon: {
                backgroundColor: "#B3B3B3"
            },
            container: {
                borderTopLeftRadius: 40,
                borderTopRightRadius: 40,
                paddingHorizontal:30
            }
        }}
    >
    {/* Menu Content */}
        <View style={{flex:6}}>
                <View style={{flexDirection:'row', alignItems:'center'}}>
                    <Title style={{marginVertical:10, marginRight:20, fontSize:16, textAlignVertical:'center'}}>Valoración</Title>
                    <Rate rate={rate} setRate={setRate} maxRate={5} rateColor='#F9A400' unrateColor='#C4C4C4' size={30}/>
                </View>
                <TextInput
                    label="Título"
                    mode='outlined'
                    returnKeyType="next"
                    onChangeText={(text: string) => setTitle(text)}
                    style={[styles.entry, {marginBottom:20}]}
                    maxLength={35}
                />
                <TextInput
                    label="Descripción"
                    returnKeyType="next"
                    mode='outlined'
                    onChangeText={(text: string) => setDescription(text)}
                    style={[styles.entry]}
                    multiline={true}
                    numberOfLines={4}
                    maxLength={120}
                />         
        </View>
        <View style={{flex:1}}>
            <Button onPress={()=>{onCommentPress()}} uppercase={false} mode='contained' style={{maxWidth:300, width:'90%', alignSelf:'center'}}>Comentar</Button>
        </View>
    </RBSheet>
</View>);
}
const styles = StyleSheet.create({
    entry: {
        backgroundColor: theme.colors.background,
        fontFamily:'Quicksand-Medium',
        fontSize: 14,
        lineHeight: 16,
        fontStyle: 'normal',
        fontWeight: '400'
    },
    drawerContent: {
        flex: 1,
    },
    userInfoSection: {
    },
    title: {
        fontWeight: '700',
        fontSize:20,
        lineHeight:28,
        marginBottom:20
    },
    caption: {
        fontSize: 14,
        lineHeight: 14,
    },
    section: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom:12
    },
    paragraph: {
        fontWeight: 'bold',
        marginRight: 3,
    },
    drawerSection: {
        marginTop: 15,
    },
    preference: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingVertical: 12,
        paddingHorizontal: 16,
    },
    });
export default memo(CommentForm);
import React, { memo } from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useAppStore } from 'baseapp/context/appStore';
import { useAnalyticsStore } from '../context/analytics';

const ErrorMessage = ({ message, submessage }: { message: string, submessage?: string }) => {

    const { session } = useAppStore();
    const { AnalyticsLogEvent } = useAnalyticsStore();

    React.useEffect(() => {
        AnalyticsLogEvent("No se encontraron resultados", { "user": session.userInfo.email ? session.userInfo.email : "guest" });
    }, []);

    return (
        <View style={{ marginTop: "20%", flex: 1, flexDirection: "column", alignContent: "center" }}>
            <Text style={{ fontSize: 20, margin: 20, fontWeight: "bold", color: '#ccc', textAlign: "center" }}>{message}</Text>
            {submessage ? <Text style={{ color: '#aaa', textAlign: "center", fontSize: 14, fontStyle: "italic" }}>{submessage}</Text> : null}
            <MaterialCommunityIcons
                name="paw"
                color="#f0f0f0"
                size={210}
                style={{ marginTop: 30, textAlign: "center" }}
            />
        </View>
    )
};

const styles = StyleSheet.create({

});

export default memo(ErrorMessage);

import ApiCaller from 'baseapp/core/ApiCaller';
import { ProductModel, ProductCollection } from 'systemDomain';
import User from 'baseapp/models/user';

const getApiCaller = (currentUser: User): ApiCaller => {
    const apiCaller = new ApiCaller(currentUser.token);
    return apiCaller
}

class ProductApi {
    constructor() { }

    static async all(currentUser: User): Promise<ProductCollection> {
        const data = await getApiCaller(currentUser).call('/v1/products', 'GET');
        return new ProductCollection(data.map((item: any) => {
            return new ProductModel(item);
        }));
    }

    static async read(currentUser: User, id: string): Promise<ProductModel> {
        const data = await getApiCaller(currentUser).call('/v1/products/' + id, 'GET');
        return new ProductModel(data);
    }

    static async create(currentUser: User, product: ProductModel): Promise<ProductModel> {
        const data = await getApiCaller(currentUser).call('/v1/products', 'POST', product.toObject());
        return new ProductModel(data);
    }

    static async update(currentUser: User, product: ProductModel): Promise<ProductModel> {
        const data = await getApiCaller(currentUser).call('/v1/products/' + product.getId(), 'POST', product.toObject());
        return new ProductModel(data);
    }

    static async delete(currentUser: User, id: string): Promise<void> {
        return await getApiCaller(currentUser).call('/v1/products/' + id + '/delete', 'GET');
    }
    static async uploadImageToServer(currentUser: any, image: any): Promise<string> {
        const paylaod: any = await getApiCaller(currentUser).call('/v1/product/image/upload', 'POST', { "image": image });
        return paylaod.filename;
    }
}

export default ProductApi;
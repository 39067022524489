import React, { memo } from 'react';
import { theme } from '../../theme';
import { IconButton } from "react-native-paper";
import { Text, View } from "react-native";

const AppIntro4 = () => {
    return (
        <View style={{ justifyContent: 'center', flex: 1, backgroundColor: theme.colors.background }}>
            <View style={{ backgroundColor: theme.colors.background, width: '90%', maxWidth: 400, height: 500, alignSelf: 'center', borderRadius: 10, padding: 20 }}>
                <IconButton style={{ backgroundColor: theme.colors.background, height: 160, width: 160, alignSelf: 'center', borderRadius: 200, justifyContent: 'center', alignItems: 'center' }}
                    icon="book-outline"
                    size={130}
                    color={theme.colors.primary}/>
                <Text style={{ fontSize: 25, fontWeight: '700', textAlign: 'center', fontFamily: "Quicksand-Bold", marginTop: 30 }}>News Feed</Text>
                <Text style={{ fontSize: 25, fontWeight: '700', textAlign: 'center', fontFamily: "Quicksand-Light", marginTop: 10 }}>En el News Feed te acercamos información importante para el bienestar de tu peludo. ¡Revísalo cada vez que entres en Sappi!</Text>

            </View>
        </View>
    )
}
export default memo(AppIntro4);

import React from "react";
import { Text } from "react-native";
import AnimalPickerStore from './components/AnimalPickerStore';
import DefaultProfile from './components/DefaultProfile';
import { theme as DefaultTheme } from 'baseapp/core/theme.tsx';

export const theme = {
  ...DefaultTheme,
  fonts: {
    regular: {fontFamily:"Quicksand-Bold"},
    medium: {fontFamily:"Quicksand-Regular"},
    light: {fontFamily:"Quicksand-Medium"},
    thin: {fontFamily:"Quicksand-Light"}
  },
  colors: {
    ...DefaultTheme.colors,
    background: 'white',
    primary: '#EC008B',
    secondary: '#F05A28',
    noSelected: '#E1E1E1',
    grey: '#BFBFBF',
    green: '#4ABF02',
    blue: '#0094FF',
    orange: '#F9A400',
    shadedBox: '#efefef',
    rateColor: '#F9A400',
    navBar: 'white',
    colorTitleSection: '#555',
    colorParagraphItem: '#777',
    companyTextColor: 'rgba(159, 159, 159, 1)',
    text: '#141c27'
    //background: "black"
  },
  gradients: {
    ...DefaultTheme.gradients,
    main: ['#F05A28', '#EC008B']
  },
  plugins: {
    ...DefaultTheme.plugins,
    auth: {
      ...DefaultTheme.plugins.auth,
      showRegisterBanner: false,
      textLoginButton: "Entrar",
      textRegisterButton: "Crear cuenta",
      textForgotPasswordLink: "Olvidaste tu contraseña?",
      registerButtonLabelColor: "rgb(223,44,92)",
      buttonContainerStyle:{ width:"70%"},

      textCreateAccountButton: "Entrar",
      registerUISlot: <>
                        <AnimalPickerStore />
                        <DefaultProfile />
                      </>,
      termsAndConditionsURL: "https://sappi.app/politica-de-privacidad/"
    }
  }
};
import React, { memo, useState } from 'react';
import { FAB } from 'react-native-paper';
import { Platform, View, SafeAreaView, FlatList, Dimensions } from 'react-native';
import EmptyMessage from '../components/EmptyMessage';
import ErrorMessage from '../components/ErrorMessage';
import Loading from '../components/Loading';
import { theme } from '../theme';
import ProductApi from '../api/Product.api';
import ApiCaller from 'baseapp/core/ApiCaller';
import Searchbar from '../components/Searchbar';
import ProductCard from '../components/ProductCard';
import { ScrollView } from 'react-native-gesture-handler';
import { useEffect } from 'react';
import FiltersList from '../components/FiltersList';
import { ProductCollection } from 'systemDomain';
import { useFocusEffect, useRoute } from '@react-navigation/native';
import { useHeaderStore } from 'baseapp/context/headerContext';
import { useAppStore } from 'baseapp/context/appStore';

const SearchTab = (props: any) => {
    const route = useRoute();
    const { setHeader } = useHeaderStore();
    const { session } = useAppStore();
    const currentUser = session.userInfo;
    const productApi = new ProductApi(new ApiCaller(currentUser.token));
    const [searchQuery, setSearchQuery] = React.useState('');
    const onChangeSearch = (query: string) => setSearchQuery(query);
    const [selectedId, setSelectedId] = useState(null);
    const [numColumns, setNumColumns] = useState(2);
    const [searchResults, setSearchResults] = useState<any[]>([]);
    const [status, setSearchStatus] = useState<string>('waiting');
    // Toggle filters buttons
    const [filters, setFilters] = useState<string[]>([]);

    const toggleFilter = async (filter: string) => {
        setSearchResults([]);
        const tmpFilters = filters.includes(filter) ? filters.filter(index => index != filter) : filters.concat(filter);
        setFilters(tmpFilters);
        if (tmpFilters.length == 0 && searchQuery.length == 0) {
            setSearchStatus('waiting')
        }
        if (tmpFilters.length == 0 && searchQuery.length != 0) {
            setSearchStatus('searching');
            try {
                await searachV2Call(tmpFilters, [], searchQuery);
            } catch (e) { }
        }
        if (tmpFilters.length != 0) {
            setSearchStatus('searching');
            try {
                await searachV2Call(tmpFilters, [], searchQuery);
            } catch (e) { }
        }
    }

    const buildSearchQueryParams = (inputFilters: string[], inputSearchQuery: string) => {
        var queryParams: string = "";
        // build button filters
        inputFilters.map((filter: string) => {
            var queryParam: string;
            queryParam = filter + '=true&';
            queryParams = queryParams + queryParam;
        });
        // Add text search param
        const textSearch: string = 's=' + inputSearchQuery;
        return queryParams + textSearch;
    }
    const searachV2Call = async (inputFilters: string[], searchResultsInput: any[], inputSearchQuery: string) => {
        const queryParams: string = buildSearchQueryParams(inputFilters, inputSearchQuery);
        const itemsToSkip: number = searchResultsInput.length;
        try {
            var result: ProductCollection = await productApi.search_V2(queryParams, itemsToSkip);
            const resultAny: any[] = result.productArr;
            if (!searchResultsInput.includes(resultAny)) {
                const finalResults: any[] = resultAny.concat(searchResultsInput);
                setSearchResults(finalResults);
                setSearchStatus('done');
            }
            if (!resultAny || !resultAny.length || resultAny.length == 0) { // set that there is any article found
                setSearchStatus('empty');
            }
        } catch (e) { }
    }

    const onClose = async () => {
        setSearchQuery('');
        if (filters.length == 0) {
            setSearchStatus('waiting');
        }
        else {
            setSearchStatus('searching')
            try{
                await searachV2Call(filters, [], '');
            }catch(e){}
        }
    }

    const onLoadMore = async () => {
        if ((searchResults.length) % 30 == 0) {
            try {
                await searachV2Call(filters, searchResults, searchQuery);
            } catch (e) { }
        }
    }

    const getNumColumns = () => {
        if (Dimensions.get('window').width > 700) {
            return 4;
        }
        else if (Dimensions.get('window').width > 550) {
            return 3
        } else {
            return 2
        }
    }

    useEffect(() => {
        const numColumnsTmp = getNumColumns();
        setNumColumns(numColumnsTmp);
    }, []);

    const onSubmit = async () => {
        setSearchResults([]);
        if (searchQuery != '') {
            setSearchStatus('searching');
            try {
                await searachV2Call(filters, [], searchQuery);
            } catch (e) { }
        }
    }

    const renderItem = ({ item }: any) => {
        return (
            <ProductCard product={item} />
        );
    }

    const footerComponent = () => {

        if (Platform.OS == 'web') {
            return (
                <FAB
                    style={{ backgroundColor: theme.colors.background, alignSelf: 'center', justifyContent: 'center' }}
                    small
                    uppercase={false}
                    icon="plus"
                    onPress={onLoadMore}
                />
            );
        }
        return null;
    }
    useFocusEffect(
        React.useCallback(() => {
            setHeader("home", 'default');
        }
            , [route])
    )

    return (
        <View style={{ flex: 1 }}>
            <View style={{ width: '85%', maxWidth: 400, backgroundColor: 'transparent', flexDirection: 'row', alignSelf: 'center' }}>
                <Searchbar searchQuery={searchQuery} placeholder={"Buscar"} onChangeSearch={onChangeSearch} onSubmit={onSubmit} onClose={onClose}></Searchbar>
            </View>
            <ScrollView style={{ flex: 12 }} contentContainerStyle={{ flex: 1 }} showsVerticalScrollIndicator={false}>
                <View style={{ justifyContent: 'center', alignSelf: 'center', maxWidth: 400 }}>
                    <View style={{ flexDirection: 'row', backgroundColor: theme.colors.background }}>
                        <FiltersList toggleFilter={toggleFilter} filters={filters} />
                    </View>
                </View>
                <View style={{ justifyContent: 'center', flexDirection: 'row', flexWrap: 'wrap', alignContent: 'center' }}>
                    {
                        status == 'waiting'
                            ? <EmptyMessage icon="magnify" message="Prueba a buscar marcas de alimento para animales, tipos de alimentos o preparaciones"></EmptyMessage>
                            : status == 'searching'
                                ? <Loading text="Buscando en sappi..."></Loading>
                                : status == 'empty'
                                    ? <ErrorMessage
                                        message="¡Ups! No se encontraron productos con estos filtros"
                                    >
                                    </ErrorMessage>
                                    : status == 'done'
                                        ?
                                        <SafeAreaView style={{ flex: 1, alignItems: 'center', alignSelf: 'center', alignContent: 'center', justifyContent: 'center', paddingBottom: Platform.OS == 'android' ? 50 : 0 }}>
                                            <FlatList
                                                data={searchResults.sort((a: any, b: any) => { return b.props.sappiScore - a.props.sappiScore; })}
                                                renderItem={renderItem}
                                                keyExtractor={(item) => item.props._id}
                                                extraData={selectedId}
                                                contentContainerStyle={{ justifyContent: 'center', alignContent: 'center', alignSelf: Platform.OS == 'web' ? 'center' : undefined, marginTop: 20, paddingBottom: Platform.OS == 'ios' ? 70 : 10 }}
                                                columnWrapperStyle={{ flex: 1, alignSelf: Platform.OS != 'web' ? 'center' : undefined }}
                                                numColumns={numColumns}
                                                onEndReached={() => { Platform.OS == 'web' ? null : onLoadMore() }}
                                                onEndReachedThreshold={4}
                                                horizontal={false}
                                                style={{ width: Dimensions.get('window').width }}
                                                ListFooterComponent={() => footerComponent()}
                                            />
                                            <View style={{ height: 100 }}></View>
                                        </SafeAreaView>
                                        : null
                    }

                </View>

            </ScrollView>
        </View>)
}

export default memo(SearchTab);
import create from "zustand";
import produce from "immer";
import User from "../models/user";
import Settings from "../core/settings";

type AppStoreData = {
    profile: any, 
    profileReady: Boolean,
    fontsLoaded: Boolean,
    setFontsLoaded: Boolean,
    setProfile: Function,
    lastMessage: {topic:string, message: any, ts: Number} | null,
    messageReceived: Function,
    session: {userInfo: User, loggedIn: boolean, ready: boolean, from?: string},
    setSession: Function,
    logout: Function,
    busChannels: string[],
    addChannel: Function
}

export const useAppStore = create<AppStoreData>((set) => ({
    profile: null,
    profileReady: false,
    lastMessage: null,
    busChannels: [],
    session: {
        loggedIn: false,
        userInfo: new User('guest', '', 'guest', 'confirmed'),
        ready: false
    },
    setProfile: (payload) => set(produce((draft:AppStoreData) => {
        draft.profile = payload;
        draft.profile.ready = true;
        draft.profileReady = true;
    })),
    fontsLoaded: null,
    setFontsLoaded: (payload) => set(produce((draft:AppStoreData) => {
        draft.fontsLoaded = payload;
    })),
    addChannel: (payload) => set(produce((draft:AppStoreData) => {
        draft.busChannels.push(payload);
    })),
    messageReceived: (payload) => set(produce((draft:AppStoreData) => {
        draft.lastMessage = payload;
    })),
    setSession: (payload:User, from?:string) => set(produce((draft:AppStoreData) => {
        draft.session = {
            userInfo: payload.type?payload:draft.session.userInfo,
            ready: true,
            loggedIn: payload.token ? true : false,
            from: from
        };
    })),
    logout: async () => {
        Settings.logout().then(() => {
            set(produce((draft:AppStoreData) => {
                let user = new User('guest', '', 'guest', 'confirmed');
                draft.session = {
                    loggedIn: false,
                    ready: true,
                    userInfo: user
                };
                draft.profile = null;
            }))
        });
    } 
}));
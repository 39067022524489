import React, { useState, useEffect, useRef } from 'react';
import { Platform } from 'react-native'
import PushNotificationApi from "../../../api/PushNotificationApi";
import * as Notifications from 'expo-notifications';
import * as Device from 'expo-device';
import { useAppStore } from '../../../context/appStore';

Notifications.setNotificationHandler({
    handleNotification: async () => ({
        shouldShowAlert: true,
        shouldPlaySound: true,
        shouldSetBadge: true,
    }),
});

const PushNotificationsLoader = () => {
    const [expoPushToken, setExpoPushToken] = useState('');
    const [notification, setNotification] = useState(false);
    const session = useAppStore(state => state.session);
    const [currentUser, setCurrentUser] = useState(session.userInfo)
    const notificationListener: any = useRef();
    const responseListener: any = useRef();

    useEffect(() => {
        setCurrentUser(session.userInfo)
    },[session]);
    
    const sendPushNotificationToken = async (currentUser, expoPushToken) => {
        try {
            await PushNotificationApi.sendToken(currentUser, expoPushToken);
        } catch (e) {
            console.error('Error enviando push notification token: ', e);
        }
    }

    async function registerForPushNotificationsAsync() {
        let token;
        if (Device.isDevice && Platform.OS != 'web') {
            const { status: existingStatus } = await Notifications.getPermissionsAsync();
            let finalStatus = existingStatus;
            if (existingStatus !== 'granted') {
                const { status } = await Notifications.requestPermissionsAsync();
                finalStatus = status;
            }
            if (finalStatus !== 'granted') {
                alert('Failed to get push token for push notification!');
                return;
            }
            token = (await Notifications.getExpoPushTokenAsync()).data;
            console.log(token);
            setExpoPushToken(token);
        } else {
            console.warn('Must use physical device for Push Notifications');
        }

        if (Platform.OS === 'android') {
            Notifications.setNotificationChannelAsync('default', {
                name: 'default',
                importance: Notifications.AndroidImportance.MAX,
                vibrationPattern: [0, 250, 250, 250],
                lightColor: '#FF231F7C',
            });
        }
    }

    useEffect(() => { // Recieve push notifications
        registerForPushNotificationsAsync(); // register to push notifications
        // This listener is fired whenever a notification is received while the app is foregrounded
        notificationListener.current = Notifications.addNotificationReceivedListener(notification => {
            setNotification(notification);
        });

        // This listener is fired whenever a user taps on or interacts with a notification (works when app is foregrounded, backgrounded, or killed)
        responseListener.current = Notifications.addNotificationResponseReceivedListener(response => {
            console.log(response);
        });

        return () => {
            Notifications.removeNotificationSubscription(notificationListener.current);
            Notifications.removeNotificationSubscription(responseListener.current);
        };
    }, []);

    useEffect(() => { 
        if (expoPushToken) {
            sendPushNotificationToken(currentUser, expoPushToken)
        }
    }, [expoPushToken, currentUser])


    return (<></>)
}
export default PushNotificationsLoader;
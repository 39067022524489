import React, { useEffect, useState } from 'react';
import { StyleSheet, View, Text, ScrollView, Platform } from 'react-native';
import { Button, Searchbar } from 'react-native-paper';
import { theme } from '../theme';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import AdditiveItem from './AdditiveItem';
import AdditivesApi from '../api/AdditivesApi';
import ApiCaller from 'baseapp/core/ApiCaller';


type Props = {
    // aditivesList: any[];
    closeAditives: Function;
    productAlreadyExist: Function;
};

const AdminProductAditive = ({ closeAditives, productAlreadyExist }: Props) => {

    const [searchQuery, setSearchQuery] = useState('');
    const [focused, setFocused] = useState<boolean>(false);
    const [additives, setAdditives] = useState<any[]>([]);

    const removeAccents = (str:string) => {
        var accents    = 'ÀÁÂÃÄÅàáâãäåßÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
        var accentsOut = "AAAAAAaaaaaaBOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz";
        var _str = str.split('');
        var strLen = _str.length;
        var i, x;
        for (i = 0; i < strLen; i++) {
          if ((x = accents.indexOf(_str[i])) != -1) {
            _str[i] = accentsOut[x];
          }
        }
        return _str.join('');
      }

    const readAdditives = async () => {
        const additivesApi = new AdditivesApi(new ApiCaller());
        const additives = await additivesApi.getAdditives();
        setAdditives(additives);
    }

    const updateAdditive = async () => {
        const additivesApi = new AdditivesApi(new ApiCaller());
        try {
            const additivesResponse = await additivesApi.updateAdditives(additives);
        }
        catch(e) {console.error('ERROR: ',e)}
        closeAditives();
    }

    const onChangeSearch = (query: string) => {
        setSearchQuery(query);
    };

    const onSearchErase = () => {
        setSearchQuery('');
    }

    const onDeleteAdditive = (additive:string) => {
        const newAdditiveList = additives.filter((addit:string) => (addit != additive));
        setAdditives(newAdditiveList);
    };

    const onAddAdditive = (searchQuery:string) => {
        additives.find((addit:string) => (removeAccents(addit.toLowerCase()) == removeAccents(searchQuery.toLowerCase()))) == undefined
        ?   (setAdditives(additives.concat(searchQuery)) , setSearchQuery(''))
        :   (setSearchQuery(searchQuery), productAlreadyExist());
    };

    useEffect(()=>{
        readAdditives();
    },[])
    return (
        <View style={{ flex: 1, justifyContent: 'center' }}>
            <View style={{ shadowRadius: 8, shadowOpacity: 0.2, elevation: 5, backgroundColor: theme.colors.background, width: '70%', height: '80%', maxHeight: 550, padding: 40, borderRadius: 10, alignSelf: 'center' }}>
                <View style={{ width: '100%', height: 50, flexDirection: 'row', alignSelf: 'center', justifyContent: 'center', marginBottom: 40 }}>
                    <Searchbar
                        placeholder="Añadir aditivo"
                        onChangeText={(text) => onChangeSearch(text)}
                        value={searchQuery}
                        inputStyle={{ fontSize: 12 }}
                        style={[styles.searchbar, { shadowOffset: { width: 0, height: 0 }, shadowRadius: 5, elevation: Platform.OS == 'android' ? 10 : 4, shadowOpacity: 0.2 }]}
                        onFocus={() => setFocused(true)}
                        onBlur={() => setFocused(false)}
                        clearIcon={() =>
                            <MaterialCommunityIcons
                                name="close"
                                size={30}
                                color={theme.colors.blue}
                                onPress={() => onSearchErase()}
                            />
                        }
                    />
                    {searchQuery != '' ?
                        <Button
                            // disabled={ingredientsList.find((index: any) => index.name == searchQuery) ? true : false}
                            uppercase={false}
                            style={[styles.addButton, { backgroundColor: theme.colors.blue }]}
                            labelStyle={styles.addLabel}
                            onPress={() => onAddAdditive(searchQuery)}
                        >
                            Añadir
                        </Button>
                        : null
                    }
                </View>
                <Text style={styles.title}>ADITIVOS</Text>
                <View style={{ flex: 10 }}>
                    <ScrollView>
                        {
                            additives.map((addit: any, index:number) => (<AdditiveItem key={index} additiveName={addit} onDeleteAdditive={onDeleteAdditive}/>))
                        }
                    </ScrollView>
                </View>
                <View style={{ width: '100%', flex: 1, flexDirection: 'row', alignItems: 'flex-end', marginTop: 20 }}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-around', width: '100%' }}>
                        <Button uppercase={false} onPress={() => closeAditives()} labelStyle={{ color: theme.colors.grey }} style={{ width: '40%' }}>Cancelar</Button>
                        <Button uppercase={false} onPress={() => updateAdditive()} labelStyle={{ color: theme.colors.background }} style={{ backgroundColor: theme.colors.primary, borderRadius: 5, width: '40%' }}
                        >Actualizar</Button>
                    </View>
                </View>
            </View>
        </View>
    )
};

const styles = StyleSheet.create({
    searchbar: {
        flex: 1,
        borderRadius: 50,
        height: 50,
        flexDirection: 'row',
        backgroundColor: 'white'
    },
    addButton: {
        marginLeft: 5,
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'flex-start',
        // backgroundColor: theme.colors.blue,
        borderRadius: 50,
        height: 48,
        paddingHorizontal: 20
    },
    addLabel: {
        color: theme.colors.background,

    },
    title: {
        fontSize: 14,
        color: theme.colors.text,
        fontFamily: theme.fonts.regular.fontFamily,
        marginBottom: 10
    }
});

export default AdminProductAditive;

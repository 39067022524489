import React, { memo } from "react";
import { View, StyleSheet } from "react-native";
import { MaterialCommunityIcons } from '@expo/vector-icons';
import Rate from '../components/Rate';
import { Title, Button, Text, TextInput, Portal, Dialog } from 'react-native-paper';
import {theme} from '../theme';
import ProductApi from "../api/Product.api";
import ApiCaller from "baseapp/core/ApiCaller";
import { useAppStore } from 'baseapp/context/appStore';

type Props = {
    title: string;
    setTitle: Function;
    description: string;
    setDescription: Function;
    rate: number;
    setRate: Function;
    id: string;
    setProduct: Function;
    navigation:any;
}
const CommentWebForm = ({ id, title, setTitle, description,setDescription, rate, setRate, setProduct, navigation} : Props) => {
    const { profile, session } = useAppStore();
    const currentUser = session.userInfo;
    const productApi = new ProductApi(new ApiCaller(currentUser.token));
    const [commentDialogVisible, setCommentDialogVisible] = React.useState<boolean>(false);

    const newCommentPress = ()=>{
        if(currentUser.token){
            setTitle('');
            setDescription('');
            setRate(0);
            setCommentDialogVisible(true);
        }else{
            navigation.navigate('login');
        }
    }

    const onCommentPress = async () => {
        if(title == '' || description == ''){
            setCommentDialogVisible(false)
            return
        }
        let comment = {
            "rate": rate,
            "title":title,
            "description":description,
            "name":profile?.get('name')
        }
        
        // make post call, sending comment as a payload
        try {
            comment = await productApi.createComment(id,comment);
            try {
                const productItem = await productApi.read(id);
                //Volevemos a leer el producto para que se actualizen los comentarios
                setProduct(productItem);
            }catch(e){
                console.log("Error while getting comments")
            }
            setCommentDialogVisible(false)
        }
        catch(e){
            console.log("Couldn't add the comment in the product with id: ", id)
        }
    }
    return (
    <View style={{flex: 1,
        paddingBottom:40
    }}>
    <View style={{flexDirection:'row', alignItems:'center', marginBottom:50}}>
        <MaterialCommunityIcons 
            name={'square-edit-outline'}
            color={'#EC1277'}
            size={20}
            style={{marginRight:10}}
            onPress={()=>{newCommentPress()}}
        />
        <Text onPress={()=>{newCommentPress()}} style={{ color: '#EC1277' }}>Escribir un comentario</Text>
    </View>
    <Portal>
        <Dialog style={{padding:20, maxWidth:600, width:'90%', alignSelf:'center'}} onDismiss={()=>{setCommentDialogVisible(false)}} visible={commentDialogVisible} >
        {/* Menu Content */}
            <View style={{flex:10}}>
                    <View style={{flexDirection:'row', alignItems:'center'}}>
                        <Title style={{marginVertical:20, marginRight:20, fontSize:16}}>Valoración</Title>
                        <Rate rate={rate} setRate={setRate} maxRate={5} rateColor='#F9A400' unrateColor='#C4C4C4' size={30}/>
                    </View>
                    <TextInput
                        label="Título"
                        mode='outlined'
                        returnKeyType="next"
                        onChangeText={(text: string) => setTitle(text)}
                        style={[styles.entry, {marginBottom:20}]}
                        maxLength={35}
                    />
                    <TextInput
                        label="Descripción"
                        returnKeyType="next"
                        mode='outlined'
                        onChangeText={(text: string) => setDescription(text)}
                        style={[styles.entry]}
                        multiline={true}
                        numberOfLines={4}
                        maxLength={120}
                    />         
            </View>
            <View>
                <Button onPress={()=>{onCommentPress()}} uppercase={false} mode='contained' style={{marginTop:20, maxWidth:300, width:'90%', alignSelf:'center'}}>Comentar</Button>
            </View>
        </Dialog>
    </Portal>
</View>);
}
const styles = StyleSheet.create({
    entry: {
        backgroundColor: theme.colors.background,
        fontFamily:'Quicksand-Medium',
        fontSize: 14,
        lineHeight: 16,
        fontStyle: 'normal',
        fontWeight: '400'
    },
    drawerContent: {
        flex: 1,
    },
    userInfoSection: {
    },
    title: {
        fontWeight: '700',
        fontSize:20,
        lineHeight:28,
        marginBottom:20
    },
    caption: {
        fontSize: 14,
        lineHeight: 14,
    },
    section: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom:12
    },
    paragraph: {
        fontWeight: 'bold',
        marginRight: 3,
    },
    drawerSection: {
        marginTop: 15,
    },
    preference: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingVertical: 12,
        paddingHorizontal: 16,
    },
    });
export default memo(CommentWebForm);